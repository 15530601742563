<template>
	<div>
		<!-- Path Detail
		<hr> -->
		<template
			v-if="loading"
		>
			Loading ...
		</template>
		<template
			v-else-if="item"
		>
			<table class="table table-bordered table-hover table-striped" style="word-break: break-word">
				<tbody>
					<tr>
						<th colspan="2" class="bg-dark text-center text-white">Fiber</th>
					</tr>
					<tr>
						<th>Cable ID</th>
						<td>
							{{item.fiber_core.fiber.cable_id}}
						</td>
					</tr>
					<tr>
						<th>Cable Meter Length</th>
						<td>
							 ({{item.fiber_core.fiber.meter_length_from}}m - {{item.fiber_core.fiber.meter_length_to}}m)
						</td>
					</tr>
					<tr>
						<th>Core Number</th>
						<td>
							{{item.fiber_core.number}}
						</td>
					</tr>
					<tr>
						<th>Core Color</th>
						<td>
							{{item.fiber_core.color}}
						</td>
					</tr>
				</tbody>
			</table>
			<hr>
			<table class="table table-bordered table-hover table-striped" style="word-break: break-word">
				<tbody>
					<tr>
						<th colspan="2" class="bg-dark text-center text-white">Connected From</th>
					</tr>
					<tr>
						<th>Type</th>
						<td>{{getTypeName(item.connected_from_type)}}</td>
					</tr>
					<tr v-if="item.connected_from[getMorphObjIndexName(item.connected_from_type)]">
						<th>Name</th>
						<td>{{item.connected_from[getMorphObjIndexName(item.connected_from_type)].name}}</td>
					</tr>
					<tr v-if="typeof item.connected_from.port_number !== 'undefined'">
						<th>Port Number</th>
						<td>{{item.connected_from.port_number}}</td>
					</tr>
					<tr v-if="typeof item.connected_from.is_uplink !== 'undefined'">
						<th>Is Uplink</th>
						<td>{{item.connected_from.is_uplink?'Yes':'No'}}</td>
					</tr>
					<tr v-if="typeof item.connected_from.meter_marking !== 'undefined'">
						<th>Meter Marking</th>
						<td>{{item.connected_from.meter_marking}}</td>
					</tr>
					<tr v-if="typeof item.connected_from.dbm !== 'undefined'">
						<th>dBM</th>
						<td>{{item.connected_from.dbm}}</td>
					</tr>
				</tbody>
			</table>
			<hr>
			<table class="table table-bordered table-hover table-striped" style="word-break: break-word">
				<tbody>
					<tr>
						<th colspan="2" class="bg-dark text-center text-white">Connected To</th>
					</tr>
					<tr>
						<th>Type</th>
						<td>{{getTypeName(item.connected_to_type)}}</td>
					</tr>
					<tr v-if="item.connected_to[getMorphObjIndexName(item.connected_to_type)]">
						<th>Name</th>
						<td>{{item.connected_to[getMorphObjIndexName(item.connected_to_type)].name}}</td>
					</tr>
					<tr v-if="typeof item.connected_to.port_number !== 'undefined'">
						<th>Port Number</th>
						<td>{{item.connected_to.port_number}}</td>
					</tr>
					<tr v-if="typeof item.connected_to.is_uplink !== 'undefined'">
						<th>Is Uplink</th>
						<td>{{item.connected_to.is_uplink?'Yes':'No'}}</td>
					</tr>
					<tr v-if="typeof item.connected_to.meter_marking !== 'undefined'">
						<th>Meter Marking</th>
						<td>{{item.connected_to.meter_marking}}</td>
					</tr>
					<tr v-if="typeof item.connected_to.dbm !== 'undefined'">
						<th>dBM</th>
						<td>{{item.connected_to.dbm}}</td>
					</tr>
				</tbody>
			</table>
		</template>
	</div>
</template>
<script>
import axios from "axios";
export default {
	name: "FiberCorePath",
	components: { },
	props: {
		"id" : Number,
	},
	data() {
	  	return {
			item: null,
			apiEndPoint: 'nm-fiber-core-map-datas',
			loading: false
	  	}
	},
	watch: {
		'id': function(newVal, oldVal){
			this.getDetail()
		}
	},
	computed: {
	},
	mounted(){
		this.getDetail()
	},
	methods: {
		getDetail() {
			this.loading = true
			axios.get(`${this.apiEndPoint}/${this.id}`).then((resp) => {
				if (resp.data.success) {
				  this.item = resp.data.data;

				} else {
				  
				}
			}).finally(()=> {
				this.loading = false
			});
		},
		getTypeName(type=null){
			let type_name = ''
			if(type){
				switch(type) {
					case 'nm_router_ports':
						type_name = 'Router';
						break;
					case 'nm_odf_ports':
						type_name = 'ODF';
						break;
					case 'nm_olt_ports':
						type_name = 'OLT';
						break;
					case 'nm_switch_ports':
						type_name = 'Switch';
						break;
					case 'nm_transmission_equipment_ports':
						type_name = 'Transmission Equipment';
						break;
					case 'nm_enclouser_fiber_core':
						type_name = 'Enclouser';
						break;
					case 'nm_fiber_core_splitter':
						type_name = 'Splitter';
						break;
					case 'nm_customer_fiber_core':
						type_name = 'Customer';
						break;

				}
			}
			return type_name;
		},
		getMorphObjIndexName(type=null){
			let index_name = ''
			if(type){
				switch(type) {
					case 'nm_router_ports':
						index_name = 'router';
						break;
					case 'nm_odf_ports':
						index_name = 'odf';
						break;
					case 'nm_olt_ports':
						index_name = 'olt';
						break;
					case 'nm_switch_ports':
						index_name = 'switch';
						break;
					case 'nm_transmission_equipment_ports':
						index_name = 'transmission_equipment';
						break;
					case 'nm_enclouser_fiber_core':
						index_name = 'enclouser';
						break;
					case 'nm_fiber_core_splitter':
						index_name = 'splitter';
						break;
					case 'nm_customer_fiber_core':
						index_name = 'customer';
						break;

				}
			}
			return index_name;
		}
	}
}
</script>