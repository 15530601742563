<template>
	<div>
		<b-modal 
			id="pop_d3" 
			size="xl" 
			hide-footer
      		@shown="initD3"
      		@hidden="modalHidden"
		>
			<template #modal-title>
		      POP Data-Driven Documents
		      <template  v-if="!loading">
		      	<button @click="toggleLayoutType" class="btn btn-sm btn-primary ml-1">{{layoutType == 'horizontal'?'Vertical':'Horizontal'}}</button>
		      </template>
		    </template>
			<template v-if="loading">
				<div style="min-height: 80vh">Loading ...</div>
			</template>
			<template
				v-else
			>
				
				<tree 
					:data="tree" 
					node-text="name" 
					:layoutType="layoutType" 
					:zoomable="true"
					:radius="5"
					linkLayout="bezier"
					:leafTextMargin="12"
					:strokeWidth="2"

					style="min-height: 80vh"
				></tree>
			</template>
		</b-modal>
	</div>
</template>


<script>

import {tree} from 'vued3tree'
import axios from "axios";
export default {
	name: "PopD3",
	props: [
		'pop_id'
	],
	components: { tree },
	emits: ['popupHidden'],
	data() {
	  return {
	      loading: true,
	      tree: {},
	      layoutType: 'horizontal'
	  }
	},
	computed: {
	  
	},
	created() {
	},
	mounted() {
		this.$bvModal.show('pop_d3')
	},
	watch: {
	   
	},
	methods: {
		initD3(){
			axios.get(`nm-d3-data/${this.pop_id}`).then((resp) => {
			    if (resp.data.success) {
			    	this.tree = resp.data.data.flare
			    } else {
			      this.$error_message(resp.data.message);
			    }
			}).finally(()=> {
			    this.loading = false;
			});
		},
		modalHidden(){
			this.$emit('popupHidden')
		},
		toggleLayoutType(){
			if(this.layoutType === 'horizontal'){
				this.layoutType = 'vertical'
			} else {
				this.layoutType = 'horizontal'
			}
		}
	},
}
</script>

<style type="text/css">
</style>