<template>
	<div
		class="customizer d-none d-md-block"
		:class="{'open': customizePopup.isOpened}"
	>	
		<!-- Header -->
		<div class="customizer-section d-flex justify-content-between align-items-center">
		  <div>
		    <h4 class="text-uppercase mb-0">{{ title }}</h4>
		    <small>{{ subtitle }}</small>
		  </div>
		  <feather-icon
		    icon="XIcon"
		    size="18"
		    class="cursor-pointer"
		    @click="closeCustomizePopup"
		  />
		</div>
		<!-- Header -->
		
		<div 
			class="customizer-section"
			v-if="customizePopup.type !== 'create'"
		>
			<button 	
				class="btn btn-sm" 
				title="Add Connector"
				@click="addConnector"
				v-if="['pops', 'fiber_core_path'].indexOf(customizePopup.entity) === -1"
				:class="(customizePopup.type === 'connector' && connectFiberCoreInfo.from.title != null)?'btn-success':'btn-outline-success'" 
			>
				<feather-icon icon="GitMergeIcon" size="16" />
			</button>
			<button 	
				class="btn btn-sm" 
				title="View" 
				@click="changeView('view')"
				:class="(customizePopup.type === 'view' && connectFiberCoreInfo.from.title == null)?'btn-secondary':'btn-outline-secondary'" 
			>
				<feather-icon icon="EyeIcon" size="16" />
			</button>
			<button 	
				class="btn btn-sm" 
				title="Edit" 
				v-if="['fiber_core_path'].indexOf(customizePopup.entity) === -1"
				@click="changeView('edit')"
				:class="(customizePopup.type === 'edit')?'btn-primary':'btn-outline-primary'" 
			>
				<feather-icon icon="EditIcon" size="16" />
			</button>
			<button 	
				class="btn btn-sm" 
				title="Delete" 
				@click="deleteItem()"
				:class="(customizePopup.type === 'delete')?'btn-danger':'btn-outline-danger'" 
			>
				<feather-icon icon="TrashIcon" size="16" />
			</button>
		</div>


		<vue-perfect-scrollbar
		  	:settings="{
				maxScrollbarLength: 60,
				wheelPropagation: false,
		    }"
		  	class="ps-customizer-area scroll-area"
		>
			<template
				v-if="customizePopup.type === 'view'"
			>
				<div class="customizer-section no-border">
	  				<MapDetailView
	  					:entity="customizePopup.entity"
						:id="customizePopup.entityDetail.id"
	  					@popupInfoChange="popupInfoChange"
	  				/>
	  			</div>
			</template>
			<template
				v-else-if="customizePopup.type === 'create'"
			>
				<div class="customizer-section no-border">
					<MapForm	
						type="create"
						:entity="customizePopup.entity"
						:lat_lon="tempLatLon"
						@submitted="entitySubmitted"
  						@popupInfoChange="popupInfoChange"
					/>
				</div>
			</template>
			<template
				v-else-if="customizePopup.type === 'edit'"
			>
				<div class="customizer-section no-border">
					<MapForm	
						type="edit"
						:entity="customizePopup.entity"
						:lat_lon="tempLatLon"
						:id="customizePopup.entityDetail.id"
						@submitted="entitySubmitted"
  						@popupInfoChange="popupInfoChange"
					/>
				</div>
			</template>
			<template
				v-else-if="customizePopup.type === 'connector'"
			>
				<div class="customizer-section no-border">
	  				<ConnectFiberCore
	  					v-if="
	  						connectFiberCoreInfo.from.title !== null ||
	  						connectFiberCoreInfo.to.title !== null
	  					" 
	  					:lat_lons="tempPath"
	  					:info="connectFiberCoreInfo"
	  					@fiber_core_connected="fiberCoreConnected"
	  					@popupInfoChange="popupInfoChange"
	  				/>
				</div>
			</template>
		</vue-perfect-scrollbar>
	</div>
</template>


<script>

import ConnectFiberCore from './connect-fiber-core';
import MapForm from './map-form';
import MapDetailView from './map-detail-view';
import axios from "axios";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
	name: "CustomizePopup",
	components: { ConnectFiberCore, MapForm, MapDetailView, VuePerfectScrollbar },
	emits: ['entityRemoved'],
	props: {
		customizePopup: Object,
		tempPath: Array,
		tempLatLon: String,
		connectFiberCoreInfo: Object,
		openCustomizePopup: Function,
		closeCustomizePopup: Function,
		fiberCoreConnected: Function,
		entitySubmitted: Function,
		addConnector: Function,
	},
	data() {
	  return {
	  	title: '',
	  	subtitle: ''
	  }
	},
	computed: {
	},
	created() {
		
	},
	watch: {
	    
	  
	},
	methods: {
		popupInfoChange({key, value}){
			if(key === 'title'){
				this.title = value
			} else if(key === 'subtitle'){
				this.subtitle = value
			}
		},
		changeView(type){
			if(['edit', 'view'].indexOf(type) > -1){
				this.openCustomizePopup(type, this.customizePopup.entity, this.customizePopup.entityDetail)
			}
		},
		deleteItem(){
			if(confirm('Are you sure to delete?')){
				this.$store.dispatch("spinnerLoading", true);
				let apiEndPoint = "";
				
				if(['customers', 'splitters', 'enclousers', 'pops', 'racks', 'routers', 'odfs', 'olts', 'fibers', 'switches'].indexOf(this.customizePopup.entity) > -1){
					apiEndPoint = "nm-" + this.customizePopup.entity
				} else if(this.customizePopup.entity === 'transmission_equipments'){
					apiEndPoint = "nm-transmission-equipments"
				} else if(this.customizePopup.entity === 'fiber_core_path'){
					apiEndPoint = "nm-fiber-core-map-datas"
				}

				axios.delete(`${apiEndPoint}/${this.customizePopup.entityDetail.id}`).then((resp) => {
				  if (resp.data.success) {
				    this.$emit('entityRemoved')
				    this.$success_message(resp.data.message);
				  } else {
				    this.$error_message(resp.data.message);
				  }
				}).finally(()=> {
				  this.$store.dispatch("spinnerLoading", false);
				});
			}
		}
	},
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
  &:not(.no-border){
  	border-bottom: 1px solid #ebe9f1;
  }

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 138px)
}
</style>
