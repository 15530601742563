<template>
    <b-modal id="modal-lg" :title="modal_title" size="lg" hide-footer>
        <div class="card shadow p-1">
            <div class="row">
                <div class="col-lg-12">
                    <div class="headline">
                        <li class="step-1 active"><span>1</span> Basic Info</li>
                        <li class="step-2"><span>2</span>Members</li>
                        <li class="step-3"><span>3</span>Preview</li>
                    </div>
                </div>
                <div class="col-lg-12 mt-4">
                    <form action="" @submit.prevent="save()">
                        <div id="step-1" class="step" style="display: block">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <b-form-group label="Group Name *" label-for="Group Name">
                                        <b-form-input id="name" v-model="form.name" />
                                    </b-form-group>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label for="type">User Type</label>
                                    <select v-model="form.user_type" id="" class="form-control">
                                        <option value="">---Select---</option>
                                        <option value="Home_User">Home User</option>
                                        <option value="Corporet_User">Corporet User</option>
                                        <option value="Dashboard_User">Dashboard User</option>
                                        <option value="Active_Customer">Active Customer</option>
                                        <option value="Inactive_Customer">Inactive Customer</option>
                                        <option value="Pending_Customer">Pending Customer</option>
                                    </select>
                                </div>
                            </div>
                            <a class="btn btn-primary" @click.prevent="next('step-1', 'step-2')">
                                Next
                            </a>
                        </div>
                        <div id="step-2" style="display: none" class="step">
                            <div class="mb-2">
                                <table class="table">
                                    <thead>
                                        <tr class="group_member--selectHeader">
                                            <th scope="row">
                                                <b-form-checkbox v-model="allcheck">
                                                </b-form-checkbox>
                                            </th>
                                            <th scope="col">
                                                Members
                                            </th>
                                            <th scope="col">
                                                Total: {{ contact_list.length }}
                                            </th>
                                            <th scope="col">
                                                Selected: {{ form.selected.length }}
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                                <div class="scrollable scrollable_group border">
                                    <table class="table">
                                        <thead>
                                        </thead>
                                        <tbody class="">
                                            <tr v-if="contact_list.length == 0">
                                                <td>
                                                    <div class="text-center">
                                                        <b-spinner style="width: 5rem; height: 5rem" label="Loading...">
                                                        </b-spinner>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-else v-for="list_item in contact_list" :key="list_item.id">
                                                <th scope="row">
                                                    <b-form-checkbox v-model="form.selected" :value="list_item.id">
                                                    </b-form-checkbox>
                                                </th>
                                                <td>{{ list_item.name }}</td>
                                                <td>{{ list_item.phone }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <a class="btn btn-success" @click.prevent="previous('step-2', 'step-1')">
                                Previous
                            </a>
                            <a class="btn btn-primary" @click.prevent="next('step-2', 'step-3')">
                                Next
                            </a>
                        </div>
                        <div id="step-3" style="display: none" class="step">
                            <table class="preview_tab m-auto">
                                <tr>
                                    <th><Strong>Group Name</Strong> </th>
                                    <th><Strong>:</Strong> </th>
                                    <td>{{ form.name }}</td>
                                </tr>
                                <tr>
                                    <th><Strong>Type</Strong> </th>
                                    <th><Strong>:</Strong> </th>
                                    <td>{{ form.user_type }}</td>
                                </tr>
                                <tr>
                                    <th><Strong>Selected Member</Strong> </th>
                                    <th><Strong>:</Strong> </th>
                                    <td>{{ form.selected.length }}</td>
                                </tr>
                            </table>
                            <div class="mt-2 text-center">
                                <a class="btn btn-lg btn-success" @click.prevent="previous('step-3', 'step-2')">
                                    Previous
                                </a>
                                <button class="btn btn-lg btn-primary" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>

import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";

import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";


export default {
    name: "smsGroup",
    components: {
        BButton,
        BModal,
        Table,
        StatisticCardHorizontal,
        BRow,
        BCol,
        BTable,
        BCardCode,
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
    },
    data() {
        return {
            contact_list: [],
            modal_title: "Create Sms Group",
            allcheck: false,
            form: new Form({
                name: "Home_User",
                selected: [],
                user_type: "Dashboard_User",
            }),
            title: "SendSms",
        };
    },

    watch: {
        allcheck(value) {
            if (value == false) {
                this.form.selected = [];
            } else {
                const arraydata = this.contact_list;
                for (const i in arraydata) {
                    this.form.selected.push(arraydata[i].id);
                }
            }
        },

    },
    methods: {
        save() {
            if (this.form.selected.length == 0) return this.$error_message("Please Select Group Members");
            this.form
                .post("sms-group")
                .then((resp) => {
                    if (resp.data.success) {
                        this.$store.dispatch('sms_group_action')
                        this.$s_tost(resp.data.message);
                        this.close()
                    } else {
                        this.$error_message(resp.data.message, resp.data.errors);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        close() {
            this.$bvModal.hide("modal-lg");
        },
        next(current, next) {
            const currentElement = document.getElementById(current);
            const nextElement = document.getElementById(next);
            if (this.$validation(current) === true) {
                currentElement.style.display = "none";
                nextElement.style.display = "block";
                document.getElementsByClassName(next)[0].classList.add("active");
            }

            if (next == 'step-2') {
                this.$store.dispatch("spinnerLoading", true);
                this.form
                    .post("fatch-user-by-type")
                    .then((resp) => {
                        this.$store.dispatch("spinnerLoading", false);
                        if (resp.data.success) {
                            this.contact_list = resp.data.data
                        } else {
                            this.$error_message(resp.data.message, resp.data.errors);
                        }
                    })
                    .catch((e) => {
                        this.$store.dispatch("spinnerLoading", false);
                        console.log(e);
                    });
            }
        },
        previous(current, previous) {
            const currentElement = document.getElementById(current);
            const previousElement = document.getElementById(previous);
            currentElement.style.display = "none";
            previousElement.style.display = "block";
        },
    }
}
</script>
<style>
.preview_tab tr {
    border: 1px solid;
}

.preview_tab tr th {
    padding: 7px 18px;
}

.preview_tab tr td {
    padding: 7px 18px;
}

.group_member--selectHeader th {
    text-align: center;
}
</style>