<template>
  <div class="bg-white shadow p-2">
    <h2 class="text-center" v-if="permissionError !== null">
      {{ permissionError }}
    </h2>
    <div v-else class="row">
      <div class="col-lg-12 mb-1">
        <b-button
          v-for="(pmsList, index) in getRoleWisePermissions"
          :key="index"
          v-show="pmsList.name == 'Create Box'"
          v-b-modal.modal-lg
          variant="primary"
          size="sm"
          @click.prevent="editData.mode = false"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />
          Add New</b-button
        >
      </div>
      <b-modal id="modal-lg" :title="modal_title" size="lg">
        <form id="form" @submit.prevent="submitHandelar">
          <div class="form-group">
            <label for="">Box Number</label>
            <input
              v-model="max_number"
              type="text"
              class="form-control"
              placeholder="Box Number"
              validate
              readonly
            />
          </div>
          <div class="form-group">
            <label for="">Location</label>
            <input
              v-model="form.location"
              type="text"
              class="form-control"
              placeholder="Location"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Onu</label>
            <input
              v-model="form.onu"
              type="text"
              class="form-control"
              placeholder="ONU"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Switch</label>
            <input
              v-model="form.switch"
              type="text"
              class="form-control"
              placeholder="Switch"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Zone</label>
            <select
              v-if="zone && zone.length > 0"
              v-model="form.zone"
              name="zone"
              class="form-control"
              validate
            >
              <option value="" disabled>
                --------Please Select One ---------
              </option>
              <option v-for="(zon, idx) in zone" :key="idx" :value="zon.id">
                {{ zon.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Status</label>
            <select id="" v-model="form.status" name="" class="form-control">
              <option value="1">Active</option>
              <option value="0">De-Active</option>
            </select>
          </div>

          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Box Number</th>
              <th scope="col">Location</th>
              <th scope="col">ONU</th>
              <th scope="col">Switch</th>
              <th scope="col">Zone</th>
              <th
                v-for="(pmsList, index) in getRoleWisePermissions"
                :key="index"
                v-show="pmsList.name == 'Change Box Status'"
                scope="col"
              >
                Status
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.box_number }}</td>
              <td>{{ item.location }}</td>
              <td>{{ item.onu }}</td>
              <td>{{ item.switch }}</td>
              <td>{{ item.zone ? item.zone.name : "" }}</td>
              <td
                v-for="(pmsList, index) in getRoleWisePermissions"
                :key="index"
                v-show="pmsList.name == 'Change Box Status'"
              >
                <label :for="'switch' + item.id" class="__switch">
                  <input
                    :id="'switch' + item.id"
                    type="checkbox"
                    :action="'box/change/status/' + item.id"
                    :checked="item.status == 1"
                    @click="$changeStatus($event)"
                  />
                  <span class="__slider" />
                </label>
              </td>

              <td>
                <button
                  v-for="(pmsList, index) in getRoleWisePermissions"
                  :key="index"
                  v-show="pmsList.name == 'Edit Box'"
                  class="btn btn-sm btn-success"
                  @click.prevent="edit(item.id)"
                >
                  Edit
                </button>
                <button
                  v-for="(pmsList, index) in getRoleWisePermissions"
                  :key="index"
                  v-show="pmsList.name == 'Delete Box'"
                  class="btn btn-sm btn-danger"
                  :action="'box/' + item.id"
                  @click.prevent="$trash($event)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :data="items"
          align="right"
          @pagination-change-page="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars

import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  name: "OLT",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: new Form({
        location: "",
        onu: "",
        switch: "",
        status: "1",
        zone: "",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      modal_title: "Monitor Network",
      items: {},
      max_number: "",
      permissionError: "",
    };
  },
  computed: {
    zone() {
      return this.$store.state.isp.others.zone;
    },
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },

  created() {
    this.getData();
    this.$store.dispatch("others", {
      zone: true,
    });
  },
  methods: {
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      if (this.$validation("form") === true) {
        this.form
          .post("box")
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    //= ========get data for edit=========

    update() {
      if (this.$validation("form") === true) {
        this.form
          .put(`box/${this.editData.id}`)
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    //= ========get data for edit=========

    edit(id) {
      const item = this.items.data.find((ele) => ele.id === id);
      this.modal_title = `Update Box:${item.box_number}`;
      this.max_number = item.box_number;
      this.form.location = item.location;
      this.form.onu = item.onu;
      this.form.switch = item.switch;
      this.form.zone = item.zone_id;
      this.editData.mode = true;
      this.editData.id = item.id;
      this.$bvModal.show("modal-lg");
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios
        .get(`box?page=?${page}`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {
            this.items = resp.data.data.box;
            // let box_number = 1000 + parseFloat(resp.data.data.box_number)
            this.max_number = `BX-${
              parseFloat(1000) + parseFloat(resp.data.data.box_number)
            }`;
          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
