<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total_user"
          statistic-title="Total Pending User"
        />
      </b-col>
      <b-col lg="6" sm="6">
        <statistic-card-horizontal
          icon="MoneyIcon"
          color="danger"
          :statistic="summary.total_due"
          statistic-title="Total Due"
        />
      </b-col>
    </b-row>
    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button
              size="sm"
              col="2"
              class="ml-2"
              variant="primary"
              @click="$bvModal.show('customer_import')"
            >
              <feather-icon icon="UploadCloudIcon" size="16" />
              Import Customers
            </b-button>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'EditImportUser',
                params: {
                  id: data.item.id,
                  for: 'import-customer',
                },
              }"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
    <CustomerImport />
  </div>
</template>
<script>
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { statusAction } from "@/utils/helpers/functions";
import Form from "vform";
import CustomerImport from "./CustomerImport.vue";

export default {
  name: "ImportsCustomers",
  components: {
    CustomerImport,
    BCardCode,
    StatisticCardHorizontal,
  },

  data() {
    return {
      title: "Imports Customers",
      statusAction,
      fields: [
        { key: "id", label: "ID" },
        { key: "Name" },
        { key: "Mobile" },
        { key: "Email" },
        { key: "Nationalid" },
        { key: "Address" },
        { key: "Zone" },
        { key: "Date_of_birth" },
        { key: "Connctinon_type" },
        { key: "Payment_type" },
        { key: "Billing_status" },
        { key: "Customer_type" },
        { key: "Package_id" },
        { key: "Package_price" },
        { key: "Package_discount" },
        { key: "Monthly_bill" },
        { key: "connection_date" },
        { key: "Expire_date" },
        { key: "action" },
      ],
      loading: true,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      items: [],

      // listModalData: null,
      listModalData: {},
      modal_title: "",
      user: {},
      template: {},
      summary: {
        total_user: 0,
        total_due: 0,
      },
      mikrotik_data: [
        { value: null, text: "Please select an option" },
        { value: "1", text: "NAS" },
        { value: "1", text: "Radius" },
      ],
      form: new Form({
        user_id: "",
        mikrotik: "",
        connection_type: "",
        user_name: "",
        user_password: "",
        service: "PPPoE",
        remote_address: "",
        mac_address: "",
        remote_ip: "",
        router_comments: "",
        profile: "",
        mobile: "",
        zone: "",
        connection_date: "",
        package: "",
      }),
      // queue_types: []
      copyFor: "",
      payment_info: {
        user_id: null,
        amount: null,
        modal_title: "Make Payment",
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },

  created() {
    this.getData();
    this.getSummaryData();
  },
  methods: {
    submitConnectionForm() {
      this.form.post("set-mikrotik-connection").then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.$bvModal.hide("set_connection");
          location.reload();
          // this.summary.total_user = resp.data.data.total_user;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData(page = 1) {
      axios.get(`customerimport?page=${page}`).then((resp) => {
        this.loading = false;
        if (resp.data.success) {
          this.items = resp.data.data.data.data;
          this.totalRows = resp.data.data.length;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getSummaryData() {
      axios.get(`customer-summary?status=0`).then((resp) => {
        this.loading = false;
        if (resp.data.success) {
          this.summary.total_user = resp.data.data.total_user;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
  },
};
</script>
