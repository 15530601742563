<template>
  <div>
    <div>

      <b-modal id="create_form" title="Create Inventory Supplier"  hide-footer>
        <form @submit.prevent="createSupplier">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Address</label>
                <textarea
                  v-model="form.address"
                  class="form-control"
                  name="address"
                  placeholder="..."
                ></textarea>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Email</label>
                <input
                  v-model="form.email_address"
                  type="email"
                  class="form-control"
                  name="email_address"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">
                  Phone 
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="cursor-pointer text-success"
                    @click="form.mobile_number.push('')"
                  />
                </label>
                <div
                  v-for="(mobile, index) in form.mobile_number"
                  class="d-flex"
                  :class="(index != form.mobile_number.length-1)?'mb-1':''"
                >
                  <div class="flex-fill position-relative">
                    <input
                      v-model="form.mobile_number[index]"
                      type="number"
                      class="form-control"
                      name="mobile_number[]"
                      :placeholder="`${(index == 0)?'Primary':(index == 1)?'Secondary':'Alternate'} Number`"
                    />
                    <span 
                      class="position-absolute" 
                      style="bottom: 5px;right: 5px;font-size: 10px;"
                      :class="(form.mobile_number[index].length < 11)?'text-primary':(form.mobile_number[index].length == 11)?'text-success':'text-danger'"
                    >
                      {{ form.mobile_number[index].length }}
                    </span>
                  </div>
                  
                  <button 
                    v-if="index != 0"
                    class="btn btn-sm text-danger"
                    type="button"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="cursor-pointer"
                      @click="form.mobile_number.splice(index, 1)"
                    />
                  </button>
                
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Status</label>
                <select
                  v-model="form.status"
                  class="form-control"
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Submitting':'Submit'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal id="update_form" title="Update Inventory Supplier"  hide-footer>
        <form @submit.prevent="updateSupplier">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Address</label>
                <textarea
                  v-model="form.address"
                  class="form-control"
                  name="address"
                  placeholder="..."
                ></textarea>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Email</label>
                <input
                  v-model="form.email_address"
                  type="email"
                  class="form-control"
                  name="email_address"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">
                  Phone 
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="cursor-pointer text-success"
                    @click="form.mobile_number.push('')"
                  />
                </label>
                <div
                  v-for="(mobile, index) in form.mobile_number"
                  class="d-flex"
                  :class="(index != form.mobile_number.length-1)?'mb-1':''"
                >
                  <div class="flex-fill position-relative">
                    <input
                      v-model="form.mobile_number[index]"
                      type="number"
                      class="form-control"
                      name="mobile_number[]"
                      :placeholder="`${(index == 0)?'Primary':(index == 1)?'Secondary':'Alternate'} Number`"
                    />
                    <span 
                      class="position-absolute" 
                      style="bottom: 5px;right: 5px;font-size: 10px;"
                      :class="(form.mobile_number[index].length < 11)?'text-primary':(form.mobile_number[index].length == 11)?'text-success':'text-danger'"
                    >
                      {{ form.mobile_number[index].length }}
                    </span>
                  </div>
                  
                  <button 
                    v-if="index != 0"
                    class="btn btn-sm text-danger"
                    type="button"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="cursor-pointer"
                      @click="form.mobile_number.splice(index, 1)"
                    />
                  </button>
                
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Status</label>
                <select
                  v-model="form.status"
                  class="form-control"
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Updating':'Update'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-card-code :title="title" no-body>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex">
              <b-button
                size="sm"
                col="2"
                v-b-modal.modal-lg
                variant="primary"
                @click="openCreateForm"
              >
                <!-- @click.prevent="editData.mode = false" -->
                <feather-icon icon="PlusCircleIcon" size="16" />
                Add New</b-button
              >
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0 ml-3"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive 
          class="position-relative"
          ref="table"
          :per-page="perPage"
          
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <template #cell(mobile_number)="{item, index}">
            <div 
              class="text-nowrap" 
              v-for="( number , index) in item.mobile_number"
            >
              {{ item.mobile_number_prefix +' '+ number}} 
              <span 
                class="text-primary" 
                v-if="index == 0"
                >
                (P)
              </span>
            </div>
          </template>

          <template #cell(status)="{item, index}">
            <b-badge
              pill
              :variant="`light-${item.status?'success':'danger'}`"
              class="text-capitalize"
            >
              {{item.status ? 'Active' : 'Inactive'}}
            </b-badge>
          </template>
          <template #cell(action)="{item, index}">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              
              <router-link :to="{ name: 'inventory_supplier_detail', params: { id: item.id } }" class="dropdown-item" >
                <feather-icon icon="LinkIcon" class="mr-50" />
                View
              </router-link>
              <b-dropdown-item @click.prevent="editSupplier(item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteSupplier(item, index)">
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNoOfRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "Suppliers",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      title: "Inventory Suppliers",
      form: new Form({
        name: "",
        address: "",
        email_address: "",
        mobile_number: [""],
        mobile_number_prefix: '+88',
        status: 1,
      }),
      fields: [
        
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        // { key: "address", label: "Address", sortable: true },
        { key: "mobile_number", label: "Phone", sortable: true },
        { key: "email_address", label: "Email", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  created() {
    this.getData();
  },
  watch: {
    currentPage: {
       handler: function(newVal, oldVal) {
           this.getData(newVal); // call it in the context of your component object
       },
       deep: true
    }
  },
  methods: {

    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`inventory-suppliers?page=${page}&item=${this.perPage}`).then((resp) => {
        if (resp.data.success) {
          this.totalNoOfRows = resp.data.data.total
          this.items = resp.data.data.data;

        } else {
          this.$error_message(resp.data.message);
        }
      }).finally(()=> {
        this.$store.dispatch("spinnerLoading", false);
      });
    },

    createSupplier() {
      this.form.post("inventory-suppliers").then((resp) => {
        
        if (resp.data.success) {
          let item = resp.data.data
          this.items = [ ...this.items, item ]

          this.$success_message(resp.data.message);
          this.form.reset()
          this.$bvModal.hide('create_form')
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    viewSupplier(item){
      this.$router.push(item.id)
    },
    editSupplier(item){
      this.form.reset()
      this.form.id = item.id
      this.form.name = item.name
      this.form.address = item.address
      this.form.email_address = item.email_address
      this.form.mobile_number = item.mobile_number
      this.form.mobile_number_prefix = item.mobile_number_prefix
      this.form.status = item.status
      this.$bvModal.show('update_form')
    },
    openCreateForm(){
      this.form.reset()
      this.$bvModal.show('create_form')
    },
    updateSupplier(){
      this.form.put(`inventory-suppliers/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.items.every((item, index) => {
            if(item.id == updatedItem.id){
              this.$set(this.items, index, updatedItem)
              return false;
            }
            return true;
          })
          this.form.reset()
          this.$success_message(resp.data.message);
          this.$bvModal.hide('update_form')
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    deleteSupplier(item, index){
      if(item && confirm('Are you sure to delete?')){
        this.$store.dispatch("spinnerLoading", true);
        axios.delete(`inventory-suppliers/${item.id}`).then((resp) => {
          if (resp.data.success) {
            this.items.splice(index, 1)
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message);
          }
        }).finally(()=> {
          this.$store.dispatch("spinnerLoading", false);
        });
      }
    },

    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
