import auth from "@/middleware/auth";
import Mikrotik from "../views/Mikrotik/Index.vue";
import MikrotikAdd from "../views/Mikrotik/Add.vue";
import MikrotikUser from "../views/Mikrotik/MikrotikUser.vue";
import AllMikrotikUsers from "../views/Mikrotik/AllMikrotikUsers.vue";
import MikrotikEdit from "../views/Mikrotik/Edit.vue";
import ppoe_active_user from "../views/Ippool/ppoe_active_user.vue";
import MikrotikIpPool from "../views/Mikrotik/MikrotikIpPool.vue";
import Queue from "../views/Queue/Index.vue";
import QueueType from "../views/Queue/QueueType.vue";
import Arp from "../views/Interface/Arp.vue";
import Addresses from "../views/Interface/Addresses.vue";
import Ethernet from "../views/Interface/Ethernet.vue";
import Interfaces from "../views/Interface/index.vue";
export default [
  {
    path: "/mikrotik",
    name: "mikrotik",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Mikrotik,
    meta: {
      pageTitle: "View Mikrotik",
      middleware: [auth],
      breadcrumb: [
        {
          text: "View Mikrotik",
          active: true,
        },
      ],
    },
  },
  {
    path: "/addMikrotik",
    name: "addMikrotik",
    component: MikrotikAdd,
    meta: {
      pageTitle: "Add Mikrotik",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Add Mikrotik",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit/addMikrotik/:id",
    name: "editmikrotik",
    component: MikrotikEdit,
    meta: {
      pageTitle: "Eidt Mikrotik",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Edit Mikrotik",
          active: true,
        },
      ],
    },
  },
  {
    path: "/PPoE-active-user",
    name: "PPoE-active-user",
    component: ppoe_active_user,
    meta: {
      pageTitle: "PPoE Active User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "PPoE Active User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mikrotik-ip-pool",
    name: "mikrotik-ip-pool",
    component: MikrotikIpPool,
    meta: {
      pageTitle: "MikroTik Ip Pool",
      middleware: [auth],
      breadcrumb: [
        {
          text: "MikroTik Ip Pool",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mikrotik-users",
    name: "mikrotik_user",
    component: MikrotikUser,
    meta: {
      pageTitle: "MikroTik users",
      middleware: [auth],
      breadcrumb: [
        {
          text: "MikroTik users",
          active: true,
        },
      ],
    },
  },
  {
    path: "/all-mikrotik-users",
    name: "AllMikrotikUsers",
    component: AllMikrotikUsers,
    meta: {
      pageTitle: "MikroTik users",
      middleware: [auth],
      breadcrumb: [
        {
          text: "MikroTik users",
          active: true,
        },
      ],
    },
  },
  {
    path: "/queue",
    name: "queue",
    component: Queue,
    meta: {
      pageTitle: "Queue",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Queue",
          active: true,
        },
      ],
    },
  },
  {
    path: "/queue-type",
    name: "queue-type",
    component: QueueType,
    meta: {
      pageTitle: "Queue Type",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Queue Type",
          active: true,
        },
      ],
    },
  },
  {
    path: "/interfaces",
    name: "interfaces",
    component: Interfaces,
    meta: {
      pageTitle: "Interfaces",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Interfaces",
          active: true,
        },
      ],
    },
  },
  {
    path: "/interfaces/arp",
    name: "interfaces.arp",
    component: Arp,
    meta: {
      pageTitle: "interfaces-arp",
      middleware: [auth],
      breadcrumb: [
        {
          text: "ARP",
          active: true,
        },
      ],
    },
  },
  {
    path: "/interfaces/addresses",
    name: "interfaces.addresses",
    component: Addresses,
    meta: {
      pageTitle: "Addresses",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Addresses",
          active: true,
        },
      ],
    },
  },
  {
    path: "/interfaces/ethernet",
    name: "interfaces.ethernet",
    component: Ethernet,
    meta: {
      pageTitle: "Ethernet",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Ethernet",
          active: true,
        },
      ],
    },
  },
];
