import auth from '@/middleware/auth'
import BillCollection from '../views/Accounts/BillCollection.vue'
import DailyExpense from '../views/Accounts/DailyExpense.vue'
import MonthlyExpense from '../views/Accounts/MonthlyExpense.vue'
import DailyIncome from '../views/Accounts/DailyIncome.vue'
import MonthlyIncome from '../views/Accounts/MonthlyIncome.vue'
import DailyAcountClose from '../views/Accounts/DailyAcountClose.vue'
import ProfiteAndLoss from '../views/Accounts/ProfiteAndLoss.vue'
import BillCollectionCategory from '../views/Accounts/Category/BillCollectionCategory.vue'
export default [
    {
        path: '/bill-collection',
        name: 'bill-collection',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: BillCollection,
        meta: {
            pageTitle: 'Bill Collection',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'Bill Collection',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/bill-collection-category',
        name: 'b-c-category',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: BillCollectionCategory,
        meta: {
            pageTitle: 'Bill Collection Category',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'Bill Collection Category',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/daily-income',
        name: 'daily-income',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: DailyIncome,
        meta: {
            pageTitle: 'Daily Income',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'Daily Iincome',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/daily-expense',
        name: 'daily-expense',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: DailyExpense,
        meta: {
            pageTitle: 'Daily Expense',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'Daily Expense',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/monthly-income',
        name: 'monthly-income',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: MonthlyIncome,
        meta: {
            pageTitle: 'monthly income',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'monthly income',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/monthly-expense',
        name: 'monthly-expense',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: MonthlyExpense,
        meta: {
            pageTitle: 'monthly Expense',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'monthly Expense',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/profit-loss',
        name: 'profit-loss',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: ProfiteAndLoss,
        meta: {
            pageTitle: 'Profit & Loss',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'Profit & Loss',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/daily-acount-close',
        name: 'daily-acount-close',
        // eslint-disable-next-line import/no-unresolved,import/extensions
        component: DailyAcountClose,
        meta: {
            pageTitle: 'Daily Acount Close',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'DailyAcountClose',
                    active: true,
                },
            ],
        },
    },

]
