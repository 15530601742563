import auth from "@/middleware/auth";
import BtrcReport from "../views/Reports/BtrcReport.vue";

export default [
  {
    path: "/btrc-report",
    name: "BtrcReport",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: BtrcReport,
    meta: {
      pageTitle: "BTRC Report",
      middleware: [auth],
      breadcrumb: [
        {
          text: "BTRC Report",
          active: true,
        },
      ],
    },
  },
];
