<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4">
        <statistic-card-horizontal icon="UsersIcon" :statistic="items.length" statistic-title="Total Nas" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal icon="UsersIcon" :statistic="summary.total_api"
          statistic-title="Total API (MikroTik)" />
      </b-col>
    </b-row>
    <b-card-code :title="title" no-body>
      <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
        <template #cell(mikrotik)="mikrotikItem">
          <span v-if="mikrotikItem.item.mikrotik !== null">
            {{ mikrotikItem.item.mikrotik.identity }}
          </span>
        </template>
        <template #cell(status)="data">
          <b-form-checkbox v-for="(pmsList, index) in getRoleWisePermissions" :key="index"
            v-show="pmsList.name == 'Nas Change Status'" v-model="data.item.status == 1" name="check-button" switch
            @change="changeStatus(data.item.id)"></b-form-checkbox>
        </template>

        <template #cell(action)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item v-for="(pmsList, index) in getRoleWisePermissions" :key="index"
              v-show="pmsList.name == 'Nas Edit'">
              <router-link :to="{ name: 'editNas', params: { id: data.item.id } }">
                <feather-icon icon="CopyIcon" class="mr-50" />
                <span>PPP Authentication Setting</span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item v-for="(pmsList, index) in getRoleWisePermissions" :key="index"
              v-show="pmsList.name == 'Nas Edit'">
              <router-link :to="{ name: 'editNas', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>Edit</span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item v-for="(pmsList, index) in getRoleWisePermissions" :key="index"
              v-show="pmsList.name == 'Nas Delete'" v-b-modal="'radius_' + data.item.id">
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(date)="date">{{ formatDateTime(date.item.issue_date) }}
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import Table from "@/components/table.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { BButton, BCol, BModal, BRow } from "bootstrap-vue";

export default {
  name: "Nas",
  components: {
    BCardCode,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BButton,
    BModal,
  },
  data() {
    return {
      title: "Nas List",
      form: new Form({
        name: "",
      }),
      fields: [
        { key: "id", label: "#" },
        { key: "shortname", label: "Name" },
        { key: "nasname", label: "Nas ip Address" },
        { key: "type", label: "Nas Type" },
        { key: "secret", label: "Nas Secret", sortable: true },
        { key: "ports", label: "NAS Port" },
        { key: "incoming_port", label: "incoming Port" },
        { key: "community", label: "Community" },
        { key: "description", label: "Description" },
        "mikrotik",
        "status",
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Create Data",
      summary: {
        total_nas: 0,
        total_api: 0,
      },
    };
  },

  computed: {
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },
  created() {
    this.getResults();
  },
  methods: {
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("district")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.items.push(resp.data.data);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`district/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.$store.dispatch("districts");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(id) {
      const item = this.items.find((ele) => ele.id === id);
      this.modal_title = `Update-${item.name}`;

      this.form.name = item.name;
      this.editData.mode = true;
      this.editData.id = item.id;
      this.$bvModal.show("modal-lg");
    },
    async getResults(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      const resp = await this.axios.get(`nas?page=${page}`);
      this.$store.dispatch("spinnerLoading", false);
      if (resp.data.success) {
        this.items = resp.data.data.data;
        this.summary.total_api = this.items.filter(
          (item) => item.is_mikrotik == 1
        ).length;
      } else {
        this.$error_message(resp.data.message);
      }
    },

    async changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      await this.axios.get(`nas/status/${id}`).then((resp) => {
        if (resp.data.success) {
          console.log(resp);
          this.$success_message(resp.data.message);
          this.getResults();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
