<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal icon="UsersIcon" :statistic="items.length" statistic-title="Total IP Pool" />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal icon="UserCheckIcon" color="success" :statistic="summary.total_active"
          statistic-title="Active" />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal icon="UserIcon" color="danger" :statistic="summary.total_inactive"
          statistic-title="In Active" />
      </b-col>
    </b-row>
    <b-modal id="modal-lg" :title="modal_title" size="lg" hide-header-close hide-footer>
      <form @submit.prevent="submitHandelar">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Type</label>
              <select v-model="form.type" class="form-control" validate name="type">
                <option value="">----Please Select One--</option>
                <option value="Hotspot">Hotspot</option>
                <option value="PPPOE">PPPOE</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6">


            <b-form-group label="Nas Type:" label-for="Nas Type">
              <div class="d-flex">
                <b-form-radio @change="selectMikrotik()" v-model="form.nas_type" name="MikroTik" value="MikroTik">
                  MikroTik
                </b-form-radio>
                <b-form-radio @change="selectMikrotik()" class="mx-2" v-model="form.nas_type" name="Radius"
                  value="Radius">
                  Radius
                </b-form-radio>
              </div>
            </b-form-group>
          </div>
          <div v-if="mikrotik_select" :class="fetch_api ? 'col-lg-5' : 'col-lg-6'">
            <div class="form-group">
              <label for="">Mikrotik</label>
              <v-select v-model="selected_mikrotik" :options="select_options_mikrotik" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Name</label>
              <input v-model="form.name" type="text" class="form-control" placeholder="Name" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Subnet</label>
              <input v-model="form.subnet" type="text" class="form-control" placeholder="Ex: 255.255.255.0" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Start Ip</label>
              <input v-model="form.start_ip" type="text" class="form-control" placeholder="Ex: 192.168.0.1"
                name="start ip" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">End Ip</label>
              <input v-model="form.end_ip" type="text" class="form-control" placeholder="End Ip" name="end ip" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Total Number Of IP</label>
              <input v-model="form.total_number_of_ip" type="text" class="form-control" placeholder="Total Number Of Ip"
                name="total_number_of_ip" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Zone</label>
              <v-select v-model="form.zone" :options="select_options_zone" multiple />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Assign Franchise</label>
              <v-select v-model="form.franchise" :options="select_options_franchise" multiple />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Assign Pop</label>
              <v-select v-model="form.pop" :options="select_options_pop" multiple />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <div class="d-flex">
                <strong class="mr-1" for="">Public IP</strong>
                <label class="mr-1" for="Chargeable">Chargeable</label>
                <b-form-checkbox v-model="checked" @change="isrealip" name="check-button" switch></b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="form.real_ip == 'yes'">
            <div class="form-group">
              <label for="">Public IP Charge</label>
              <input type="text" class="form-control" placeholder="Public IP Charge" name="" id="" />
            </div>
          </div>
        </div>
      </form>
      <button class="btn btn-primary" @click="submitHandelar" :disabled="form.busy">
        Submit
      </button>
      <button @click="close" class="btn btn-warning">Close</button>
    </b-modal>

    <b-card-code no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button v-if="permission.add" size="sm" col="2" variant="primary" @click.prevent="openModel('add')">
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button>
            <!-- sorting  -->
            <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="3" label-for="sortBySelect"
              class="mb-0 ml-3">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" label-cols-sm="4" :disabled="!sortBy">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(status)="data">
          <b-form-checkbox v-if="permission.status" v-model="data.item.status == 1" name="check-button" switch
            @change="changeStatus(data.item.id)"></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item v-if="permission.edit">
              <button class="btn btn-sm p-0" @click.prevent="edit(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.delete">
              <button class="btn btn-sm p-0" :action="'pool/' + data.item.id" @click.prevent="$trash($event)">
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </button>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalNoOfRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      {{ getRoleWisePermissions }}
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "IPPOOL",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      title: "IP Pool",
      form: new Form({
        name: "",
        nas_type: "",
        type: "PPPOE",
        subnet: "",
        zone: "",
        pop: "",
        total_number_of_ip: "",
        franchise: "",
        mikrotik_id: "",
        mikrotik: "",
        start_ip: "",
        end_ip: "",
        real_ip: "no",
        real_ip_charge: "",
        have_real_ip: false,
        bandwidth: "",
      }),
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name" },
        { key: "nas_type", label: "Nas Type", sortable: true },
        // {key: 'zone', label: 'Zone', sortable: true},
        { key: "subnet", label: "Subnet", sortable: true },
        { key: "mikrotik", label: "Mikrotik", sortable: true },
        // {key: 'mac_address', label: 'Mac Address'},
        { key: "start_ip", label: "Start IP", sortable: true },
        { key: "end_ip", label: "End Ip", sortable: true },
        { key: "total_number_of_ip", label: "Total IP", sortable: true },
        { key: "status", label: "status" },

        "action",
      ],
      items: [],
      selected_mikrotik: "",
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      modal_title: "IP Pool",
      select_options_zone: [],
      select_options_pop: [],
      select_options_franchise: [],
      select_options_mikrotik: [],
      fetch_api: false,
      mikrotik_select: false,
      summary: {
        total_user: 0,
        total_active: 0,
        total_inactive: 0,
      },

      checked: false,
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("IP Pool");
      this.permission.add = this.checkpermission("IP Pool Add");
      this.permission.edit = this.checkpermission("IP Pool Edit");
      this.permission.delete = this.checkpermission("IP Pool Delete");
      this.permission.status = this.checkpermission("IP Pool Change Status");
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    zone() {
      return this.$store.state.isp.others.zone;
    },
    pop() {
      return this.$store.state.isp.others.pop;
    },
    franchise() {
      return this.$store.state.isp.others.franchise;
    },
    mikrotik() {
      return this.$store.state.isp.others.mikrotik;
    },
    sysdefault() {
      return this.$store.state.app.system_defaults;
    },
  },
  watch: {
    items() {
      this.loading = false;
    },

    zone(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: element.name,
          };
          this.select_options_zone.push(obj);
        });
      }
    },
    pop(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.name}-${element.mobile_number}-${element.email}`,
          };
          this.select_options_pop.push(obj);
        });
      }
    },
    franchise(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.name}-${element.mobile_number}-${element.email}`,
          };
          this.select_options_franchise.push(obj);
        });
      }
    },
    mikrotik(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.identity}-${element.host}`,
          };
          this.select_options_mikrotik.push(obj);
        });
      }
    },
    selected_mikrotik(value) {
      this.fetch_api = true;
      this.form.mikrotik = value.code;
      this.axios.get(`ippool/assign/mikrotik/${value.code}`).then((resp) => {
        if (resp.data.success && resp.data.data.length > 0) {
          const options = {};
          resp.data.data.forEach((element) => {
            options[element.name] = `${element.name}`;
          });
          Swal.fire({
            title: "Please Select One",
            input: "select",
            inputOptions: options,
            inputPlaceholder: "Select One",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              const selectedData = resp.data.data.find(
                (ele) => ele.name === result.value
              );
              const ip = selectedData.ranges.split("-");
              this.form.name = selectedData.name;
              this.form.start_ip = ip["0"];
              this.form.end_ip = ip["1"];
            }
            this.fetch_api = false;
          });
        }
      });
    },
  },
  created() {
    this.getData();
    this.$store.dispatch("others", {
      pop: true,
      zone: true,
      franchise: true,
      mikrotik: true,
    });
  },
  methods: {
    isrealip() {
      if (this.checked == true) {
        this.form.real_ip = "yes";
      } else {
        this.form.real_ip = "no";
      }
    },
    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    changeType() {
      if (this.form.nas_type == "MikroTik") {
        this.mikrotik_select = true;
      } else {
        this.mikrotik_select = false;
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("pool")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.items.data.unshift(resp.data.data);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`pool/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(data) {
      this.modal_title = "Update";
      this.form.name = data.name;
      this.form.nas_type = data.nas_type;
      this.form.type = data.type;
      this.form.subnet = data.subnet;
      this.form.zone = data.zone;
      this.form.pop = data.pop;
      this.form.total_number_of_ip = data.total_number_of_ip;
      this.form.franchise = data.franchise;
      this.form.mikrotik = data.mikrotik_id;
      this.form.start_ip = data.start_ip;
      this.form.end_ip = data.end_ip;
      this.form.real_ip = data.real_ip;
      this.form.real_ip_charge = data.real_ip_charge;
      this.form.have_real_ip = data.have_real_ip;
      this.form.bandwidth = data.bandwidth;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("modal-lg");
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },
    assignMikrotik() {
      console.log("apiiiiii");
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`pool?page=${page}`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data;
          this.totalNoOfRows = resp.data.data.length;

          this.summary.total_active = this.items.filter(
            (item) => item.status == 1
          ).length;

          this.summary.total_inactive = this.items.filter(
            (item) => item.status == 0
          ).length;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    async openModel() {
      await this.$store.dispatch("app/get_system_info");
      if (this.$store.state.app.system_defaults.data) {
        this.form.nas_type = this.$store.state.app.system_defaults.data.nas_type
        this.openModel.mode = false
        this.$bvModal.show("modal-lg");
      }
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`pool/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
