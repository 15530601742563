import auth from '@/middleware/auth'
import Olt from '../views/Olt/Index.vue'
import Brand from '../views/Brand/Index.vue'
import NetworkMonitor from '../views/NetworkMonitor/Index.vue'
import Box from '../views/Box/Index.vue'
import TJB from '../views/TJB/Index.vue'
import VLAN from '../views/FTTX/Vlan/index.vue'
import Onu from '../views/FTTX/Onu/index.vue'

export default [
  {
    path: '/olt',
    name: 'olt',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Olt,
    meta: {
      pageTitle: 'OLT',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'OLT',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brands',
    name: 'brands',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Brand,
    meta: {
      pageTitle: 'Brand',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Brand',
          active: true,
        },
      ],
    },
  },
  {
    path: '/NetworkMonitor',
    name: 'NetworkMonitor',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: NetworkMonitor,
    meta: {
      pageTitle: 'Network Monitor',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Netowrk Monitor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/box',
    name: 'box',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Box,
    meta: {
      pageTitle: 'Box',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Box',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tjb',
    name: 'tjb',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: TJB,
    meta: {
      pageTitle: 'TJB',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'TJB',
          active: true,
        },
      ],
    },
  },
  {
    path: '/onu',
    name: 'onu',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Onu,
    meta: {
      pageTitle: 'ONU',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'ONU',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vlan',
    name: 'vlan',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: VLAN,
    meta: {
      pageTitle: 'VLAN',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'VLAN',
          active: true,
        },
      ],
    },
  },
]
