<template>
	<div>
		<!-- <h5 class="text-center">Customer {{type}}</h5> -->
		<template
			v-if="loading"
		>
			Loading ...
		</template>
		<template
			v-else
		>
			<DynamicForm
				:submitForm="submit"
				:form="form"
				:type="type"
				:fields="dynamicFormFields"
			/>
		</template>
	</div>
</template>


<script>

import Form from "vform";
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "Customer",
	components: {  DynamicForm },
	props: {
		"type": {
			type: String,
			default: 'create'
		},
		"lat_lon": {
			type: String,
		},
		"id" : {
			type: Number,
			default: null
		}
	},
	data() {
	  return {

	  	form: new Form({
	  		name: '',
	  		gender: 'male',
	  		address: '',
	  		lat_lon: ''
	  	}),
      	genderOptions: [{
			'title': 'Male',
			'value': 'male'
		}, {
			'title': 'Female',
			'value': 'female'
		}, {
			'title': 'Third Gender',
			'value': 'third-gender'
		}],
		apiEndPoint: 'nm-customers',
		loading: false
	  }

	},
	emits: ['submitted'],
	computed: {
	  dynamicFormFields(){
	  	const formFields = [
			{
				'type': 'text',
				'name': 'name',
				'label': 'Name',
			},
			{
				'type': 'select',
				'name': 'gender',
				'label': 'Gender',
				'options': this.genderOptions	
			},
			{
				'type': 'textarea',
				'name': 'address',
				'label': 'Address',
			},
			{
				'type': 'text',
				'name': 'lat_lon',
				'label': 'Latitude Longitude',
				'disabled': true
			}
		]

		return formFields
	  }
	},
	created() {
		this.form.lat_lon = this.lat_lon
		if(this.type == 'edit'){
			this.edit()
		}
	},
	watch: {
	    'lat_lon': function(newVal, oldVal){
	    	this.form.lat_lon = this.lat_lon
	    }
	  
	},
	methods: {
		submit(){
			if(this.type === 'create'){
				this.create()
			} else if(this.type === 'edit'){
				this.update()
			}
		},
		create() {
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  
				  this.$emit('submitted', item)
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},
		edit() {
			this.loading = true
			axios.get(`${this.apiEndPoint}/${this.id}`).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data;
				  this.form.name = item.name
				  this.form.address = item.address
				  this.form.gender = item.gender
				  this.form.lat_lon = item.lat_lon
				} else {
				  
				}
			}).finally(()=> {
				this.loading = false
			});
		},
		update() {
			this.form.put(`${this.apiEndPoint}/${this.id}`).then((resp) => {
			  if (resp.data.success) {
			    let updatedItem = resp.data.data;

			    this.$emit('submitted', updatedItem)

			    this.form.reset()

			    this.$success_message(resp.data.message);
			  } else {
			    this.$error_message(resp.data.message, resp.data.errors);
			  }
			});
		}
 
	},
}
</script>