<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total"
          statistic-title="Total"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Active"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_inactive"
          statistic-title="In Active"
        />
      </b-col>
    </b-row>

    <b-card-code no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              size="sm"
              col="2"
              v-b-modal.modal-lg
              variant="primary"
              @click="modelAction('add')"
            >
              <!-- @click.prevent="editData.mode = false" -->
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>
      <b-modal
        id="modal-lg"
        :title="modal_title"
        size="lg"
        hide-header-close
        hide-footer
      >
        <form action="">
          <div class="form-group row">
            <div class="col-6">
              <label for="name">Name</label>
              <input
                @input="changeinput"
                v-model="form.name"
                type="text"
                name=""
                class="form-control"
                placeholder="Name"
                id="name"
              />
            </div>
            <div class="col-6">
              <label for="mac">MAC</label>
              <input
                v-model="form.mac"
                type="text"
                class="form-control"
                placeholder="EX: 00:11:22:33:44:55"
                id="mac"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-6">
              <label for="olt_id">OLT</label>
              <select
                @change="changeOlt()"
                v-model="form.olt"
                id=""
                class="form-control"
              >
                <option value="">Select</option>
                <option
                  v-for="olt in olts"
                  :key="'olt' + olt.id"
                  :value="olt.id"
                >
                  {{ olt.name }} | {{ olt.non_of_pon_port }}
                </option>
              </select>
            </div>
            <div class="col-6">
              <label for="pon_port">Pon Port</label>
              <select
                v-model="form.pon_port"
                id="pon_port"
                class="form-control"
              >
                <option value="">Select</option>
                <option
                  v-for="(index, item) in pon_ports"
                  :v-if="item !== 0"
                  :key="'pon_port' + index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <div class="d-flex row">
            <div class="form-group col-4">
              <label for="onu_id">Onu Id</label>
              <input
                v-model="form.onu_id"
                type="text"
                class="form-control"
                placeholder="Onu Id"
                id="onu_id"
              />
            </div>

            <div class="form-group col-4">
              <label for="rx_power">Rx Power</label>
              <input
                v-model="form.rx_power"
                type="text"
                class="form-control"
                placeholder="Rx Power"
                id="rx_power"
              />
            </div>
            <div class="form-group col-4">
              <label for="distance">Distance (M)</label>
              <input
                v-model="form.distance"
                type="text"
                class="form-control"
                placeholder="Distance"
                id="distance"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6">
              <label for="user_id">Customer</label>
              <select
                v-model="form.user_id"
                name=""
                id=""
                class="form-control"
              >
                <option value="">Select</option>
                <option
                  v-for="customer in customers"
                  :key="customer.id"
                  :value="customer.user_id"
                >
                  {{ customer.username }}
                </option>
              </select>
            </div>
            <div v-if="zone" class="col-6">
              <label for="zone_id ">Zone</label>
              <input
                class="form-control"
                disabled
                type="text"
                name="zone_id"
                v-model="zone"
                id=""
              />
            </div>
          </div>

          <div class="form-group d-flex">
            <div class="w-50">
              <b-form-checkbox
                v-model="form.vlan_tagged"
                name="check-button"
                switch
              >
                <label for="vlan_tagged">Vlan Tagged</label>
              </b-form-checkbox>
            </div>
            <div v-if="form.vlan_tagged" class="w-50">
              <label for="vlan_id">Vlan Id</label>
              <input
                v-model="form.vlan_id"
                type="text"
                class="form-control"
                placeholder="Vlan Id"
                id="vlan_id"
              />
            </div>
          </div>
        </form>
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="submitHandelar" class="btn btn-primary">Save</button>
      </b-modal>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(olt)="olt">
          {{ olt.item.olt.name }}
        </template>
        <template #cell(status)="status">
          <b-form-checkbox
            v-model="status.item.status == '0'"
            name="check-button"
            switch
            @change="changeStatus(data.item.id)"
          ></b-form-checkbox>
        </template>

        <template #cell(vlan_tagged)="vlan_tagged">
          {{ vlan_tagged == 1 ? "Yes" : "NO" }}
        </template>
        <template #cell(zone)="zone">
          {{ zone.item.olt.zone.name }}
        </template>
        <template #cell(user_id)="user_id">
          {{ user_id.item.customer }}
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item>
              <!-- v-for="(pmsList, index) in getRoleWisePermissions"
              :key="index + 'p2'"
              v-show="pmsList.name == 'Edit MikroTik Ip Pool'" -->
              <button
                class="btn btn-sm p-0"
                @click.prevent="edit(data.item.id)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="deleteData(data.item.id)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <b-pagination-nav
          @input="getData"
          :link-gen="linkGen"
          :number-of-pages="items.last_page"
          :limit="perPage"
          use-router
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "Onu",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      zone: "",
      olts: {},
      customers: {},
      pon_ports: [],
      form: new Form({
        vlan_tagged: false,
        name: "",
        mac: "",
        olt: "",
        onu_id: "",
        pon_port: "",
        rx_power: "",
        distance: "",
        vlan_id: "",
        user_id: "",
        zone_id: "",
      }),
      title: "Onu",
      fields: [
        { key: "id", label: "#", sortable: true },
        "olt",
        { key: "pon_port", label: "pon_port", sortable: true },
        { key: "onu_id", label: "Onu ID", sortable: true },
        { key: "rx_power", label: "rx_power", sortable: true },
        { key: "distance", label: "distance", sortable: true },
        "vlan_tagged",
        { key: "vlan_id", label: "vlan", sortable: true },
        { key: "user_id", label: "customer", sortable: true },
        "zone",
        "status",
        "action",
      ],
      items: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      modal_title: "New Onu",
      summary: {
        total_user: 0,
        total_active: 0,
        total_inactive: 0,
      },
      lData: null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    changeOlt() {
      const fOlt = this.olts.find((el) => (el.id = this.form.olt));
      this.zone = fOlt.zone.name;
      this.form.zone_id = fOlt.zone.id;
      for (let index = 0; index < parseInt(fOlt.non_of_pon_port) + 1; index++) {
        this.pon_ports.push({
          item: index,
        });
      }
      console.log(this.pon_ports);
    },
    changeinput() {
      this.customers = this.$store.state.extra.onu_module_info.customer;
      this.olts = this.$store.state.extra.onu_module_info.olt;
    },
    modelAction(val = null) {
      this.$store.dispatch("moduleOnuAtion", {
        olt: true,
        customer: true,
      });
      this.changeinput();
      this.$bvModal.show("modal-lg");
    },
    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    selectMikrotik() {
      if (this.form.nas_type == "MikroTik") {
        this.mikrotik_select = true;
      } else {
        this.mikrotik_select = false;
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("onu")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`onu/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    async edit(id) {
      axios.get(`onu-edit/${id}`).then((resp) => {
        if (resp.data.success) {
          this.form.name = resp.data.data.name;
          this.form.mikrotik = resp.data.data.mikrotik_id;
          this.form.queue_kind = resp.data.data.kind;
          this.form.rate = resp.data.data.pcq_rate;
          this.form.burst_rate = resp.data.data.burst_rate;
          this.form.burst_threshold = resp.data.data.burst_threshold;
          this.form.burst_time = resp.data.data.burst_time;
          this.form.src_address = resp.data.data.src_address;
          this.form.dst_address = resp.data.data.dst_address;
          this.modal_title = "Update";
          this.editData.mode = true;
          this.editData.id = id;
          this.$bvModal.show("modal-lg");
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`onu?page=${this.currentPage}&item${this.perPage}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$store.dispatch("spinnerLoading", false);
            this.items = resp.data.data.data;
            this.summary.total = parseInt(resp.data.data.data.total);
            this.summary.total_active = parseInt(resp.data.data.active);
            this.summary.total_inactive = parseInt(resp.data.data.inactive);
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    linkGen(pageNum) {
      return { path: `/onu?pages=${pageNum}` };
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`onu/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
