/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <!-- v-if="permission.add" -->
        <b-button
          v-b-modal.modal-lg
          variant="primary"
          size="sm"
          @click.prevent="editData.mode = false"
        >
          <span>
            <feather-icon icon="PlusCircleIcon" size="16" />
            Add New
          </span>
        </b-button>

        <b-button
            v-b-modal.modal-lg
            variant="primary"
            size="sm"
            @click.prevent="downloadPDF"
        >
          <span>
            <feather-icon icon="PlusCircleIcon" size="16" />
            Export PDF
          </span>
        </b-button>
      </div>
      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="md">
        <form @submit.prevent="submitHandelar">
          <div class="form-group">
            <label for="">Name</label>
            <input
              autofocus="autofocus"
              v-model="form.name"
              type="text"
              class="form-control"
              name="name"
              placeholder="Name"
            />
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label for="">Employee Image </label>
                <input
                  id="file"
                  type="file"
                  class="form-control"
                  @change="uploadImage($event)"
                />
              </div>
              <div class="col-lg-6">
                <img
                  id="employee-img"
                  :src="form.image_src"
                  alt="employee-img"
                  style="
                    text-align: center;
                    border: 3px solid #867df2;
                    border-radius: 8px;
                    padding: 30px;
                    max-height: 120px;
                    width: 100%;
                  "
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">Department</label>
            <v-select
              v-model="form.department_id"
              :options="select_options_department"
            />
          </div>
          <div class="form-group">
            <label for="">Designation</label>
            <v-select
              v-model="form.designation_id"
              :options="select_options_designation"
            />
          </div>
          <div class="form-group">
            <label for="">Mobile No</label>
            <input
              autofocus="autofocus"
              v-model="form.mobile_no"
              type="text"
              class="form-control"
              name="mobile_no"
              placeholder="Mobile No"
            />
          </div>
          <div class="form-group">
            <label for="">Birth Date</label>
            <input
              autofocus="autofocus"
              v-model="form.birth_date"
              type="date"
              class="form-control"
              name="birth_date"
              placeholder="Birth Date"
            />
          </div>

          <div class="form-group">
            <label for="">NID</label>
            <input
              autofocus="autofocus"
              v-model="form.nid"
              type="text"
              class="form-control"
              name="nid"
              placeholder="NID"
            />
          </div>
          <div class="form-group">
            <label for="">Gender</label>
            <select v-model="form.gender" name="gender" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Others</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Present Address</label>
            <textarea
              v-model="form.present_address"
              class="form-control"
              id=""
              cols="30"
              rows="5"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="">Permanent Address</label>
            <textarea
              v-model="form.permanent_address"
              class="form-control"
              id=""
              cols="30"
              rows="5"
            ></textarea>
          </div>

          <div class="form-group">
            <label for="">Salary</label>
            <input
              autofocus="autofocus"
              v-model="form.salary"
              type="text"
              class="form-control"
              name="salary"
              placeholder="Salary"
            />
          </div>
          <div class="form-group">
            <label for="">Advance Salary</label>
            <input
                autofocus="autofocus"
                v-model="form.advance_salary"
                type="text"
                class="form-control"
                name="advance_salary"
                placeholder="Advance Salary"
            />
          </div>
          <div class="form-group">
            <label for="">Joining Date</label>
            <input
              autofocus="autofocus"
              v-model="form.joining_date"
              type="date"
              class="form-control"
              name="joining_date"
              placeholder="Joining Date"
            />
          </div>
          <div class="form-group">
            <label for="">Blood Group</label>
            <select v-model="form.blood_group" name="blood_group" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="A+">(A+)</option>
              <option value="A-">(A-)</option>
              <option value="B+">(B+)</option>
              <option value="B-">(B-)</option>
              <option value="O+">(O+)</option>
              <option value="O-">(O-)</option>
              <option value="AB+">(AB+)</option>
              <option value="AB-">(AB-)</option>
            </select>
          </div>
		  <div class="form-group">
			<label class="d-flex flex-row justify-content-between">
				<div>Office Issued Mobile</div>
				<div>
					<input
              v-model="form.is_issued_mobile_no"
              type="checkbox"
              id="is_issued_mobile_no"
              name="is_issued_mobile_no"
              @change="toggleOfficeIssuedMobileNoField">
				</div>
			</label>
			<input
          autofocus="autofocus"
          disabled
          v-model="form.office_issued_mobile_no"
          type="text"
          class="form-control"
			    id="office_issued_mobile_no"
          name="office_issued_mobile_no"
          placeholder="Enter Office Issued Mobile No."
      />
		  </div>
          <div class="form-group">
            <label for="">Status</label>
            <select v-model="form.status" name="status" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
              <option value="2">Terminate</option>
            </select>
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
        <template #modal-footer>
          <div class="btn btn-warning" @click="close" type="">Close</div>
        </template>
      </b-modal>
      <b-modal
        id="modal-assign-shift"
        :title="modal_title"
        hide-header-close
        size="md"
      >
        <form @submit.prevent="submitHandelarAssignShift">
          <div class="form-group">
            <label for="">Shift</label>
            <v-select
              v-model="form.work_hour_id"
              :options="select_options_shift"
            />
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
        <div class="mt-50">
          Assigned Shifts
          <div class="d-flex">
            <div v-for="shift in selectedEmployee" :key="shift.id" :id="'shift_'+shift.id" class="d-flex align-items-center border border-primary m-50">
              <div>{{shift.working_hour.name}}</div>
              <div class="ml-50 ">
                <button @click="removeShift(shift.id)"  type="button" class="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
<!--        <button type="button" v-for="shift in selectedEmployee.shifts" :key="shift">{{shift.working_hour}}</button>-->
        <template #modal-footer>
          <div class="btn btn-warning" @click="assignShiftClose" type="">
            Close
          </div>
        </template>
      </b-modal>
      <b-modal
        id="modal-employee-attendance"
        :title="modal_title"
        hide-header-close
        size="md"
      >
        <form @submit.prevent="submitHandelarEmployeeAttendance">
          <div class="form-group">
            <label for="">attendance</label>
            <select v-model="form.attendance" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="Present">Present</option>
              <option value="Absent">Absent</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Attendance Date</label>
            <input
              autofocus="autofocus"
              v-model="form.attendance_date"
              type="date"
              class="form-control"
              name="attendance_date"
              placeholder="Attendance Date"
            />
          </div>
          <div class="form-group">
            <label for="">Clock In</label>
            <input
              autofocus="autofocus"
              v-model="form.clock_in"
              type="time"
              class="form-control"
              name="clock_in"
              placeholder="Clock In"
            />
          </div>
          <div class="form-group">
            <label for="">Clock Out</label>
            <input
              autofocus="autofocus"
              v-model="form.clock_out"
              type="time"
              class="form-control"
              name="clock_out"
              placeholder="Clock Out"
            />
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
        <template #modal-footer>
          <div class="btn btn-warning" @click="assignShiftClose" type="">
            Close
          </div>
        </template>
      </b-modal>

      <div class="w-100 px-2">
        Filter
        <div class="d-flex justify-content-between">
          <div class="form-group w-100 mr-2">
            <label for="">Name</label>
            <input
                @keyup="filterData"
                autofocus="autofocus"
                type="text"
                class="form-control"
                v-model="employee_name"
                placeholder="Name"
            />
          </div>
          <div class="form-group w-100 mr-2">
            <label for="">Department</label>
            <v-select
                @change="filterData"
                v-model="filter_department"
                class="w-100"
                :options="select_options_department"
            />
          </div>
          <div class="form-group w-100 mr-2">
            <label for="">Designation</label>
            <v-select
                @change="filterData"
                v-model="filter_designation"
                :options="select_options_designation"
            />
          </div>
          <div class="form-group w-100 mr-2">
            <label for="">Status</label>
            <select @change="filterData" v-model="filter_status" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
              <option value="2">Terminate</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Department</th>
              <th scope="col">Designation</th>
              <th scope="col">Shifts</th>
              <th scope="col">Mobile No</th>
              <th scope="col">Joining Date</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(employee, index) in $store.state.hrm.employees.data"
              :key="index + 'employees'"
            >
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ employee.name }}</td>
              <td>{{ employee.department.name }}</td>
              <td>{{ employee.designation.title }}</td>
              <td>{{ employee.assigned_shifts }}</td>
              <td>{{ employee.mobile_no }}</td>
              <td>{{ employee.joining_date }}</td>
              <td>{{ employee.status == 1 ? "Active" : "Inactive" }}</td>

              <td>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="permission.edit"
                    @click.prevent="edit(employee.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Edit
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="permission.edit"
                    @click.prevent="assignShift(employee.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Assign Shift
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="permission.edit"
                    @click.prevent="employeeAttendance(employee.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Add Attendance
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="permission.delete"
                    :action="'employee/' + employee.id"
                    @click.prevent="$trash('employee/' + employee.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                      Delete
                    </button>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";
import axios from 'axios'
import jsPDF from "jspdf";

export default {
  nameL: "role",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        name: "",
        image:"",
        department_id: {},
        designation_id: {},
        is_issued_mobile_no: "",
        office_issued_mobile_no: "",
        mobile_no:"",
        birth_date:"",
        nid:"",
        gender:"",
        present_address:"",
        permanent_address:"",
        salary:"",
        advance_salary:"",
        joining_date:"",
        blood_group:"",
        status:"",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      select_options_department: [],
      select_options_designation: [],
      select_options_shift: [],
      employee_name:"",
      filter_department:"",
      filter_designation:"",
      filter_status:"",
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        permssions: false,
      },
      selectedEmployee: null,
    };
  },
  computed: {
    employees() {


      if(this.employee_name == "" && this.filter_department == "" && this.filter_designation == "" && this.filter_status == "") {
        return this.$store.state.hrm.employees.data;
      }
      else if(this.employee_name != "" && this.filter_department == "" && this.filter_designation == "" && this.filter_status == ""){
        // return this.$store.state.hrm.attendances.data;
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name == "" && this.filter_department != "" && this.filter_designation == "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name != "" && this.filter_department != ""  && this.filter_designation == "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true && this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name == "" && this.filter_department == ""  && this.filter_designation != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation.label){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name == "" && this.filter_department != ""  && this.filter_designation != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label && this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation.label){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name != "" && this.filter_department == ""  && this.filter_designation != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation.label && this.$store.state.hrm.employeess.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name != "" && this.filter_department != ""  && this.filter_designation != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label && this.$store.state.hrm.employees.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true && this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation.label){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name == "" && this.filter_department == ""  && this.filter_designation == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].filter_status <= this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name == "" && this.filter_department == ""  && this.filter_designation != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation.label && this.$store.state.hrm.employees.data[i].status == this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name == "" && this.filter_department != ""  && this.filter_designation == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label && this.$store.state.hrm.employees.data[i].status == this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name == "" && this.filter_department != ""  && this.filter_designation != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label && this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation.label && this.$store.state.hrm.employees.data[i].status == this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name != "" && this.filter_department == ""  && this.filter_designation == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true && this.$store.state.hrm.employees.data[i].filter_status <= this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name != "" && this.filter_department == ""  && this.filter_designation != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true && this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation.label && this.$store.state.hrm.employees.data[i].status == this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name != "" && this.filter_department != ""  && this.filter_designation == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true && this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label  && this.$store.state.hrm.employees.data[i].status == this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }
      else if(this.employee_name != "" && this.filter_department != ""  && this.filter_designation != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.employees.data.length; i++){
          if(this.$store.state.hrm.employees.data[i].name.toLowerCase().includes(this.employee_name.toLowerCase()) == true && this.$store.state.hrm.employees.data[i].department.name == this.filter_department.label && this.$store.state.hrm.employees.data[i].designation.title == this.filter_designation  && this.$store.state.hrm.employees.data[i].status == this.filter_status){
            filters.push(this.$store.state.hrm.employees.data[i])
          }
        }
        return filters;
      }

    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Employee");
      this.permission.add = this.checkpermission("Employee Add");
      this.permission.addEmployeeAttendance = this.checkpermission(
        "EmployeeAttendance Add"
      );
      this.permission.edit = this.checkpermission("Employee Edit");
      this.permission.delete = this.checkpermission("Employee Delete");
    },
    department() {
      return this.$store.state.hrm.active_departments;
    },
    designation() {
      return this.$store.state.hrm.active_designations;
    },
    shifts() {
      return this.$store.state.hrm.active_shifts;
    },
  },
  watch: {
    roles() {
      this.loading = false;
    },
    department(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: element.name,
          }
          this.select_options_department.push(obj)
        })
      }
    },
    shifts(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: element.name,
          };
          this.select_options_shift.push(obj);
        });
      }
    },
    designation(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: element.title,
          }
          this.select_options_designation.push(obj)
        });
      }
    },
  },
  created() {
    this.$store.dispatch("getEmployees");
    this.$store.dispatch("getActiveDepartments");
    this.$store.dispatch("getActiveDesignations");
    this.$store.dispatch("getActiveShifts");
  },
  methods: {

    downloadPDF() {
      const doc = new jsPDF();
      doc.autoTable({ html: "#my-table" });

      var col = [
        "Id",
        "Name",
        "Department",
        "Designation",
        "Mobile No",
        "Joining Date",
        "Status",
      ];
      var rows = [];
      this.employees.forEach((element) => {
        var id = [element.id];
        var name = [element.name];
        var department = [element.department.name];
        var designation = [element.designation.title];
        var mobile_no= [element.mobile_no];
        var joining_date = [element.joining_date];
        var status = [element.status];
        rows.push([
          id,
          name,
          department,
          designation,
          mobile_no,
          joining_date,
          status,
        ]);
      });

      doc.autoTable(col, rows, { startY: 10 });

      doc.save("income.pdf");
    },

    filterData(){
      this.employees
    },

    uploadImage($event) {
      const file = $event.target.files[0];
      if (!file.type.match("image.*")) {
        alert("This is not any kind of image");
        return;
      }
      this.form.image = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        const img = new Image();
        img.onload = () => {
          document.getElementById("employee-img").src = evt.target.result;
        };
        img.src = evt.target.result;
      };
    },

	//= ===Toggle Office Issued Mobile Number Field =========
	toggleOfficeIssuedMobileNoField() {
		let form_field = document.getElementById('office_issued_mobile_no');
		if(form_field.disabled == true){
			form_field.disabled = false;
		}
		else{
			form_field.disabled = true;
		}
	},

    //= ===handle the form close =========
    close() {
      this.form.name = "";
      this.form.is_issued_mobile_no = "";
      this.form.office_issued_mobile_no = "";
      this.form.mobile_no = "";
      this.form.birth_date = "";
      this.form.nid = "";
      this.form.gender = "";
      this.form.present_address = "";
      this.form.permanent_address = "";
      this.form.salary = "";
      this.form.advance_salary = "";
      this.form.joining_date = "";
      this.form.blood_group = "";
      this.form.status = "";

      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form close =========
    assignShiftClose() {
      this.$bvModal.hide("modal-assign-shift");
    },
    //= ===handle the form close =========
    EmployeeAttendanceClose() {
      this.$bvModal.hide("modal-employee-attendance");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ===handle the Assign shift form submit=========
    submitHandelarAssignShift() {
      this.AssignShift();
    },
    //= ===handle the Employee Attendance form submit=========
    submitHandelarEmployeeAttendance() {
      this.EmployeeAttendanceSave();
    },

    //= ========data save=========
    save() {
      console.log(this.form)
      this.form
        .post("employee")
        .then((resp) => {
          if (resp.data.success) {
            console.log(resp.data.employee)
            this.close();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getEmployees");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========Assign shift=========

    AssignShift() {
      this.form
        .put(`assign_shift/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.assignShiftClose();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getEmployees");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========Assign shift=========

    EmployeeAttendanceSave() {
      this.form
        .put(`add-employee-attendance/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.EmployeeAttendanceClose();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getEmployees");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      console.log(this.form)
      // this.form
        axios.put(`employee/${this.editData.id}`,this.form)
        .then((resp) => {
          if (resp.data.success) {
            console.log(resp)
            this.close();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getEmployees");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(id) {
      const employee = this.employees.find((ele) => ele.id === id);
      this.modal_title = `Update-${employee.name}`;
      this.form.name = employee.name;
      this.form.department_id.label = employee.department.name;
      this.form.department_id.code = employee.department.id;
      this.form.designation_id.label = employee.designation.title;
      this.form.designation_id.code = employee.designation.id;
      this.form.mobile_no = employee.mobile_no;
      this.form.status = employee.status;
      this.form.joining_date = employee.joining_date;
      this.form.birth_date = employee.birth_date;
      this.form.nid = employee.nid;
      this.form.gender = employee.gender;
      this.form.present_address = employee.present_address;
      this.form.permanent_address = employee.permanent_address;
      this.form.is_issued_mobile_no = employee.is_office_issued_mobile;
      this.form.office_issued_mobile_no = employee.office_issued_mobile_no;
      this.form.salary = employee.salary;
      this.form.blood_group = employee.blood_group;
      this.form.image_src = "http://127.0.0.1:8000/" + employee.image;
      this.editData.mode = true;
      this.editData.id = employee.id;
      this.$bvModal.show("modal-lg");
    },
    //= ========get data for Assign Shift=========

    assignShift(id) {
      const employee = this.employees.find((ele) => ele.id === id);
      this.modal_title = `Assign Shift to-${employee.name}`;
      this.form.name = employee.name;
      this.form.department_id.label = employee.department.name;
      this.form.designation_id.label = employee.designation.title;
      this.editData.mode = true;
      this.editData.id = employee.id;
      this.selectedEmployee = employee.shifts;
      this.$bvModal.show("modal-assign-shift");
    },


    removeShift(id) {
      axios.post('remove_shift/'+id, {_method:'DELETE'})
        .then((resp) => {
          if (resp.data.success) {
            document.getElementById(`shift_${id}`).classList.remove('d-flex')
            document.getElementById(`shift_${id}`).style.display = 'none';
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getEmployees");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for Employee Attendance=========

    employeeAttendance(id) {
      const employee = this.employees.find((ele) => ele.id === id);
      this.modal_title = `Add Attendance to-${employee.name}`;
      this.form.name = employee.name;
      this.editData.mode = true;
      this.editData.id = employee.id;
      this.$bvModal.show("modal-employee-attendance");
    },
  },
};
</script>
