<template>
  <div class="shadow">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <b-button v-if="permission.add" variant="primary" size="sm" @click.prevent="OpenModal('add')">
          <feather-icon icon="PlusCircleIcon" size="16" />
          Add New
        </b-button>
      </div>
      <b-modal id="modal-lg" :title="modal_title" size="lg">
        <form @submit.prevent="submitHandelar">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Type</label>
                <v-select v-model="form.type" :options="['pop', 'franchise', 'agent', 'app_manager']" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Name</label>
                <input v-model="form.name" type="text" class="form-control" name="name" placeholder="name" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Email</label>
                <input v-model="form.email" type="text" class="form-control" name="email" placeholder="Email" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Phone</label>
                <input v-model="form.phone" type="text" class="form-control" name="phone" placeholder="Phone" />
              </div>
            </div>
            <div v-if="!editData.mode" class="col-lg-6">
              <div class="form-group">
                <label for="">Password</label>
                <input v-model="form.password" type="password" class="form-control" name="password"
                  placeholder="Password" />
              </div>
            </div>
            <div v-if="!editData.mode" class="col-lg-6">
              <div class="form-group">
                <label for="">Confirm Password</label>
                <input v-model="form.confirmed_password" type="password" class="form-control" name="password"
                  placeholder="Confirm Password" />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Zone</label>
                <v-select v-model="form.selected_zone" :options="select_options_zone" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="address">Address</label>
                <input class="form-control" type="text" v-model="form.address" placeholder="Address" />
              </div>
            </div>
            <div class="col-lg-6" v-if="form.type !== 'agent'">
              <div class="form-group">
                <label v-b-tooltip.top.v-primary title="Give Permission To Allow grace for every Other Customer">Allow
                  Grace In days {{ form.default_grace }}</label>
                <input min="1" :max="form.default_grace" id="allow_gress" v-model="form.allow_grace_mark" type="number"
                  class="form-control" placeholder="Ex:3" />
              </div>
            </div>
            <div class="col-lg-6" v-if="form.type == 'agent'">
              <div class="form-group">
                <label class="d-flex" for="commission_rate">Commission Rate
                  <small class="text-info">({{
                    form.commision_type == true ? "%" : "Flat Rate"
                  }})</small>
                  <b-form-checkbox v-model="form.commision_type" name="check-button" switch></b-form-checkbox></label>
                <input min="1" id="commission_rate" v-model="form.commission_rate" type="number" class="form-control"
                  placeholder="Commission Rate" />
              </div>
            </div>
            <div class="col-lg-6" v-if="form.type !== 'agent'">
              <b-form-group class="float-left w-50" label="Allow WinBox Access">
                <b-form-checkbox v-model="form.is_winbox" name="check-button" switch></b-form-checkbox>
              </b-form-group>
              <b-form-group class="float-left w-50" v-if="form.is_winbox" label="Select Access Type">
                <v-select v-model="form.winbox_access" :options="winboxess" />
              </b-form-group>
            </div>
            <div class="col-lg-6" v-if="form.type == 'agent'">
              <div class="form-group">
                <label for="billing_date">Billing Date</label>
                <input min="1" id="billing_date" v-model="form.billing_date" type="datetime-local" class="form-control"
                  placeholder="Commission Rate" />
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer>
          <button @click="submitHandelar" class="btn btn-primary" type="submit">
            Submit
          </button>
        </template>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Roles</th>
              <th scope="col">Type</th>
              <th scope="col">Zone</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in   users" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone || "" }}</td>
              <td>
                <span v-for="role in user.roles" :key="role.id">{{
                  role.name
                }}</span>
              </td>
              <td>{{ user.type || "" }}</td>
              <td>{{ user.zone ? user.zone.name : "" }}</td>
              <td>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <!-- :to="{ name: 'editpackage', params: { id: data.item.id } }" -->
                  <b-dropdown-item :to="{
                    name: 'user.users_profile',
                    params: { id: user.id },
                  }">
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      View
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="edit(user.id)">
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Edit
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="permission.delete" :action="'user/' + user.id" @click.prevent="$trash($event)">
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                      Delete
                    </button>
                  </b-dropdown-item>
                  <!-- v-if="permission.permssions" -->
                  <b-dropdown-item title="Assign Role" @click.prevent="assign_role(user.id)">
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="AlertCircleIcon" class="mr-50" />
                      Assign Role
                    </button>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {{ getRoleWisePermissions }}
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";
import axios from "axios";
import router from "@/router";

export default {
  nameL: "users",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        name: "",
        phone: "",
        allow_grace_mark: null,
        default_grace: 3,
        password: "",
        confirmed_password: "",
        email: "",
        type: "app_manager",
        commission_rate: null,
        billing_date: null,
        commision_type: true,
        is_winbox: false,
        winbox_access: {},
        selected_zone: {
          code: "",
          label: "------------- please select one ----------",
        },
      }),
      permissionError: "",
      users: [],

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      modal_title: "Create Data",
      select_options_zone: [],
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        permssions: false,
      },
      winboxess: [
        { code: '', label: '---Select---' },
        { code: 'fullaccess', label: 'Full' },
        { code: 'writeaccess', label: 'Write' },
        { code: 'readaccess', label: 'Read' }
      ]
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("User");
      this.permission.add = this.checkpermission("Add User");
      this.permission.edit = this.checkpermission("User Edit");
      this.permission.delete = this.checkpermission("User Delete");
      this.permission.permssions = this.checkpermission("User Permssions");
    },
    roles() {
      return this.$store.state.isp.roles;
    },
    zone() {
      return this.$store.state.isp.zone;
    },
  },
  watch: {
    zone(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: element.name,
          };
          this.select_options_zone.push(obj);
        });
      }
    },
  },
  created() {
    this.getData();
    this.$store.dispatch("getRoles");
    this.$store.dispatch("zone");
  },
  methods: {
    async OpenModal(val) {
      this.getGrace();
      if (val == "add") {
        this.editData.id = null
        this.form.reset();
        this.$bvModal.show("modal-lg");
      }
    },

    // user grace user or system action
    getGrace() {
      // for = system-grace, user-grace
      axios.get(`get-grace?gracefor=system-grace`).then((res) => {
        if (res.data.success) {
          this.form.default_grace = res.data.data.value;
        }
      });
    },

    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ===get data=========
    getData() {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("user").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.users = resp.data.data;
          //  console.log("Users " + JSON.stringify(this.users))
        } else {
          if (resp.data.code == 403) {
            this.permissionError = resp.data.message;
            this.$error_message(resp.data.message, resp.data.errors);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        }
      });
    },

    //= ========data save=========
    save() {
      this.form
        .post("user")
        .then((resp) => {
          if (resp.data.success) {
            if (this.form.type == "app_manager") {
              router.push({ name: "users" });
            } else if (this.form.type == "agent") {
              router.push({ name: "agents" });
            }
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.form.reset();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`user/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.form.reset();
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========
    edit(id) {
      this.getGrace();
      const user = this.users.find((ele) => ele.id === id);
      this.modal_title = `Update-${user.name}`;
      this.form.name = user.name;
      this.form.email = user.email;
      this.form.phone = user.phone;
      this.form.type = user.type;
      this.form.address = user.address;
      this.form.is_winbox = user.is_winbox == 1 ? true : false;
      const winbox_access = this.winboxess.find((ele) => ele.code == user.winbox_access);
      if (winbox_access) {
        this.form.winbox_access = { code: winbox_access.code, label: winbox_access.label };
      }
      // zone
      const zone = this.select_options_zone.find((ele) => ele.code == user.zone_id);
      if (zone) {
        this.form.selected_zone = { code: zone.code, label: zone.label }
      }
      this.form.allow_grace_mark = user.allow_grace_mark;
      this.editData.mode = true;
      this.editData.id = user.id;
      console.log(this.form);
      this.$bvModal.show("modal-lg");
    },
    //assign_role
    assign_role(id) {
      const options = {};
      this.roles.forEach((element) => {
        options[element.id] = element.name;
      });
      this.Swal.fire({
        title: "Please Select A Role",
        input: "select",
        inputOptions: options,
        inputPlaceholder: "Select One",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          axios
            .get(`user/assign/role/${id}`, {
              params: {
                role_id: result.value,
              },
            })
            .then((resp) => {
              if (resp.data.success) {
                this.getData();
                this.$s_tost(resp.data.message);
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
  },
};
</script>
