<template>
  <div>
    <div>
      <b-modal id="modal_form" :title="`Invoice (${title})`" hide-footer>
        <form @submit.prevent="submitHandler()">
          <!-- <b-form-group label="name" label-for="name">
            <b-form-input v-model="form.name" id="name" placeholder="..." />
          </b-form-group> -->
          <b-form-group label="Type" label-for="type">
            <b-form-input
              disabled
              v-model="form.type"
              id="type"
              placeholder="..."
            />
          </b-form-group>
          <b-form-group label="provider" label-for="provider">
            <v-select
              v-model="form.provider"
              :options="providers_items"
              label="label"
            >
            </v-select>
          </b-form-group>
          <b-form-group label="total bill" label-for="total_bill">
            <b-form-input
              type="number"
              v-model="form.total_bill"
              id="total_bill"
              placeholder="...."
            />
          </b-form-group>
          <b-form-group label="Received Amount" label-for="received_amount">
            <b-form-input
              @input="changeReceived()"
              type="number"
              v-model="form.received_amount"
              id="received_amount"
              placeholder="...."
            />
          </b-form-group>
          <b-form-group label="due" label-for="due">
            <b-form-input
              disabled
              type="number"
              v-model="form.due"
              id="due"
              placeholder="...."
            />
          </b-form-group>

          <b-form-group label="Issue Date" label-for="">
            <flat-pickr
              v-model="form.due_date"
              class="form-control"
              placeholder="..."
            />
          </b-form-group>
          <div class="mt-1 justify-content-center">
            <div class="col-lg-6">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                :disabled="form.busy"
              >
                {{ form.busy ? "Submitting" : "Submit" }}
              </button>
            </div>
          </div>
        </form>
      </b-modal>
      <b-card-code :title="`Invoice (${title})`" no-body>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex">
              <b-button
                size="sm"
                col="2"
                variant="primary"
                :to="{ name: 'vendor-invoice' }"
              >
                <feather-icon icon="PlusCircleIcon" size="16" />
                Add New</b-button
              >
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0 ml-3"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          ref="table"
          :per-page="Number(perPage)"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(mac_address)="{ item, index }">
            {{ item.mac_address ? "Yes" : "No" }}
          </template>

          <template #cell(status)="{ item, index }">
            {{ item.status ? "Inactive" : "Active" }}
          </template>

          <template #cell(action)="{ item, index }">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'vendor-invoice-edit', params: { id: item.id } }"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                View
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'vendor-invoice-edit', params: { id: item.id } }"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteData(item, index)">
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNoOfRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
          {{ getRoleWisePermissions }}
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "VendorInvoices",
  components: { BCardCode, StatisticCardHorizontal, flatPickr },
  data() {
    return {
      title: "Vendor Invoices",
      form: new Form({
        id: null,
        type: "",
        provider: null,
        due_date: "",
        total_bill: null,
        received_amount: null,
        due: 0,
      }),
      fields: [
        { key: "id", label: "#ID", sortable: true },
        { key: "inv_no", label: "#INV NO", sortable: true },
        { key: "issue_date", label: "Issue date", sortable: true },
        { key: "total_bill", label: "total bill", sortable: true },
        { key: "received_amount", label: "received amount", sortable: true },
        { key: "due", label: "due", sortable: true },
        { key: "vat", label: "vat(TK)", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      providers_items: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      permission: {
        vendor_invoice: false,
        vendor_invoice_add: false,
        vendor_invoice_edit: false,
        vendor_invoice_delete: false,
        vendor_invoice_change_status: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.vendor_invoice = this.checkpermission("vendor_invoice");
      this.permission.vendor_invoice_add =
        this.checkpermission("vendor_invoice_add");
      this.permission.vendor_invoice_edit = this.checkpermission(
        "vendor_invoice_edit"
      );
      this.permission.vendor_invoice_delete = this.checkpermission(
        "vendor_invoice_delete"
      );
      this.permission.vendor_invoice_change_status = this.checkpermission(
        "vendor_invoice_change_status"
      );
    },
    sortOptions() {
      const pathArray = this.$route.path.split("/");
      const lastpath = pathArray[pathArray.length - 1];
      this.title = lastpath;
      this.form.type = lastpath;
      this.getData();
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  watch: {
    currentPage: {
      handler: function (newVal, oldVal) {
        this.getData(newVal); // call it in the context of your component object
      },
      deep: true,
    },
  },
  methods: {
    changeReceived() {
      this.form.due =
        parseInt(this.form.total_bill) - parseInt(this.form.received_amount);
    },

    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(
          `vendor-invoice?page=${page}&item=${this.perPage}&type=${this.title}`
        )
        .then((resp) => {
          if (resp.data.success) {
            this.totalNoOfRows = resp.data.data.total;
            this.items = resp.data.data.data;
          } else {
            this.$error_message(resp.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch("spinnerLoading", false);
        });
    },

    createData() {
      this.form.post("vendor-invoice").then((resp) => {
        if (resp.data.success) {
          let item = resp.data.data;
          this.items = [...this.items, item];
          this.$s_tost(resp.data.message);
          this.form.reset();
          this.$bvModal.hide("modal_form");
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    submitHandler() {
      this.form.id == null ? this.createData() : this.updateData();
    },
    // editData
    async editData(item) {
      this.form.reset();
      this.openModal("edit", item.provider_id);
      this.form.id = item.id;
      this.form.type = item.type;
      this.form.due_date = item.due_date;
      this.form.total_bill = item.total_bill;
      this.form.received_amount = item.received_amount;
      this.form.due = item.due;
      this.$bvModal.show("modal_form");
    },
    // updateData
    updateData() {
      this.form.put(`vendor-invoice/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;
          this.items.every((item, index) => {
            if (item.id == updatedItem.id) {
              this.$set(this.items, index, updatedItem);
              return false;
            }
            return true;
          });
          this.form.reset();
          this.$s_tost(resp.data.message);
          this.$bvModal.hide("modal_form");
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    openModal(action, val) {
      axios
        .get(`vendor-provider?item=all&status=active`)
        .then((resp) => {
          if (resp.data.success) {
            this.providers_items = [];
            resp.data.data.data.forEach((ele) => {
              const obj = {
                code: ele.id,
                label: `${ele.name}`,
              };
              this.providers_items.push(obj);
            });
            if (action == "add") {
              this.$bvModal.show("modal_form");
            } else {
              const find_item = this.providers_items.find(
                (item) => item.code == val
              );
              if (find_item) {
                this.form.provider = {
                  code: find_item.code,
                  label: `${find_item.label}`,
                };
              }
            }
          } else {
            this.$error_message(resp.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch("spinnerLoading", false);
        });
    },
    // deleteData
    deleteData(item, index) {
      if (item && confirm("Are you sure to delete?")) {
        this.$store.dispatch("spinnerLoading", true);
        axios
          .delete(`vendor-invoice/${item.id}`)
          .then((resp) => {
            if (resp.data.success) {
              this.items.splice(index, 1);
              this.$s_tost(resp.data.message);
            } else {
              this.$error_message(resp.data.message);
            }
          })
          .finally(() => {
            this.$store.dispatch("spinnerLoading", false);
          });
      }
    },

    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
