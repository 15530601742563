<template>
  <div>
    <h2 class="text-center" v-if="permissionError !== ''">
      {{ permissionError }}
    </h2>
    <div v-else>
      <b-row class="match-height">
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
            icon="UsersIcon"
            :statistic="summary.total_packages"
            statistic-title="Total Packages"
          />
        </b-col>
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
            icon="UserCheckIcon"
            color="success"
            :statistic="summary.total_active"
            statistic-title="Active"
          />
        </b-col>
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
            icon="UserIcon"
            color="danger"
            :statistic="summary.total_in_active"
            statistic-title="In Active"
          />
        </b-col>
      </b-row>

      <b-modal id="modal-lg" :title="modal_title" size="sm" hide-footer>
        <form id="form" @submit.prevent="submitHandelar">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="">User Name</label>
              <select
                v-model="form.user_id"
                class="form-control"
                validate
                name="type"
                @change="getMonthlyBill"
              >
                <option value="" selected disabled>
                  ---------Please Select One-------
                </option>
                <option
                  v-for="(user, index) in users"
                  :key="index"
                  :value="user.id"
                >
                  {{ user.email }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="">Amount </label>
              <input
                v-model="form.amount"
                type="text"
                class="form-control"
                placeholder="Amount"
                validate
              />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="">Received Amount </label>
              <input
                v-model="form.received_amount"
                type="text"
                class="form-control"
                placeholder="Received Amount"
                validate
              />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="">Paid By</label>
              <select
                v-model="form.paid_by"
                class="form-control"
                validate
                name="type"
              >
                <option value="" selected disabled>
                  ---------Please Select One-------
                </option>
                <option value="cash">Cash</option>
                <option value="bkash">Bkash</option>
                <option value="nagad">Nagad</option>
              </select>
            </div>
          </div>

          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
      </b-modal>

      <b-card-code :title="title" no-body>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-button
                v-for="(pmsList, index) in getRoleWisePermissions"
                :key="index"
                v-show="pmsList.name == 'Create Invoice'"
                size="sm"
                col="2"
                class="ml-2"
                v-b-modal.modal-lg
                variant="primary"
                @click.prevent="editData.mode = false"
              >
                <feather-icon icon="PlusCircleIcon" size="16" />
                Add New</b-button
              >
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
        >
          <template #cell(zone)="data">
            {{ data.item.zone ? data.item.zone.name : "" }}
          </template>
          <template #cell(package)="data">
            {{ data.item.package ? data.item.package.name : "" }}
          </template>
          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>
          <template #cell(status)="data">
            <b-badge pill class="text-capitalize" variant="success">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-for="(pmsList, index) in getRoleWisePermissions"
                :key="index"
                v-show="pmsList.name == 'Make Invoice Payment'"
                @click="makePayment(data.item.id)"
              >
                <feather-icon icon="DollarSignIcon" class="mr-50" />
                <span>Make Payment</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "NAS",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      title: "Corporate Billing List",
      modal_title: "Add Invoice",
      form: new Form({
        id: "",
        user_id: "",
        amount: "",
        received_amount: 0,
        paid_by: "",
      }),
      fields: [
        { key: "id", label: "ID" },
        { key: "invoice_no", label: "Invoice No" },
        { key: "zone", label: "Zone", sortable: true },
        { key: "package", label: "Package", sortable: true },
        { key: "created_at", label: "Billing Date", sortable: true },
        { key: "amount", label: "Amount" },
        { key: "received_amount", label: "Received Amount" },
        { key: "due_amount", label: "Due Amount" },
        { key: "advanced_amount", label: "Advance Amount" },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      permissionError: "",

      summary: {
        total: 0,
        total_paid: 0,
        total_unpaid: 0,
        total_bill: 0,
        total_received: 0,
        total_due: 0,
      },
    };
  },
  computed: {
    users() {
      return this.$store.state.isp.users;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },

  created() {
    this.getData(this.perPage, this.currentPage);
    this.$store.dispatch("getUsers");
  },
  methods: {
    getMonthlyBill() {
      axios
        .get(`invoice/monthly-bill/${this.form.user_id}`)
        .then((res) => {
          this.form.amount = res.data.data.monthly_bill;
        })
        .catch((err) => console.log(err));
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        console.log("save");
        this.save();
      } else {
        console.log("edit");
        this.update();
      }
    },

    makePayment(id) {
      this.editData.mode = true;
      this.$root.$emit("bv::show::modal", "modal-lg");
      let data = this.items.find((x) => x.id === id);
      this.form = {
        id: data.id,
        user_id: data.user_id,
        amount: data.amount,
        received_amount: data.received_amount,
      };
    },

    //= ========data save=========
    save() {
      if (this.$validation("form") === true) {
        this.form
          .post("invoice/create")
          .then((resp) => {
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.items.data.unshift(resp.data.data);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    //= ========get data for edit=========
    update() {
      if (this.$validation("form") === true) {
        axios
          .post("invoice/update", this.form)
          .then((resp) => {
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    //get package data list
    async getData(perPage, page) {
      this.$store.dispatch("spinnerLoading", true);
      await axios
        .get(`invoice/index?user_type=3&item=${perPage}&page=${page}`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {
            this.items = resp.data.data;
            this.totalRows = resp.data.data.length;
          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
