<template>
	<div>
		<!-- <h5 class="text-center">Pop {{type}}</h5> -->
		<DynamicForm
			:submitForm="create"
			:form="form"
			:type="type"
			:fields="dynamicFormFields"
		/>
	</div>
</template>


<script>

import Form from "vform";
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "Pop",
	components: {  DynamicForm },
	props: [
		"type",
		"lat_lon"
	],
	data() {
	  return {

	  	form: new Form({
	        name: '',
	        lat_lon: ''
	  	}),
		apiEndPoint: 'nm-pops'
	  }

	},
	emits: ['submitted'],
	computed: {
	  dynamicFormFields(){
	  	const formFields = [
			{
				'type': 'text',
				'name': 'name',
				'label': 'Name',
			},
			{
				'type': 'text',
				'name': 'lat_lon',
				'label': 'Latitude Longitude',
				'disabled': true
			}
		]

		return formFields
	  }
	},
	created() {
		if(this.type == 'create'){
			this.form.lat_lon = this.lat_lon
		}
	},
	watch: {
	    'lat_lon': function(newVal, oldVal){
	    	this.form.lat_lon = this.lat_lon
	    }
	  
	},
	methods: {
		
		create() {
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  
				  this.$emit('submitted', item)
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},
 
	},
}
</script>