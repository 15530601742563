<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total"
          statistic-title="Total"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Active"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_inactive"
          statistic-title="In Active"
        />
      </b-col>
    </b-row>

    <b-card-code no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              size="sm"
              col="2"
              v-b-modal.modal-lg
              variant="primary"
              @click="modelAction('add')"
            >
              <!-- @click.prevent="editData.mode = false" -->
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
       
        <template  #cell(message)="data">
          <span class="badge">{{ data.item.message }}</span>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item>
              <button
                class="btn btn-sm p-0"
                @click.prevent="edit(data.item.id)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="deleteData(data.item.id)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <b-pagination-nav
          @input="getData"
          :link-gen="linkGen"
          :number-of-pages="items.last_page"
          :limit="perPage"
          use-router
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "Onu",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      zone: "",
      olts: {},
      customers: {},
      pon_ports: [],
      form: new Form({
        vlan_tagged: false,
        name: "",
        mac: "",
        olt: "",
        onu_id: "",
        pon_port: "",
        rx_power: "",
        distance: "",
        vlan_id: "",
        user_id: "",
        zone_id: "",
      }),
      title: "Onu",
      fields: [
        "number",
        "message_id",
        "sms_apis_id",
        "sms_templates_id",
        "users_id",
        "message",
        "status",
        "status_code",
        "status",
        "action",
      ],
      items: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      modal_title: "New Onu",
      summary: {
        total_user: 0,
        total_active: 0,
        total_inactive: 0,
      },
      lData: null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`sent-sms?page=${this.currentPage}&item${this.perPage}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$store.dispatch("spinnerLoading", false);
            this.items = resp.data.data.data;
            this.summary.total = parseInt(resp.data.data.data.total);
            this.summary.total_active = parseInt(resp.data.data.active);
            this.summary.total_inactive = parseInt(resp.data.data.inactive);
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    linkGen(pageNum) {
      return { path: `/sent-sms?pages=${pageNum}` };
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`sent-sms/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
