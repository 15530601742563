  <template>
  <div>
    <b-row v-if="items.total > 0" class="match-height">
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="DollarSignIcon"
          statistic="BALANCE"
          :statistic-title="summary.balance"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total"
          statistic-title="TOTAL"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_accept"
          statistic-title="DELIVERED"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_deliverd"
          statistic-title="EXPIRED"
        />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- @click.prevent="editData.mode = false" -->
          <div class="d-flex">
            <!-- <b-button
              size="sm"
              col="2"
              v-b-modal.modal-lg
              variant="primary"
              @click="modelAction('add')"
            >
              <feather-icon icon="PlusCircleIcon" size="16" />
              Test
            </b-button> -->
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="items.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at) }}
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item>
              <button class="btn btn-sm p-0" @click.prevent="view(data.item)">
                <feather-icon icon="EyeIcon" class="mr-50" />
                View Info
              </button>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <button class="btn btn-sm p-0" @click.prevent="edit(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item> -->
            <b-dropdown-item @click.prevent="deleteData(data.item.id)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "SmsReport",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        name: "",
        // template: '',
        template: "",
      }),
      title: "SMS Report",
      fields: [
        { key: "id", label: "#ID", sortable: true },
        { key: "message_id", label: "Message Id", sortable: true },
        { key: "number", label: "Mobile Number", sortable: true },
        { key: "message", label: "Message" },
        { key: "created_at", label: "Done Date", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      modal_title: "SmsReport",
      summary: {
        balance: 0,
        total_user: 0,
        total_accept: 0,
        total_deliverd: 0,
      },
      lData: null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    modelAction() {
      this.axios
        .get("sendsms/getallmessage")
        .then((resp) => {
          // if (resp.data.success) {
          //   this.$bvModal.hide("modal-lg");
          //   this.getData();
          //   this.$s_tost(resp.data.message);
          // } else {
          //   this.$error_message(resp.data.message, resp.data.errors);
          // }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ========data save=========
    save() {
      this.form
        .post("sms-template")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
            this.$s_tost(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`sms-template/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    //= ========get data for edit=========

    update() {
      this.form
        .put(`sms-template/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            // this.$success_message(resp.data.message);
            this.$s_tost(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========
    view(val) {},

    async edit(data) {
      if (data) {
        this.form.name = data.name;
        this.form.template = data.template;
        this.modal_title = "Update";
        this.editData.mode = true;
        this.editData.id = data.id;
        this.$bvModal.show("modal-lg");
      } else {
        this.$error_message(resp.data.message);
      }
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },

    getData() {
      this.$store.dispatch("primary_api_balance_action");
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`sms-report?page=${this.currentPage}&item${this.perPage}`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          console.log(resp.data.data);
          if (resp.data.success) {
            this.items = resp.data.data.data;
            this.summary.total = parseInt(resp.data.data.data.total);
            this.summary.total_accept = resp.data.data.ACCEPTD;
            this.summary.total_deliverd = resp.data.data.DELIVRD;
            this.summary.balance = this.$store.state.sms.primary_api_balance;
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`sms-template/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
