import axios from "axios";
import { $themeBreakpoints } from '@themeConfig'
import router from '../../router'
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    assets_url: "http://127.0.0.1:8000/",
    shallShowOverlay: false,
    googleMapKey: null,
    system_defaults: null
  },

  // ########## all getters start ########
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    googleMapKey: (state) => {
      if (state.googleMapKey == null && localStorage.getItem('googleMapKey')) {
        return localStorage.getItem('googleMapKey')
      }
      return state.googleMapKey
    },
    system_defaults_getter: (state) => {
      return state.system_defaults
    }
  },

  // ########## all mutations start ########
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    UPDATE_GOOGLE_MAP_KEY(state, val) {
      state.googleMapKey = val !== undefined ? val : null
      localStorage.setItem('googleMapKey', state.googleMapKey)
    },
    get_softwer_system_mutation(state, val) {
      state.system_defaults = val
    },
  },

  // ########## all Actions start ######## 
  actions: {
    async setGoogleMapKey(context) {
      await axios.get(`general/setting`).then((res) => {
        if (res.data.data.google_map_api_key) {
          context.commit("UPDATE_GOOGLE_MAP_KEY", res.data.data.google_map_api_key)
        }
        // } else if (router.currentRoute.fullPath !== '/settingGeneral' && confirm('Google map key not found! Setup it from settings.')) {
        //   router.push('/settingGeneral')
        // }
      });
    },

    async get_system_info(context) {
      await axios.get("software-system?all").then((resp) => {
        if (resp.data.success) {
          context.commit("get_softwer_system_mutation", resp.data.data);
        }
      });
    },
  },
}
