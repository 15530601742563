<template>
	<div>
		<!-- <h5 class="text-center">Rack {{type}}</h5> -->
		<DynamicForm
			:submitForm="create"
			:form="form"
			:type="type"
			:fields="dynamicFormFields"
		/>
	</div>
</template>


<script>

import Form from "vform";
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "Rack",
	components: {  DynamicForm },
	props: [
		"type",
	],
	data() {
	  return {

	  	form: new Form({
	        name: "",
	        type: "open",
	        no_of_u: 0,
	        pop_id: '',
	  	}),
		apiEndPoint: 'nm-racks',
		typeOptions: [{
			'title': 'Open',
			'value': 'open'
			}, {
			'title': 'Close',
			'value': 'close'
		}],
		pops: []
	  }

	},
	emits: ['submitted'],
	computed: {
	  dynamicFormFields(){
	  	
	  	const formFields = [
			{
				'type': 'text',
				'name': 'name',
				'label': 'Name',
			},
			{
				'type': 'select',
				'name': 'type',
				'label': 'Type',
				'options': this.typeOptions	
			},
			{
				'type': 'number',
				'name': 'no_of_u',
				'label': 'Total Number Of U',
			},
			{
				'type': 'select',
				'name': 'pop_id',
				'label': 'Pop',
				'options': this.pops,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				}	
			},
		]


		return formFields
	  }
	},
	created() {
		this.getPops()
	},
	watch: {
	},
	methods: {
		
		create() {
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  
				  this.$emit('submitted', item)
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},

		getPops() {
		  axios.get(`nm-pops?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.pops = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
 
	},
}
</script>