<template>
	<div>
		<!-- Enclouser Detail
		<hr> -->
		<template
			v-if="loading"
		>
			Loading ...
		</template>
		<template
			v-else-if="item"
		>
			<table class="table table-bordered table-hover table-striped" style="word-break: break-word">
				<tbody>
					<tr>
						<th>Name</th>
						<td>{{item.name}}</td>
					</tr>
					<tr>
						<th>Location</th>
						<td>{{item.route}}</td>
					</tr>
					<tr>
						<th>Type</th>
						<td>{{item.type.name}}</td>
					</tr>
					<tr>
						<th>No Of Port</th>
						<td>{{item.type.no_of_ports}}</td>
					</tr>
					<tr>
						<th>No of loops</th>
						<td>{{item.no_of_loops}}</td>
					</tr>
					<tr>
						<th>Loop location</th>
						<td>{{item.loop_location}}</td>
					</tr>
					<tr>
						<th>Installed By</th>
						<td>{{item.installed_by}}</td>
					</tr>
					<tr>
						<th>Installed At</th>
						<td>{{item.installed_at}}</td>
					</tr>
					<tr>
						<th>Comment</th>
						<td>{{item.comment}}</td>
					</tr>
					<tr>
						<th>Primary Fiber</th>
						<td>
							<template v-if="item.primary_fiber">
							{{item.primary_fiber.cable_id}} ({{item.primary_fiber.meter_length_from}}m - {{item.primary_fiber.meter_length_to}}m)
							</template>
						</td>
					</tr>
					<tr>
						<th>Splitter</th>
						<td>
							<template v-if="item.splitter">
								{{item.splitter.name}}
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</template>
	</div>
</template>
<script>
import axios from "axios";
export default {
	name: "Enclouser",
	components: { },
	props: {
		"id" : Number,
	},
	data() {
	  	return {
			item: null,
			apiEndPoint: 'nm-enclousers',
			loading: false
	  	}
	},
	watch: {
		'id': function(newVal, oldVal){
			this.getDetail()
		}
	},
	computed: {
	},
	mounted(){
		this.getDetail()
	},
	methods: {
		getDetail() {
			this.loading = true
			axios.get(`${this.apiEndPoint}/${this.id}`).then((resp) => {
				if (resp.data.success) {
				  this.item = resp.data.data;

				} else {
				  
				}
			}).finally(()=> {
				this.loading = false
			});
		},
	}
}
</script>