<template>
	<div>
	  <b-row>
        <b-col
          cols="12"
        >
          <b-card>

		    <b-row>

		      <!-- User Info: Left col -->
		      <b-col
		        cols="21"
		        md="6"
		        class="d-flex justify-content-between flex-column"
		      >
		        <!-- User Avatar & Action Buttons -->
		        <div class="d-flex justify-content-start">
		          <b-avatar
		            size="104px"
		            rounded
		          />
		          <div class="d-flex flex-column ml-1">
		            <div class="mb-1">

				      <b-badge variant="light-primary">
				        Supplier
				      </b-badge>
		              <h4 class="mb-0 mt-50">
		                {{ item.name }}
		              </h4>
		              <span class="card-text">{{ item.email_address }}</span>
		            </div>
		          </div>
		        </div>

		      </b-col>

		      <!-- Right Col: Table -->
		      <b-col
		        cols="12"
		        md="6"
		      >
		        <table class="mt-2 mt-xl-0 w-100">
		          <!-- <tr>
		            <th class="pb-50">
		              <feather-icon
		                icon="UserIcon"
		                class="mr-75"
		              />
		              <span class="font-weight-bold">Username</span>
		            </th>
		            <td class="pb-50">
		              {{ item.username }}
		            </td>
		          </tr> -->
		          <tr>
		            <th class="pb-50">
		              <feather-icon
		                icon="CheckIcon"
		                class="mr-75"
		              />
		              <span class="font-weight-bold">Status</span>
		            </th>
		            <td class="pb-50 text-capitalize">
		              {{ item.status?'Active':'Inactive' }}
		            </td>
		          </tr>
		          <!-- <tr>
		            <th class="pb-50">
		              <feather-icon
		                icon="StarIcon"
		                class="mr-75"
		              />
		              <span class="font-weight-bold">Role</span>
		            </th>
		            <td class="pb-50 text-capitalize">
		              {{ item.role }}
		            </td>
		          </tr>
		          <tr>
		            <th class="pb-50">
		              <feather-icon
		                icon="FlagIcon"
		                class="mr-75"
		              />
		              <span class="font-weight-bold">Country</span>
		            </th>
		            <td class="pb-50">
		              {{ item.country }}
		            </td>
		          </tr> -->
		          <tr
		          	v-if="item.mobile_number && item.mobile_number[0]"
		          >
		            <th>
		              <feather-icon
		                icon="PhoneIcon"
		                class="mr-75"
		              />
		              <span class="font-weight-bold">Contact</span>
		            </th>
		            <td>
		              {{ item.mobile_number[0] }}
		            </td>
		          </tr>
		        </table>
		      </b-col>

		      <!-- User Stats -->
		      <b-col cols="12">
		        <div class="d-flex align-items-center mt-2 flex-wrap">
		        	<div class="d-flex align-items-center mr-2">
		        	  <b-avatar
		        	    variant="light-warning"
		        	    rounded
		        	  >
		        	    <feather-icon
		        	      icon="DatabaseIcon"
		        	      size="18"
		        	    />
		        	  </b-avatar>
		        	  <div class="ml-1">
		        	    <h5 class="mb-0">
		        	      ৳{{ item.payment_stat.total_amount.toLocaleString('en-US') }}
		        	    </h5>
		        	    <small>Total Purchase</small>
		        	  </div>
		        	</div>
		          

		          <div class="d-flex align-items-center mr-2">
		            <b-avatar
		              variant="light-success"
		              rounded
		            >
		              <feather-icon
		                icon="PlusIcon"
		                size="18"
		              />
		            </b-avatar>
		            <div class="ml-1">
		              <h5 class="mb-0">
		                ৳{{ item.payment_stat.total_paid_amount.toLocaleString('en-US') }}
		              </h5>
		              <small>Total Paid</small>
		            </div>
		          </div>
		          <div class="d-flex align-items-center mr-2">
		            <b-avatar
		              variant="light-danger"
		              rounded
		            >
		              <feather-icon
		                icon="MinusIcon"
		                size="18"
		              />
		            </b-avatar>
		            <div class="ml-1">
		              <h5 class="mb-0">
		                ৳{{ item.payment_stat.due_amount.toLocaleString('en-US') }}
		              </h5>
		              <small>Total Due</small>
		            </div>
		          </div>

		        </div>
		      </b-col>
		    </b-row>
		  </b-card>
        </b-col>
      </b-row>

      <b-row>
      	<b-col
      		cols="12"
      	>
      		<b-card>
		        <b-table
		          striped
		          hover
		          responsive
		          class="position-relative"
		          ref="table"
		          :per-page="item.purchases.length"
		          
		          :items="item.purchases"
		          :fields="fields"
		        >


		          <template #cell(index)="{index}">
		          	{{ index+1 }}
		          </template>


		          <template #cell(purchase_reference)="{item,index}">
		          	# {{ item.id }}
		          </template>
		          
		          <template #cell(products)="{item, index}">
		          	<ol 
		          		v-if="item.items.length > 0"
		          		class="p-0 m-0" 
		          	>
		          		<li v-for="product in item.items">
		          			{{product.product.name}}
		          		</li>
		          	</ol>
		          </template>


		          <template #cell(status)="{item, index}">
		            <b-badge
		              pill
		              :variant="`light-${item.payment_stat.status == 'paid'?'success':(item.payment_stat.status == 'over-paid'?'warning':'danger')}`"
		              class="text-capitalize"
		            >
		              {{item.payment_stat.status.replaceAll('-', ' ')}}
		            </b-badge>
		          </template>

		          <template #cell(total_amount)="{item, index}">
		          	৳{{item.payment_stat.total_amount.toLocaleString("en-US")}}
		          </template>
		          <template #cell(discount)="{item, index}">
		          	৳{{item.payment_stat.discount.toLocaleString("en-US")}}
		          </template>

		          <template #cell(due)="{item, index}">
		          	৳{{item.payment_stat.due_amount.toLocaleString("en-US")}}
		          </template>

		          <template #cell(paid)="{item, index}">
		          	৳{{item.payment_stat.total_paid_amount.toLocaleString("en-US")}}
		          </template>
		          
		        </b-table>
      		</b-card>
      	</b-col>
      </b-row>
	</div>
</template>
<script>

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "SupplierDetail",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
    	id: null,
    	item: {},

	    fields: [
	        
	        { key: "index", label: "#", sortable: true },
	        { key: "purchase_reference", label: "Purchase Reference", sortable: true },
	        { key: "products", label: "Product(s)", sortable: true },
	        { key: "purchased_at_formatted", label: "Purchase Date", sortable: true },
	        { key: "total_amount", label: "Total Amount", sortable: true },
	        { key: "discount", label: "Discount", sortable: true },
	        { key: "paid", label: "Paid", sortable: true },
	        { key: "due", label: "Due", sortable: true },
	        { key: "status", label: "Status", sortable: true },
	        
	    ]
    }
  },
  created(){
  	this.id = this.$route.params.id;
  	this.getData()
  },
  methods:{
  	 getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`inventory-suppliers/${this.id}`).then((resp) => {
        if (resp.data.success) {
          this.item = resp.data.data;

        } else {
          this.$error_message(resp.data.message);
        }
      }).finally(()=> {
        this.$store.dispatch("spinnerLoading", false);
      });
    },
  }
}
</script>