<template>
	<div>
		<h5 class="text-center">Fiber Core Info</h5>
		<DynamicForm
			:no_submit="true"
			:form="form.fiber_core"
			type="create"
			:fields="dynamicFormFields"
		/>
	</div>
</template>


<script>
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "FiberCore",
	components: {  DynamicForm },
	props: {
		"form" : Object
	},
	data() {
	  return {
      fibers: [],
      tubes: [],
      colorOptions: [{
			'title': 'Blue',
			'value': 'blue'
		}, {
			'title': 'Orange',
			'value': 'orange'
		}, {
			'title': 'Green',
			'value': 'green'
		}, {
			'title': 'Brown',
			'value': 'brown'
		}, {
			'title': 'Slate',
			'value': 'slate'
		}, {
			'title': 'White',
			'value': 'white'
		}, {
			'title': 'Red',
			'value': 'red'
		}, {
			'title': 'Black',
			'value': 'black'
		}, {
			'title': 'Yellow',
			'value': 'yellow'
		}, {
			'title': 'Violet',
			'value': 'violet'
		}, {
			'title': 'Rose',
			'value': 'rose'
		}, {
			'title': 'Aqua',
			'value': 'aqua'
		}],
      booleanOptions: [{
			'title': 'Yes',
			'value': 1
		}, {
			'title': 'No',
			'value': 0
		}],
      statusOptions: [{
			'title': 'Active',
			'value': 1
		}, {
			'title': 'Inactive',
			'value': 0
		}]
	  }

	},
	computed: {
	  dynamicFormFields(){
	  	const formFields = [{
				'type': 'select',
				'name': 'fiber_id',
				'label': 'Fiber',
				'options': this.fibers,
				'option_settings': {
					'title': 'cable_id',
					'value': 'id',
					'template': {
						'title': '%s (%sm - %sm)',
						'value': [
							'cable_id', 
							'meter_length_from',
							'meter_length_to'
						]
					}
				}
			},{
				'type': 'select',
				'name': 'tube_id',
				'label': 'Tube',
				'options': this.tubes,
				'option_settings': {
					'title': 'color',
					'value': 'id',
					'template': {
						'title': '%s-%s',
						'value': [
							'number', 
							'color'
						]
					}
				}
			},
			{
				'type': 'number',
				'name': 'number',
				'label': 'Number',
			},
			{
				'type': 'select',
				'name': 'color',
				'label': 'Color',
				'options': this.colorOptions	
			},
			{
				'type': 'textarea',
				'name': 'comment',
				'label': 'Comment',
			},
			{
				'type': 'select',
				'name': 'status',
				'label': 'Status',
				'options': this.statusOptions	
			}
		]

		let color_index = this.form.fiber_core.number % 12 == 0 ? 12 : this.form.fiber_core.number % 12

		this.form.fiber_core.color = this.colorOptions[color_index-1].value

		return formFields
	  }
	},
	created() {
		this.form.fiber_core = {
	        fiber_id: null,
	        tube_id: null,
	        number: 1,
	        color: null,
	        comment: null,
	        status: 1,
	      }
	 	this.getFibers()
	},
	watch: {
	    'form.fiber_id': {
	       handler: function(newVal, oldVal) {
	           this.getTubes()
	       },
	       deep: true	
	    }
	  
	},
	methods: {
		

		getFibers() {
		  axios.get(`nm-fibers?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.fibers = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},

		getTubes() {
		  axios.get(`nm-fiber-tubes?page=1&item=all&fiber_id=${this.form.fiber_id}`).then((resp) => {
		    if (resp.data.success) {
		      this.tubes = resp.data.data.data;
		      if(this.tubes.length === 0){
		      	this.form.tube_id = null
		      }
		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
 
	},
}
</script>