<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total_user"
          statistic-title="Total Corporate User"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_paid_user"
          statistic-title="Total Paid Customer"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_unpaid_user"
          statistic-title="Total Unpaid Customer"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_bill"
          statistic-title="Total Bill"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_receive"
          statistic-title="Total Receive"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_due"
          statistic-title="Total Due"
        />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button
              size="sm"
              col="2"
              class="ml-2"
              variant="primary"
              :to="{ name: 'customerAdd' }"
            >
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
          </div>
          <!-- filter -->
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(customer_type)>Home</template>
        <template #cell(zone)="data">
          {{
            data.item.billing_info ? data.item.billing_info.zone.name : "n/a"
          }}
        </template>

        <template #cell(mikrotik)="data">
          {{
            data.item.connection_info
              ? data.item.connection_info.mikrotik.identity
              : "n\a"
          }}
        </template>

        <template #cell(mikrotik_status)="data">
          <b-badge
            pill
            :variant="`${statusHtml(
              data.item.connection_info
                ? data.item.connection_info.mikrotik.status
                : 0
            )}`"
            class="text-capitalize"
          >
            {{
              statusAction(
                data.item.connection_info
                  ? data.item.connection_info.mikrotik.status
                  : 0
              )
            }}
          </b-badge>
        </template>

        <template #cell(mac_address)="data">
          {{
            data.item.connection_info
              ? data.item.connection_info.mac_address
              : "n/a"
          }}
        </template>

        <template #cell(billing_status)="data">
          <b-badge
            pill
            :variant="`${statusHtml(
              data.item.billing_info ? data.item.billing_info.status : 1
            )}`"
            class="text-capitalize"
          >
            {{
              statusAction(
                data.item.billing_info ? data.item.billing_info.status : 1
              )
            }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-for="(pms, index) in getRoleWisePermissions"
              :key="index"
              v-show="pms.name == 'Daily Income View'"
              @click="viewItem(data.item)"
            >
              <span>
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>View</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link
                :to="{ name: 'customerEdit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>Edit</span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(pms, index) in getRoleWisePermissions"
              :key="index"
              v-show="pms.name == 'Daily Income Delete'"
              @click.prevent="deleteData(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
  </div>
</template>
<script>
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { statusAction } from "@/utils/helpers/functions";
// const Swal = require('sweetalert2')

export default {
  name: "UserManage",
  components: {
    BCardCode,
    StatisticCardHorizontal,
  },

  data() {
    return {
      title: "Corporate User list",
      loading: true,

      fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "customer_type", label: "Cus Type" },
        { key: "zone", label: "Zone", sortable: true },
        { key: "mikrotik", label: "Mikrotik", sortable: true },
        { key: "mikrotik_status", label: "Mikrotik Status" },
        { key: "mac_address", label: "Mac Address" },
        { key: "mac_bind", label: "Mac Bind" },
        { key: "expire_date", label: "Expire Date" },
        { key: "billing_status", label: "Billing Status" },
        { key: "due", label: "Due" },
        { key: "action", label: "Action" },
      ],
      statusAction,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      items: [],

      user: {},
      type: "",
      summary: {
        total_user: 0,
        total_paid_user: 0,
        total_unpaid_user: 0,
        total_bill: 0,
        total_receive: 0,
        total_due: 0,
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    mikrotik() {
      return this.$store.state.isp.others.mikrotik;
    },
    nas() {
      return this.$store.state.isp.others.nas;
    },
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },

  created() {
    this.getData();
    this.getSummaryData();
  },
  methods: {
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      if (this.$route.name == "homeCustomer") {
        this.type = "home";
      } else if (this.$route.name == "corporateCustomer") {
        this.type = "corporate";
      }
      axios.get(`customer?type=${this.type}&page=${page}`).then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.items = resp.data.data.data;
          this.totalRows = resp.data.data.data.length;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getSummaryData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`customer-summary?type=${this.type}`).then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.summary.total_user = resp.data.data.total_user;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    statusHtml(status) {
      return this.$resolveStatusVariant(status);
    },
    userDetails(user) {
      this.user = user;
      this.$bvModal.show("my-modal");
    },
  },
};
</script>
