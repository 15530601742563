/* eslint-disable indent */
/* eslint-disable space-before-blocks */
/* eslint-disable comma-dangle */
/* eslint-disable space-infix-ops */
/* eslint-disable no-return-assign */
import axios from "axios";
import helper from "@/helper";
export default {
  state: {
    copyFor: "",
    simpleQueue: {},
    queues: [],
    queueType: [],
    allImterfaces: [],
    onu_module_info: {},
    ticketCategory: [],
    // user & system grace
    grace: null,
    // user & system grace
  },
  getters: {
    // ticketCategoryGetter
    ticketCategoryGetter(state) {
      return state.ticketCategory;
    },
    simpleQueueGetter(state) {
      return state.simpleQueue;
    },
    queueGetter(state) {
      return state.queues;
    },
    queueTypeGetter(state) {
      return state.queueType;
    },
    allImterfacesGetter(state) {
      return state.allImterfaces;
    },
    graceGretter(state) {
      return state.grace;
    },
  },
  // ###########
  // ========= actions ==========
  // ###########
  actions: {
    // appdefaultsaction
    async appdefaultsAction(context, payload) {
      await axios.get(`get/system/settings`).then((res) => {
        context.commit("ticaketCategoryMutation", res.data.data);
      });
    },
    // ticketCategory action
    async ticketCategoryeAction(context, payload) {
      await axios.get(`all-ticket-category`).then((res) => {
        // console.log('tkcat', res.data.data);
        context.commit("ticaketCategoryMutation", res.data.data);
      });
    },
    // queues
    async queueAction(context, payload) {
      await axios.get(`queue_all_mikrotik?mikrotik=${payload}`).then((res) => {
        context.commit("queueMutation", res.data.data);
      });
    },
    // getSimpleQueue
    async getSimpleQueueAction(context, payload) {
      await axios
        .get(`all-queue-by-mikrotik?mikrotik=${payload}`)
        .then((res) => {
          context.commit("getSimpleQueueMutation", res.data.data);
        });
    },
    getqueueTypeAction(context) {
      axios.get("queuetype/withselect").then((res) => {
        if (res.data.success) {
          context.commit("queueTypeMutation", res.data.data);
        }
      });
    },
    allImterfacesAction(context) {
      axios.get("interface-withselect").then((res) => {
        context.commit("allImterfacesMutation", res.data.data);
      });
    },

    /// /=========get franchises data
    async moduleOnuAtion(context, payload) {
      const resp = await axios.get("onumodule", {
        params: {
          ...payload,
        },
      });
      if (resp.data.success) {
        context.commit("moduleOnuMution", resp.data.data);
      } else {
        this.$error_message(resp.data.message);
      }
    },

    // user grace user or system action
    graceGetAction(context, payload) {
      // for = system-grace, user-grace
      axios.get(`get-grace?gracefor=${payload}`).then((res) => {
        const data =
          payload == "system-grace"
            ? res.data.data.value
            : payload == "user-grace"
            ? res.data.data.allow_grace_mark
            : "";
        console.log(payload, data);
        context.commit("graceGetMutation", data);
      });
    },
  },

  // ###########
  // ========= mutations==========
  // ###########
  mutations: {
    ticaketCategoryMutation(state, payload) {
      console.log(payload);
      state.ticketCategory = payload;
    },
    getSimpleQueueMutation(state, payload) {
      state.simpleQueue = payload;
    },
    queueMutation(state, payload) {
      state.queues = payload;
    },

    queueTypeMutation(state, payload) {
      state.queueType = payload;
    },

    allImterfacesMutation(state, payload) {
      state.allImterfaces = payload;
    },
    moduleOnuMution(state, payload) {
      state.onu_module_info = payload;
    },
    graceGetMutation(state, payload) {
      state.grace = payload;
    },
  },
};
