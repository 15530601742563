/* eslint-disable vue/no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable vue/html-indent */
/* eslint-disable vue/html-indent */
/* eslint-disable quote-props */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
<template>
  <div class="card p-2">
    <form id="form" @submit.prevent="update()">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="">Nas Name</label>
            <input
              v-model="form.nasname"
              name="nasname"
              type="text"
              class="form-control"
              placeholder="Enter Nas Name"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Nas Ip address</label>
            <input
              v-model="form.ipaddress"
              type="text"
              class="form-control"
              placeholder="Enter Ip address"
              validate
              name="Ip_address"
            />
          </div>
          <div class="form-group">
            <label for="">Select Nas Type</label>
            <select v-model="form.type" class="form-control">
              <option value="" selected disabled>
                --------Please Select One------
              </option>
              <option value="other">Mikrotik</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Nas Secret</label>
            <input
              v-model="form.secret"
              type="text"
              class="form-control"
              placeholder="Enter Nas Secret"
            />
          </div>
          <div class="form-group">
            <label for="">Nas Port</label>
            <input
              type="number"
              v-model="form.ports"
              class="form-control"
              placeholder="Enter Nas Port"
            />
          </div>
          <div class="form-group">
            <label for="">Incoming Port</label>
            <input
              v-model="form.incoming_port"
              type="text"
              class="form-control"
              placeholder="Incoming Port"
            />
          </div>
          <div class="form-group">
            <label for="">Community</label>
            <input
              v-model="form.community"
              type="text"
              class="form-control"
              placeholder="community"
            />
          </div>
          <div class="form-group">
            <label for="">Description</label>
            <input
              v-model="form.description"
              type="text"
              class="form-control"
              placeholder="Description"
            />
          </div>
          <div>
            <b-form-checkbox
              @change="toggleswitch"
              v-model="form.checked"
              name="check-button"
              switch
            >
              Set Mikrotik
              <b>
                <span v-if="!form.checked">Default: </span>{{ form.checked }}</b
              >
            </b-form-checkbox>
          </div>
        </div>
        <div v-if="form.checked" class="col-lg-6">
          <div class="form-group">
            <label for="">Select MikroTik</label>
            <select
              @change="selectmikrotik()"
              v-model="form.mikrotiks"
              class="form-control"
              id=""
            >
              <option value="" selected>--------Please Select One------</option>
              <option
                v-for="mktitem in mikrotiklist"
                :key="mktitem.id"
                :value="mktitem"
              >
                {{ mktitem.identity }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">MikroTik Identity</label>
            <input
              v-model="form.identity"
              name="nasname"
              type="text"
              class="form-control"
              placeholder="MikroTik Identity"
            />
          </div>
          <div class="form-group">
            <label for="">MikroTik IP</label>
            <input
              v-model="form.host"
              type="text"
              class="form-control"
              placeholder="MikroTik IP"
              name="Ip_address"
            />
          </div>

          <div class="form-group">
            <label for="">API User Name</label>
            <input
              v-model="form.username"
              type="text"
              class="form-control"
              placeholder="API User Name"
            />
          </div>
          <div class="form-group" v-if="!form.mikrotik_id">
            <label for="">API User Password</label>
            <input
              v-model="form.password"
              type="text"
              class="form-control"
              placeholder="API  Password"
            />
          </div>
          <div class="form-group">
            <label for="">API Port</label>
            <input
              v-model="form.port"
              type="text"
              class="form-control"
              placeholder="API Port"
            />
          </div>
          <div class="form-group">
            <label for="">Site Name</label>
            <input
              v-model="form.sitename"
              type="text"
              class="form-control"
              placeholder="Site Name"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Address</label>
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              placeholder="Address"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 m-auto">
          <div class="d-flex">
            <button class="btn btn-primary btn-block">Submit</button>
            <router-link
              :to="{ name: 'ViewNas' }"
              class="btn btn-success mt-0 ml-3 btn-block"
              >Back</router-link
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Form from "vform";

export default {
  name: "NasAdd",
  data() {
    return {
      form: new Form({
        nasname: "",
        mikrotiks: "",
        mikrotik_id: "",
        shortname: "",
        ipaddress: "",
        type: "other",
        ports: null,
        secret: "secret",
        incoming_port: "",
        server_info: "",
        community: "",
        description: "RADIUS Client",
        // kikrotiks items
        identity: "",
        host: "",
        username: "",
        password: "",
        port: "",
        sitename: "",
        address: "",
        checked: false,
      }),
      mikrotiklist: [],
    };
  },
  async created() {
    this.edit();
    const resp = await this.axios.get("allmikrotik");
    if (resp.data.success) {
      this.mikrotiklist = resp.data.data;
    } else {
      this.$error_message(resp.data.message);
    }
  },

  methods: {
    async toggleswitch() {
      if (this.form.checked) {
        const resp = await this.axios.get("allmikrotik");
        if (resp.data.success) {
          this.mikrotiklist = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      } else {
        this.form.mikrotiks = "";
        this.form.mikrotik_id = "";
        this.form.identity = "";
        this.form.host = "";
        this.form.username = "";
        this.form.password = "";
        this.form.port = "";
        this.form.sitename = "";
        this.form.address = "";
      }
    },
    // onchange action selectmikrotik
    selectmikrotik() {
      this.form.identity = this.form.mikrotiks.identity;
      this.form.mikrotik_id = this.form.mikrotiks.id;
      this.form.host = this.form.mikrotiks.host;
      this.form.username = this.form.mikrotiks.username;
      this.form.port = this.form.mikrotiks.port;
      this.form.sitename = this.form.mikrotiks.sitename;
      this.form.address = this.form.mikrotiks.address;
    },
    // getmMkTInfo if exists mikrotik id
    async getmMkTInfo(data) {
      this.form.checked = true;
      const findmkt = await this.axios.get(`mikrotik/${data}`);
      if (findmkt.data.mikrotik) {
        const mkData = findmkt.data.mikrotik;
        this.form.mikrotiks = mkData;
        this.form.identity = mkData.identity;
        this.form.mikrotik_id = mkData.id;
        this.form.host = mkData.host;
        this.form.username = mkData.username;
        this.form.port = mkData.port;
        this.form.sitename = mkData.sitename;
        this.form.address = mkData.address;
      } else {
        this.$error_message(findmkt.data.message);
      }
    },
    // edit defalut created method
    async edit() {
      await this.axios.get(`nas/${this.$route.params.id}`).then((resp) => {
        if (resp.data.nas) {
          const { nas } = resp.data;
          this.form.ipaddress = nas.ipaddress;
          this.form.nasname = nas.nasname || "";
          this.form.type = nas.type || "";
          this.form.ports = nas.ports || "";
          this.form.secret = nas.secret || "";
          this.form.server = nas.server || "";
          this.form.incoming_port = nas.incoming_port || "";
          this.form.community = nas.community || "";
          this.form.description = nas.description || "";
          if (nas.mikrotik_id) {
            this.getmMkTInfo(nas.mikrotik_id);
          }
        }
      });
    },
    update() {
      if (this.$validation("form") === true) {
        this.form
          .put(`nas/${this.$route.params.id}`)
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message, true, "/ViewNas");
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
