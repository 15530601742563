/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="shadow p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <b-button
          v-b-modal.modal-lg
          variant="primary"
          size="sm"
          @click.prevent="editData.mode = false"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />

          Add New</b-button
        >
      </div>
      <b-modal id="modal-lg" :title="modal_title" size="lg">
        <form id="form" @submit.prevent="submitHandelar">
          <div class="form-group">
            <label for="">Brand</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Name"
              validate
            />
          </div>

          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <div
              v-if="loading"
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <tr v-for="(item, index) in items.data" v-else :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.name }}</td>

              <td>
                <button
                  class="btn btn-sm btn-success"
                  @click.prevent="edit(item.id)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  :action="'brand/' + item.id"
                  @click.prevent="$trash($event)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :data="items"
          align="right"
          @pagination-change-page="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  name: "OLT",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        name: "",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Create Brand",
      items: {},
    };
  },

  created() {
    this.getData();
  },
  methods: {
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      if (this.$validation("form") === true) {
        this.form
          .post("brand")
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    //= ========get data for edit=========

    update() {
      if (this.$validation("form") === true) {
        this.form
          .put(`brand/${this.editData.id}`)
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    //= ========get data for edit=========

    edit(id) {
      const item = this.items.data.find((ele) => ele.id === id);
      this.modal_title = `Update Brand:${item.name}`;

      this.form.name = item.name;

      this.editData.mode = true;
      this.editData.id = item.id;
      this.$bvModal.show("modal-lg");
    },
    getData(page = 1) {
      this.axios
        .get(`brand?page=?${page}`)
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.success) {
            this.items = resp.data.data;
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
