/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="shadow p-2">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <form id="form" @submit.prevent="save()">
          <div class="form-group">
            <label for="">MikroTik Identity</label>
            <input
              v-model="form.identity"
              name="nasname"
              type="text"
              class="form-control"
              placeholder="MikroTik Identity"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">MikroTik IP</label>
            <input
              v-model="form.host"
              type="text"
              class="form-control"
              placeholder="MikroTik IP"
              validate
              name="Ip_address"
            />
          </div>

          <div class="form-group">
            <label for="">API User Name</label>
            <input
              v-model="form.username"
              type="text"
              class="form-control"
              placeholder="API User Name"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">API User Password</label>
            <input
              v-model="form.password"
              type="text"
              class="form-control"
              placeholder="API  Password"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">API Port</label>
            <input
              v-model="form.port"
              type="text"
              class="form-control"
              placeholder="API Port"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Site Name</label>
            <input
              v-model="form.sitename"
              type="text"
              class="form-control"
              placeholder="Site Name"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Address</label>
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              placeholder="Address"
              validate
            />
          </div>
          <div class="row">
            <div class="col-lg-8">
              <button class="btn btn-primary btn-block">Submit</button>
            </div>
            <div class="col-lg-4">
              <router-link
                :to="{ name: 'mikrotik' }"
                class="btn btn-success btn-block"
                >Back</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";

export default {
  name: "NasAdd",
  data() {
    return {
      form: new Form({
        identity: "",
        host: "",
        username: "",
        password: "",
        port: "",
        sitename: "",
        address: "",
      }),
    };
  },
  methods: {
    save() {
      if (this.$validation("form") === true) {
        this.form
          .post("mikrotik")
          .then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message, true, "/mikrotik");
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log("testing");
            this.$error_message(resp.data.message, e);
          });
      }
    },
  },
};
</script>
