<template>
  <div>
    <div>
      <b-row class="match-height">
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
              icon="UsersIcon"
              :statistic="this.total_salary_paid"
              statistic-title="Total Salary Paid"
          />
        </b-col>
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
              icon="UserCheckIcon"
              color="success"
              :statistic="this.total_salary_due"
              statistic-title="Total Salary Due"
          />
        </b-col>
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
              icon="UserIcon"
              color="danger"
              :statistic="this.total_employee"
              statistic-title="Total Employee"
          />
        </b-col>
      </b-row>


      <b-button
          class="float-right"
          v-b-modal.modal-lg
      >
          Add Payroll
      </b-button>

      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="lg">
        <form @submit.prevent="submitHandelar">
          <div class="row">

            <div class="col-12">
              <div class="form-group">
                <label for="">Employee Id</label>
                <input
                    id="employee"
                    v-model="form.employee_id"
                    type="text"
                    class="form-control"
                    name="name"
                    placeholder="Employee Id"
                />
              </div>
            </div>

          </div>

        <div class="row">
          <div class="col-12">
            <input
                v-model="form.month"
                id="month"
                type="month"
                class="form-control"
            />
          </div>
        </div>



        <div class=" align-items-center">
          <div class="form-group">
            <button @click="GetEmployeeDetails" type="button">Find Employee</button>
          </div>
        </div>

        <div id="details_section" class="d-none">
          <div>
            <div class="form-group">
              <label>Employee Name</label>
              <input id="employee_name" v-model="form.employee_name" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Department</label>
              <input id="department" v-model="form.department" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Designation</label>
              <input id="designation" type="text" v-model="form.designation" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Salary </label>
              <input id="salary" v-model="form.salary" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Advance Salary Paid</label>
              <input id="advance_salary"  v-model="form.advance_salary" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Total Hours Worked </label>
              <input id="total_hours" type="text"  v-model="form.total_hours" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Overtime </label>
              <input id="overtime"  v-model="form.overtime" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Incentive </label>
              <input id="incentive" v-model="incentive" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Bonus </label>
              <input id="bonus" v-model="bonus" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label class="d-flex justify-content-between">
                Deduction
                <div>
                  <input type="checkbox"  v-model="form.dfas"> Deduct From Advance Paid Salary
                </div>
              </label>
              <input id="deduction" v-model="deduction" type="text" class="form-control">
            </div>
          </div>


          <div>
            <div class="form-group">
              <label>Total Amount </label>
              <input id="total_amount" v-model="form.total_amount" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Total Paid </label>
              <input id="total_paid" v-model="total_paid" type="text" class="form-control">
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>Total Due </label>
              <input id="total_due" v-model="form.total_due" type="text" class="form-control">
            </div>
          </div>

          <div class="form-group">
            <div>Salary Status</div>
            <select  v-model="form.salary_status"  class="form-control">
              <option value="unpaid">Unpaid</option>
              <option value="paid">Paid</option>
            </select>
          </div>

        </div>

        </form>
        <template #modal-footer>
          <button @click="close" class="btn btn-warning">Close</button>
          <button
              @click="submitHandelar"
              class="btn btn-primary"
              type="submit"
              :disabled="form.busy"
          >
            Submit
          </button>
        </template>
      </b-modal>

      <b-modal id="invoice-modal" title="Generated Invoice" hide-header-close size="lg">
        <div id="payslip">
<!--          <div class="h3">Smart ISP</div>-->
<!--          <div>-->
<!--            Salary:-->
<!--          </div>-->
          <div class="container mt-5 mb-5">
            <div class="row">
              <div class="col-md-12">
                <div class="text-center lh-1 mb-2">
                  <h6 class="fw-bold">Payslip</h6> <span class="fw-normal">Payment slip for the month of {{payslip_month}} {{ payslip_year }}</span>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        <div> <span class="fw-bolder">EMP Code:</span> <small class="ms-3" >{{payslip_employee_id}}</small> </div>
                      </div>
                      <div class="col-md-4">
                        <div> <span class="fw-bolder">EMP Name:</span> <small class="ms-3" >{{payslip_employee_name}}</small> </div>
                      </div>
                      <div class="col-md-4">
                        <div> <span class="fw-bolder">Designation:</span> <small class="ms-3" >{{payslip_employee_designation}}</small> </div>
                      </div>
                    </div>
                  </div>
                  <table class="mt-4 table table-bordered">
                    <thead class="bg-dark text-white">
                    <tr>
                      <th scope="col-md-10"></th>
                      <th scope="col-md-2"></th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Salary</th>
                        <td >{{payslip_salary}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Incentive</th>
                        <td>{{payslip_incentive}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Bonus</th>
                        <td>{{ payslip_bonus }} </td>
                      </tr>
                      <tr>
                        <th scope="row">Deductions</th>
                        <td>{{payslip_deduction}} </td>
                      </tr>
                    </tbody>
<!--                      <tr>-->
<!--                        <th scope="row">Total Amount</th>-->
<!--                        <td>120.00 </td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                        <th scope="row">Total Paid</th>-->
<!--                        <td>0.00 </td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                        <th scope="row">Total Due</th>-->
<!--                        <td>0.00 </td>-->
<!--                      </tr>-->

                  </table>
                  <div class="d-flex justify-content-end w-75">
                    <div class="d-block ">
                      <div class="d-flex">
                        <div class="mr-4">
                          Total Amount
                        </div>
                        <div>{{ payslip_total_amount }}</div>
                      </div>
                      <div class="d-flex ">
                        <div class="mr-5">
                          Total Paid
                        </div>
                        <div class="ml-2">{{ payslip_total_paid }}</div>
                      </div>
                      <div class="d-flex">
                        <div class="mr-5">
                          Total Due
                        </div>
                        <div class="ml-2">{{ payslip_total_due }}</div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="border col-md-12">
                    <div class="d-flex flex-column"> <span>In Words</span> <span></span> </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <div class="d-flex flex-column mt-2"> <span class="mt-4">Authorised Signatory</span> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" @click="generateInvoicePDF">Generate</button>
      </b-modal>

      <b-modal id="edit-payroll-modal" title="Edit Payroll" hide-header-close size="lg">
        <div class="form-group">
          <label for="">Employee Id</label>
          <input
              id="employee_id_edit"
              v-model="form.employee_id"
              type="text"
              class="form-control"
              name="employee_id_edit"
              placeholder="Employee Id"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="">Month</label>
          <select id="payroll-month" v-model="form.month" class="form-control">
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>

        <div class="form-group">
          <label for="">Year</label>
          <input
              id="year_edit"
              v-model="form.year"
              type="text"
              class="form-control"
              name="year_edit"
          />
        </div>

        <div class="form-group">
          <label for="">Department</label>
          <input
              id="department_edit"
              type="text"
              v-model="form.department"
              class="form-control"
              name="department_edit"
          />
        </div>

        <div class="form-group">
          <label for="">Designation</label>
          <input
              id="designation_type"
              type="text"
              v-model="form.designation"
              class="form-control"
              name="designation_edit"
          />
        </div>

        <div class="form-group">
          <label for="">Salary</label>
          <input
              id="salary_edit"
              type="text"
              v-model="form.salary"
              class="form-control"
              name="salary_edit"
          />
        </div>

        <div>
          <div class="form-group">
            <label>Overtime </label>
            <input id="overtime_edit"  v-model="form.overtime" type="text" class="form-control">
          </div>
        </div>

        <div class="form-group">
          <label for="">Incentive</label>
          <input
              id="incentive_edit"
              type="text"
              v-model="form.incentive"
              class="form-control"
              name="incentive_edit"
          />
        </div>


        <div class="form-group">
          <label for="">Bonus</label>
          <input
              id="bonus_edit"
              type="text"
              v-model="form.bonus"
              class="form-control"
              name="bonus_edit"
          />
        </div>

        <div class="form-group">
          <label for="">Deduction</label>
          <input
              id="deduction_edit"
              v-model="form.deduction"
              type="text"
              class="form-control"
              name="deduction_edit"
          />
        </div>


        <div class="form-group">
          <label for="">Total Amount</label>
          <input
              id="total_amount_edit"
              v-model="form.total_amount"
              type="text"
              class="form-control"
              name="total_amount_edit"
          />
        </div>

        <div class="form-group">
          <label for="">Total Paid</label>
          <input
              id="total_pait_edit"
              v-model="form.total_paid"
              type="text"
              class="form-control"
              name="total_paid_edit"
          />
        </div>

        <div class="form-group">
          <label for="">Total Due</label>
          <input
              id="total_due_edit"
              type="text"
              v-model="form.total_due"
              class="form-control"
              name="total_due_edit"
          />
        </div>

        <div class="form-group">
          <div>Salary Status</div>
          <select  v-model="form.salary_status"   class="form-control">
            <option value="unpaid">Unpaid</option>
            <option value="paid">Paid</option>
          </select>
        </div>

        <template #modal-footer>
          <button @click="close" class="btn btn-warning">Close</button>
          <button
              @click="update"
              class="btn btn-primary"
              type="submit"
              :disabled="form.busy"
          >
            Submit
          </button>
        </template>

      </b-modal>

      <div>
        <div>Filter</div>
        <div class="d-flex justify-content-between">
          <div class="form-group w-100 mr-2">
            <label for="">Name</label>
            <input
                @keyup="filterData"
                autofocus="autofocus"
                type="text"
                class="form-control"
                v-model="filter_employee_name"
                placeholder="Name"
            />
          </div>
          <div class="form-group w-100 mr-2">
            <label for="">Month</label>
            <select @change="filterData" class="form-control" v-model="filter_month">
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div class="form-group w-100 mr-2">
            <label for="">Year</label>
            <input
                @keyup="filterData"
                autofocus="autofocus"
                type="text"
                class="form-control"
                v-model="filter_year"
            />
          </div>
          <div class="form-group w-100 mr-2">
            <label for="">Status</label>
            <select @change="filterData" class="form-control" v-model="filter_status">
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </select>
          </div>
        </div>
      </div>


      <div class="col-lg-12">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Month</th>
            <th scope="col">Year</th>
            <th scope="col">Salary</th>
            <th scope="col">Overtime</th>
            <th scope="col">Incentive</th>
            <th scope="col">Bonus</th>
            <th scope="col">Deduction</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Total Paid</th>
            <th scope="col">Total Due</th>
            <th scope="col">Salary Status</th>
            <th scope="col">Action</th>
          </tr>
          </thead>

          <tbody>
            <tr v-for="(payroll, index) in this.payrolls" :key="index + 'payrolls'">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ payroll.employee.name }}</td>
              <td>
                <div v-if="payroll.month == '01'">January</div>
                <div v-if="payroll.month == '02'">February</div>
                <div v-if="payroll.month == '03'">March</div>
                <div v-if="payroll.month == '04'">April</div>
                <div v-if="payroll.month == '05'">May</div>
                <div v-if="payroll.month == '06'">June</div>
                <div v-if="payroll.month == '07'">July</div>
                <div v-if="payroll.month == '08'">August</div>
                <div v-if="payroll.month == '09'">September</div>
                <div v-if="payroll.month == '10'">October</div>
                <div v-if="payroll.month == '11'">November</div>
                <div v-if="payroll.month == '12'">December</div>
              </td>
              <td>{{ payroll.year }}</td>
              <td>{{ payroll.salary }}</td>
              <td>{{ payroll.overtime }}</td>
              <td>{{ payroll.incentive }}</td>
              <td>{{ payroll.bonus }}</td>
              <td>{{ payroll.deduction }}</td>
              <td>{{ payroll.total_amount }}</td>
              <td>{{ payroll.total_paid }}</td>
              <td>{{ payroll.total_due }}</td>
              <td>{{ payroll.salary_status }}</td>
              <td>
                <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                      @click.prevent="showInvoice(payroll.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Invoice
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item
                      @click.prevent="edit(payroll.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Edit
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item
                  >
                    <button @click="deleteData(payroll.id)" class="btn btn-sm p-0">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                      Delete
                    </button>
                  </b-dropdown-item>
                </b-dropdown>
              </td>

            </tr>
          </tbody>
        </table>
      </div>

      {{ getRoleWisePermissions }}
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
const Swal = require("sweetalert2");
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
export default {
  nameL: "BillCollection",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      form: new Form({
        employee_name:"",
        employee_id: "",
        month: "",
        total_hours:"",
        salary: "",
        overtime: "",
        incentive: "",
        bonus: "",
        deduction: "",
        advance_salary: "",
        department: "",
        designation: "",
        dfas: "",
        total_amount: "",
        total_paid: "",
        total_due: "",
        salary_status:"",
        year:""
      }),

      fields: [
        { key: "id", label: "Id" },
        { key: "client_name", label: "Client Name", sortable: true },
        { key: "client_id", label: "Client ID", sortable: true },
        { key: "invoice_no", label: "Invoice No", sortable: true },
        { key: "monthly_bill", label: "Monthly Bill" },
        { key: "received", label: "Received", sortable: true },
        { key: "method", label: "Method", sortable: true },
        { key: "received_by", label: "Received By", sortable: true },
        { key: "transaction_id", label: "Transaction Id", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "date",
        "action",
      ],
      summary: {
        total: 0,
        compelte: 0,
        pending: 0,
      },
      listData: [],
      dashboardusers: [],
      permissionError: "",
      incentive: "",
      bonus: "",
      deduction: "",
      total_paid: "",
      filter_employee_name: "",
      filter_month: "",
      filter_year: "",
      filter_status: "",
      invoice_salary: "",
      payslip_employee_id: "",
      payslip_employee_name: "",
      payslip_employee_designation:"",
      payslip_salary:"",
      payslip_incentive:"",
      payslip_bonus:"",
      payslip_deduction: "",
      payslip_total_amount:"",
      payslip_total_paid:"",
      payslip_total_due: "",
      payslip_month: "",
      payslip_year: "",

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      editPayroll: "",
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },

  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Payroll");
      this.permission.add = this.checkpermission("Payroll Add");
      this.permission.edit = this.checkpermission("Payroll Edit");
      this.permission.delete = this.checkpermission("Payroll Delete");
    },

    payrolls() {
      if(this.filter_employee_name == "" && this.filter_month == "" && this.filter_year == "" && this.filter_status == ""){
        return this.$store.state.hrm.payrolls.data;
      }
      else if(this.filter_employee_name == "" && this.filter_month == "" && this.filter_year == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name == "" && this.filter_month == "" && this.filter_year != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].year == this.filter_year){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name == "" && this.filter_month == "" && this.filter_year != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].year == this.filter_year && this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name == "" && this.filter_month != "" && this.filter_year == "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].month == this.filter_month){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name == "" && this.filter_month != "" && this.filter_year == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].month == this.filter_month && this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name == "" && this.filter_month != "" && this.filter_year != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].month == this.filter_month && this.$store.state.hrm.payrolls.data[i].year == this.filter_year){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name == "" && this.filter_month != "" && this.filter_year != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].month == this.filter_month && this.$store.state.hrm.payrolls.data[i].year == this.filter_year && this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month == "" && this.filter_year == "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month == "" && this.filter_year == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true && this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month == "" && this.filter_year != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true && this.$store.state.hrm.payrolls.data[i].year == this.filter_year){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month == "" && this.filter_year != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true && this.$store.state.hrm.payrolls.data[i].year == this.filter_year && this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month != "" && this.filter_year == "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true && this.$store.state.hrm.payrolls.data[i].month == this.filter_month){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month != "" && this.filter_year == "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true && this.$store.state.hrm.payrolls.data[i].month == this.filter_month && this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month != "" && this.filter_year != "" && this.filter_status == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true && this.$store.state.hrm.payrolls.data[i].month == this.filter_month && this.$store.state.hrm.payrolls.data[i].year == this.filter_year){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
      else if(this.filter_employee_name != "" && this.filter_month != "" && this.filter_year != "" && this.filter_status != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.payrolls.data.length; i++){
          if(this.$store.state.hrm.payrolls.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true && this.$store.state.hrm.payrolls.data[i].month == this.filter_month && this.$store.state.hrm.payrolls.data[i].year == this.filter_year && this.$store.state.hrm.payrolls.data[i].salary_status == this.filter_status){
            filters.push(this.$store.state.hrm.payrolls.data[i])
          }
        }
        return filters;
      }
    },

    employees() {
      return this.$store.state.hrm.employees.data;
    },

    departments(){
      return this.$store.state.hrm.departments.data;
    },

    designations(){
      return this.$store.state.hrm.designations.data;
    },

    total_salary_paid() {
      let sum = 0;
      for(let i=0; i< this.$store.state.hrm.payrolls.data.length; i++){
        sum = parseInt(this.$store.state.hrm.payrolls.data[i].total_paid) + parseInt(sum);
      }
      return sum;
    },

    total_salary_due() {
      let sum = 0;
      for(let i=0; i< this.$store.state.hrm.payrolls.data.length; i++){
        sum = parseInt(this.$store.state.hrm.payrolls.data[i].total_due) + parseInt(sum);
      }
      return sum;
    },

    total_employee() {
      return this.$store.state.hrm.employees.data.length;
    },
  },

  watch: {
    incentive(newVal, oldVal){
      let total_amount = document.getElementById('total_amount').value;
      total_amount = (Number(total_amount) + Number(newVal)) - Number(oldVal);
      document.getElementById('total_amount').value = total_amount;
      this.form.total_amount = total_amount;
      this.form.incentive = newVal;
    },

    bonus(newVal, oldVal){
      let total_amount = document.getElementById('total_amount').value;
      total_amount = (Number(total_amount) + Number(newVal)) - Number(oldVal);
      document.getElementById('total_amount').value = total_amount;
      this.form.total_amount = total_amount;
      this.form.bonus = newVal;
    },

    deduction(newVal, oldVal){
      let total_amount = document.getElementById('total_amount').value;
      total_amount = (Number(total_amount) - Number(newVal)) + Number(oldVal);
      document.getElementById('total_amount').value = total_amount;
      this.form.total_amount = total_amount;
      this.form.deduction = newVal;
    },

    total_paid(newVal, oldVal){
      let total_amount = document.getElementById('total_amount').value;
      let total_due = (Number(total_amount) - Number(newVal));
      document.getElementById('total_due').value = total_due;
      this.form.total_due = total_due;
      this.form.total_paid = newVal;
    }
  },

  created() {
    this.$store.dispatch("getPayrolls");
    this.$store.dispatch("getEmployees");
    this.$store.dispatch("getDepartments");
    this.$store.dispatch("getDesignations");
  },
  methods: {

    filterData(){
      this.payrolls()
    },

    showInvoice(id){
      this.$bvModal.show("invoice-modal");
      const payroll = this.payrolls.find((ele) => ele.id == id);
      const employee = this.employees.find((ele) => ele.id == payroll.employee.id);
      this.payslip_employee_id = payroll.employee.employee_id;
      this.payslip_employee_name = payroll.employee.name;
      this.payslip_employee_designation = employee.designation.title;
      this.payslip_salary = payroll.salary;
      this.payslip_incentive = payroll.incentive;
      this.payslip_bonus = payroll.bonus;
      this.payslip_deduction = payroll.deduction;
      this.payslip_total_amount = payroll.total_amount;
      this.payslip_total_paid = payroll.total_paid;
      this.payroll_total_due = payroll.total_due;

      let month_no = payroll.month;
      if(month_no == '01'){
        this.payslip_month = 'January'
      }
      else if(month_no == '02'){
        this.payslip_month = 'February'
      }
      else if(month_no == '03'){
        this.payslip_month = 'March'
      }
      else if(month_no == '04'){
        this.payslip_month = 'April'
      }
      else if(month_no == '05'){
        this.payslip_month = 'May'
      }
      else if(month_no == '06'){
        this.payslip_month = 'June'
      }
      else if(month_no == '07'){
        this.payslip_month = 'July'
      }
      else if(month_no == '08'){
        this.payslip_month = 'August'
      }
      else if(month_no == '09'){
        this.payslip_month = 'September'
      }
      else if(month_no == '10'){
        this.payslip_month = 'October'
      }
      else if(month_no == '11'){
        this.payslip_month = 'November'
      }
      else if(month_no == '12'){
        this.payslip_month = 'December'
      }

      this.payslip_year = payroll.year

    },

    generateInvoicePDF(){
      console.log(document.getElementById('payslip'))
      const doc = new jsPDF(

          {
            orientation: 'l',
            unit: 'px',
            format: 'tabloid',
            putOnlyUsedFonts:true,
            compress:true
          }

      );
      const invoice = document.getElementById('payslip')
      doc.html(invoice, {
        callback: function(doc){
          doc.save('output2.pdf');
        },
      });
    },

    showdata(){
      let data = this.$store.state.hrm.payrolls.data;
      console.log(data)
    },
    // serch_by_date
    serch_by_date() {
      this.form
          .post("searchbillcollections")
          .then((res) => {
            if (res.data.success) {
              this.listData = res.data.data;
            } else {
              this.$error_message(res.data.message, res.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },

    // ============= downloadPDF  ================
    downloadPDF() {
      const doc = new jsPDF();
      doc.autoTable({ html: "#my-table" });

      var col = [
        "Id",
        "Create By",
        "Claimant",
        "Amount",
        "Method",
        "Transaction Id",
        "Issue Date",
        "Status",
        "Details",
      ];
      var rows = [];
      this.items.forEach((element) => {
        var id = [element.id];
        var create_by = [element.create_by];
        var exp_claimant = [element.exp_claimant];
        var amount = [element.amount];
        var method = [element.method];
        var transaction_id = [element.transaction_id];
        var issue_date = [element.issue_date];
        var status = [element.status == 0 ? "Pending" : "Completed"];
        var desc = [element.description];
        rows.push([
          id,
          create_by,
          exp_claimant,
          amount,
          method,
          transaction_id ? transaction_id : null,
          issue_date,
          status,
          desc,
        ]);
      });

      doc.autoTable(col, rows, { startY: 10 });

      doc.save("income.pdf");
    },

    // Change status
    s_e_d(data) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("billcollections/" + data).then((res) => {
        if (res.data.success) {
          this.getData();
          this.$bvToast.toast(res.data.message, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
          });
          // this.$store.dispatch("actions_success", res.data.message);
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //=========== Get All user ============
    getUser() {
      this.axios.get("dashboardusers").then((res) => {
        if (res.data.success) {
          this.dashboardusers = res.data.data;
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //= ===haxndle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ===get data=========

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("billcollections").then((res) => {
        this.$store.dispatch("spinnerLoading", false);
        if (res.data.success) {
          this.listData = res.data.data;
        } else {
          if (res.data.code == 403) {
            this.permissionError = res.data.message;
            this.$error_message(res.data.message, res.data.errors);
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        }
      });
    },

    GetEmployeeDetails(){
      const id = document.getElementById('employee').value;
      const month_year = document.getElementById('month').value;
      const month_year_split = month_year.split("-") ;
      const month = month_year_split[1];
      const year = month_year_split[0];
      this.axios.get(`getEmployeeDetails/${id}/${month}/${year}`).then((res) => {
        if(res.data.success){
          document.getElementById('details_section').classList.remove('d-none');
          document.getElementById('employee_name').value = res.data.data[0].name;
          this.form.employee_name = res.data.data[0].name;
          document.getElementById('salary').value = res.data.data[0].salary;
          this.form.salary = res.data.data[0].salary;
          document.getElementById('advance_salary').value = res.data.data[0].advance_salary;
          this.form.advance_salary = res.data.data[0].advance_salary;
          document.getElementById('department').value = res.data.data[1].name;
          this.form.department = res.data.data[1].name;
          document.getElementById('designation').value = res.data.data[2].title;
          this.form.designation = res.data.data[2].title;
          document.getElementById('total_hours').value = res.data.data[3];
          this.form.total_hours = res.data.data[3];
          if(res.data.data[3] > 40) {
            document.getElementById('overtime').value = (res.data.data[3] - 40.00);
            this.form.overtime = (res.data.data[3] - 40.00);
          }
          else{
            document.getElementById('overtime').value = 0;
            this.form.overtime = 0;
          }
          document.getElementById('total_amount').value = res.data.data[0].salary;
          this.form.total_amount = res.data.data[0].salary;
          console.log(res.data.data);
        }else {
          if (res.data.code == 403) {
            this.permissionError = res.data.message;
            this.$error_message(res.data.message, res.data.errors);
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        }
      })
    },

    //= ========data save=========
    save() {
      this.form
          .post("payroll")
          .then((res) => {
            if (res.data.success) {
              this.$s_tost(res.data.message);
              this.close();
              this.$store.dispatch('getPayrolls');
            } else {
              this.$error_message(res.data.message, res.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },

    //= ========get data for edit=========

    update() {
      this.$store.dispatch("spinnerLoading", true);
      this.form
          .put(`payroll/${this.editPayroll}`)
          .then((res) => {
            if (res.data.success) {
              this.$s_tost(res.data.message);
              this.listData.unshift(this.form);
              this.$bvModal.hide("edit-payroll-modal");
              this.$store.dispatch('getPayrolls');
              this.$store.dispatch("spinnerLoading", false);
            } else {
              this.$error_message(res.data.message, res.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    //= ========get data for edit=========
    edit(id) {
      this.$bvModal.show("edit-payroll-modal");
      const payroll = this.payrolls.find((ele) => ele.id == id);
      const employee = this.employees.find((ele) => ele.id == payroll.employee_id);
      const department = this.departments.find((ele) => ele.id == employee.department_id);
      const designation = this.designations.find((ele) => ele.id == employee.designation_id);
      this.form.employee_id = employee.employee_id;
      this.form.month = payroll.month;
      this.form.year = payroll.year;
      this.form.overtime = payroll.overtime;
      this.form.department = department.name;
      this.form.designation = designation.title;
      this.form.salary = payroll.salary;
      this.form.incentive = payroll.incentive;
      this.form.bonus = payroll.bonus;
      this.form.deduction = payroll.deduction;
      this.form.total_amount = payroll.total_amount;
      this.form.total_paid = payroll.total_paid;
      this.form.total_due = payroll.total_due;
      this.form.salary_status = payroll.salary_status;
      this.editPayroll = payroll.id;
    },
    //= ======== close Modal =========
    close() {
      (this.client_id = ""),
          (this.client_name = ""),
          (this.invoice_no = ""),
          (this.monthly_bill = ""),
          (this.received = ""),
          (this.received_by = ""),
          (this.method = ""),
          (this.transaction_id = ""),
          (this.note = ""),
          (this.issue_date = ""),
          this.$bvModal.hide("modal-lg");
    },
    //= ======== delete Modal =========
    async deleteData(id) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`payroll/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.$store.dispatch('getPayrolls');
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    //= ======== delete Modal end =========
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
