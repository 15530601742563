<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="UsersIcon" :statistic="summary.total_user"
          statistic-title="Total Pending User" />
      </b-col>
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="MoneyIcon" color="danger" :statistic="summary.total_due"
          statistic-title="Total Due" />
      </b-col>
    </b-row>
    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="3" label-for="sortBySelect"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" label-cols-sm="4" :disabled="!sortBy">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button size="sm" col="2" class="ml-2" variant="primary" :to="{ name: 'newCustomerRegistration' }">
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New
            </b-button>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(customer_type)> Home </template>

        <template #cell(zone)="data">
          {{ data.item.billing_info ? data.item.billing_info.zone.name : "" }}
        </template>

        <template #cell(package)="data">
          {{
            data.item.billing_info ? data.item.billing_info.package.name : ""
          }}
        </template>

        <template #cell(mikrotik_status)="data">
          <b-badge @click.prevent="enabledUser(data.item)" pill :variant="
            data.item.connection_info && data.item.connection_info.status == 1
              ? 'success'
              : data.item.connection_info &&
                data.item.connection_info.status == 0
                ? 'warning'
                : 'danger'
          " class="text-capitalize">
            {{
              data.item.connection_info
              ? data.item.connection_info &&
                data.item.connection_info.status == 1
                ? "Enabled"
                : "Disabled"
              : "Not Connected"
            }}
          </b-badge>
        </template>

        <template #cell(billing_status)="data">
          <b-badge pill :variant="`${statusHtml(
            data.item.billing_info ? data.item.billing_info.status : 1
          )}`" class="text-capitalize">
            {{
              statusAction(
                data.item.billing_info ? data.item.billing_info.status : 1
              )
            }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <!-- <b-dropdown-item @click="form.user_id = data.item.id" v-b-modal="'connection_'+ data.item.id"> -->
            <!-- v-if="!data.item.connection_info" -->
            <b-dropdown-item v-if="!data.item.connection_info" @click="setData(data.item)">
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>Set Connection</span>
            </b-dropdown-item>
            <b-dropdown-item v-else-if="data.item.connection_info !== null" @click="makePayment(data.item)">
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>Invocie Payment</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.is_send_sms == 0" @click.prevent="send_sms(data.item)">
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>Send Welcome Sms</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
    <b-modal id="set_connection" size="lg" title="Set Connection" hide-footer>
      <b-form-row class="mb-2">
        <input type="hidden" v-model="form.user_id" />
        <!-- <b-form-input type="hidden" class="d-hidden" v-model="form.user_id" :value="data.item.id"></b-form-input> -->

        <b-form-group class="col-6" id="connection_type" label="Connection Type">
          <b-form-select @change="change_con_type()" v-model="form.connection_type">
            <b-form-select-option selected value="" disabled>---Select Type--</b-form-select-option>
            <option value="di_private">Dynamic IP Private (PPPOE NAT)</option>
            <option value="di_public">Dynamic IP Public(PPPOE)</option>
            <option value="si_private">Static IP Private (PPPOE)</option>
            <option value="si_public">Static IP Public (PPPOE)</option>
            <option value="si_private_queue">Static IP Private (Queue)</option>
            <option value="si_public_queue">Static IP Public (Queue)</option>
            <option value="si_m_b">Static IP MAC Bind (ARP)</option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-6" id="mikrotik" label="Mikrotik">
          <b-form-select disabled v-model="form.mikrotik">
            <b-form-select-option selected value="" disabled>-- Please select an option --</b-form-select-option>
            <b-form-select-option v-for="(mikrotik, index) in mikrotiks" :key="index" :value="mikrotik.id">{{
              mikrotik.identity }} {{ mikrotik.host }}</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <div v-if="
          form.connection_type == 'di_private' ||
          form.connection_type == 'di_public' ||
          form.connection_type == 'si_private' ||
          form.connection_type == 'si_public'
        " class="col-lg-6 mt-1">
          <label for="">User Name <sup class="text-danger">*</sup></label>
          <div class="from-group d-flex">
            <input title="Please Copy The Ref To Use Previous Name" readonly class="w-25 form-control"
              :value="form.user_reference_number + '-'" />
            <input v-model="form.username" type="text" class="w-75 form-control" placeholder="User Name" />
          </div>
        </div>
        <div v-if="
          form.connection_type == 'di_private' ||
          form.connection_type == 'di_public' ||
          form.connection_type == 'si_private' ||
          form.connection_type == 'si_public'
        " class="col-lg-6 mt-1">
          <label for="">User Password <sup class="text-danger">*</sup></label>
          <input v-model="form.userpassword" type="text" class="form-control" placeholder="User Password" />
        </div>

        <div v-if="
          form.connection_type == 'di_private' ||
          form.connection_type == 'di_public' ||
          form.connection_type == 'si_private' ||
          form.connection_type == 'si_public'
        " class="col-lg-6 mt-1">
          <label for="Service">Service</label>
          <select v-model="form.service" id="Service" class="form-control">
            <option value="oVPN">oVPN</option>
            <option value="PPPoE">PPPoE</option>
            <option value="PPtP">PPtP</option>
          </select>
        </div>
        <div v-if="
          form.connection_type == 'si_public' ||
          form.connection_type == 'si_private'
        " class="col-lg-6 my-1">
          <label for="">Remote Address</label>
          <div class="d-flex">
            <input v-model="form.remote_address" type="text" class="form-control" placeholder="Remote Address" />
            <button @click.prevent="see_remote_address()" class="btn btn-primary btn-sm">
              <feather-icon icon="EyeIcon" size="16" />
            </button>
          </div>
        </div>

        <div v-if="
          form.connection_type == 'si_private_queue' ||
          form.connection_type == 'si_m_b' ||
          form.connection_type == 'si_public_queue'
        " class="col-lg-6">
          <label class="mt-1 mr-2" for="queues">Queues</label>
          <div class="d-flex">
            <select @change="change_queue_name" class="form-control" v-model="form.queue_name" id="">
              <!-- let data = this.items.find((x) => x.id === id); -->
              <option value="">---Select--</option>
              <option v-for="queue_item in queue_data" :key="queue_item.id" :value="queue_item.name">
                {{ queue_item.name }}
              </option>
            </select>
            <button @click.prevent="modelAction('add_simple_queue')" class="btn btn-primary btn-sm">
              <feather-icon icon="PlusCircleIcon" size="16" />
            </button>
          </div>
        </div>
        <div v-if="
          form.connection_type == 'si_private_queue' ||
          form.connection_type == 'si_m_b' ||
          form.connection_type == 'si_public_queue'
        " class="col-lg-6 my-1">
          <label for="target_address">Target Address</label>
          <div class="d-flex">
            <input v-model="form.target_address" type="text" class="form-control" placeholder="Ex: 192.168.0.1"
              id="target_address" />
            <button @click.prevent="modelAction('target_address')" class="btn btn-primary btn-sm">
              <feather-icon icon="EyeIcon" size="16" />
            </button>
          </div>
        </div>
        <div v-if="
          form.connection_type == 'si_private_queue' ||
          form.connection_type == 'si_m_b' ||
          form.connection_type == 'si_public_queue'
        " class="col-lg-6 my-1">
          <label for="destination">Destination (Interfaces)</label>
          <select v-model="form.destination" class="form-control" id="destination">
            <option value="">---Select Destination (Interfaces)----</option>
            <option v-for="inf in interfaces" :key="inf.id" :value="inf.name">
              {{ inf.name }}
            </option>
          </select>
        </div>
        <div v-if="
          form.connection_type == 'si_private_queue' ||
          form.connection_type == 'si_m_b' ||
          form.connection_type == 'si_public_queue'
        " class="col-lg-6 my-1">
          <label for="queue_type">Queue Type (PCQ)</label>
          <div class="d-flex">
            <select disabled v-model="form.queue" class="form-control" id="queue_type">
              <option value="">---Select Queue Type----</option>
              <option v-for="q_t in queue_types" :key="q_t.id" :value="`${q_t.name}/${q_t.name}`">
                {{ q_t.name }}
              </option>
            </select>
            <button @click.prevent="modelAction('add_queue_type')" v-b-modal="'add_queue-modal'"
              class="btn btn-primary btn-sm">
              <feather-icon icon="PlusCircleIcon" size="16" />
            </button>
          </div>
        </div>
        <div v-if="
          form.connection_type == 'si_public' ||
          form.connection_type == 'si_m_b' ||
          form.connection_type == 'si_private'
        " class="col-lg-6 mt-1">
          <!-- form.connection_type == 'si_m_b' || -->
          <label for="">MAC Address</label>
          <input v-model="form.mac_address" type="text" class="form-control" placeholder="EX: 00:00:00:00:00:00" />
        </div>
        <div class="col-lg-6 mt-1">
          <label for="">Router Comments</label>
          <input v-if="
            form.connection_type == 'si_private_queue' ||
            form.connection_type == 'si_m_b' ||
            form.connection_type == 'si_public_queue'
          " disabled v-model="form.router_component" type="text" class="form-control"
            placeholder="Router Comments" />
          <input v-else v-model="form.router_component" type="text" class="form-control" placeholder="Router Comments" />
        </div>
      </b-form-row>
      <b-button variant="danger" @click="$bvModal.hide('set_connection')">Cancel</b-button>
      <b-button variant="success" @click="submitConnectionForm">Submit</b-button>
    </b-modal>
    <!-- RemotAddress modal  -->
    <RemotAddress v-if="listModalData" @target_address="getTarget_address" :list-modal-data="listModalData"
      :modal_title="modal_title" :copyFor="copyFor"></RemotAddress>
    <!-- MakePayment modal  -->
    <MakePayment v-if="payment_info.user_id !== null" :payment-info="payment_info"></MakePayment>
  </div>
</template>
<script>
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { statusAction } from "@/utils/helpers/functions";
import Form from "vform";
import RemotAddress from "./RemotAddress.vue";
import MakePayment from "../Billing/MakePayment.vue";

export default {
  name: "UserManage",
  components: {
    BCardCode,
    StatisticCardHorizontal,
    RemotAddress,
    MakePayment,
  },

  data() {
    return {
      title: "Pending User",
      statusAction,
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        { key: "customer_type", label: "Cus Type", sortable: true },
        { key: "zone", label: "Zone", sortable: true },
        { key: "package", label: "Package" },
        { key: "phone", label: "Mobile No" },
        { key: "mikrotik_status", label: "MikroTik Status" },
        { key: "user_type", label: "Expire Date" },
        { key: "billing_status", label: "Billing Status", sortable: true },
        { key: "user_type", label: "Last Payment Date" },
        { key: "user_type", label: "Due" },
        { key: "user_type", label: "ONU Collected", sortable: true },
        "action",
      ],
      loading: true,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      items: [],

      // listModalData: null,
      listModalData: {},
      modal_title: "",
      user: {},
      template: {},
      summary: {
        total_user: 0,
        total_due: 0,
      },
      mikrotik_data: [
        { value: null, text: "Please select an option" },
        { value: "1", text: "NAS" },
        { value: "1", text: "Radius" },
      ],
      form: new Form({
        user_id: "",
        mikrotik: "",
        connection_type: "",
        user_name: "",
        user_password: "",
        service: "PPPoE",
        remote_address: "",
        mac_address: "",
        remote_ip: "",
        router_comments: "",
        profile: "",
        mobile: "",
        zone: "",
        connection_date: "",
        package: "",
      }),
      // queue_types: []
      copyFor: "",
      payment_info: {
        user_id: null,
        amount: null,
        modal_title: "Make Payment",
      },
    };
  },
  computed: {
    mikrotiks() {
      return this.$store.state.isp.others.mikrotik;
    },
    nas() {
      return this.$store.state.isp.others.nas;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
    queue_data() {
      return this.$store.getters.queueGetter;
    },
    queue_types() {
      return this.$store.getters.queueTypeGetter;
    },
    interfaces() {
      return this.$store.getters.allImterfacesGetter;
    },
  },

  created() {
    this.$store.dispatch("others", {
      mikrotik: true,
      nas: true,
    });
    this.getData();
    this.getSummaryData();
  },
  methods: {
    //========= makePayment========
    async makePayment(val) {
      this.payment_info.amount = val.billing_info.monthly_bill;
      this.payment_info.user_id = val.id;
      await this.$store.dispatch("getUsers");
      this.$bvModal.show("modal-lg");
    },

    getTarget_address(val) {
      console.log(val);
    },
    change_queue_name() { },
    change_con_type() {
      if (
        this.form.connection_type == "si_m_b" ||
        this.form.connection_type == "si_public_queue" ||
        this.form.connection_type == "si_private_queue"
      ) {
        this.$store.dispatch("getqueueTypeAction");
        this.$store.dispatch("queueAction", this.form.mikrotik);
        this.$store.dispatch("allImterfacesAction");
        this.form.service =
          this.form.connection_type == "si_m_b"
            ? "ARP"
            : this.form.connection_type == "si_public_queue"
              ? "Public Queue"
              : this.form.connection_type == "si_private_queue"
                ? "Private Queue"
                : this.form.service;
      }
    },
    setData(user) {
      this.$bvModal.show("set_connection");
      // console.log(user);
      this.form.mikrotik = user.billing_info.package.mikrotik_id;
      this.form.user_id = user.id;
      this.form.profile = user.billing_info.package.bandwidth;
      this.form.mobile = user.phone;
      this.form.zone = user.billing_info.zone.name;
      this.form.connection_date = user.billing_info.connection_date;
      this.form.user_reference_number = user.billing_info.user_reference_number;
      this.form.package = user.billing_info.package_id;
    },
    // connection
    // showModal(index) {
    //   let modal_id = "connection_" + index;
    //   this.$refs[modal_id][0].show(index);
    // },
    // set-mikrotik-connection
    submitConnectionForm() {
      this.form.post("set-mikrotik-connection").then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.$bvModal.hide("set_connection");
          location.reload();
          // this.summary.total_user = resp.data.data.total_user;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    modelAction(val = null) {
      if (val == "add_simple_queue") {
        this.editData.mode = false;
        this.getImterfaces();
        this.$store.dispatch("getallmikrotiks");
        this.$bvModal.show("simple_queue_modal");
      } else if (val == "target_address") {
        this.copyFor = "target_address";
        this.modal_title = "Target Address";
        this.getIpAddress();
        this.$bvModal.show("remote_adress-modal");
      } else if (val == "remote_address") {
        this.copyFor = "remote_address";
        this.modal_title = "Remote Address";
        this.getIpAddress();
        this.$bvModal.show("remote_adress-modal");
      } else if (val == "add_queue") {
        this.$bvModal.show("add_queue_modal");
      }
    },

    async get_all_remote_address() {
      await axios.get("all_remote_address").then((resp) => {
        if (resp.data.success) {
          this.all_remote_address = resp.data.data;
        }
      });
    },
    async see_remote_address(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      await this.get_all_remote_address();
      await axios.get(`pool?page=${page}`).then((resp) => {
        this.poll_items = null;
        if (resp.data.success) {
          this.poll_items = resp.data.data;
          this.$store.dispatch("spinnerLoading", false);
          const options = {};
          resp.data.data.forEach((element) => {
            options[element.id] = element.name;
          });
          this.Swal.fire({
            title: "Please Select A Ip Pool",
            input: "select",
            inputOptions: options,
            inputPlaceholder: "Select One",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              const find_item = this.poll_items.find(
                (item) => item.id == result.value
              );
              if (find_item) {
                const number = find_item.start_ip.split(".");
                const start_number = number.pop();
                const end_number = find_item.end_ip.split(".").pop();
                this.listModalData = [];
                for (let i = start_number; i < end_number; i++) {
                  const address =
                    number[0] + "." + number[1] + "." + number[2] + "." + i;
                  const checked_status = this.all_remote_address.find(
                    (ele) => ele.remote_address == address
                  );
                  let status = checked_status ? true : false;
                  this.listModalData.push({
                    status: status,
                    address: address,
                  });
                }
                this.copyFor = "remote_address";
                this.modal_title = "remote adress";
                this.$bvModal.show("remote_adress-modal");
              } else {
                this.$error_message("Data Not Found");
              }
            }
          });
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },

    async getIpAddress() {
      await this.getCInfoMacAddress();
      await axios.get("ipaddresses_all").then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          // this.listModalData = resp.data.data;
          this.listModalData = {};
          resp.data.data.forEach((el) => {
            console.log("el.address", el);
            const number = el.address.split(".");
            const last_item = number.pop();
            const last_address_item = last_item.split("/");
            const last_address = last_address_item[0];

            let range =
              last_address_item[1] == 9
                ? 8388606
                : last_address_item[1] == 10
                  ? 4194302
                  : last_address_item[1] == 11
                    ? 2097150
                    : last_address_item[1] == 12
                      ? 1048574
                      : last_address_item[1] == 13
                        ? 524286
                        : last_address_item[1] == 14
                          ? 262142
                          : last_address_item[1] == 15
                            ? 131070
                            : last_address_item[1] == 16
                              ? 65534
                              : last_address_item[1] == 17
                                ? 32766
                                : last_address_item[1] == 18
                                  ? 16382
                                  : last_address_item[1] == 19
                                    ? 8190
                                    : last_address_item[1] == 20
                                      ? 4094
                                      : last_address_item[1] == 21
                                        ? 2046
                                        : last_address_item[1] == 22
                                          ? 1022
                                          : last_address_item[1] == 23
                                            ? 210
                                            : last_address_item[1] == 24
                                              ? 254
                                              : last_address_item[1] == 25
                                                ? 126
                                                : last_address_item[1] == 26
                                                  ? 62
                                                  : last_address_item[1] == 27
                                                    ? 30
                                                    : last_address_item[1] == 28
                                                      ? 14
                                                      : last_address_item[1] == 29
                                                        ? 6
                                                        : last_address_item[1] == 30
                                                          ? 2
                                                          : last_address_item[1] == 31
                                                            ? 2
                                                            : 0;

            for (let i = 0; i < range + 2; i++) {
              const num = parseInt(last_address);
              const tmum = num + i;
              const address =
                number[0] + "." + number[1] + "." + number[2] + "." + tmum;

              console.log("this.allmacAddress", this.allmacAddress);
              const checked_status = this.allmacAddress.find(
                (ele) => ele.remote_ip == address
              );
              let status = checked_status ? true : false;
              this.listModalData.push({
                status: status,
                address: address,
              });
            }
          });
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    statusHtml(status) {
      return this.$resolveStatusVariant(status);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData(page = 1) {
      axios.get(`customer?type=home&status=0&page=${page}`).then((resp) => {
        this.loading = false;
        if (resp.data.success) {
          this.items = resp.data.data.data;
          this.totalRows = resp.data.data.length;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getSummaryData() {
      axios.get(`customer-summary?status=0`).then((resp) => {
        this.loading = false;
        if (resp.data.success) {
          this.summary.total_user = resp.data.data.total_user;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    userDetails(user) {
      this.user = user;
      this.$bvModal.show("my-modal");
    },
    // send_sms
    send_sms(data) {
      this.axios
        .get(
          `send-custome-message?user_id=${data.id}&phone=${data.phone}&sms_template=welcome_sms`
        )
        .then((resp) => {
          if (resp.data.success) {
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
    },
  },
};
</script>
