<template>
	<div>
		<!-- Splitter Detail
		<hr> -->
		<template
			v-if="loading"
		>
			Loading ...
		</template>
		<template
			v-else-if="item"
		>
			<table class="table table-bordered table-hover table-striped" style="word-break: break-word">
				<tbody>
					<tr>
						<th>Main/Child</th>
						<td>{{item.parent_id == null?'Main':'Child'}}</td>
					</tr>
					<tr>
						<th>Type</th>
						<td>{{item.type.name}}</td>
					</tr>
					<tr>
						<th>Location</th>
						<td>{{item.lat_lon}}</td>
					</tr>
					<tr>
						<th>Insertion Type</th>
						<td>{{item.type.insertion}}</td>
					</tr>
					<tr>
						<th>Output</th>
						<td>{{item.type.output}}</td>
					</tr>
					<tr>
						<th>Installed At</th>
						<td>{{item.installed_at}}</td>
					</tr>
					<tr>
						<th>Installed By</th>
						<td>{{item.installed_by}}</td>
					</tr>
					<tr>
						<th>Comment</th>
						<td>{{item.comment}}</td>
					</tr>
					<tr>
						<th>Status</th>
						<td>{{item.status?'Active':'Inactive'}}</td>
					</tr>
				</tbody>
			</table>
		</template>
	</div>
</template>
<script>
import axios from "axios";
export default {
	name: "Splitter",
	components: { },
	props: {
		"id" : Number,
	},
	data() {
	  	return {
			item: null,
			apiEndPoint: 'nm-splitters',
			loading: false
	  	}
	},
	watch: {
		'id': function(newVal, oldVal){
			this.getDetail()
		}
	},
	computed: {
	},
	mounted(){
		this.getDetail()
	},
	methods: {
		getDetail() {
			this.loading = true
			axios.get(`${this.apiEndPoint}/${this.id}`).then((resp) => {
				if (resp.data.success) {
				  this.item = resp.data.data;

				} else {
				  
				}
			}).finally(()=> {
				this.loading = false
			});
		},
	}
}
</script>