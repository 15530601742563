import auth from "@/middleware/auth";
import Vendor from "../views/Vendor/Index.vue";
import VendorProviders from "../views/Vendor/VendorProviders.vue";
import VendorInvoices from "../views/Vendor/VendorInvoices.vue";
import InvoiceForm from "../views/Vendor/InvoiceForm.vue";
import InvoiceEditForm from "../views/Vendor/InvoiceEditForm.vue";
export default [
  {
    path: "/vendors",
    name: "vendors",
    component: Vendor,
    meta: {
      pageTitle: "Vendors",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Vendors",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-providers/upstream",
    name: "VendorProvidersUpstream",
    component: VendorProviders,
    meta: {
      pageTitle: "VendorProviders",
      middleware: [auth],
      breadcrumb: [
        {
          text: "VendorProviders",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-providers/downstream",
    name: "VendorProviders",
    component: VendorProviders,
    meta: {
      pageTitle: "VendorProviders",
      middleware: [auth],
      breadcrumb: [
        {
          text: "VendorProviders",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-invoice/payable",
    name: "Vendor-invoice-payable",
    component: VendorInvoices,
    meta: {
      pageTitle: "VendorInvoices",
      middleware: [auth],
      breadcrumb: [
        {
          text: "VendorInvoices",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-invoice/receiveable",
    name: "Vendor-invoice-receiveable",
    component: VendorInvoices,
    meta: {
      pageTitle: "VendorInvoices",
      middleware: [auth],
      breadcrumb: [
        {
          text: "VendorInvoices",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor/invoice",
    name: "vendor-invoice",
    component: InvoiceForm,
    meta: {
      pageTitle: "InvoiceForm",
      middleware: [auth],
      breadcrumb: [
        {
          text: "InvoiceForm",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor/invoice/:id",
    name: "vendor-invoice-edit",
    component: InvoiceEditForm,
    meta: {
      pageTitle: "InvoiceEditForm",
      middleware: [auth],
      breadcrumb: [
        {
          text: "InvoiceEditForm",
          active: true,
        },
      ],
    },
  },
];
