<template>
  <div>
    <b-row class="match-height">
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="total_income"
          statistic-title="Net Income"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          statistic="00"
          statistic-title="Total Bill Collection"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="total_expence"
          statistic-title="Total Expance"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="total_revenue"
          statistic-title="Total Income"
        />
      </b-col>
    </b-row>
    <div class="card card-body">
      <div class="row mb-3">
        <div class="col-12">
          <b-dropdown
            size="sm"
            id="dropdown-left"
            text="Export"
            variant="primary"
          >
            <b-dropdown-item>
              <export-excel name="income.xls" :data="items"> xls </export-excel>
            </b-dropdown-item>
            <b-dropdown-item>
              <export-excel :data="items" type="csv" name="income.csv">
                csv
              </export-excel>
            </b-dropdown-item>
            <b-dropdown-item>
              <span @click="downloadPDF"> PDF</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="col-12">
          <form @submit.prevent="searchDate">
            <div class="d-flex">
              <div class="form-group">
                <label for="from-date">From Date</label>
                <br />
                <input
                  v-model="form.start_date"
                  type="date"
                  name=""
                  class="form-control"
                  id=""
                />
              </div>
              <div class="form-group">
                <label for="from-date">To Date</label>
                <br />
                <input
                  v-model="form.end_date"
                  type="date"
                  name=""
                  class="form-control"
                  id=""
                />
              </div>
              <div class="mt-2">
                <input type="submit" class="btn btn-info" value="Check" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";

export default {
  components: { BCardCode, StatisticCardHorizontal },

  nameL: "ProfiteAndLoss",
  data() {
    return {
      form: new Form({
        start_date: "",
        end_date: "",
      }),

      total_income: 0,
      total_expence: 0,
      total_revenue: 0,
      dashboardusers: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      modal_title: "Create Data",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // ============= downloadPDF  ================
    downloadPDF() {
      const doc = new jsPDF();
      doc.autoTable({
        head: [["Total Income", "Total Expance", "Total Peofit & Loss"]],
        body: [[this.total_income, this.total_expence, this.total_revenue]],
      });

      doc.save("monthlyexpenses.pdf");
    },
    searchDate() {
      console.log(this.form);
      this.form
        .post("searchbetweenDate")
        .then((res) => {
          console.log(res);
          if (res.data.success) {
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ===get data=========

    getData() {
      this.axios.get("netIncomeAndLoss").then((res) => {
        if (res.data.success) {
          console.log(res.data);
          this.total_income = res.data.total;
          this.total_expence = res.data.tincome;
          this.total_revenue = res.data.texpence;
          this.listData = res.data.data;
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
  },
};
</script>
