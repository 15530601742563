<template>
  <div class="shadow" v-if="userData">
    <div class="row">
      <!-- User Sidebar -->
      <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
        <!-- User Card -->
        <div class="card">
          <div class="card-body">
            <div class="user-avatar-section">
              <div class="d-flex align-items-center flex-column">
                <img
                  class="user_avatar--img img-fluid rounded mt-3 mb-2"
                  :src="
                    avater_image !== ''
                      ? avater_image
                      : require('@/assets/images/avatars/13-small.png')
                  "
                  height="110"
                  width="110"
                  alt="User avatar"
                />
                <div class="d-flex">
                  <div v-if="!is_change_avater">
                    <label
                      for="avater_image"
                      class="edit_image--icon btn btn-primary btn-sm mb-1"
                    >
                      <feather-icon size="20" icon="EditIcon" />
                    </label>
                    <input
                      @change="changetAvaterImage"
                      type="file"
                      class="hidden"
                      id="avater_image"
                    />
                  </div>
                  <div v-else>
                    <div
                      @click.prevent="saveAvaterImage()"
                      for="avater_image"
                      class="edit_image--icon btn btn-success btn-sm mb-1 mx-1"
                    >
                      Save
                    </div>
                    <div
                      @click.prevent="cancelChangeAvater()"
                      for="avater_image"
                      class="edit_image--icon btn btn-warning btn-sm mb-1"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
                <div class="user-info text-center">
                  <h4 class="text-capitalize">{{ userData.name }}</h4>
                  <span class="badge bg-light-secondary text-capitalize">{{
                    userData.roles[0].name
                  }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-around my-2 pt-75">
              <div class="d-flex align-items-start">
                <span class="badge bg-light-primary p-75 rounded mr-1">
                  <feather-icon size="16" icon="UserIcon" class="" />
                </span>
                <div class="ms-75">
                  <h4 class="mb-0">
                    {{ userData.homecolletioncustomer.length }}
                  </h4>
                  <small>Assign Customer</small>
                </div>
              </div>
              <div class="d-flex align-items-start me-2">
                <span class="badge bg-light-primary p-75 rounded mr-1">
                  <feather-icon size="16" icon="DollarSignIcon" class="" />
                </span>
                <div class="ms-75">
                  <h4 class="mb-0 badge badge-light-info">
                    {{ userData.balance }}
                  </h4>
                  <small>Balance</small>
                </div>
              </div>
            </div>
            <h4 class="fw-bolder border-bottom pb-50 mb-1">Details</h4>
            <div class="info-container">
              <ul class="list-unstyled">
                <li class="mb-75">
                  <span class="fw-bolder me-25">Username:</span>
                  <span>{{ userData.name }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Billing Email:</span>
                  <span>{{ userData.email }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Status:</span>
                  <span class="badge bg-light-success">{{
                    userData.status == 1 ? "Active" : "Inactive"
                  }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Role:</span>
                  <span>{{ userData.roles[0].name }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25"
                    >Contact:
                    <input
                      v-if="is_changePhone"
                      type="text"
                      class="form-control"
                      v-model="phone_no"
                    />
                    <span v-else>{{ userData.phone }} </span>
                    <span
                      v-if="!is_changePhone"
                      class="btn btn-sm text-primary"
                      @click.prevent="editPhone(userData.phone)"
                    >
                      <feather-icon size="20" icon="EditIcon" />
                    </span>
                    <div v-if="is_changePhone" class="mt-1">
                      <span
                        class="btn btn-sm btn-primary"
                        @click.prevent="saveUserTableData('save')"
                      >
                        <feather-icon size="20" icon="SaveIcon" />
                      </span>
                      <span
                        class="btn btn-sm btn-danger"
                        @click.prevent="saveUserTableData('cancel')"
                      >
                        <feather-icon size="20" icon="XCircleIcon" />
                      </span>
                    </div>
                  </span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Language:</span>
                  <span>Bangali</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Country:</span>
                  <span>Bangladesh</span>
                </li>
              </ul>
              <div v-if="$route.params.id == user.id"></div>
              <div v-else class="d-flex justify-content-center pt-2">
                <a
                  href="javascript:;"
                  class="ml-1 btn btn-outline-danger suspend-user"
                  >Suspended</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- /User Card -->
        <!-- Plan Card -->
        <div class="card border-primary">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
              <!-- <span class="badge bg-light-primary">Standard</span> -->
            </div>

            <div class="d-grid w-100 mt-2">
              <!-- <button
                class="btn btn-primary"
                data-bs-target="#upgradePlanModal"
                data-bs-toggle="modal"
              >
                Upgrade Plan
              </button> -->
            </div>
          </div>
        </div>
        <!-- /Plan Card -->
      </div>
      <!--/ User Sidebar -->

      <!-- User Content -->
      <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
        <!-- User Pills -->
        <ul class="nav nav-pills mb-2">
          <li @click.prevent="changetab('account')" class="nav-item">
            <a v-bind:class="tab == 'account' ? 'nav-link active' : 'nav-link'">
              <i data-feather="user" class="font-medium-3 me-50"></i>
              <span class="fw-bold">Account</span></a
            >
          </li>
          <li class="nav-item" @click.prevent="changetab('security')">
            <a
              v-bind:class="tab == 'security' ? 'nav-link active' : 'nav-link'"
            >
              <i data-feather="lock" class="font-medium-3 me-50"></i>
              <span class="fw-bold">Security</span>
            </a>
          </li>
          <li class="nav-item" @click.prevent="changetab('ticket')">
            <a
              v-bind:class="tab == 'ticket' ? 'nav-link active' : 'nav-link'"
              class="nav-link"
            >
              <i data-feather="lock" class="font-medium-3 me-50"></i>
              <span class="fw-bold">Tickets</span>
            </a>
          </li>
          <li class="nav-item" @click.prevent="changetab('invoices')">
            <a
              v-bind:class="tab == 'invoices' ? 'nav-link active' : 'nav-link'"
            >
              <i data-feather="lock" class="font-medium-3 me-50"></i>
              <span class="fw-bold">Invoice Collection</span>
            </a>
          </li>
        </ul>
        <!--/ User Pills -->
        <div v-if="tab == 'account'">
          <!-- Project table -->
          <div class="card">
            <h4 class="card-header">Bill Collection Customer</h4>
            <div
              style="height: 300px"
              class="table-responsive scrollable scrollable_group"
            >
              <table class="table datatable-project">
                <thead>
                  <tr>
                    <th>#No</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      collectable_customer, index
                    ) in userData.homecolletioncustomer"
                    :key="collectable_customer.id + 'd'"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'apps-users-view',
                          params:
                            collectable_customer.users.connection_info.user_id,
                        }"
                        target="_blank"
                      >
                        {{
                          collectable_customer.users.connection_info.username
                        }}
                      </router-link>
                    </td>
                    <td>{{ collectable_customer.users.phone }}</td>
                    <td>
                      {{
                        collectable_customer.users.userdetails.house_number_one
                      }}
                      {{
                        collectable_customer.users.userdetails.road_number_one
                      }}
                      {{ collectable_customer.users.userdetails.area_one }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- /Project table -->

          <!-- Activity Timeline -->
          <div class="card">
            <h4 class="card-header">Login Log</h4>
            <div
              style="height: 200px"
              class="card-body pt-1 scrollable scrollable_group"
            >
              <ul class="timeline ms-50">
                <li
                  v-for="(loginitem, index) in userData.loginhistory"
                  :key="index"
                  class="timeline-item"
                >
                  <span class="timeline-point timeline-point-indicator"></span>
                  <div class="timeline-event">
                    <div
                      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
                    >
                      <h6>{{ loginitem.platform }} {{ loginitem.browser }}</h6>
                      <span class="timeline-event-time me-1">
                        iP {{ loginitem.ip }}
                      </span>
                    </div>
                    <p>
                      User login at {{ formatDateTime(loginitem.created_at) }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Activity Timeline -->

          <!-- Invoice table -->
          <div class="card" v-if="userData.billtransferhistory.length > 0">
            <h4 class="card-header">Balance Transfer History</h4>
            <table class="invoice-table table text-nowrap">
              <thead>
                <tr>
                  <th>#No</th>
                  <th class="cell-fit">Date</th>
                  <th class="text-truncate">Transfer To</th>
                  <th class="text-truncate">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userData.billtransferhistory"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ formatDateTime(item.created_at) }}</td>
                  <td>{{ item.users.name }}</td>
                  <td>
                    <span v-if="item.collection">
                      {{ item.collection.received }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /Invoice table -->
        </div>
        <div v-else-if="tab == 'security'">
          <form action="">
            <div class="row">
              <div class="col-sm-12">
                <label for="current_confirmation">Current password</label>
                <b-input-group>
                  <b-form-input
                    v-model="restPasswordform.current_password"
                    id="current_confirmation"
                    :type="passwordFieldType"
                    placeholder="Current Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm-12">
                <label for="new_password">New confirmation</label>
                <b-input-group>
                  <b-form-input
                    v-model="restPasswordform.current_password"
                    id="new_password"
                    :type="passwordFieldType"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm-12">
                <label for="password_confirmation">password confirmation</label>
                <b-input-group>
                  <b-form-input
                    v-model="restPasswordform.password_confirmation"
                    id="password_confirmation"
                    :type="passwordFieldType"
                    placeholder="Confirmation Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility('confirm')"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
            <button class="mt-2 btn btn-sm btn-primary">Submit</button>
          </form>
        </div>
        <div v-else-if="tab == 'ticket'" class="card">
          <h4 class="card-header">Ticket</h4>
          <table class="invoice-table table text-nowrap table-responsive">
            <thead>
              <tr>
                <th>#No</th>
                <th class="text-truncate">ticket no</th>
                <th class="text-truncate">status</th>
                <th class="cell-fit">Open Date</th>
                <th class="cell-fit">Solved Date</th>
                <th class="cell-fit">Details</th>
              </tr>
            </thead>
            <tbody v-if="userData.assign_ticket.length > 0">
              <tr v-for="(item, index) in userData.assign_ticket" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.ticket_no }}</td>
                <td>
                  <span
                    :class="`badge ${
                      item.status == 0
                        ? 'badge-light-warning'
                        : item.status == 1
                        ? 'badge-light-info'
                        : item.status == 2
                        ? 'badge-light-success'
                        : ''
                    }`"
                  >
                    {{
                      item.status == 0
                        ? "Pending"
                        : item.status == 1
                        ? "Processing"
                        : item.status == 2
                        ? "Complete"
                        : ""
                    }}
                  </span>
                </td>
                <td>{{ formatDateTime(item.created_at) }}</td>
                <td>{{ formatDateTime(item.updated_at) }}</td>
                <td>
                  <span v-b-tooltip.top.v-primary :title="item.details">
                    {{ limit_text("......", 10) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="tab == 'invoices'" class="card">
          <h4 class="card-header text-capitalize">invoices</h4>
          <table class="invoice-table table text-nowrap table-responsive">
            <thead>
              <tr>
                <th>#No</th>
                <th class="cell-fit">Date</th>
                <th class="text-truncate">invoice_no</th>
                <th class="text-truncate">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in userData.collection" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ formatDateTime(item.created_at) }}</td>
                <td>{{ item.invoice_no }}</td>
                <td>{{ item.received }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--/ User Content -->
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";
import axios from "axios";
import ticket from "@/router/ticket";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
export default {
  nameL: "userProfile",
  components: {
    BButton,
    BModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      restPasswordform: new Form({
        current_password: "",
        new_password: "",
        password_confirmation: "",
        change_field: "change_password",
        user_id: this.$route.params.id,
      }),
      tab: "account",
      userData: null,
      is_change_avater: false,
      avater_image: "",
      avater_file: "",
      is_changePhone: false,
      phone_no: "",
    };
  },
  created() {
    this.getdata();
  },

  computed: {
    user() {
      return this.$store.state.isp.user.info;
    },
    passwordToggleIcon(action) {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    getdata() {
      axios
        .get(`user-by-id/${this.$route.params.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.userData = resp.data.data;
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    changetAvaterImage(e) {
      console.log(e.target.files[0]);
      const file = e.target.files[0];
      this.avater_file = e.target.files[0];
      this.avater_image = URL.createObjectURL(file);
      this.is_change_avater = true;
    },

    saveAvaterImage() {
      console.log("this.avater_file", this.avater_file);
      const formData = new FormData();
      formData.append("image", this.avater_file);
      axios
        .post(`save-file`, {
          formData,
          image_for: "users-profile-image",
          file: this.avater_file,
          user_id: this.$route.params.id,
        })
        .then((resp) => {
          if (resp.data.success) {
            this.getdata();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    editPhone(val) {
      this.is_changePhone = true;
      this.phone_no = val;
    },
    saveUserTableData(val) {
      if (val == "save") {
        axios
          .post(`change-user-data`, {
            user_id: this.$route.params.id,
            phone: this.phone_no,
            change_field: "phone_no",
          })
          .then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getdata();
              this.is_changePhone = false;
              this.phone_no = "";
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (val == "cancel") {
        this.is_changePhone = false;
        this.phone_no = "";
      } else if (val == "change_password") {
        this.restPasswordform
          .post(`change-user-data`)
          .then((resp) => {
            if (resp.data.success) {
              this.$router.push("/login");
              this.$s_tost(resp.data.message);
              localStorage.clear();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    cancelChangeAvater() {
      this.is_change_avater = false;
      this.avater_image =
        this.userData.avatar !== null
          ? this.userData.avatar
          : require("@/assets/images/avatars/13-small.png");
    },
    changetab(val) {
      this.tab = val;
    },
    changePassword() {
      this.form.post("");
    },
  },
};
</script>
<style>
label {
  text-transform: capitalize;
}
</style>
