<template>
  <div class="container card shadow p-2">
    <h2 class="text-center" v-if="permissionError !== ''">
      {{ permissionError }}
    </h2>
    <div v-else class="row">
      <div class="col-12">
        <b-card-code :title="title" no-body>
          <b-card-body>
            <form id="form" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <h5>Nas Type</h5>
                  <div class="d-flex form-group">
                    <b-form-radio v-model="form.nas_type" name="MikroTik" value="MikroTik">
                      MikroTik
                    </b-form-radio>
                    <b-form-radio class="mx-2" v-model="form.nas_type" name="Radius" value="Radius">
                      Radius
                    </b-form-radio>
                    <b-form-radio v-model="form.nas_type" name="MikroTik+Radius" value="MikroTik+Radius">
                      MikroTik + Radius
                    </b-form-radio>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <b-form-group label="Disconnected Package:" label-for="package">
                    <select v-model="form.package_name" class="form-control" id="">
                      <option value="">Select</option>
                      <option v-for="pkgItem in packages" :key="pkgItem.id" :value="pkgItem.name">
                        {{ pkgItem.name }}
                      </option>
                    </select>
                  </b-form-group>
                  <!-- <b-form-group v-else label="Disconnected Package:" label-for="Package">
                    <b-form-input v-model="form.Package" id="Package" placeholder="Enter Disconnect Package Name" />
                  </b-form-group> -->
                </div>
                <div class="col-sm-12 col-md-2">
                  <b-form-group label="grace:" label-for="package">
                    <input type="number" class="form-control" placeholder="Enter Dynamic Grace Number EX:3"
                      v-model="form.grace" name="" id="" />
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-md-2">
                  <input type="submit" value="Save" class="mt-2 btn btn-primary" />
                </div>
              </div>
            </form>
            <hr>
            <div class="row">
              <div class="col-12">
                <h5>Radacct Optimazation</h5>
              </div>
              <div class="col-sm-12 col-md-3">
                <b-form-group label="1st Optimization Day: " description="Date of the month">
                  <input type="number" class="form-control" placeholder="EX:15"
                    v-model="radacctForm.radacct_optimization_first_date" name="" id="" />
                </b-form-group>
                <b-form-group label="2nd Optimization Day: ">
                  <input type="number" class="form-control" placeholder="EX:15"
                    v-model="radacctForm.radacct_optimization_second_date" name="" id="" />
                </b-form-group>
                <b-form-group label="3rd Optimization Day:">
                  <input type="number" class="form-control" placeholder="EX:15"
                    v-model="radacctForm.radacct_optimization_third_date" name="" id="" />
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-3">
                <b-form-group label="Number of row : ">
                  <input type="number" class="form-control" placeholder="EX:120" v-model="radacctForm.row_limit" name=""
                    id="" />
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-3">
                <b-form-group label="Time Interval in second : ">
                  <input type="number" class="form-control" placeholder="EX:120" v-model="radacctForm.time_interval"
                    name="" id="" />
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-2">
                <input @click.prevent="updateRadacctData()" type="submit" value="Save" class="mt-2 btn btn-primary" />
                <input @click.prevent="checkRadachTable('check')" type="submit" value="Move Table"
                  class="mt-2 btn btn-primary" />
              </div>
            </div>
          </b-card-body>
        </b-card-code>
      </div>
    </div>

    <b-modal id="data_modal" size="md" title="Radacct data Backup" hide-footer no-close-on-backdrop>
      <b-row class="match-height">
        <b-col lg="6" sm="6">
          <statistic-card-horizontal icon="InfoIcon" :statistic="total_rows" statistic-title="Total Rows" />
        </b-col>
        <b-col lg="6" sm="6">
          <b-form @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pb-0 d-flex">
                <b-form-group label="Number of row : " class="pr-1">
                  <input type="number" class="form-control" placeholder="EX:500" v-model="row_limit" name="" id="" />
                </b-form-group>
              </b-card-body>
            </b-card>
            <input @click.prevent="checkRadachTable('submit')" type="submit" value="Save"
              class="mt-2 btn btn-primary float-right" />
          </b-form>
        </b-col>
      </b-row>
      <b-col cols="12">

      </b-col>
    </b-modal>
  </div>
</template>
<script>
import Form from "vform";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import Swal from "sweetalert2";

export default {
  name: "SoftwareSetting",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      title: "Software Setting",
      form: new Form({
        grace: null,
        nas_type: "",
        package: "",
        package_name: "",
        package_model: {},
      }),
      radacctForm: new Form({
        radacct_optimization_first_date: null,
        radacct_optimization_second_date: null,
        radacct_optimization_third_date: null,
        row_limit: null,
        time_interval: null,
      }),
      permissionError: "",
      select_options_package: [],
      total_rows: 0,
      row_limit: 0,
      time_interval: 0,
    };
  },
  computed: {
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
    packages() {
      return this.$store.state.isp.others.package;
    },
  },
  watch: {},
  created() {
    this.$store.dispatch("others", {
      zone: true,
      mikrotik: true,
      user_reference_number: true,
      package: true,
      districts: true,
    });
    this.getData();
  },
  methods: {
    selectPackge(e) { },
    // =====getData=============
    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("software-system").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.form.nas_type = resp.data.data.data.nas_type;
          this.form.package_name = resp.data.data.data.disconnected_package;
          this.form.grace = resp.data.data.grace.value;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    // ======save data========
    save() {
      this.$store.dispatch("spinnerLoading", true);
      this.form.post("software-system").then((resp) => {
        if (resp.data.success) {
          this.getData();
          this.$s_tost(resp.data.message);
          window.location.reload();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    // updateRadacctData
    updateRadacctData() {
      if (this.radacctForm.radacct_optimization_first_date == null) {
        this.$error_message('Radacct optimization first date is Required');
      } else if (this.radacctForm.row_limit == null) {
        this.$error_message('Number of Row filed is Required');
      } else if (this.radacctForm.time_interval == null) {
        this.$error_message('Time Interval filed is Required');
      }


      // set data admin settings table
      this.radacctForm.post("system/setting").then((resp) => {
        if (resp.data.success) {
          this.getData();
          this.$s_tost(resp.data.message);
          window.location.reload();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      }).finally(() => {
        this.$store.dispatch("spinnerLoading", false);
      });
    },

    //
    checkRadachTable(value) {
      if (value == 'check') {
        this.$store.dispatch("spinnerLoading", true);
        this.$bvModal.show('data_modal')
        axios.get("get-total-radacct-row").then((resp) => {
          if (resp.data.success) {
            this.total_rows = resp.data.data
          }
        }).finally(() => {
          this.$store.dispatch("spinnerLoading", false);
        });
      } else {
        console.log(this.row_limit);
        if (this.row_limit == null || this.row_limit <= 0 || this.row_limit > 1000) return this.$error_message('Please Input Number of Row (Max: 1000)');
        Swal.fire({
          title: "Are you sure?",
          text: `You Want to move radacct table`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#70e111",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("spinnerLoading", true);
            const startTime = new Date().getTime(); // get the current time
            axios.get(`backup-radacct-table?row_limit=${this.row_limit}&time_interval=${this.time_interval}`).then(resp => {
              if (resp.data.success) {
                this.$s_tost(resp.data.message)
                this.form.reset()
                this.$bvModal.hide('data_modal')
              }
            }).finally((response) => {
              const endTime = new Date().getTime();
              const responseTimeInSeconds = (endTime - startTime) / 1000; // convert milliseconds to seconds
              this.$store.dispatch("spinnerLoading", false);
              return this.$error_message(`Response time: ${responseTimeInSeconds.toFixed(1)} Second`);
            });
          }
        })
      }


      // const slug = ['radacct_optimization_first_date',
      //   'radacct_optimization_second_date',
      //   'radacct_optimization_third_date',
      //   'row_limit',
      //   'time_interval']
      //   axios.get(`get-dynamic-data?slug=${slug}`).then((resp) => {

    },
  },
};
</script>
