/* eslint-disable vue/no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable vue/html-indent */
/* eslint-disable vue/html-indent */
/* eslint-disable quote-props */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="card bg-grey shadow round p-4">
          <dl
            v-if="!editData.mode"
            class="row"
          >
            <dt class="col-sm-3">Name:</dt>
            <dd class="col-sm-9"><span v-if="form.name">
                                   {{ form.name }}</span>
              <Skeleton
                v-else
                height="20px"
              /></dd>
            <dt class="col-sm-3">Email:</dt>
            <dd class="col-sm-9"> <span v-if="form.email">{{ form.email}}</span>
              <Skeleton
                v-else
                height="20px"
              />
            </dd>
            <dt class="col-sm-3">Phone:</dt>
            <dd class="col-sm-9"><span v-if="form.phone">
                                   {{ form.phone }}
                                 </span>
              <Skeleton
                v-else
                height="20px"
              />
            </dd>
            <button
              class="btn btn-primary mt-2 btn-block"
              @click.prevent="editData.mode=true"
            >Edit</button>

          </dl>
          <form
            v-else
            @submit.prevent="update"
          >
            <div class="form-group">
              <label for="">Name</label>
              <input
                v-model="form.name"
                type="text"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label for="">Email</label>
              <input
                v-model="form.email"
                type="text"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label for="">Phone</label>
              <input
                v-model="form.phone"
                type="text"
                class="form-control"
              >
            </div>
            <button
              type="submit"
              class="btn btn-primary mt-2 btn-block"
            >Update</button>
            <a
              class="btn btn-info mt-2 btn-block"
              @click.prevent="editData.mode=false"
            >Back</a>

          </form>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Form from 'vform'

export default {
  name: 'Profile',

  data() {
    return {
      form: new Form({
        name: '',
        email: '',
        phone: '',

      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: 'Create Data',
    }
  },
  created() {
    setTimeout(() => {
      this.user()
    }, 2000)
  },
  methods: {
    user() {
      const user = this.$store.state.isp.user.info

      this.form.name = user.name
      this.form.email = user.email
      this.form.phone = user.phone
      // this.editData.id = user.id
    },
    update() {
      this.form
        .put(`user/${this.editData.id}`)
        .then(resp => {
          console.log(resp)
          if (resp.data.success) {
            this.$success_message(resp.data.message)
            this.getData()
          } else {
            this.$error_message(resp.data.message, resp.data.errors)
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
  },

}
</script>
