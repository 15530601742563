<template>
  <b-modal
    id="customer_import"
    :title="modal_title"
    size="sm"
    hide-header-close
    hide-footer
  >
    <form enctype="multipart/form-data">
      <b-form-file
        @change="uploadImage($event)"
        v-model="form.file"
        placeholder="Choose a csv file or drop it here..."
        drop-placeholder="Drop file here..."
      />
      <b-button
        size="sm"
        col="2"
        class="mt-1"
        variant="outline-success"
        @click.prevent="download_customer_demo_sample()"
      >
        <feather-icon icon="DownloadCloudIcon" size="16" />
        Download Sample
      </b-button>
      <div class="mt-1">
        <button @click.prevent="close()" class="btn btn-warning">Close</button>
        <button @click.prevent="SaveImportFile()" class="btn btn-primary">
          <feather-icon icon="UploadCloudIcon" size="16" /> Import
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Form from "vform";
import { BFormFile } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "CustomerImport",
  data() {
    return {
      form: new Form({
        file: null,
      }),
      modal_title: "Customer Imports",
    };
  },
  methods: {
    uploadImage($event) {
      const file = $event.target.files[0];
      if (!file.type.match("text/csv")) {
        alert("This is not any kind of image");
        return;
      }
      this.form.file = file;
      console.log(this.form.file);
    },
    close() {
      this.$bvModal.hide("customer_import");
    },
    // SaveImportFile
    SaveImportFile() {
      this.form.post("customer-import").then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.$bvModal.hide("customer_import");
          router.push({ name: "ImportsCustomers" });
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    //  = ====== OpenModal
    OpenModal(action = "update_balance", val) {
      if (action == "customer_import") {
        this.$bvModal.show("customer_import");
      }
    },
    // download_customer_demo_sample
    download_customer_demo_sample() {
      axios.get("download-customer-demo-report").then((resp) => {
        const url = `${axios.defaults.baseURL}download-customer-demo-report`;
        window.location.href = url;
      });
    },
  },
  components: {
    BFormFile,
    FeatherIcon,
  },
};
</script>
