<template>
  <div>
    <b-row class="match-height" v-if="items">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.data.length"
          statistic-title="Total Open"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Total Complete"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="warning"
          :statistic="summary.total_inactive"
          statistic-title="Total Pending"
        />
      </b-col>
    </b-row>
    <b-card-code no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              v-if="permission.add"
              size="sm"
              col="2"
              variant="primary"
              @click="modelAction('add')"
            >
              <!-- @click.prevent="editData.mode = false" -->
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New
            </b-button>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(Category)="data">
          {{ data.item.t_category.name }}
        </template>
        <template #cell(details)="data">
          <span v-b-tooltip.top.v-primary :title="data.item.details">{{
            limit_text(data.item.details, 20)
          }}</span>
        </template>
        <template #cell(created_by)="data">
          {{ data.item.create_by ? data.item.create_by.name : "" }}
        </template>
        <template #cell(Solved_By)="data">
          {{ data.item.solved_by ? data.item.solved_by.name : "" }}
        </template>
        <template #cell(assign_to)="data">
          <div class="d-flex">
            <span v-if="data.item.assign_employee" class="badge">{{
              data.item.assign_employee.name
            }}</span>
            <span
              v-if="data.item.status !== 2"
              @click.prevent="assign_employee(data.item.id)"
              class="badge badge-primary"
              v-b-tooltip.top.v-primary
              title="Change Assign Employ"
            >
              <feather-icon icon="UserPlusIcon" class="mr-50" />
            </span>
          </div>
        </template>
        <template #cell(status)="data">
          <select
            style="width: 120px"
            v-if="permission.status && data.item.status !== 2"
            @change="changeStatus(data.item)"
            v-model="data.item.status"
            :class="`form-control font-weight-bold ${
              data.item.status == 0
                ? 'bg-warning text-white'
                : data.item.status == 1
                ? 'bg-info text-white'
                : data.item.status == 2
                ? 'bg-success text-white'
                : ''
            }`"
          >
            <option value="0">Pending</option>
            <option value="1">Processing</option>
            <option value="2">Complete</option>
          </select>
          <span v-else class="badge badge-light-success">Completed</span>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item v-if="permission.edit">
              <button class="btn btn-sm p-0" @click.prevent="edit(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.delete"
              @click.prevent="deleteData(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalNoOfRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
    <!-- modal  lg-->
    <b-modal
      id="modal-lg"
      :title="modal_title"
      size="xl"
      hide-header-close
      hide-footer
    >
      <form @submit.prevent="submitHandelar">
        <div class="row">
          <div class="col-sm-6 col-md-8">
            <label for="Customer Id or Phone"
              >Customer Id or Phone ({{ useroptions.length }})</label
            >
            <v-select
              class="border-primary rounded"
              @input="ChangeSerch"
              label="title"
              v-model="form.usersItems"
              :options="useroptions"
              placeholder="Customer Id or Phone"
            />
            <!-- <div class="col-sm-12 col-md-4"> -->

            <b-card v-if="uerInfos !== null" no-body class="border mt-1 p-1">
              <h3 class="text-center">
                <b-badge variant="light-primary">Basic info</b-badge>
              </h3>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Name" label-for="name">
                    <b-form-input
                      v-model="form.name"
                      id="name"
                      placeholder="Name"
                    />
                  </b-form-group>
                  <b-form-group label="Phone" label-for="Phone">
                    <b-form-input
                      v-model="form.phone"
                      id="Phone"
                      placeholder="Phone"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" class="border-left">
                  <span>Pending Complaint :(0)</span> <br />
                  <span>Address:</span><br />
                  <span>Zone :</span> <br />
                </b-col>
                <b-col md="4" class="border-left">
                  <h6>
                    Total Complaint :
                    <b-button
                      v-b-popover.hover.top="'Total Complaint'"
                      size="sm"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-info"
                      class="btn-icon rounded-circle"
                    >
                      {{ anothertickets.length }}
                    </b-button>
                    <b-button
                      v-b-popover.hover.top="'Total Pending Complaint'"
                      size="sm"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-warning"
                      class="btn-icon rounded-circle"
                    >
                      {{ anothertickets.filter((e) => e.status == 0).length }}
                    </b-button>
                    <b-button
                      v-b-popover.hover.top="'Total Processing Complaint '"
                      size="sm"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                    >
                      {{ anothertickets.filter((e) => e.status == 1).length }}
                    </b-button>
                    <b-button
                      v-b-popover.hover.top="'Total Solved'"
                      size="sm"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      class="btn-icon rounded-circle"
                    >
                      {{ anothertickets.filter((e) => e.status == 2).length }}
                    </b-button>
                  </h6>
                  <div class="scrollable" style="height: 150px">
                    <app-timeline v-if="anothertickets.length > 0">
                      <app-timeline-item
                        v-for="(oldtkt, index) in anothertickets"
                        :key="index + 'old'"
                        :variant="
                          oldtkt.status == 0
                            ? 'warning'
                            : oldtkt.status == 1 //processing
                            ? 'primary'
                            : oldtkt.status == 2 // completed
                            ? 'success'
                            : 'secondary'
                        "
                      >
                        <div
                          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                        >
                          <h6>{{ oldtkt.details }}</h6>
                          <div class="">
                            <small class="float-left text-muted"
                              >{{ oldtkt.t_category.name }} |
                            </small>
                            <small class="float-left text-muted"
                              >{{ oldtkt.priority }} |
                            </small>
                            <small class="float-left text-muted">
                              {{ formatDateInAgo(oldtkt.created_at) }} |
                            </small>
                            <small class="float-left text-muted"
                              >Created By: {{ oldtkt.create_by.name }}</small
                            >
                          </div>
                        </div>
                      </app-timeline-item>
                    </app-timeline>
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="uerInfos !== null" no-body class="border p-1">
              <h3 class="text-center">
                <b-badge variant="light-primary"> Ticket Option</b-badge>
              </h3>
              <b-card-body class="p-1">
                <b-row>
                  <b-col md="4">
                    <label for="ticketType">Ticket Type</label>
                    <select
                      @change="changeTicketType"
                      v-model="form.ticketType"
                      id="ticketType"
                      class="form-control"
                    >
                      <option value="">---SELECT TYPE----</option>
                      <option
                        v-for="(tkItem, index) in ticketCategorysOptions"
                        :key="index + 'prority'"
                        :value="{
                          id: tkItem.id,
                          priority: tkItem.priority,
                        }"
                      >
                        {{ tkItem.name }} | {{ tkItem.priority }}
                      </option>
                    </select>
                  </b-col>
                  <b-col md="4">
                    <!-- default ticket category priority -->
                    <label for="priority">Priority</label>
                    <select
                      id="priority"
                      v-model="form.priority"
                      class="form-control"
                    >
                      <option value="">Select</option>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                  </b-col>
                  <b-col md="4">
                    <b-form-checkbox class="mt-2" v-model="form.send_sms">
                      Send SMS
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="12">
                    <div class="form-group">
                      <label for="">Complaint Details</label>
                      <textarea
                        v-model="form.details"
                        class="form-control"
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                      ></textarea>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </div>
          <div class="col-sm-6 col-md-4">
            <b-card v-if="mikrotik_info.length > 0" no-body class="border pt-1">
              <h3 class="text-center m-0">
                <b-badge variant="light-primary"> Mikrotik info</b-badge>
              </h3>
              <b-card-body class="p-1">
                <tr
                  v-for="(value, name, index) in mikrotik_info[0]"
                  :key="'mkt' + index"
                >
                  <th v-if="name !== '.id'">
                    {{ name }}
                  </th>
                  <th v-if="name !== '.id'"><div class="mx-1">:</div></th>
                  <td>
                    <b-badge
                      v-if="name == 'disabled' && value == 'false'"
                      variant="light-success"
                      >No
                    </b-badge>
                    <b-badge
                      v-else-if="name == 'disabled' && value == 'true'"
                      variant="light-danger"
                      >Yes
                    </b-badge>
                    <span v-else-if="name !== '.id'">{{ value }}</span>
                    <!-- <span v-else>{{ value }}</span> -->
                  </td>
                </tr>
              </b-card-body>
            </b-card>
            <b-card
              v-if="uerInfos !== null && uerInfos.billing_info !== null"
              no-body
              class="border pt-1"
            >
              <h3 class="text-center">
                <b-badge variant="light-primary">
                  Package & Billing info</b-badge
                >
              </h3>
              <b-card-body class="px-1">
                <tr>
                  <th>Current Package</th>
                  <th><div class="mx-1">:</div></th>
                  <td>{{ uerInfos.billing_info.package.name }}</td>
                </tr>
                <tr>
                  <th>Package Price</th>
                  <th><div class="mx-1">:</div></th>
                  <td>{{ uerInfos.billing_info.package.price }}</td>
                </tr>
                <tr>
                  <th>Billing Status</th>
                  <th><div class="mx-1">:</div></th>
                  <td>
                    <b-badge
                      pill
                      :variant="
                        uerInfos.billing_info &&
                        uerInfos.billing_info.status == 0
                          ? 'warning'
                          : 'success'
                      "
                      class="text-capitalize"
                    >
                      {{
                        uerInfos.billing_info &&
                        uerInfos.billing_info.status == 0
                          ? "Pending"
                          : "success"
                      }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th>Monthly Bill</th>
                  <th><div class="mx-1">:</div></th>
                  <td>{{ uerInfos.billing_info.monthly_bill }}</td>
                </tr>
                <tr>
                  <th>Last Paid Amount</th>
                  <th><div class="mx-1">:</div></th>
                  <td></td>
                </tr>
                <tr>
                  <th>Last Paid Date</th>
                  <th><div class="mx-1">:</div></th>
                  <td></td>
                </tr>
                <tr>
                  <th>Balance</th>
                  <th><div class="mx-1">:</div></th>
                  <td>{{ uerInfos.billing_info.balance }}</td>
                </tr>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </form>
      <div class="mt-1">
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="submitHandelar" class="btn btn-primary">Save</button>
      </div>
    </b-modal>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";
import vSelect from "vue-select";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "Ticket",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
    vSelect,
    AppTimeline,
    AppTimelineItem,
  },
  directives: { Ripple },
  data() {
    return {
      form: new Form({
        name: "",
        phone: "",
        priority: "",
        send_sms: false,
        ticketType: "",
        details: "",
        usersItems: {},
        ticketCategorys: {},
      }),
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "ticket_no", label: "ticket no" },
        { key: "name", label: "Name" },
        "Category",
        { key: "priority", label: "priority" },
        "created_by",
        "assign_to",
        "Solved_By",
        { key: "status", label: "status" },
        { key: "details", label: "details" },
        "action",
      ],
      uerInfos: null,
      pkg_bill_info: {},
      mikrotik_info: {},
      anothertickets: [],
      ticketCategorysOptions: [],
      items: [],
      useroptions: [],
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      modal_title: "New Ticket",
      pageOptions: [3, 5, 10],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      loading: true,
      summary: {
        total_user: 0,
        total_active: 0,
        total_inactive: 0,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("ticket");
      this.permission.add = this.checkpermission("ticket_add");
      this.permission.edit = this.checkpermission("ticket_edit");
      this.permission.delete = this.checkpermission("ticket_delete");
      this.permission.status = this.checkpermission("ticket_change_status");
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  watch: {},
  created() {
    this.getData();
  },
  methods: {
    changeTicketType() {
      this.form.priority = this.form.ticketType.priority;
    },
    ticketCategorys() {
      this.axios.get(`all-ticket-category`).then((res) => {
        this.ticketCategorysOptions = res.data.data;
      });
    },
    // search
    async ChangeSerch() {
      await axios
        .get(`user-ticket-info/${this.form.usersItems.code}`)
        .then((res) => {
          if (res.data.success) {
            this.uerInfos = res.data.data.data;
            this.form.name = res.data.data.data.name;
            this.form.phone = res.data.data.data.phone;
            this.anothertickets = res.data.data.anotherticket;
            this.mikrotik_info =
              res.data.data.mikrotik_info !== null
                ? res.data.data.mikrotik_info
                : {};
            this.ticketCategorys();
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        });
    },
    // modelAction
    async modelAction(val = null) {
      this.$store.dispatch("spinnerLoading", true);
      this.ticketCategorys;
      await axios.get(`all-customer-by-id-or-phone`).then((res) => {
        this.useroptions = [];
        res.data.data.forEach((element) => {
          this.useroptions.push({
            title: `${
              element.billingInfo
                ? element.billingInfo.user_reference_number
                : "null"
            } | ${element.name} | ${element.phone}`,
            code: element.id,
          });
          if (val !== null && val == element.id) {
            this.form.usersItems = {
              title: `${
                element.billingInfo
                  ? element.billingInfo.user_reference_number
                  : "null"
              } | ${element.name} | ${element.phone}`,
              code: element.id,
            };
          }
        });
        this.$bvModal.show("modal-lg");
        this.$store.dispatch("spinnerLoading", false);
      });
    },
    close() {
      this.$bvModal.hide("modal-lg");
      this.uerInfos = null;
      this.form = {
        name: "",
        phone: "",
        priority: "",
        send_sms: false,
        ticketType: "",
        details: "",
        usersItems: {},
        ticketCategorys: {},
      };
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      axios
        .post("ticket", this.form)
        .then((resp) => {
          if (resp.data.success) {
            this.$s_tost(resp.data.message);
            this.getData();
            this.close();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`ticket/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    async edit(data) {
      this.editData.mode = true;
      this.editData.id = data.id;
      this.modal_title = "Update Ticket";
      this.form.usersItems.code = data.user_id;
      this.form.name = data.name;
      this.form.phone = data.phone;
      this.form.priority = data.priority;
      this.form.details = data.details;
      this.modelAction(data.user_id);
      this.ChangeSerch();
      this.ticketCategorys();
      const findticketcategory = this.ticketCategorysOptions.find(
        (item) => item.id === data.ticket_categories_id
      );
      this.form.ticketType = {
        id: findticketcategory.id,
        priority: findticketcategory.priority,
      };
      this.$bvModal.show("modal-lg");
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`ticket?page=${page}`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data.data;
          this.summary.total_user = 0;
          this.summary.total_active = 0;
          this.summary.total_inactive = 0;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // data.item.id, data.item.status
    // id ,val
    changeStatus(val) {
      if (!val.assign_employee) {
        this.getData();
        return this.$error_message("Please Select Assign Employee");
      }

      var sms_option = false;
      if (val.status == 2) {
        this.Swal.fire({
          title: "Are you sure?",
          text: "Do You Want To Send Ticket Completed SMS",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning ml-1",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            sms_option = true;
          }
          this.axios
            .get(`ticket/${val.id}?val=${val.status}&send_sms=${sms_option}`)
            .then((resp) => {
              if (resp.data.success) {
                this.$s_tost(resp.data.message);
                this.getData();
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        });
      } else {
        this.axios
          .get(`ticket/${val.id}?val=${val.status}&send_sms=${sms_option}`)
          .then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
      }
    },
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`ticket/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },

    // assign Employ
    async assign_employee(id) {
      await this.$store.dispatch("get_all_dashboard_user");
      const user = await this.$store.getters.all_dashboard_users;

      const options = {};
      user.forEach((element) => {
        options[element.id] = element.name;
      });
      this.Swal.fire({
        title: "Please Select Employee",
        input: "select",
        inputOptions: options,
        inputPlaceholder: "Select One",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          axios
            .get(`ticket-assing-employee/${id}`, {
              params: {
                user_id: result.value,
              },
            })
            .then((resp) => {
              if (resp.data.success) {
                this.getData();
                this.$s_tost(resp.data.message);
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
  },
};
</script>
