/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <h2 class="text-center" v-if="permissionError !== ''">
      {{ permissionError }}
    </h2>

    <div v-else class="row">
      <div class="col-lg-12 mb-1">
        <b-button
          v-if="permission.add"
          size="sm"
          col="2"
          v-b-modal.modal-lg
          variant="primary"
          @click.prevent="editData.mode = false"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />
          Add New</b-button
        >
      </div>
      <b-modal id="modal-lg" :title="modal_title" size="lg" hide-footer>
        <form @submit.prevent="submitHandelar">
          <div class="form-group">
            <label for="">Name</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              name="name"
              placeholder="name"
            />
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index + 'dis'">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.name }}</td>
              <td>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="permission.edit"
                    @click.prevent="edit(item.id)"
                  >
                    <feather-icon icon="EditIcon" class="mr-50" />
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="permission.delete"
                    :action="'district/' + item.id"
                    @click.prevent="$trash($event)"
                  >
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :data="items"
          align="right"
          @pagination-change-page="getResults"
        />
      </div>
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  name: "Role",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        name: "",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      permissionError: "",
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("District");
      this.permission.add = this.checkpermission("District Add");
      this.permission.edit = this.checkpermission("District Edit");
      this.permission.delete = this.checkpermission("District Delete");
    },
    items() {
      return this.$store.state.isp.districts;
    },
  },
  created() {
    this.$store.dispatch("districts", 1);
  },
  methods: {
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("district")
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.items.push(resp.data.data);
          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`district/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.$store.dispatch("districts");
          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(id) {
      const item = this.items.find((ele) => ele.id === id);
      this.modal_title = `Update-${item.name}`;

      this.form.name = item.name;
      this.editData.mode = true;
      this.editData.id = item.id;
      this.$bvModal.show("modal-lg");
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },
  },
};
</script>
