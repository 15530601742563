<template>
  <div class="p-2">
    <div class="card card-body row justify-content-center">
      <div class="col-lg-12">
        <form id="form" @submit.prevent="save">
          <div class="row">
            <div class="col-lg-6">

              <b-form-group label="Nas Type:" label-for="Nas Type">
                <div class="d-flex">
                  <b-form-radio @change="selectType('PPPOE')" v-model="form.type" name="MikroTik" value="PPPOE">
                    PPPoE
                  </b-form-radio>
                  <b-form-radio class="mx-1" @change="selectType('Hotspot')" v-model="form.type" name="Hotspot"
                    value="Hotspot">
                    Hotspot
                  </b-form-radio>
                  <b-form-radio @change="selectType('MikroTik')" v-model="form.type" name="MikroTik" value="MikroTik">
                    MikroTik
                  </b-form-radio>
                </div>
              </b-form-group>
              <div class="form-group">
                <div class="form-group d-flex" v-if="form.type == 'MikroTik' || form.type == 'PPPOE'">
                  <label for="is_queue">Queue</label>
                  <label for="is_queue" class="__switch">
                    <input @change="change_is_queue()" id="is_queue" v-model="form.is_queue" type="checkbox"
                      @input="form.is_queue = !form.is_queue" :checked="form.is_queue" />
                    <span class="__slider" />
                  </label>
                </div>
              </div>
              <div v-if="form.is_queue && form.type !== 'PPPOE'" class="form-group">
                <div class="row">
                  <div class="col-9">
                    <v-select v-model="selected_queue" :options="select_options_queue" />
                  </div>
                  <div class="col-3">
                    <button @click.prevent="modelAction('add_queue')" class="btn btn-primary btn-sm">
                      <feather-icon icon="PlusCircleIcon" size="16" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group" v-if="form.type == 'MikroTik'">
                <label for="">Mikrotik</label>
                <!-- <v-select
                  v-model="form.selected_mikrotik"
                  :options="select_options_mikrotik"
                /> -->
                <select disabled v-model="form.mikrotik" class="form-control">
                  <option v-for="mki in mikrotik" :key="mki.id" :value="mki.id">
                    {{ mki.identity + "-" + mki.host }}
                  </option>
                </select>
              </div>
              <div class="form-group" v-else-if="form.type == 'nas'">
                <label for="">NAS</label>
                <select v-model="form.radius" class="form-control">
                  <option v-for="nas in select_options_nas" :key="nas.id" :value="nas.code">
                    {{ nas.label }}
                  </option>
                </select>
                <!-- <v-select v-model="form.radius" :options="select_options_nas" /> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Package Name</label>
                <input v-model="form.name" type="text" class="form-control" placeholder="Name" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Synonym </label>
                <input v-model="form.synonym" type="text" class="form-control" placeholder="Synonym" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6" v-if="form.type !== 'Hotspot'">
              <div class="form-group">
                <label for="">IP Pool</label>
                <!-- <v-select
                  v-model="form.ip_pool"
                  :options="select_options_pool"
                /> -->
                <select v-model="form.ip_pool" class="form-control">
                  <option v-for="pitem in select_options_pool" :key="pitem.id" :value="pitem.code">
                    {{ pitem.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Price</label>
                <input min="1" v-model="form.price" type="number" class="form-control" placeholder="Pirce" />
              </div>
            </div>
            <div v-if="!form.is_queue && this.form.type == 'MikroTik'" class="col-6">
              <div class="form-group">
                <label for="local_address">Local Address</label>
                <input v-model="form.local_address" type="text" class="form-control" placeholder="Enter Local Address"
                  id="local_address" />
              </div>
            </div>
            <div class="col-lg-6" v-if="form.type !== 'Hotspot'">
              <div class="form-group">
                <label for="">POP Price</label>
                <input min="1" v-model="form.pop_price" type="number" class="form-control" placeholder="POP Price"
                  name="pop_price" />
              </div>
            </div>
            <div class="col-lg-6" v-if="form.type !== 'Hotspot'">
              <div class="form-group">
                <label for="">Franchise Price</label>
                <input v-model="form.franchise_price" type="number" min="1" class="form-control"
                  placeholder="Franchise Price" name="franchise_price" />
              </div>
            </div>
            <div class="col-lg-6" v-if="form.type == 'PPPOE'">
              <div class="form-group">
                <label for="">Bandwidth/Speed</label>
                <input v-model="form.bandwidth" type="text" class="form-control"
                  placeholder="Ex1: 1M/1M | Ex2: 1M/1M 2M/2M 1M/1M 30/30 8" name="bandwidth" />
              </div>
            </div>
            <div class="col-lg-6" v-if="form.type == 'Hotspot' || form.type == 'PPPOE'">
              <div class="form-group">
                <label for="">Simultaneous Use</label>
                <input v-model="form.simultaneous" type="number" min="1" class="form-control" placeholder="Simultaneous"
                  name="simultaneous" />
              </div>
            </div>
            <div v-if="form.type == 'Hotspot'" class="col-lg-6">
              <div class="form-group">
                <label for="" class="text-capitalize">Bandwidth/Speed unit
                  <span v-if="speedcal.unit_er !== ''" class="text-danger">{{
                    speedcal.unit_er
                  }}</span></label>
                <select @change="changeSpeed" v-model="form.speed_unit" id="" class="form-control">
                  <option value="">Select Bandwidth Unit</option>
                  <option value="Mbps">Mbps</option>
                  <option value="Kbps">Kbps</option>
                </select>
              </div>
            </div>
            <div v-if="form.type == 'Hotspot'" class="col-lg-6">
              <div class="form-group">
                <label for="Quota">Maximum Usage Quota (MBS)
                  <span v-if="form.quota" class="text-success text-capitalize">
                    | Size in KB: {{ quota.kb }} KB | Size in GB:
                    {{ quota.gb }} GB
                  </span>
                </label>
                <input @input="ChangeMaximumUsageQuota()" type="number" min="1" v-model="form.quota" class="form-control"
                  placeholder="Enter Quota" id="Quota" />
              </div>
            </div>
            <div v-if="form.type == 'Hotspot'" class="col-lg-6">
              <div>
                <label for="">Upload Speed
                  <span v-if="form.uploadspeed">
                    | Bytes per Second:
                    <span class="text-success"> {{ form.uploadspeed }} </span>
                  </span></label>
                <div class="form-group">
                  <input min="1" @input="changeSpeed" type="number" v-model="speedcal.uploadspeed" class="form-control"
                    placeholder="1 or 2" id="" />
                </div>
              </div>
            </div>
            <div v-if="form.type == 'Hotspot'" class="col-lg-6">
              <div>
                <label class="text-capitalize" for="">Download Speed
                  <span v-if="form.downloadspeed">
                    | Bytes per Second:
                    <span class="text-success"> {{ form.downloadspeed }} </span>
                  </span></label>
                <div class="form-group">
                  <input min="1" @input="changeSpeed" v-model="speedcal.downloadspeed" type="number" class="form-control"
                    placeholder="1 or 2" id="" />
                </div>
              </div>
            </div>
            <div v-if="
              form.type == 'PPPOE' ||
              form.type == 'Hotspot' ||
              form.type == 'MikroTik'
            " :class="'col-12'">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="Duration">Duration</label>
                    <select v-model="form.durationmeasure" name="" id="Duration" class="form-control">
                      <option value="">Select Duration</option>
                      <option value="Months">Months</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Days">Days</option>
                      <option value="Hours">Hours</option>
                      <option value="Minutes">Minutes</option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="Period">Period</label>
                    <input v-model="form.period" type="number" class="form-control" placeholder="Enter Period"
                      id="Period" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group d-flex">
                    <label for="Period">Fixed Expiry Day</label>
                    <label :for="'ex_days_b'" class="__switch">
                      <input id="ex_days_b" v-model="form.fixed_expire_time_status" type="checkbox" @input="
                        form.fixed_expire_time_status =
                        !form.fixed_expire_time_status
                      " :checked="form.fixed_expire_time_status" />
                      <span class="__slider" />
                    </label>
                    <div v-if="form.fixed_expire_time_status" class="">
                      <input type="datetime-local" class="form-control" v-model="form.fixed_expire_time"
                        placeholder="Enter Period" id="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <router-link :to="{ name: 'Packages' }" class="btn btn-warning" type="submit" :disabled="form.busy">
            <feather-icon icon="ArrowLeftIcon" size="16" />
            Back
          </router-link>

          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            <feather-icon icon="SaveIcon" size="16" />
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
export default {
  name: "EditPackege",
  data() {
    return {
      title: "Package List",
      form: new Form({
        name: "",
        synonym: "",
        radius: "",
        price: "",
        local_address: "",
        pop_price: "",
        franchise_price: "",
        mikrotik: "",
        ip_pool: "",
        type: "",
        simultaneous: 1,
        quota: null,
        speed_unit: "",
        uploadspeed: "",
        downloadspeed: "",
        durationmeasure: "",
        period: "",
        fixed_expire_time_status: false,
        fixed_expire_time: "",
        is_queue: false,
        queue: "",
      }),

      selected_mikrotik: "",
      selected_queue: "",
      loading: true,
      select_options_mikrotik: [],
      select_options_nas: [],
      select_options_pool: [],
      select_options_queue: [],

      quota: {
        kb: null,
        gb: null,
      },
      speedcal: {
        unit_er: "",
        uploadspeed: "",
        downloadspeed: "",
      },
    };
  },

  computed: {
    mikrotik() {
      return this.$store.state.isp.others.mikrotik;
    },
    nas() {
      return this.$store.state.isp.others.nas;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    selected_queue(val) {
      this.form.queue = val.code;
    },
    mikrotik(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.identity}-${element.host}`,
          };
          this.select_options_mikrotik.push(obj);
        });
      }
    },
    nas(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.nasname}`,
          };
          this.select_options_nas.push(obj);
        });
      }
    },
    selected_mikrotik(value) {
      this.form.mikrotik = value.code;
      this.select_options_pool = [];
      this.select_options_queue = [];
      if (this.form.is_queue == true) {
        const mikrotik = this.mikrotik.find((ele) => ele.id === value.code);
        if (mikrotik.queue.length > 0) {
          mikrotik.queue.forEach((ele) => {
            const obj = {
              code: ele.id,
              label: `${ele.name}`,
            };
            this.select_options_queue.push(obj);
          });
        }
      } else {
        const mikrotik = this.mikrotik.find((ele) => ele.id === value.code);
        if (mikrotik.pool.length > 0) {
          mikrotik.pool.forEach((ele) => {
            const obj = {
              code: ele.id,
              label: `${ele.name}`,
            };
            this.select_options_pool.push(obj);
          });
        }
      }
    },
  },
  created() {
    this.$store.dispatch("others", {
      mikrotik: true,
      nas: true,
    });
    this.getdynamicData();
  },
  methods: {
    getdynamicData() {
      this.axios.get(`package/edit/${this.$route.params.id}`).then((res) => {
        console.log(res.data.data);
        if (res.data.success) {
          this.form.name = res.data.data.name;
          this.form.synonym = res.data.data.synonym;
          this.form.radius = res.data.data.nas_id;
          this.form.price = res.data.data.price;
          this.form.pop_price = res.data.data.pop_price;
          this.form.franchise_price = res.data.data.franchise_price;
          this.form.mikrotik = res.data.data.mikrotik_id;
          this.form.ip_pool = res.data.data.pool_id;
          this.form.bandwidth = res.data.data.bandwidth;
          this.form.type = res.data.data.type;
          this.form.local_address = res.data.data.local_address;
          this.form.simultaneous = res.data.data.numberofdevices
            ? res.data.data.numberofdevices
            : 1;
          this.form.quota = res.data.data.quota;
          this.form.speed_unit = res.data.data.speed_unit;
          this.form.uploadspeed = res.data.data.uploadspeed;
          this.form.downloadspeed = res.data.data.downloadspeed;
          this.form.durationmeasure = res.data.data.durationmeasure
            ? res.data.data.durationmeasure.replace(/[0-9]/g, "")
            : "";
          this.form.period = res.data.data.durationmeasure
            ? res.data.data.durationmeasure.replace(/[^0-9]/g, "")
            : "";
          this.form.fixed_expire_time_status =
            res.data.data.fixed_expire_time_status == 1 ? true : false;
          this.form.fixed_expire_time = res.data.data.fixed_expire_time;
          this.form.is_queue = res.data.data.is_queue ? true : false;
          this.select_options_pool = [];
          this.getIpPool();
          const mikrotik = this.mikrotik.find(
            (ele) => ele.id === res.data.data.mikrotik_id
          );
          if (res.data.data.queue_id) {
            this.mikrotik.forEach((mkt) => {
              if (mkt.queue.length > 0) {
                mkt.queue.forEach((ele) => {
                  const obj = {
                    code: ele.id,
                    label: `${ele.name}`,
                  };
                  this.select_options_queue.push(obj);
                });
              }
            });
          }
          if (mikrotik) {
            if (mikrotik.pool.length > 0) {
              mikrotik.pool.forEach((ele) => {
                const obj = {
                  code: ele.id,
                  label: `${ele.name}`,
                };
                this.select_options_pool.push(obj);
              });
              this.form.ip_pool = res.data.data.pool_id;
            }
          } else {
            this.select_options_pool = [];
            this.mikrotik.forEach((mkt) => {
              if (mkt.pool.length > 0) {
                mkt.pool.forEach((ele) => {
                  const obj = {
                    code: ele.id,
                    label: `${ele.name}`,
                  };
                  this.select_options_pool.push(obj);
                });
              }
            });
          }
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    change_is_queue() {
      if (this.form.type == 'PPPOE') {

      } else {
        if (this.form.mikrotik) {
          this.select_options_queue = [];
          const mikrotik = this.mikrotik.find(
            (ele) => ele.id === this.form.mikrotik
          );
          if (mikrotik.queue.length > 0) {
            mikrotik.queue.forEach((ele) => {
              const obj = {
                code: ele.id,
                label: `${ele.name}`,
              };
              this.select_options_queue.push(obj);
            });
          }
        } else {
          this.form.is_queue = false;
          this.$error_message("Please Select Mikrotik");
        }
      }
    },
    changeSpeed() {
      if (this.form.speed_unit !== "") {
        this.speedcal.unit_er = "";
        if (this.form.speed_unit == "Mbps") {
          this.form.uploadspeed = this.speedcal.uploadspeed * 1024 * 1024;
          this.form.downloadspeed = this.speedcal.downloadspeed * 1024 * 1024;
        } else {
          this.form.uploadspeed = this.speedcal.uploadspeed * 1024;
          this.form.downloadspeed = this.speedcal.downloadspeed * 1024;
        }
      } else {
        this.speedcal.unit_er = "plese Select First";
      }
    },

    getIpPool() {
      axios.get(`pool`).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((ele) => {
            const obj = {
              code: ele.id,
              label: `${ele.name}`,
            };
            this.select_options_pool.push(obj);
          });
        }
      });
    },
    ChangeMaximumUsageQuota() {
      this.quota.kb = this.form.quota * 1024;
      this.quota.gb = this.form.quota / 1024;
    },
    selectType(val) {
      this.select_options_pool = [];
      this.mikrotik.forEach((mkt) => {
        if (mkt.pool.length > 0) {
          mkt.pool.forEach((ele) => {
            const obj = {
              code: ele.id,
              label: `${ele.name}`,
            };
            this.select_options_pool.push(obj);
          });
        }
      });
    },
    //= ========data save=========
    save() {
      if (this.$validation("form") === true) {
        this.form
          .put(`package/${this.$route.params.id}`)
          .then((resp) => {
            if (resp.data.success) {
              this.$router.push({ name: "Packages" });
              this.$success_message(resp.data.message);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    //= ========get data for edit=========
  },
};
</script>

<style></style>
