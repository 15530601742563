<template>
  <div>
    <h2 class="text-center" v-if="permissionError !== ''">
      {{ permissionError }}
    </h2>
    <div v-else>
      <b-row class="match-height">
        <b-col lg="6" sm="12">
          <statistic-card-horizontal icon="UsersIcon" :statistic="summary.total_received"
            statistic-title="Total Received" />
        </b-col>
        <b-col lg="6" sm="12">
          <statistic-card-horizontal icon="UserCheckIcon" color="success" :statistic="summary.today_received"
            statistic-title="Today Received" />
        </b-col>
      </b-row>
      <b-card-code :title="title" no-body>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <!-- sorting  -->
              <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="3"
                label-for="sortBySelect" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" label-cols-sm="4" :disabled="!sortBy">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group label="Filter" label-cols-sm="3" label-align-sm="left" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
          :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
          show-empty>
          <template #cell(CustomerName)="data">
            {{ data.item.user.connection_info.username }}
          </template>
          <template #cell(zone)="data">
            {{ data.item.zone ? data.item.zone.name : "" }}
          </template>
          <template #cell(package)="data">
            {{ data.item.package ? data.item.package.name : "" }}
          </template>
          <template #cell(status)="data">
            <b-badge pill class="text-capitalize" variant="success">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(action)="data">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <!-- <b-dropdown-item @click="makePayment(data.item.id)">
                <feather-icon icon="DollarSignIcon" class="mr-50" />
                <span>Make Payment</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "Received Invoice",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      title: "Received Invoice List",
      modal_title: "Add Invoice",
      form: new Form({
        id: "",
        user_id: "",
        amount: "",
        received_amount: 0,
        paid_by: "",
      }),
      fields: [
        { key: "invoice_no", label: "Invoice No" },
        { key: "id", label: "Cus Id" },
        "CustomerName",
        { key: "zone", label: "Zone", sortable: true },
        { key: "package", label: "Package", sortable: true },
        { key: "expire_date", label: "Expire Date", sortable: true },
        { key: "amount", label: "Amount" },
        { key: "received_amount", label: "Received Amount" },
        { key: "due_amount", label: "Due Amount" },
        { key: "advanced_amount", label: "Advanced Amount" },
        { key: "comment" },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      permissionError: "",
      summary: {
        total_received: 0,
        today_received: 0,
      },
    };
  },
  computed: {
    users() {
      return this.$store.state.isp.users;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },

  created() {
    this.getData(this.perPage, this.currentPage);
    this.getReceivedSummary();
    this.$store.dispatch("getUsers");
  },
  methods: {
    //get package data list
    async getData(perPage, page) {
      this.$store.dispatch("spinnerLoading", true);
      await axios
        .get(`invoice/index?item=${perPage}&page=${page}&menu=Received-Payment`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {

            this.items = resp.data.data.data;
            this.totalRows = resp.data.data.data.length;
            // this.items = resp.data.data.map(function (x) {
            //   return { ...x, paid_by: x.billing_info[0].paid_by };
            // });

          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
        });
    },

    getReceivedSummary() {
      axios
        .get("invoice/received/summary")
        .then((res) => {
          this.summary = {
            total_received: res.data.data.total_received,
            today_received: res.data.data.today_received ?? 0,
          };
        })
        .catch((error) => console.log(error));
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
