import auth from "@/middleware/auth";
import ApiSettings from "../views/Sms/ApiSettings/index.vue";
import EmailApi from "../views/Sms/ApiSettings/EmailApi.vue";
import SmsTemplate from "../views/Sms/SmsTemplate.vue";
import EmailTemplate from "../views/Sms/EmailTemplate.vue";
import SmsReport from "../views/Sms/SmsReport.vue";
import SendSms from "../views/Sms/SendSms.vue";
import AllSentMessage from "../views/Sms/AllSentMessage.vue";
import FetherIcon from "../views/Demo/FetherIcon.vue";

export default [
  {
    path: "/sms/report",
    name: "sms.report",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: SmsReport,
    meta: {
      pageTitle: "Report",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Report",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sms/send-message",
    name: "sms.send_message",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: SendSms,
    meta: {
      pageTitle: "Send Message",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Send Message",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sms/api-settings",
    name: "ApiSettings",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: ApiSettings,
    meta: {
      pageTitle: "Api Settings",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Api Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sms/emailapi-settings",
    name: "EmailApi",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: EmailApi,
    meta: {
      pageTitle: "Email Settings",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Api Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sms/sms-template",
    name: "sms.template",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: SmsTemplate,
    meta: {
      pageTitle: "Sms Template",
      middleware: [auth],
      breadcrumb: [
        {
          text: "SMS Template",
          active: true,
        },
      ],
    },
  },
  {
    path: "/email/template",
    name: "email.template",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: EmailTemplate,
    meta: {
      pageTitle: "Sms Template",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Email Template",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sms/sent-message",
    name: "sms.sent-message",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: AllSentMessage,
    meta: {
      pageTitle: "Sms Template",
      middleware: [auth],
      breadcrumb: [
        {
          text: "sms Template",
          active: true,
        },
      ],
    },
  },
  {
    path: "/FetherIcon",
    name: "FetherIcon",
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: FetherIcon,
    meta: {
      pageTitle: "FetherIcon",
      middleware: [auth],
      breadcrumb: [
        {
          text: "FetherIcon",
          active: true,
        },
      ],
    },
  },
];
