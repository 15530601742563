<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" sm="6" v-if="selectedTmp.length > 0">
        <div class="card">
          <div class="card-body p-1">
            <b-row>
              <b-col md="6">
                <tr v-for="(stemp, index) in selectedTmp" :key="index">
                  <td class="text-capitalize">
                    {{ replaceString(stemp.slug, "_", " ") }}
                  </td>
                  <td>:</td>
                  <td>
                    <span class="badge badge-light-info">
                      {{
                        (($stmpdata = items.data.find(
                          (el) => el.id == stemp.value
                        )),
                          $stmpdata ? $stmpdata.name : "")
                      }}
                    </span>
                  </td>
                </tr>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col lg="2" sm="2">
        <statistic-card-horizontal icon="UserCheckIcon" color="success" :statistic="summary.total"
          statistic-title="Total" />
      </b-col>
      <b-col lg="2" sm="2">
        <statistic-card-horizontal icon="UserIcon" color="danger" :statistic="summary.total_active"
          statistic-title="Active" />
      </b-col>
      <b-col lg="2" sm="2">
        <statistic-card-horizontal icon="UserIcon" color="danger" :statistic="summary.total_inactive"
          statistic-title="In Active" />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button v-if="permission.add" size="sm" col="2" variant="primary" @click="modelAction('add')">
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New
            </b-button>
            <!-- sorting  -->
            <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="3" label-for="sortBySelect"
              class="mb-0 ml-3">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" label-cols-sm="4" :disabled="!sortBy">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>
      <b-modal id="modal-lg" :title="modal_title" size="lg" hide-header-close hide-footer>
        <form @submit.prevent="submitHandelar" class="row">
          <div class="col-sm-12 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input v-model="form.name" type="text" name="" class="form-control" placeholder="Name" id="name" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="API">Select API</label>
                  <select v-model="form.smsapi" id="API" class="form-control">
                    <option value="">----Select API--</option>
                    <option v-for="(api_item, index) in all_sms_apis" :key="index" :value="api_item.id">
                      {{ api_item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="name">Template For</label>
                  <select v-model="form.template_for" id="" class="form-control">
                    <option value="">----Select--</option>
                    <option value="welcome_sms">welcome sms</option>
                    <option value="invoice_create">Invoice create</option>
                    <option value="invoice_payment">Invoice payment</option>
                    <option value="account_expire">account expire</option>
                    <option value="package_change">package change</option>
                    <option value="ticket_accept">Ticket Accept</option>
                    <option value="ticket_pending">Ticket Pending</option>
                    <option value="ticket_success">Ticket Success</option>
                    <option value="assign_ticket_to_support">
                      assign ticket to support
                    </option>
                    <option value="user_info">User Info</option>
                    <option value="update_balance">Update Balance</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="Template">Template</label>
              <textarea v-on:keyup="countdown" v-model="form.template" class="form-control" cols="30" rows="10">
                      </textarea>
              <div class="d-flex">
                <p class="pr-1 text-small">Used : {{ form.template.length }}</p>
                |
                <!-- <p class='px-1 text-small' v-bind:class="{ 'text-danger': hasError }">Left : {{ remainingCount }} </p> -->
                <p class="pl-1 text-small">SMS Count : {{ total_sms }}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 border">
            <h3 class="text-center mb-0 pb-0">Keys</h3>
            <br />
            <span @click.prevent="change_textare('{user_name}')" for="user_name">{user_name}</span>
            <br />
            <span @click.prevent="change_textare('{customer_user_id}')" for="customer_user_id">{customer_user_id}</span>
            <br />
            <span @click.prevent="change_textare('{customer_user_password}')"
              for="customer_user_password">{customer_user_password}</span>
            <br />
            <span @click.prevent="change_textare('{customer_package}')" for="customer_package">{customer_package}</span>
            <br />
            <span @click.prevent="change_textare('{due_amount}')" for="due_amount">{due_amount}</span>
            <br />
            <span @click.prevent="change_textare('{invoice_no}')" for="invoice_no">{invoice_no}</span>
            <br />
            <span @click.prevent="change_textare('{last_payment_date}')"
              for="last_payment_date">{last_payment_date}</span>
            <br />
            <span @click.prevent="change_textare('{expire_date}')" for="expire_date">{expire_date}</span>
            <br />
            <span @click.prevent="change_textare('{monthly_bill}')" for="monthly_bill">{monthly_bill}</span>
            <br />
            <span @click.prevent="change_textare('{company_name}')" for="company_name">{company_name}</span>
            <br />
            <span @click.prevent="change_textare('{company_phone_number}')"
              for="company_phone_number">{company_phone_number}</span>
            <br />
            <span @click.prevent="change_textare('{company_bkash_number}')"
              for="company_bkash_number">{company_bkash_number}</span>
            <br />
            <span @click.prevent="change_textare('{company_roket_number}')"
              for="company_roket_number">{company_roket_number}</span>
            <br />
            <span @click.prevent="change_textare('{company_nagad_number}')"
              for="company_nagad_number">{company_nagad_number}</span>
            <br />
            <span @click.prevent="change_textare('{received_amount}')" for="received_amount">{received_amount}</span>
            <br />
            <span @click.prevent="change_textare('{ticket_no}')" for="ticket_no">{ticket_no}</span>
            <br />
            <span @click.prevent="change_textare('{taka}')" for="taka">{taka}</span>
            <br />
            <span @click.prevent="change_textare('{expire_date}')" for="expire_date">{expire_date}</span>
            <br />
          </div>
        </form>
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="submitHandelar" class="btn btn-primary">Save</button>
      </b-modal>
      <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="items.data" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(sms_api)="data">
          {{ data.item.sms_api ? data.item.sms_api.name : "" }}
        </template>
        <template #cell(Name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(status)="status">
          <b-form-checkbox v-if="permission.status" v-model="status.item.status == '0'" name="check-button" switch
            @change="changeStatus(status.item.id)"></b-form-checkbox>
        </template>

        <template #cell(action)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item v-if="permission.edit">
              <button class="btn btn-sm p-0" @click.prevent="edit(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.delete" @click.prevent="deleteData(data.item.id)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>
        <!-- pagination -->
        <b-pagination-nav @input="getData" :link-gen="linkGen" :number-of-pages="items.last_page" :limit="perPage"
          use-router>
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "SmsTemplate",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        name: "",
        template_for: "",
        smsapi: "",
        template:
          "Dear {user_name}, Your internet due bill= {due_amount}tk. Please pay by Cash/bKash/Rocket. Your Ref ID={customer_user_id}. For query call: 09639-949494 Thanks -{company_name}",
      }),
      title: "SMS Template",
      fields: [
        { key: "id", label: "#", sortable: true },
        "Name",
        { key: "sms_api", label: "Sms Api", sortable: true },
        { key: "template", label: "template", sortable: true },
        "Total SMS Sent",
        "status",
        "action",
      ],
      items: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      maxCount: 160,
      remainingCount: 160,
      total_sms: 0,
      hasError: false,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      modal_title: "SMS Template",
      summary: {
        total_user: 0,
        total_active: 0,
        total_inactive: 0,
      },
      lData: null,
      selectedTmp: [],
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("SMS Template");
      this.permission.add = this.checkpermission("sms_template_add");
      this.permission.edit = this.checkpermission("sms_template_edit");
      this.permission.delete = this.checkpermission("sms_template_delete");
      this.permission.status = this.checkpermission(
        "sms_template_change_status"
      );
    },
    all_sms_apis() {
      return this.$store.getters.all_sms_apis_getter;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    change_textare(val) {
      this.form.template += val + " ";
    },
    countdown() {
      this.remainingCount = this.maxCount - this.form.template.length;
      this.hasError = this.remainingCount < 0;
      this.total_sms =
        this.form.template.length == 0
          ? 0
          : Math.floor(
            this.form.template.length < 161
              ? this.form.template.length / 161 + 1
              : this.form.template.length / 160 + 1
          );
    },
    close() {
      this.form.reset()
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
        this.editData.id = null;
        this.editData.mode = false;
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("sms-template")
        .then((resp) => {
          if (resp.data.success) {
            this.close()
            this.getData();
            this.$s_tost(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeStatus(id) {
      console.log(id);
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`sms-template/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    //= ========get data for edit=========

    update() {
      this.form
        .put(`sms-template/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.close()
            this.$s_tost(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========
    async edit(data) {
      if (data) {
        this.form.name = data.name;
        this.form.template = data.template;
        this.modal_title = "Update";
        this.editData.mode = true;
        this.editData.id = data.id;
        const tmp_for = this.selectedTmp.find((el) => el.value == data.id);
        if (tmp_for) {
          this.form.template_for = tmp_for.slug;
        }
        this.$bvModal.show("modal-lg");
      } else {
        this.$error_message(resp.data.message);
      }
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`sms-template?page=${this.currentPage}&item${this.perPage}`)
        .then((resp) => {
          console.log(resp.data.data.data);
          if (resp.data.success) {
            this.$store.dispatch("spinnerLoading", false);
            this.items = resp.data.data.data;
            this.summary.total = parseInt(resp.data.data.data.total);
            this.summary.total_active = parseInt(resp.data.data.active);
            this.summary.total_inactive = parseInt(resp.data.data.inactive);
            this.selectedTmp = resp.data.data.selectedTmp;
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    linkGen(pageNum) {
      return { path: `/sms-template?pages=${pageNum}` };
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },

    modelAction(val) {
      this.$store.dispatch("all_sms_api_action");
      this.$bvModal.show("modal-lg");
    },
    // Delete data from database
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`sms-template/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
<style>
select option {
  text-transform: capitalize;
}
</style>
