import auth from '@/middleware/auth'
import IpPool from '../views/Ippool/Index.vue'
import PublicIP from '../views/Ippool/PublicIP.vue'
import Package from '../views/Package/Index.vue'
import PopPackage from '../views/Package/PopPackge.vue'
import AddPackege from '../views/Package/AddPackege.vue'
import EditPackege from '../views/Package/EditPackege.vue'
import FranchisePackage from '../views/Package/FranchisePackge.vue'
import Pop from '../views/Pop/Index.vue'
import Franchise from '../views/Franchise/Index.vue'

export default [
  {
    path: '/ippool',
    name: 'ippool',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: IpPool,
    meta: {
      pageTitle: 'IP Pool',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'IP Ppool',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ippool/public-ip',
    name: 'ippool.publicip',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: PublicIP,
    meta: {
      pageTitle: 'Public IP',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Public IP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mikrotik/public-ip',
    name: 'mikrotik.publicip',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: PublicIP,
    meta: {
      pageTitle: 'Public IP',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Public IP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package',
    name: 'Packages',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Package,
    meta: {
      pageTitle: 'Packages',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Packages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-package',
    name: 'addpackage',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: AddPackege,
    meta: {
      pageTitle: 'Add New Package',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Add New Package',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-package/:id',
    name: 'editpackage',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: EditPackege,
    meta: {
      pageTitle: 'Edit Packages',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Edit Packages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/packagePop',
    name: 'PopPackge',
    component: PopPackage,
    meta: {
      pageTitle: 'Pop Package',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Pop Packages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/franchisePackgae',
    name: 'FranchisePackge',
    component: FranchisePackage,
    meta: {
      pageTitle: 'Franchise Package',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Franchise Package',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pop',
    name: 'pop',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Pop,
    meta: {
      pageTitle: 'POP',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'POP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Franchise',
    name: 'Franchise',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Franchise,
    meta: {
      pageTitle: 'Franchise',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Franchise',
          active: true,
        },
      ],
    },
  },
]
