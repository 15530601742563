<template>
	<div>
		<!-- <h5 class="text-center">Fiber {{type}}</h5> -->
		<DynamicForm
			:submitForm="create"
			:form="form"
			:type="type"
			:fields="dynamicFormFields"
		/>
	</div>
</template>


<script>

import Form from "vform";
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "Fiber",
	components: {  DynamicForm },
	props: [
		"type",
	],
	data() {
	  return {
	  	form: new Form({
	        meter_length_from: null,
	        meter_length_to: null,
	        cable_type_id: null,
	        cable_id: null,

	        comment: null,
	        status:1
	  	}),
      	types: [],
		statusOptions: [{
			'title': 'Active',
			'value': 1
		}, {
			'title': 'Inactive',
			'value': 0
		}],
		apiEndPoint: 'nm-fibers'
	  }

	},
	emits: ['submitted'],
	computed: {
	  dynamicFormFields(){
	  	const formFields = [
			{
				'type': 'text',
				'name': 'cable_id',
				'label': 'Cable Id',
			},
			{
				'type': 'select',
				'name': 'cable_type_id',
				'label': 'Type',
				'options': this.types,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				},
			},
			{
				'type': 'number',
				'name': 'meter_length_from',
				'label': 'Meter length from',
			},
			{
				'type': 'number',
				'name': 'meter_length_to',
				'label': 'Meter length to',
			},
			{
				'type': 'textarea',
				'name': 'comment',
				'label': 'Comment',
			},
			{
				'type': 'select',
				'name': 'status',
				'label': 'Status',
				'options': this.statusOptions	
			},
		]

		return formFields
	  }
	},
	created() {
		this.getFiberSettings()
	},
	watch: {

	},
	methods: {
		create() {
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  
				  this.$emit('submitted', item)
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},
		getFiberSettings() {
			axios.get(`nm-fiber-settings?page=1&item=all`).then((resp) => {
				if (resp.data.success) {
					this.types = resp.data.data.data;

				} else {

				}
			}).finally(()=> {

			});
		},

 
	},
}
</script>