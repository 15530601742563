<template>
  <div class="container p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <b-button
          v-b-modal.modal-lg
          variant="primary"
          @click.prevent="editData.mode = false"
          >Add
        </b-button>
      </div>
      <b-modal id="modal-lg" :title="modal_title" size="lg">
        <form @submit.prevent="submitHandelar">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="exp_voucher_no">Expence Voucher No</label>
                <input
                  v-model="form.exp_voucher_no"
                  id="exp_voucher_no"
                  type="text"
                  class="form-control"
                  name="text"
                  placeholder="Invoice No"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <label for="exp_claimant">Exp Claimant</label>
              <input
                v-model="form.exp_claimant"
                type="text"
                placeholder="Exp Claimant"
                id="exp_claimant"
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="amount">Amount</label>
                <input
                  v-model="form.amount"
                  type="number"
                  class="form-control"
                  name="phone"
                  placeholder="Monthly Bill"
                />
              </div>
            </div>
            <div class="col-lg-6"></div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="received_by">Create By</label>
                <select v-model="form.create_by" class="form-control">
                  <option value="">-----Select-------</option>
                  <option
                    v-for="uItem in dashboardusers"
                    :key="uItem.id"
                    :value="uItem.name"
                  >
                    {{ uItem.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="received_by">Category</label>
                <select v-model="form.create_by" class="form-control">
                  <option value="">-----Select-------</option>
                  <option
                    v-for="uItem in dashboardusers"
                    :key="uItem.id"
                    :value="uItem.name"
                  >
                    {{ uItem.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="received_by">description</label>
            <textarea
              placeholder="Write Something"
              v-model="form.description"
              class="form-control"
              id=""
              cols="30"
              rows="5"
            ></textarea>
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">SL/NO</th>
              <th scope="col">Creator Name</th>
              <th scope="col">Expcategory</th>
              <th scope="col">Exp Voucher No</th>
              <th scope="col">Amount</th>
              <th scope="col">Exp Claimant</th>
              <th scope="col">Description</th>
              <th scope="col">Date</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="listData.length <= 0">
              <td colspan="1000" style="text-align: center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-for="(listItem, index) in listData" v-else :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ listItem.create_by }}</td>
              <td>{{ listItem.expcategory }}</td>
              <td>{{ listItem.exp_voucher_no }}</td>
              <td>{{ listItem.amount }}</td>
              <td>{{ listItem.exp_claimant || "" }}</td>
              <td>{{ listItem.description || "" }}</td>
              <td>{{ formatDateTime(listItem.created_at) }}</td>
              <td style="width: 250px" class="d-flex">
                <button
                  class="btn btn-sm btn-success"
                  @click.prevent="edit(listItem)"
                >
                  <feather-icon icon="EditIcon" size="18" />
                </button>
                <button
                  :action="`accounts-info/${listItem.id}`"
                  class="btn btn-sm btn-danger"
                  style="margin: 0px 1px"
                  @click.prevent="$trash($event)"
                >
                  <feather-icon icon="Trash2Icon" size="18" />
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  @click.prevent="assign_role(listItem.id)"
                >
                  <feather-icon icon="EyeIcon" size="18" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  nameL: "DailyAcountClose",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        description: "",
        expcategory: "",
        exp_voucher_no: "",
        exp_claimant: "",
        amount: "",
        create_by: "",
      }),

      listData: [],
      dashboardusers: [],

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      modal_title: "Create Data",
    };
  },
  created() {
    this.getData();
    this.getUser();
  },
  methods: {
    //=========== Get All user ============
    getUser() {
      this.axios.get("dashboardusers").then((res) => {
        if (res.data.success) {
          this.dashboardusers = res.data.data;
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //= ===haxndle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ===get data=========

    getData() {
      this.axios.get("daily-expense").then((res) => {
        if (res.data.success) {
          this.listData = res.data.data;
          console.log(this.listData);
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },

    //= ========data save=========
    save() {
      this.form
        .post("daily-expense")
        .then((res) => {
          if (res.data.success) {
            this.$success_message(res.data.message);
            this.listData.unshift(this.form);
            this.$bvModal.hide("modal-lg");
            this.clearData();
            this.getData(0);
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`accounts-info/${this.editData.id}`)
        .then((res) => {
          if (res.data.success) {
            this.$success_message(res.data.message);
            this.listData.unshift(this.form);
            this.$bvModal.hide("modal-lg");
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========
    edit(data) {
      // const data = this.listData.find((ele) => ele.id === id);
      this.modal_title = "Update";
      this.form.description = data.description;
      this.form.expcategory = data.expcategory;
      this.form.expcategory = data.expcategory;
      this.form.exp_voucher_no = data.exp_voucher_no;
      this.form.exp_claimant = data.exp_claimant;
      this.form.amount = data.amount;
      this.form.create_by = data.create_by;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("modal-lg");
    },
  },
};
</script>
