<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total"
          statistic-title="Total IP Pool"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Active"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_inactive"
          statistic-title="In Active"
        />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              v-if="permission.add"
              size="sm"
              col="2"
              v-b-modal.modal-lg
              variant="primary"
              @click="modelAction('add')"
            >
              <!-- @click.prevent="editData.mode = false" -->
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>
      <b-modal
        id="modal-lg"
        :title="`${modal_title}`"
        size="md"
        hide-header-close
        hide-footer
      >
        <form action="">
          <div class="form-group">
            <label for="">MikroTik <sup class="text-danger">*</sup></label>
            <select
              v-if="mikrotiks"
              v-model="form.mikrotik"
              class="form-control"
            >
              <option value="">---------Please Select One -------</option>
              <option
                v-for="(mikrotik, midx) in mikrotiks"
                :key="midx"
                :value="mikrotik.id"
              >
                {{ mikrotik.identity + "-" + mikrotik.host }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Name"
              id="name"
            />
          </div>
          <div class="form-group">
            <label for="arp">Arp</label>
            <select v-model="form.arp" name="" id="" class="form-control">
              <option value="">---Select---</option>
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
              <option value="local-proxy-arp">local-proxy-arp</option>
              <option value="proxy-arp">Proxy-arp</option>
              <option value="reply-only">Reply-only</option>
            </select>
          </div>

          <div class="form-group">
            <label for="mtu">Mtu</label>
            <input
              v-model="form.mtu"
              min="1"
              type="number"
              class="form-control"
              placeholder="Mtu"
              id="mtu"
            />
          </div>
          <div
            v-if="form.vlan_id !== '' && editData.mode == true"
            class="form-group"
          >
            <label for="vlan_id">Vlan Id</label>
            <input
              v-model="form.vlan_id"
              min="1"
              type="number"
              class="form-control"
              placeholder="Vlan Id"
              id="vlan_id"
            />
          </div>
          <div class="form-group">
            <label for="interface">Interface</label>
            <select v-model="form.interface" class="form-control" id="">
              <option value="">---Select One--</option>
              <option
                v-for="intItem in interfaces"
                :key="intItem.id"
                :value="intItem.name"
              >
                {{ intItem.name }}
              </option>
            </select>
          </div>
          <div
            v-if="form.use_service_tag !== '' && editData.mode == true"
            class="form-group"
          >
            <label for="use-service-tag">Use Service Tag</label>
            <select
              v-model="form.use_service_tag"
              class="form-control"
              id="use-service-tag"
            >
              <option value="">---Select One--</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="form-group">
            <label for="comment">Comment</label>
            <textarea
              class="form-control"
              name=""
              v-model="form.comment"
              id="comment"
              placeholder="Comment"
            ></textarea>
          </div>
        </form>
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="submitHandelar" class="btn btn-primary">Save</button>
      </b-modal>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(mikrotik_id)="data">
          {{ data.item.mikrotik.identity }} ||
          {{ data.item.mikrotik.host }}
        </template>
        <template #cell(status)="data">
          <b-form-checkbox
            v-model="data.item.status == 'false'"
            name="check-button"
            switch
            @change="changeStatus(data.item.id)"
          ></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-if="permission.edit"
              @click.prevent="edit(data.item.id)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              Edit
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.delete"
              @click.prevent="deleteData(data.item.id)"
            >
              <!-- v-for="(pmsList, index) in getRoleWisePermissions"
              :key="index + 'pd'"
              v-show="pmsList.name == 'Delete MikroTik Ip Pool'" -->
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <b-pagination-nav
          @input="getData"
          :link-gen="linkGen"
          :number-of-pages="items.last_page"
          :limit="perPage"
          use-router
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "Interfaces",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        name: "",
        mikrotik: "",
        arp: "",
        mtu: "",
        vlan_id: "",
        interface: "",
        use_service_tag: "no",
        comment: "",
      }),
      title: "Interfaces",
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "mikrotik_id", label: "mikrotik", sortable: true },
        { key: "type", label: "type", sortable: true },
        { key: "mtu", label: "mtu", sortable: true },
        { key: "mac_address", label: "mac address", sortable: true },
        { key: "comment", label: "comment" },
        { key: "status", label: "status" },
        "action",
      ],
      items: [],
      interfaces: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      modal_title: "Add Interface (VLAN)",
      pageOptions: [10, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      summary: {
        total: 0,
        total_active: 0,
        total_inactive: 0,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("interface");
      this.permission.add = this.checkpermission("interface_add");
      this.permission.edit = this.checkpermission("interface_edit");
      this.permission.delete = this.checkpermission("interface_delete");
      this.permission.status = this.checkpermission("interface_change_status");
    },
    mikrotiks() {
      return this.$store.getters.getallmikrotiks;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("interface")
        .then((resp) => {
          if (resp.data.success) {
            // this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`interface/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    async edit(id) {
      axios.get(`interface-find-data/${id}`).then((resp) => {
        if (resp.data.success) {
          resp.data.data;
          this.getImterfaces();
          this.$store.dispatch("getallmikrotiks");
          this.modal_title = "Update Interface";
          this.form.name = resp.data.data.name;
          this.form.mikrotik = resp.data.data.mikrotik_id;
          this.form.arp = resp.data.data.arp;
          this.form.mtu = resp.data.data.actual_mtu;
          this.form.interface = resp.data.data.interface;
          this.form.vlan_id = resp.data.data.vlan_id;
          this.form.use_service_tag = resp.data.data.use_service_tag;
          this.form.comment = resp.data.data.comment;
          this.editData.mode = true;
          this.editData.id = id;
        } else {
          this.$error_message(resp.data.message);
        }
      });
      this.$bvModal.show("modal-lg");
    },

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`interface?page=${this.currentPage}&item${this.perPage}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$store.dispatch("spinnerLoading", false);
            this.items = resp.data.data.data;
            this.summary.total = resp.data.data.data.total;
            this.summary.total_active = resp.data.data.active;
            this.summary.total_inactive = resp.data.data.inactive;
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`interface/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$success_message(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    linkGen(pageNum) {
      return { path: `/interface?pages=${pageNum}&item${this.perPage}` };
    },
    getImterfaces() {
      axios.get("interface-withselect").then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.interfaces = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    modelAction(val = null) {
      if (val == "add") {
        this.editData.mode = false;
      }
      this.getImterfaces();
      this.$store.dispatch("getallmikrotiks");
      this.$bvModal.show("modal-lg");
    },

    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`interface/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
