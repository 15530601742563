import auth from '@/middleware/auth'
import Nttns from '../views/NetworkManager/nttns/index.vue'
import Brands from '../views/NetworkManager/brands/index.vue'
import SplitterSettings from '../views/NetworkManager/splitter-settings/index.vue'
import FiberSettings from '../views/NetworkManager/fiber-settings/index.vue'
import Pops from '../views/NetworkManager/pops/index.vue'
import EnclouserSettings from '../views/NetworkManager/enclouser-settings/index.vue'
import Racks from '../views/NetworkManager/racks/index.vue'
import Routers from '../views/NetworkManager/routers/index.vue'
import Switches from '../views/NetworkManager/switches/index.vue'
import TransmissionEquipments from '../views/NetworkManager/transmission-equipments/index.vue'
import Odfs from '../views/NetworkManager/odfs/index.vue'
import Olts from '../views/NetworkManager/olts/index.vue'
import Splitters from '../views/NetworkManager/splitters/index.vue'
import Enclousers from '../views/NetworkManager/enclousers/index.vue'
import Fibers from '../views/NetworkManager/fibers/index.vue'
import FiberTubes from '../views/NetworkManager/fiber-tubes/index.vue'
import FiberCores from '../views/NetworkManager/fiber-cores/index.vue'
import RouterPorts from '../views/NetworkManager/router-ports/index.vue'
import SwitchPorts from '../views/NetworkManager/switch-ports/index.vue'
import OdfPorts from '../views/NetworkManager/odf-ports/index.vue'
import OltPorts from '../views/NetworkManager/olt-ports/index.vue'
import TransmissionEquipmentPorts from '../views/NetworkManager/transmission-equipment-ports/index.vue'
import PivotEnclouserFiberCore from '../views/NetworkManager/pivot-enclouser-fiber-core/index.vue'
import PivotFiberCoreSplitter from '../views/NetworkManager/pivot-fiber-core-splitter/index.vue'
import Map from '../views/NetworkManager/map/index.vue'

export default [
  {
    path: '/network-manager/nttns',
    name: 'nm_nttns',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Nttns,
    meta: {
      pageTitle: 'View Nttns',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Nttns',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/brands',
    name: 'nm_brands',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Brands,
    meta: {
      pageTitle: 'View Brands',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Brands',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/splitter-settings',
    name: 'nm_splitter_settings',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: SplitterSettings,
    meta: {
      pageTitle: 'View Splitter Settings',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Splitter Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/fiber-settings',
    name: 'nm_fiber_settings',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: FiberSettings,
    meta: {
      pageTitle: 'View Fiber Settings',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Fiber Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/pops',
    name: 'nm_pops',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Pops,
    meta: {
      pageTitle: 'View Pops',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Pops',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/enclouser-settings',
    name: 'nm_enclouser_settings',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: EnclouserSettings,
    meta: {
      pageTitle: 'View Enclouser Settings',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Enclouser Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/racks',
    name: 'nm_racks',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Racks,
    meta: {
      pageTitle: 'View Racks',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Racks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/routers',
    name: 'nm_routers',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Routers,
    meta: {
      pageTitle: 'View Routers',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Routers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/switches',
    name: 'nm_switches',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Switches,
    meta: {
      pageTitle: 'View Switches',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Switches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/transmission-equipments',
    name: 'nm_transmission_equipments',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: TransmissionEquipments,
    meta: {
      pageTitle: 'View Transmission Equipments',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Transmission Equipments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/odfs',
    name: 'nm_odfs',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Odfs,
    meta: {
      pageTitle: 'View Odfs',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Odfs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/olts',
    name: 'nm_olts',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Olts,
    meta: {
      pageTitle: 'View Olts',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Olts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/splitters',
    name: 'nm_splitters',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Splitters,
    meta: {
      pageTitle: 'View Splitters',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Splitters',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/enclousers',
    name: 'nm_enclousers',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Enclousers,
    meta: {
      pageTitle: 'View Enclousers',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Enclousers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/fibers',
    name: 'nm_fibers',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Fibers,
    meta: {
      pageTitle: 'View Fibers',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Fibers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/fiber-tubes',
    name: 'nm_fiber_tubes',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: FiberTubes,
    meta: {
      pageTitle: 'View Fiber Tubes',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Fiber Tubes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/fiber-cores',
    name: 'nm_fiber_cores',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: FiberCores,
    meta: {
      pageTitle: 'View Fiber Cores',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Fiber Cores',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/router-ports',
    name: 'nm_router_ports',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: RouterPorts,
    meta: {
      pageTitle: 'View Router Ports',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Router Ports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/switch-ports',
    name: 'nm_switch_ports',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: SwitchPorts,
    meta: {
      pageTitle: 'View Switch Ports',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Switch Ports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/odf-ports',
    name: 'nm_odf_ports',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: OdfPorts,
    meta: {
      pageTitle: 'View ODF Ports',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View ODF Ports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/olt-ports',
    name: 'nm_olt_ports',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: OltPorts,
    meta: {
      pageTitle: 'View OLT Ports',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View OLT Ports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/transmission-equipment-ports',
    name: 'nm_transmission_equipment_ports',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: TransmissionEquipmentPorts,
    meta: {
      pageTitle: 'View Transmission Equipment Ports',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Transmission Equipment Ports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/pivot-enclouser-fiber-core',
    name: 'nm_pivot_enclouser_fiber_core',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: PivotEnclouserFiberCore,
    meta: {
      pageTitle: 'View Pivot Enclouser Fiber Core',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Pivot Enclouser Fiber Core',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/pivot-fiber-core-splitter',
    name: 'nm_pivot_fiber_core_splitter',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: PivotFiberCoreSplitter,
    meta: {
      pageTitle: 'View Pivot Fiber Core Splitter',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Pivot Fiber Core Splitter',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-manager/map',
    name: 'nm_map',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Map,
    meta: {
      pageTitle: 'View Map',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Map',
          active: true,
        },
      ],
    },
  },
]