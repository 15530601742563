import auth from '@/middleware/auth'
import Nas from '../views/Nas/Index.vue'
import NasAdd from '../views/Nas/Add.vue'
import NasEdit from '../views/Nas/Edit.vue'
import Maintanence from '../views/Maintenance/index.vue'

export default [
  {
    path: '/ViewNas',
    name: 'ViewNas',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Nas,
    meta: {
      pageTitle: 'View Nas',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Nas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/addNas',
    name: 'addNas',
    component: NasAdd,
    meta: {
      pageTitle: 'Add Nas',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Add Nas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit/nas/:id',
    name: 'editNas',
    component: NasEdit,
    meta: {
      pageTitle: 'Eidt Nas',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Edit Nas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/maintanence',
    name: 'maintanence',
    component: Maintanence,
    meta: {
      pageTitle: 'Maintanence',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Maintanence',
          active: true,
        },
      ],
    },
  },
]
