<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total"
          statistic-title="Total IP Pool"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Active"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_inactive"
          statistic-title="In Active"
        />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              v-if="permission.add"
              size="sm"
              col="2"
              v-b-modal.modal-lg
              variant="primary"
              @click="modelAction('add')"
            >
              <!-- @click.prevent="editData.mode = false" -->
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>
      <b-modal
        id="modal-lg"
        :title="`${modal_title} IP / Arp`"
        size="md"
        hide-header-close
        hide-footer
      >
        <form action="">
          <div class="form-group">
            <label for="">MikroTik <sup class="text-danger">*</sup></label>
            <select
              v-if="mikrotiks"
              v-model="form.mikrotik"
              class="form-control"
            >
              <option value="">---------Please Select One -------</option>
              <option
                v-for="(mikrotik, midx) in mikrotiks"
                :key="midx"
                :value="mikrotik.id"
              >
                {{ mikrotik.identity + "-" + mikrotik.host }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Name"
              id="name"
            />
          </div>
          <div class="form-group">
            <label for="dst">DHCP</label>
            <select v-model="form.dhcp" name="" id="" class="form-control">
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </div>
          <div class="form-group">
            <label for="interface">Interface</label>
            <select v-model="form.interface" class="form-control" id="">
              <option value="">---Select One--</option>
              <option
                v-for="intItem in interfaces"
                :key="intItem.id"
                :value="intItem.name"
              >
                {{ intItem.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="address">IP Address</label>
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              placeholder="Ip Address"
              id="address"
            />
          </div>

          <div class="form-group">
            <label for="mac_address">Mac Address</label>
            <input
              v-model="form.mac_address"
              type="text"
              class="form-control"
              placeholder="Mac Address"
              id="mac_address"
            />
          </div>

          <div class="form-group">
            <label for="comment">Comment</label>
            <input
              v-model="form.comment"
              type="text"
              class="form-control"
              placeholder="Comment"
              id="comment"
            />
          </div>
        </form>
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="save" class="btn btn-primary">Save</button>
      </b-modal>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(mikrotik_id)="data">
          {{ data.item.mikrotik.identity }} ||
          {{ data.item.mikrotik.host }}
        </template>
        <template #cell(status)="data">
          <b-form-checkbox
            v-if="permission.status"
            v-model="data.item.status == 'false'"
            name="check-button"
            switch
            @change="changeStatus(data.item.id)"
          ></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-if="permission.delete"
              v-b-modal="'radius_' + data.item.id"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalNoOfRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      {{ getRoleWisePermissions }}
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "Arp",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        name: "",
        mikrotik: "",
        dhcp: "false",
        interface: "",
        address: "",
        mac_address: "",
        comment: "",
      }),
      title: "Ip Arp",
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "address", label: "ip address", sortable: true },
        { key: "mikrotik_id", label: "mikrotik", sortable: true },
        { key: "mac_address", label: "mac address", sortable: true },
        { key: "interface", label: "interface" },
        { key: "status", label: "status" },
        "action",
      ],
      items: [],
      queue_types: [],
      interfaces: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      modal_title: "Add",
      pageOptions: [3, 5, 10],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      summary: {
        total: 0,
        total_active: 0,
        total_inactive: 0,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("arp");
      this.permission.add = this.checkpermission("arp_add");
      this.permission.edit = this.checkpermission("interface_edit");
      this.permission.delete = this.checkpermission("arp_delete");
      this.permission.status = this.checkpermission("arp_change_status");
    },
    mikrotiks() {
      return this.$store.getters.getallmikrotiks;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("iparp")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`iparp?page=${page}`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data.data.data;
          this.summary.total = resp.data.data.data.total;
          this.summary.total_active = resp.data.data.active;
          this.summary.total_inactive = resp.data.data.inactive;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`iparp/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$success_message(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getImterfaces() {
      axios.get("interface-withselect").then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.interfaces = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },

    modelAction(val = null) {
      this.getImterfaces();
      this.$store.dispatch("getallmikrotiks");
      this.$bvModal.show("modal-lg");
    },
  },
};
</script>
