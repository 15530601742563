<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <b-button
          v-if="permission.add"
          size="sm"
          col="2"
          v-b-modal.modal-lg
          variant="primary"
          @click.prevent="send_message('sms_api')"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />
          Add New SMS API
        </b-button>
        <b-button
          v-if="permission.send_sms"
          size="sm"
          col="2"
          variant="info"
          @click.prevent="send_message('send_sms')"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />
          Send SMS
        </b-button>
      </div>
      <div v-for="item in items" :key="item.id" class="col-sm-12 col-md-6 mt-2">
        <div
          :class="`card card-body ${
            default_getway !== null && default_getway.value == item.id
              ? 'border-success'
              : ''
          }`"
        >
          <div class="d-flex flex-wrap mb-2">
            <b-button
              v-if="permission.edit"
              @click.prevent="edit(item)"
              variant="primary"
              size="sm"
            >
              Edit
            </b-button>
            <b-button
              v-if="permission.delete"
              @click.prevent="deleteData(item.id)"
              size="sm"
              variant="outline-danger"
              class="ml-1"
            >
              Delete
            </b-button>
            <!-- <b-button
              title="Check Api"
              size="sm"
              variant="outline-success"
              class="ml-1"
            >
              Check
            </b-button> -->
            <div v-if="permission.sms_api_make_as_primary">
              <b-button
                v-if="
                  default_getway !== null && default_getway.value == item.id
                "
                @click.prevent="make_primary(item.id)"
                title="Make As Primary Api"
                size="sm"
                variant="success"
                class="ml-1"
              >
                Primary Gateway
              </b-button>
              <b-button
                v-else
                @click.prevent="make_primary(item.id)"
                title="Make As Primary Api"
                size="sm"
                variant="outline-warning"
                class="ml-1"
              >
                Make As Primary
              </b-button>
            </div>
          </div>
          <div class="d-flex">
            <div class="border w-50 m-auto p-1">
              <h3 class="text-center font-weight-bold">{{ item.name }}</h3>
            </div>
            <div class="border w-50 p-1 ml-1">
              <div class="d-flex align-items-center">
                <div
                  v-if="permission.sms_api_check_blance"
                  @click.prevent="checkBlance(item.id)"
                  title="Click here to show the total SMS Balance"
                  class="btn btn-sm btn-primary"
                >
                  <feather-icon icon="RefreshCcwIcon" size="16" />
                </div>
                <div v-if="balance.length > 0" class="ml-2">
                  {{
                    ((bdata = balance.find((el) => el.id == item.id)),
                    bdata && bdata.balance ? bdata.balance : "")
                  }}
                </div>
              </div>
            </div>
          </div>
          <table class="mt-2">
            <tr class="mt-2">
              <th>Api Url</th>
              <td>:</td>
              <td>{{ item.api_url }}</td>
            </tr>
            <tr class="mt-2">
              <th>API Key</th>
              <td>:</td>
              <td>{{ item.api_key }}</td>
            </tr>
            <tr class="mt-2">
              <th>{{ item.name == "Brillent" ? "Client Id" : "Caller ID" }}</th>
              <td>:</td>
              <td>{{ item.client_id }}</td>
            </tr>
            <tr class="mt-2">
              <th>
                {{ item.name == "Brillent" ? "Sender Id" : "Secret key" }}
              </th>
              <td>:</td>
              <td>{{ item.sender_id }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-lg"
      :title="modal_title"
      size="lg"
      hide-header-close
      hide-footer
    >
      <form @submit.prevent="submitHandelar" action="" class="card-body">
        <div class="" v-if="modal_for == 'default'">
          <div class="form-group">
            <label for="name">Name</label>
            <select v-model="form.name" class="form-control" id="">
              <option value="">--- Select Getway ---</option>
              <option value="Brillent">Brillent</option>
              <option value="Reve System">REVE Systems</option>
            </select>
          </div>
          <div class="form-group">
            <label for="api_url">Api Url</label>
            <input
              v-model="form.api_url"
              type="url"
              class="form-control"
              placeholder="Enter Api Url"
              id="api_url"
            />
          </div>
          <div class="form-group">
            <label for="api_key">Api Key</label>
            <input
              v-model="form.api_key"
              type="text"
              api_key=""
              class="form-control"
              placeholder="Enter Api Key"
              id="api_key"
            />
          </div>
          <div class="form-group">
            <label for="SenderId">{{
              form.name == "Brillent" ? "Sender Id" : "Secret key"
            }}</label>
            <input
              v-model="form.sender_id"
              type="text"
              SenderId=""
              class="form-control"
              :placeholder="`Enter ${
                form.name == 'Brillent' ? 'Sender Id' : 'Secret key'
              }`"
              id="SenderId"
            />
          </div>
          <div class="form-group">
            <label for="ClientId">
              {{ form.name == "Brillent" ? "Client Id" : "Caller ID" }}</label
            >
            <input
              v-model="form.client_id"
              type="text"
              class="form-control"
              :placeholder="`Enter ${
                form.name == 'Brillent' ? 'Client Id' : 'Caller ID'
              }`"
              id="ClientId"
            />
          </div>
        </div>
        <div v-else class="">
          <div class="form-group">
            <label for="ClientId"> Phone Number </label>
            <input
              v-model="t_sms.number"
              type="text"
              class="form-control"
              placeholder="Enter Phone Number"
              id="ClientId"
            />
          </div>
          <div class="form-group">
            <label for="ClientId">Message </label>
            <textarea
              class="form-control"
              v-model="t_sms.message"
              id=""
              cols="30"
              rows="5"
              placeholder="Write Message"
            ></textarea>
          </div>
        </div>
      </form>
      <button @click="close" class="btn btn-warning">Close</button>
      <button @click="submitHandelar" class="btn btn-primary">Save</button>
    </b-modal>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "ApiSettings",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        api_url: "",
        name: "",
        api_key: "",
        sender_id: "",
        client_id: "",
        desc: "",
      }),
      t_sms: new Form({
        number: "",
        message: "",
      }),
      modal_for: "default", //or t_sms
      editData: {
        mode: true,
        id: null,
      },
      modal_title: "New Api",
      items: null,
      default_getway: {},
      balance: [],
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
        sms_api_check_blance: false,
        sms_api_make_as_primary: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("sms_api");
      this.permission.add = this.checkpermission("sms_api_add");
      this.permission.edit = this.checkpermission("sms_api_edit");
      this.permission.delete = this.checkpermission("sms_api_delete");
      this.permission.status = this.checkpermission("sms_api_change_status");
      this.permission.sms_api_check_blance = this.checkpermission(
        "sms_api_check_blance"
      );
      this.permission.sms_api_make_as_primary = this.checkpermission(
        "sms_api_make_as_primary"
      );
    },
  },
  created() {
    this.getData();
  },
  methods: {
    checkBlance(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`check-blance/${id}`).then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          if (this.balance.length > 0) {
            const data = this.balance.find((el) => el.id == id);
            if (!data) {
              this.balance.push({
                id: id,
                balance: resp.data.data,
              });
            }
          } else {
            this.balance.push({
              id: id,
              balance: resp.data.data,
            });
          }
          console.log(this.balance);
          this.$store.dispatch("spinnerLoading", false);
          this.$s_tost("Success");
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    close() {
      this.editData.mode = false;
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode && this.modal_for == "default") {
        this.save();
      } else if (this.modal_for == "t_sms") {
        this.send_t_sms();
      } else {
        this.update();
      }
    },
    //= ========data save=========
    save() {
      this.form
        .post("sms-api")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========
    update() {
      this.form
        .put(`sms-api/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    async edit(data) {
      this.form.name = data.name;
      this.form.api_url = data.api_url;
      this.form.api_key = data.api_key;
      this.form.sender_id = data.sender_id;
      this.form.client_id = data.client_id;
      this.modal_title = "Update Api";
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("modal-lg");
    },

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`sms-api`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data.data;
          this.default_getway = resp.data.data.primary_getway;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    async make_primary(id) {
      // mk-primary-sms-getway
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to change it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.get(`mk-primary-sms-getway/${id}`).then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.$store.dispatch("spinnerLoading", false);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },

    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`sms-api/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    send_message(val) {
      if (val == "send_sms") {
        this.modal_title = "Send Message";
        this.modal_for = "t_sms";
        this.$bvModal.show("modal-lg");
      } else {
        this.modal_title = "Add New API";
        this.modal_for = "default";
        this.$bvModal.show("modal-lg");
      }
    },
    send_t_sms() {
      this.t_sms
        .post("sms-test")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
            this.$s_tost(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
