/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="shadow p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <b-button
          v-b-modal.modal-lg
          variant="primary"
          @click.prevent="editData.mode = false"
          size="sm"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />

          Add New</b-button
        >
      </div>
      <b-modal id="modal-lg" :title="modal_title" size="xl" hide-footer>
        <form id="form" @submit.prevent="submitHandelar">
          <div class="d-flex">
            <div class="form-group __width_50">
              <label for="name">Name</label>
              <input
                type="text"
                v-model="form.name"
                name="name"
                placeholder="Name"
                id="name"
                class="form-control"
              />
            </div>
            <div class="form-group __width_50">
              <label for="">Location</label>
              <input
                v-model="form.location"
                type="text"
                class="form-control"
                name="location"
                placeholder="Location"
                validate
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="form-group __width_50 d-flex">
              <div class="form-group __width_50">
                <label for="">Zone</label>
                <select
                  v-if="zone && zone.length > 0"
                  v-model="form.zone"
                  class="form-control"
                  name="zone"
                  validate
                >
                  <option value="" selected disabled>
                    ----Please Select One -------
                  </option>
                  <option v-for="(zn, zidx) in zone" :key="zidx" :value="zn.id">
                    {{ zn.name }}
                  </option>
                </select>
              </div>
              <div class="form-group __width_50">
                <label for="">Type</label>
                <select
                  v-model="form.type"
                  class="form-control"
                  name="type"
                  validate
                >
                  <option value="" selected disabled>
                    --------Please Select One-------
                  </option>
                  <option value="EPON">EPON</option>
                  <option value="GPON">GPON</option>
                  <option value="XGPON">XGPON</option>
                </select>
              </div>
            </div>
            <div class="form-group __width_50">
              <label for="">No of PON Port</label>
              <input
                v-model="form.non_of_pon_port"
                type="text"
                class="form-control"
                name="No of PON Port"
                placeholder="No of PON Port"
                validate
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="form-group __width_50">
              <label for="">No Of Uplink Port</label>
              <input
                v-model="form.no_of_uplink_port"
                type="text"
                class="form-control"
                name="No Of Uplink Port"
                placeholder="No Of Uplink Port"
                validate
              />
            </div>
            <div class="form-group __width_50">
              <label for="">Uplink Port Type</label>
              <input
                v-model="form.uplink_port_type"
                type="text"
                class="form-control"
                name="Abbreviatoin"
                placeholder="Uplink Port Type"
                validate
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="form-group __width_50">
              <label for="">Management IP</label>
              <input
                v-model="form.management_ip"
                type="text"
                class="form-control"
                name="Management IP"
                placeholder="Management IP"
                validate
              />
            </div>
            <div class="form-group __width_50">
              <label for="">Total ONU</label>
              <input
                v-model="form.total_onu"
                type="text"
                class="form-control"
                name="total_onu"
                placeholder="Total ONU"
                validate
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="form-group __width_50">
              <label for="">Management Vlan ID</label>
              <input
                v-model="form.management_vlan_id"
                type="text"
                class="form-control"
                name="Management Vlan ID"
                placeholder="Management Vlan ID"
                validate
              />
            </div>
            <div class="form-group __width_50">
              <label for="">Management Vlan IP</label>
              <input
                v-model="form.management_vlan_ip"
                type="text"
                class="form-control"
                name="anagement Vlan IP"
                placeholder="Management Vlan IP"
                validate
              />
            </div>
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Zone</th>
              <th scope="col">Location</th>
              <th scope="col">Type</th>
              <th scope="col">Non Of Pon Port</th>
              <th scope="col">Non Of Uplink Port</th>
              <th scope="col">Uplink Port Type</th>
              <th scope="col">Management Ip</th>
              <th scope="col">Management Vlan Ip</th>
              <th scope="col">Management Vlan ID</th>
              <th scope="col">Total ONU</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.zone ? item.zone.name : "" }}</td>
              <td>{{ item.location || "" }}</td>
              <td>{{ item.type || "" }}</td>
              <td>{{ item.non_of_pon_port || "" }}</td>
              <td>{{ item.no_of_uplink_port || "" }}</td>
              <td>{{ item.uplink_port_type || "" }}</td>
              <td>{{ item.management_ip || "" }}</td>
              <td>{{ item.management_vlan_ip || "" }}</td>
              <td>{{ item.management_vlan_id || "" }}</td>
              <td>{{ item.total_onu || "" }}</td>
              <td>
                <button
                  class="btn btn-sm btn-success"
                  @click.prevent="edit(item.id)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  :action="'olt/' + item.id"
                  @click.prevent="$trash($event)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :data="items"
          align="right"
          @pagination-change-page="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  name: "OLT",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        name: "",
        zone: "",
        location: "",
        type: "",
        non_of_pon_port: "",
        no_of_uplink_port: "",
        uplink_port_type: "",
        management_ip: "",
        management_vlan_ip: "",
        management_vlan_id: "",
        total_onu: "",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      modal_title: "Create Data",
      items: {},
    };
  },
  computed: {
    zone() {
      return this.$store.state.isp.others.zone;
    },
  },

  created() {
    this.getData();
    this.$store.dispatch("others", {
      zone: true,
    });
  },
  methods: {
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      if (this.$validation("form") === true) {
        this.form
          .post("olt")
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    //= ========get data for edit=========

    update() {
      if (this.$validation("form") === true) {
        this.form
          .put(`olt/${this.editData.id}`)
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    //= ========get data for edit=========

    edit(id) {
      const item = this.items.data.find((ele) => ele.id === id);
      this.modal_title = "Update OLT";

      this.form.zone = item.zone_id;
      this.form.location = item.location;
      this.form.type = item.type;
      this.form.non_of_pon_port = item.non_of_pon_port;
      this.form.no_of_uplink_port = item.no_of_uplink_port;
      this.form.uplink_port_type = item.uplink_port_type;
      this.form.management_ip = item.management_ip;
      this.form.management_vlan_ip = item.management_vlan_ip;
      this.form.management_vlan_id = item.management_vlan_id;
      this.form.total_onu = item.total_onu;
      this.editData.mode = true;
      this.editData.id = item.id;
      this.$bvModal.show("modal-lg");
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios
        .get(`olt?page=?${page}`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          console.log(resp);
          if (resp.data.success) {
            this.items = resp.data.data;
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
