<template lang="">
  <div>
    <b-modal id="modal-lg" :title="modal_title" size="lg">
      <form @submit.prevent="submitHandelar">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Type</label>
              <v-select
                v-model="form.type"
                :options="['pop', 'franchise', 'agent', 'app_manager']"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Name</label>
              <input
                v-model="form.name"
                type="text"
                class="form-control"
                name="name"
                placeholder="name"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Email</label>
              <input
                v-model="form.email"
                type="text"
                class="form-control"
                name="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Phone</label>
              <input
                v-model="form.phone"
                type="text"
                class="form-control"
                name="phone"
                placeholder="Phone"
              />
            </div>
          </div>
          <div v-if="!edit_data.mode" class="col-lg-6">
            <div class="form-group">
              <label for="">Password</label>
              <input
                v-model="form.password"
                type="password"
                class="form-control"
                name="password"
                placeholder="Password"
              />
            </div>
          </div>
          <div v-if="!edit_data.mode" class="col-lg-6">
            <div class="form-group">
              <label for="">Confirm Password</label>
              <input
                v-model="form.confirmed_password"
                type="password"
                class="form-control"
                name="password"
                placeholder="Confirm Password"
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Zone</label>
              <v-select
                v-model="form.selected_zone"
                :options="select_options_zone"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="address">Address</label>
              <input
                class="form-control"
                type="text"
                v-model="form.address"
                placeholder="Address"
              />
            </div>
          </div>
          <div class="col-lg-6" v-if="form.type !== 'agent'">
            <div class="form-group">
              <label
                v-b-tooltip.top.v-primary
                title="Give Permission To Allow grace for every Other Customer"
                >Allow Grace In days {{ form.default_grace }}</label
              >
              <input
                min="1"
                :max="form.default_grace"
                id="allow_gress"
                v-model="form.allow_grace_mark"
                type="number"
                class="form-control"
                placeholder="Ex:3"
              />
            </div>
          </div>
          <div class="col-lg-6" v-if="form.type == 'agent'">
            <div class="form-group">
              <label class="d-flex" for="commission_rate"
                >Commission Rate
                <small class="text-info"
                  >({{
                    form.commision_type == true ? "%" : "Flat Rate"
                  }})</small
                >
                <b-form-checkbox
                  v-model="form.commision_type"
                  name="check-button"
                  switch
                ></b-form-checkbox
              ></label>
              <input
                min="1"
                id="commission_rate"
                v-model="form.commission_rate"
                type="number"
                class="form-control"
                placeholder="Commission Rate"
              />
            </div>
          </div>
          <div class="col-lg-6" v-if="form.type == 'agent'">
            <div class="form-group">
              <label for="billing_date">Billing Date</label>
              <input
                min="1"
                id="billing_date"
                v-model="form.billing_date"
                type="datetime-local"
                class="form-control"
                placeholder="Commission Rate"
              />
            </div>
          </div>
        </div>
      </form>
      <template #modal-footer>
        <button @click.prevent="closeModal()" class="btn btn-warning">
          Close
        </button>
        <button @click="submitHandelar" class="btn btn-primary" type="submit">
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";
import axios from "axios";
import router from "@/router";

export default {
  props: ["editedata"],
  nameL: "UserAddForm",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        name: "",
        phone: "",
        allow_grace_mark: null,
        default_grace: 3,
        password: "",
        confirmed_password: "",
        email: "",
        address: "",
        type: "app_manager",
        commission_rate: null,
        billing_date: null,
        commision_type: true,
        selected_zone: {
          code: "",
          label: "------------- please select one ----------",
        },
      }),

      //= ====for data edit
      edit_data: {
        mode: false,
        id: null,
      },
      modal_title: "Create Data",
      select_options_zone: [],
    };
  },
  computed: {
    roles() {
      return this.$store.state.isp.roles;
    },
    zone() {
      return this.$store.state.isp.zone;
    },
  },
  mounted() {
    console.log(this.editedata);
    if (this.editedata !== null) {
      this.edit_data.mode = true;
      this.edit_data.id = this.editedata.id;
      this.modal_title = "Update " + this.editedata.name;
      this.form.name = this.editedata.name;
      this.form.phone = this.editedata.phone;
      this.form.email = this.editedata.email;
      this.form.type = this.editedata.type;
      this.form.commission_rate = this.editedata.agent_details.commission_rate;
      this.form.billing_date = this.editedata.agent_details.billing_date;
      this.form.commision_type =
        this.editedata.agent_details.commision_type == 0 ? false : true;
      this.form.address = this.editedata.agent_details.address;
      console.log(this.editedata);
    }
  },

  watch: {
    zone(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: element.name,
          };
          this.select_options_zone.push(obj);
        });
      }
    },
  },
  created() {
    this.$store.dispatch("getRoles");
    this.$store.dispatch("zone");
  },
  methods: {
    async OpenModal(val) {
      this.getGrace();
      if (val == "add") {
        this.$bvModal.show("modal-lg");
      }
    },

    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.edit_data.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("user")
        .then((resp) => {
          if (resp.data.success) {
            if (this.form.type == "app_manager") {
              router.push({ name: "users" });
            } else if (this.form.type == "agent") {
              router.push({ name: "agents" });
            }
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.users.unshift(this.form);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`user/${this.edit_data.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$emit("my-event"), this.$s_tost(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    closeModal() {
      this.form.name = "";
      this.form.phone = "";
      this.form.allow_grace_mark = "";
      this.form.default_grace = "";
      this.form.password = "";
      this.form.confirmed_password = "";
      this.form.email = "";
      this.form.address = "";
      this.form.type = "";
      this.form.commission_rate = "";
      this.form.billing_date = "";
      this.form.commision_type = "";
      this.form.selected_zone = "";
      this.selected_zone = {
        code: "",
        label: "----- please select one ---",
      };
      this.$bvModal.hide("modal-lg");
    },
  },
};
</script>
