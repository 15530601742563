<template>
  <div>
    <b-card-code no-body>
      <b-modal
        id="simple_queue_modal"
        :title="`${modal_title} Simple Queue`"
        size="md"
        hide-header-close
        hide-footer
      >
        <form action="">
          <div class="form-group">
            <label for="">MikroTik <sup class="text-danger">*</sup></label>
            <select
              v-if="mikrotiks"
              v-model="form.mikrotik"
              class="form-control"
            >
              <option value="">---------Please Select One -------</option>
              <option
                v-for="(mikrotik, midx) in mikrotiks"
                :key="midx"
                :value="mikrotik.id"
              >
                {{ mikrotik.identity + "-" + mikrotik.host }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              v-model="form.queue_name"
              type="text"
              class="form-control"
              placeholder="Name"
              id="name"
            />
          </div>
          <div class="form-group">
            <h5 for="">Queue Type</h5>
            <div class="row">
              <div class="col-6">
                <label for="queue_type">Upload</label>
                <select
                  v-model="form.queue_type_upload"
                  name=""
                  id="queue_type"
                  class="form-control"
                >
                  <option>---Select---</option>
                  <option
                    v-for="queue_item in queue_types"
                    :key="queue_item.id"
                    :value="queue_item.name"
                  >
                    {{ queue_item.name }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <label for="queue_type">Download</label>
                <select
                  v-model="form.queue_type_download"
                  name=""
                  id="queue_type"
                  class="form-control"
                >
                  <option>---Select---</option>
                  <option
                    v-for="queue_item in queue_types"
                    :key="queue_item.name"
                    :value="queue_item.name"
                  >
                    {{ queue_item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <label for="target">Target</label>
                <input
                  v-model="form.target"
                  type="text"
                  class="form-control"
                  placeholder="Target"
                  id="target"
                />
              </div>
              <div class="col-6">
                <label for="dst">Destination (Interface)</label>
                <select v-model="form.destination" class="form-control" id="">
                  <option value="">---Select One--</option>
                  <option
                    v-for="intItem in interfaces"
                    :key="intItem.id"
                    :value="intItem.name"
                  >
                    {{ intItem.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="max-limit">Max Limit</label>
            <div class="row">
              <div class="col-6">
                <input
                  v-model="form.max_limit_upload"
                  type="text"
                  class="form-control"
                  placeholder="Upload"
                  id="burst-limit"
                />
              </div>
              <div class="col-6">
                <input
                  v-model="form.max_limit_download"
                  type="text"
                  class="form-control"
                  placeholder="Download"
                  id="burst-limit"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="burst-limit">Burst Limit</label>
            <div class="row">
              <div class="col-6">
                <input
                  v-model="form.burst_limit_upload"
                  type="text"
                  class="form-control"
                  placeholder="Upload"
                />
              </div>
              <div class="col-6">
                <input
                  v-model="form.burst_limit_download"
                  type="text"
                  class="form-control"
                  placeholder="Download"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="burst-Threshol">Burst Threshold</label>
            <div class="row">
              <div class="col-6">
                <input
                  v-model="form.burst_threshold_upload"
                  type="text"
                  class="form-control"
                  placeholder="Upload"
                />
              </div>
              <div class="col-6">
                <input
                  v-model="form.burst_threshold_download"
                  type="text"
                  class="form-control"
                  placeholder="Download"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="burst-time">Burst Time</label>
            <div class="row">
              <div class="col-6">
                <input
                  v-model="form.burst_time_upload"
                  type="text"
                  class="form-control"
                  placeholder="Upload"
                  id="burst-limit"
                />
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Download"
                  v-model="form.burst_time_download"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="priority">Priority</label>
            <div class="row">
              <div class="col-6">
                <input
                  v-model="form.priority_upload"
                  type="number"
                  class="form-control"
                  placeholder="Upload"
                />
              </div>
              <div class="col-6">
                <input
                  v-model="form.priority_download"
                  type="number"
                  class="form-control"
                  placeholder="Download"
                  id="burst-limit"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="comment">Comment</label>
            <input
              v-model="form.comment"
              type="text"
              class="form-control"
              placeholder="Comment"
              id="comment"
            />
          </div>
        </form>
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="submitHandelar" class="btn btn-primary">Save</button>
      </b-modal>
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode";
export default {
  name: "AddQueue",
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        queue_name: "",
        mikrotik: "",
        queue_type_upload: "",
        queue_type_download: "",
        target: "",
        destination: "",
        max_limit_download: "",
        max_limit_upload: "",
        burst_limit_download: "",
        burst_limit_upload: "",
        burst_threshold_upload: "",
        burst_threshold_download: "",
        priority_download: "",
        priority_upload: "",
        burst_time_download: "",
        burst_time_upload: "",
        comment: "",
      }),
      title: "Queue",
      queue_types: [],
      interfaces: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      modal_title: "Add",
    };
  },
  computed: {
    mikrotiks() {
      return this.$store.getters.getallmikrotiks;
    },
  },

  methods: {
    close() {
      this.$bvModal.hide("simple_queue_modal");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ========data save=========
    save() {
      this.form
        .post("queue")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("simple_queue_modal");
            this.$success_message(resp.data.message);
            this.items.data.unshift(resp.data.data);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`queue/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("simple_queue_modal");
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(data) {
      this.$store.dispatch("getallmikrotiks");
      this.modal_title = "Update";
      this.form.queue_name = data.name;
      this.form.mikrotik = data.mikrotik_id;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("simple_queue_modal");
    },

    getqueueType() {
      axios.get("queuetype/withselect").then((resp) => {
        console.log(resp.data.data);
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.queue_types = resp.data.data;
          console.log(resp.data);
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getImterfaces() {
      axios.get("interface-withselect").then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.interfaces = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    modelAction(val = null) {
      if (val == "add") {
        this.editData.mode = false;
      }
      this.getImterfaces();
      this.getqueueType();
      this.$store.dispatch("getallmikrotiks");
      this.$bvModal.show("simple_queue_modal");
    },
  },
};
</script>
