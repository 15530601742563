/* eslint-disable indent */
/* eslint-disable space-before-blocks */
/* eslint-disable comma-dangle */
/* eslint-disable space-infix-ops */
/* eslint-disable no-return-assign */
import axios from "axios";
import helper from "@/helper";
import Swal from "sweetalert2";
export default {
  state: {
    d: "",
  },
  getters: {},
  actions: {},
  mutations: {},
};
