<template>
	<div>
		<h5 class="text-center">Splitter Info ({{this.type}})</h5>
		<DynamicForm
			:no_submit="true"
			:form="form[type]"
			type="create"
			:fields="dynamicFormFields"
		/>
	</div>
</template>

<script>
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";
export default {
	name: "ConnectorSplitter",
	components: { DynamicForm },
	props: {
		"type" : String,
		"id" : Number,
		"form" : Object
	},
	data() {
	  return {
	        typeOptions: [{
				'title': 'Input',
				'value': 'input'
			}, {
				'title': 'Output',
				'value': 'output'
			}],
			splitters: []
	  }
	},
	computed: {
		dynamicFormFields(){
		  	const formFields = [
				{
					'type': 'select',
					'name': 'splitter_id',
					'label': 'Splitter',
					'options': this.splitters,
					'option_settings': {
						'title': 'name',
						'value': 'id',
					},
					'disabled': true
				},
				{
					'type': 'select',
					'name': 'type',
					'label': 'Type',
					'options': this.typeOptions,
					'disabled': true
				},
				{
					'type': 'number',
					'name': 'meter_marking',
					'label': 'Meter Marking',
					'column_class': 'col-md-6'
				},
				{
					'type': 'text',
					'name': 'dbm',
					'label': 'dBM',
					'column_class': 'col-md-6'
				},
				{
					'type': 'textarea',
					'name': 'comment',
					'label': 'Comment',
				},
			]

			return formFields
		}
	},
	created(){
		this.form[this.type] ={
			splitter_id: this.id,
			type: null,
	        meter_marking: null,
	        dbm: null,
	        comment: null,
	    }

	    this.form[this.type][`connected_${this.type}_type`] = 'splitters'

		if(this.type == 'from'){
			this.form[this.type].type = 'output'
		} else {
			this.form[this.type].type = 'input'
		}
		this.getSplitters()
	},
	methods: {
		
		getSplitters() {
		  axios.get(`nm-splitters?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.splitters = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
	}
}
</script>