<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="DownloadIcon" statistic="" statistic-title="Total Download" />
      </b-col>
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="UploadIcon" color="info" statistic="" statistic-title="Total Upload" />
      </b-col>
    </b-row>
    <b-card-code :title="title" no-body>
      <b-card-body>
        <b-row>
          <b-col cols="12" lg="3" md="6" class="mb-1">
            <flat-pickr class="form-control" :config="{
              mode: 'range',
            }" placeholder="Session Date" @on-close="filterChange" />
          </b-col>
        </b-row>
        <div class="table table-sm table-striped">
          <table>
            <thead>
              <tr>
                <th>User Name</th>
                <th>Login Mac Address</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Total Online Time</th>
                <th>Package Name</th>
                <th>Download Data</th>
                <th>Upload Data</th>
                <th>Total Data</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index + 'session'">
                <td>{{ item.username }}</td>
                <td>{{ item.callingstationid }}</td>
                <td> <span class="badge text-secondary">{{ item.acctstarttime }}</span></td>
                <td> <span class="badge text-secondary">{{ item.acctstoptime }}</span></td>
                <td> <span class="badge text-success">{{ formatSeconds(item.acctsessiontime) }}</span></td>
                <td>{{ item.package_id }}</td>
                <td>{{ calculateBandwidth(item.acctinputoctets) }}</td>
                <td>{{ calculateBandwidth(item.acctoutputoctets) }}</td>
                <td>{{ calculateBandwidth(item.acctinputoctets + item.acctoutputoctets) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-body>

    </b-card-code>

  </div>
</template>
<script>
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Form from "vform";
export default {
  name: "UserManage",
  components: {
    BCardCode,
    StatisticCardHorizontal,
    flatPickr,
    Ripple,
  },
  data() {
    return {
      title: "UserSessionHistory",
      items: [],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    filterChange() {

    },
    formatSeconds(value) {
      const year = Math.floor(value / 31536000);
      value -= year * 31536000;
      const month = Math.floor(value / 2592000);
      value -= month * 2592000;
      const week = Math.floor(value / 604800);
      value -= week * 604800;
      const day = Math.floor(value / 86400);
      value -= day * 86400;
      const hour = Math.floor(value / 3600);
      value -= hour * 3600;
      const minute = Math.floor(value / 60);
      const second = value % 60;
      let result = '';
      if (year > 0) result += year + ' Y ';
      if (month > 0) result += month + ' M ';
      if (week > 0) result += week + ' W ';
      if (day > 0) result += day + ' D ';
      if (hour > 0) result += hour + ' H ';
      if (minute > 0) result += minute + ' M ';
      if (second > 0) result += second + ' S ';
      return result.trim();
    },

    calculateBandwidth(bytes) {
      // return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      if (bytes < 1024 * 1024) {
        // less than 1 MB
        return (bytes / 1024).toFixed(2) + " KB";
      } else if (bytes < 1024 * 1024 * 1024) {
        // between 1 MB and 1 GB
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        // 1 GB or greater
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },
    getData(userData) {
      console.log(userData);
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`user-session-history?username=${'test'}`).then((resp) => {
        if (resp.data.success) {
          this.items = resp.data.data;
        } else {

        }
      }).finally(() => {
        this.$store.dispatch("spinnerLoading", false);
      })
    }
  }
};
</script>
