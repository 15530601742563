<template>
  <div>
    <b-card-code :title="title" no-body>
      <b-card-body>
        <b-form-checkbox
          v-model="form.individual_sms"
          name="check-button"
          switch
        >
          <label for="vlan_tagged">Individual SMS</label>
        </b-form-checkbox>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <form action="">
              <div class="form-group">
                <b-form-group
                  class="position-relative"
                  v-if="form.individual_sms"
                  label="Enter Phone Number *"
                  label-for="Enter Phone Number"
                >
                  <b-form-input
                    @input="changePhoneNumber($event)"
                    type="textarea"
                    id="name"
                    v-model="form.numbers"
                    placeholder="Ex:8801XXXXXXXXXX"
                  />
                  <ul
                    class="list_data scrollable scrollable_group border"
                    v-if="userdataList.length > 0"
                  >
                    <li
                      v-for="(item, index) in userdataList"
                      :key="index + 'tag'"
                      @click.prevent="getPhoneNumber(item.phone)"
                    >
                      <span>{{ item.name }}</span> |
                      <span>{{ item.phone }}</span>
                    </li>
                  </ul>
                </b-form-group>
                <label for="tampate">Select Template</label>
                <select
                  @change="selectTemplate()"
                  v-model="form.template"
                  v-if="smstemplates !== null"
                  name=""
                  id="tampate"
                  class="form-control"
                >
                  <option value="">Select Template</option>
                  <option
                    v-for="temp in smstemplates"
                    :key="temp.id"
                    :value="temp.id"
                  >
                    {{ temp.name }}
                  </option>
                </select>
                <label for="Message">Message</label>
                <textarea
                  v-on:keyup="countdown"
                  v-model="form.message"
                  name="Message"
                  id="Message"
                  placeholder="Message"
                  class="form-control"
                  cols="30"
                  rows=""
                ></textarea>
                <div class="d-flex">
                  <p class="pr-1 text-small">
                    Used : {{ form.message.length }}
                  </p>
                  |
                  <!-- <p class='px-1 text-small' v-bind:class="{ 'text-danger': hasError }">Left : {{ remainingCount }} </p> -->
                  <p class="pl-1 text-small">SMS Count : {{ total_sms }}</p>
                </div>
              </div>
            </form>
            <div v-if="!form.individual_sms" class="">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Group</th>
                    <th scope="col">
                      <b-button
                        @click.prevent="editData.mode = false"
                        class="float-right"
                        size="sm"
                        v-b-modal.modal-lg
                        variant="primary"
                      >
                        <!-- @click.prevent="editData.mode = false" -->
                        <feather-icon icon="PlusCircleIcon" size="16" />
                        Create Group
                      </b-button>
                    </th>
                  </tr>
                </thead>
              </table>
              <div class="scrollable scrollable_group border">
                <table class="table">
                  <thead></thead>
                  <tbody class="">
                    <tr
                      v-if="sms_group.length > 0"
                      v-for="(item, index) in sms_group"
                      :key="index"
                    >
                      <th scope="row">
                        <b-form-checkbox
                          :value="item.id"
                          v-model="select_group"
                        >
                        </b-form-checkbox>
                      </th>
                      <td>{{ item.name }}</td>
                      <!-- <td>{{ item.smsgroupusers.length }}</td> -->
                      <td>
                        <div class="float-right">
                          <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template v-slot:button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                              />
                            </template>
                            <b-dropdown-item>
                              <button
                                class="btn btn-sm p-0"
                                @click.prevent="edit(item)"
                              >
                                <feather-icon icon="EditIcon" class="mr-50" />
                                Edit
                              </button>
                            </b-dropdown-item>
                            <b-dropdown-item
                              @click.prevent="deleteData(item.id)"
                            >
                              <feather-icon icon="Trash2Icon" class="mr-50" />
                              Delete
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table class="table">
                <tfoot>
                  <tr>
                    <th colspan="2">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-info"
                        pill
                      >
                        Total Group ({{
                          sms_group !== null ? sms_group.length : 0
                        }})
                      </b-button>
                    </th>
                    <th colspan="2">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-success"
                        pill
                      >
                        Selected ({{ select_group.length }})
                      </b-button>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div v-if="!form.individual_sms" class="col-sm-12 col-md-6">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    <b-form-checkbox
                      v-if="contact_list.length > 0"
                      v-model="checkallnumber"
                    >
                    </b-form-checkbox>
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
            </table>
            <div class="scrollable border">
              <table class="table">
                <thead></thead>
                <tbody class="">
                  <tr
                    v-if="contact_list.length > 0"
                    v-for="(c_item, index) in contact_list"
                    :key="index"
                  >
                    <th scope="row">
                      <b-form-checkbox
                        v-model="form.selectd_number"
                        :value="c_item.phone"
                      >
                      </b-form-checkbox>
                    </th>
                    <td>{{ c_item.name }}</td>
                    <td>{{ c_item.phone ? c_item.phone : "Null" }}</td>
                    <td>{{ c_item.type ? c_item.type : "null" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table class="table">
              <tfoot>
                <tr>
                  <th colspan="2">Total Numbers ({{ contact_list.length }})</th>
                  <th colspan="2" class="text-success">
                    Selected ({{ form.selectd_number.length }})
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div v-else class="col-sm-12 col-md-6">
            <div class="card-body">
              <h1>Coming Soon</h1>
              <p>
                Individual sms when type number show the client name or other
                details
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-button
              @click="save()"
              class="mt-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
            >
              <feather-icon icon="SendIcon" class="mr-50" />
              <span class="align-middle">Send Message</span>
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card-code>
    <SmsGroup></SmsGroup>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import SmsGroup from "./Components/SmsGroup.vue";
const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";
import Ripple from "vue-ripple-directive";
export default {
  name: "SendSms",
  components: {
    SmsGroup,
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
    Ripple,
  },
  directives: { Ripple },
  data() {
    return {
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "template", label: "template", sortable: true },
        "Total SMS Sent",
        "status",
        "action",
      ],
      form: new Form({
        name: "",
        template: "",
        message: "",
        numbers: "",
        selectd_number: [],
        individual_sms: false,
      }),
      select_group: [],
      userdataList: [],
      title: "Send SMS",
      checkallnumber: false,
      smstemplates: [],
      contact_list: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      maxCount: 160,
      remainingCount: 160,
      total_sms: 0,
      hasError: false,
      auto_file: false,
    };
  },
  watch: {
    checkallnumber(value) {
      if (value == false) {
        this.form.selectd_number = [];
      } else {
        this.form.selectd_number = [];
        const arraydata = this.contact_list;
        for (const i in arraydata) {
          this.form.selectd_number.push(arraydata[i].phone);
        }
        console.log(this.form.selectd_number);
      }
    },
    select_group(val) {
      console.log(val.length);
      if (val.length > 0) {
        this.$store.dispatch("spinnerLoading", true);
        this.axios
          .get(`sendsms?group=${val}`)
          .then((resp) => {
            this.$store.dispatch("spinnerLoading", false);
            if (resp.data.success) {
              this.contact_list = resp.data.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.contact_list = [];
        this.checkallnumber = false;
        this.form.selectd_number = [];
      }
    },
  },
  computed: {
    sms_group() {
      return this.$store.getters.sms_group_getter;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },
  created() {
    this.$store.dispatch("sms_group_action");
    this.getData();
  },
  methods: {
    countdown() {
      this.remainingCount = this.maxCount - this.form.message.length;
      this.hasError = this.remainingCount < 0;
      this.total_sms =
        this.form.message.length == 0
          ? 0
          : Math.floor(
              this.form.message.length < 161
                ? this.form.message.length / 161 + 1
                : this.form.message.length / 160 + 1
            );
    },
    selectTemplate() {
      const fData = this.smstemplates.find((el) => el.id == this.form.template);
      this.form.message = fData.template;
    },
    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      if (this.form.message == "")
        return this.$e_tost("Please Select template");
      if (
        this.form.selectd_number.length <= 0 &&
        this.form.individual_sms == false
      )
        return this.$e_tost("Please Select Group or Phone Number");
      this.form
        .post("sendsms")
        .then((resp) => {
          console.log(resp);
          if (resp.data.ErrorCode == 0) {
            this.$s_tost("SMS Sent Successfully");
          } else {
            this.$error_message(resp.data.message);
            this.$error_message(resp.data.ErrorDescription);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async edit(data) {
      if (data) {
        this.form.name = data.name;
        this.form.template = data.template;
        this.modal_title = "Update";
        this.editData.mode = true;
        this.editData.id = data.id;
        this.$bvModal.show("modal-lg");
      } else {
        this.$error_message(resp.data.message);
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("get-all-smstemplate").then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.smstemplates = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    //delete sms group
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`sms-group/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.$store.dispatch("sms_group_action");
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    // search by username phone no
    changePhoneNumber(e) {
      this.auto_file = false;
      console.log(e);
      if (e.length > 0) {
        this.axios.get(`user-search?query=${e}`).then((resp) => {
          this.userdataList = [];
          if (resp.data.success) {
            console.log(resp.data.data);
            this.userdataList = resp.data.data;
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
      } else {
        this.auto_file = false;
        this.userdataList = [];
      }
    },
    // getPhoneNumber
    getPhoneNumber(value) {
      if (!this.auto_file) this.form.numbers = "";
      // var serch_data = this.replaceString(value, "+", "");
      // console.log("serch_data", serch_data);
      var serch_data = this.replaceString(value, "880", "");
      this.form.numbers += `880${serch_data},`;
      this.auto_file = true;
      const data = this.userdataList.find((stack) => {
        return stack.phone === value;
      });
      this.userdataList.splice(data, 1);
    },
  },
};
</script>
<style>
.scrollable {
  height: 500px;
  overflow-y: auto;
  width: 100%;
}

.scrollable_group {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}

/* scrollbar width */
.scrollable::-webkit-scrollbar {
  width: 5px;
}

/* scrollbar track */
.scrollable::-webkit-scrollbar-track {
  background: #eee;
}

/* scrollbar handle */
.scrollable::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #00d2ff;
  background-image: linear-gradient(to top, #00d2ff 0%, #3a7bd5 100%);
}

.p_half {
  padding: 2px;
}

.btn_disabled {
  cursor: none;
}

/* list_data */
.list_data {
  position: absolute;
  background-color: #fff;
  color: black;
  width: 100%;
  border-radius: 5px;
  padding: 0;
  z-index: 999 !important;
}
.list_data li {
  list-style: none;
  padding: 0 5px;
  margin-top: -1px;
  border: 1px solid;
  cursor: pointer;
}
</style>
