/* eslint-disable indent */
/* eslint-disable space-before-blocks */
/* eslint-disable comma-dangle */
/* eslint-disable space-infix-ops */
/* eslint-disable no-return-assign */
import axios from "axios";
import helper from "@/helper";
export default {
  state: {
    sms_group: {},
    all_sms_apis: [],
    primary_api_balance: "",
  },
  getters: {
    all_sms_apis_getter(state) {
      return state.all_sms_apis;
    },

    // sms_group_getter
    sms_group_getter(state) {
      return state.sms_group;
    },
    // primary_api_balance_getter
    primary_api_balance_getter(state) {
      return state.primary_api_balance;
    },
  },

  // actions
  actions: {
    // get all sms api
    all_sms_api_action(context) {
      axios.get("sms-api").then((resp) => {
        if (resp.data.success) {
          console.log(resp.data.data.data);
          context.commit("all_sms_api_mutation", resp.data.data.data);
        } else {
          console.log(resp);
        }
      });
    },

    // sms_group_action
    sms_group_action(context) {
      axios.get("sms-group").then((resp) => {
        if (resp.data.success) {
          context.commit("sms_group_mutation", resp.data.data);
        } else {
          console.log(resp);
        }
      });
    },

    // get primary sms balance
    primary_api_balance_action(context) {
      axios.get("primary-api-balance").then((resp) => {
        if (resp.data.ErrorCode == 0) {
          context.commit(
            "primary_api_balance_mutation",
            resp.data.Data[0].Credits
          );
        } else if (resp.data.Balance) {
          context.commit("primary_api_balance_mutation", resp.data.Balance);
        }
      });
    },
  },

  //   =========mutations==========
  mutations: {
    all_sms_api_mutation(state, payload) {
      state.all_sms_apis = payload;
    },
    // get sms group
    sms_group_mutation(state, payload) {
      state.sms_group = payload;
    },
    // primary_api_balance_mutation
    primary_api_balance_mutation(state, payload) {
      state.primary_api_balance = payload;
    },
  },
};
