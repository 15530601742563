<template>
  <div>
    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="row mb-1">
          <div class="col-3">
            <flat-pickr
              v-model="filterData.filter_by_date"
              class="form-control"
              :config="{
                mode: 'range',
              }"
              placeholder="BTRC Repoert By Date"
              @on-close="filterChange"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <vue-json-to-csv
              v-if="permission.report_generate_csv"
              :json-data="items"
            >
              <b-button size="sm" col="2" class="ml-2" variant="relief-primary">
                <feather-icon icon="DownloadIcon" size="16" />
                Generate CSV File</b-button
              >
            </vue-json-to-csv>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import axios from "axios";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { statusAction } from "@/utils/helpers/functions";
import VueJsonToCsv from "vue-json-to-csv";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

export default {
  name: "BtrcReport",
  components: {
    BCardCode,
    StatisticCardHorizontal,
    flatPickr,
    VueJsonToCsv,
  },
  data() {
    return {
      title: "BTRC Report",
      fields: [
        { key: "name_operator", label: "Operator Name" },
        { key: "type_of_client", label: "type of client" },
        {
          key: "distribution_Location_point",
          label: "distribution Location point",
        },
        { key: "name_of_client" },
        { key: "type_of_connection" },
        { key: "activation_date" },
        { key: "bandwidth_allocation_MB" },
        { key: "allowcated_ip" },
        { key: "house_no" },
        { key: "road_no" },
        { key: "area" },
        { key: "district" },
        { key: "thana" },
        { key: "client_phone" },
        { key: "mail" },
        { key: "excluding_vat", label: "selling_bandwidthBDT (Excluding VAT)" },
      ],
      statusAction,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      items: [],
      user: {},
      type: "",
      summary: {
        total_user: 0,
        total_paid_user: 0,
        total_unpaid_user: 0,
      },

      filterData: {
        filter_by_date: null,
      },
      // access controll
      permission: {
        report_generate_csv: false,
      },
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  computed: {
    filterQuery() {
      let filterQuery = "";
      if (this.filterData.filter_by_date) {
        filterQuery += `filter_by_date=${this.filterData.filter_by_date}`;
      }
      console.log(filterQuery);
      return filterQuery;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.report_generate_csv = this.checkpermission(
        "report_generate_csv"
      );
    },
  },

  created() {
    this.$store.dispatch("getRoles");
    this.getData();
    this.getSummaryData();
  },
  methods: {
    //  ========= get customer data
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      this.items = [];
      axios
        .get(`reports/btrc?type=home&page=${page}&status=1&${this.filterQuery}`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {
            resp.data.data.data.data.forEach((el) => {
              console.log(el);
              let speed_range = el.billing_info.package.name.replace(
                /[^\d].*/,
                ""
              );
              speed_range = speed_range
                ? speed_range
                : el.billing_info.package.bandwidth.replace(/[^\d].*/, "");

              this.items.push({
                name_operator: resp.data.data.sitename,
                type_of_client: el.type,
                distribution_Location_point:
                  el.connection_info.mikrotik.sitename,
                name_of_client: el.connection_info.username,
                type_of_connection: el.connection_info.service,
                activation_date: moment(
                  String(el.billing_info.connection_date)
                ).format("DD/MM/YYYY"),
                bandwidth_allocation_MB: speed_range,
                allowcated_ip: el.connection_info.username,
                house_no: el.userdetails.house_number_one,
                road_no: el.userdetails.road_number_one,
                area: el.userdetails.area_one,
                district: el.userdetails.district_one,
                thana: el.userdetails.upazila_one,
                client_phone: el.phone,
                mail: el.email,
                excluding_vat: el.billing_info.package.price,
              });
            });
            // this.items = resp.data.data.data;
            this.totalRows = resp.data.data.data.length;
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    getSummaryData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`customer-summary?type=home`).then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.summary.total_user = resp.data.data.total_user;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    filterChange() {
      setTimeout(() => {
        this.getData();
      }, 100);
    },
  },
};
</script>
