/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <h3>Permissions</h3>
    <div class="row">
      <div class="col-12">
        <input
          type="checkbox"
          v-model="form.is_check_all"
          name=""
          id="all"
          @click="check_all"
        />
        <label for="all">All</label>
      </div>
      <div
        v-for="(permission, i) in permissions"
        :key="i"
        class="col-lg-6 col-sm-12"
      >
        <div class="permissions-list">
          <div class="row align-items-center">
            <div class="col-lg-4">
              <!-- <input
                type="checkbox"
                @change="change_perissions_group"
                :value="i"
                name=""
                :id="i"
              /> -->
              <label :for="i"> {{ i }} </label>
            </div>
            <div class="col-lg-7 p-2">
              <div v-for="(item, j) in permission" :key="j">
                <label :for="'permission_name_' + item.id" class="mr-1">
                  <input
                    :id="'permission_name_' + item.id"
                    v-model="permissiondata"
                    type="checkbox"
                    :value="item.id"
                  />
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button
        class="btn btn-primary"
        :disabled="form.busy"
        @click.prevent="save"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
import Form from "vform";

export default {
  name: "Permission",

  data() {
    return {
      form: new Form({
        name: "",
        phone: "",
        password: "",
        email: "",
        permission_id: [],
        is_check_all: false,
      }),
      roles: [],
      permissiondata: [],
      allpermission: [],
      allpermissionItems: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
    };
  },

  computed: {
    permissions() {
      if (this.allpermissionItems.length / 2 >= this.allpermission.length) {
        const arraydata = this.$store.state.isp.permissions;
        for (const i in arraydata) {
          arraydata[i].forEach((element) => {
            this.allpermission.push(element.id);
          });
        }
      }
      if (this.allpermission.length !== this.form.permission_id.length) {
        this.form.is_check_all = false;
      } else {
        this.form.is_check_all = true;
      }
      return this.$store.state.isp.permissions;
    },
  },

  created() {
    this.$store.dispatch("getPermissonList");
    this.getData();
  },
  // watch method start
  watch: {
    permissiondata(selected) {
      this.form.permission_id = selected;
      if (this.allpermission.length !== this.form.permission_id.length) {
        this.form.is_check_all = false;
      } else {
        this.form.is_check_all = true;
      }
    },
  },
  methods: {
    //= ===get data=========
    getData() {
      this.$store.dispatch("spinnerLoading", true);
      const arraydata = this.$store.state.isp.permissions;
      for (const i in arraydata) {
        arraydata[i].forEach((element) => {
          this.allpermissionItems.push(element.id);
        });
      }
      this.axios
        .get(`role/permissions/${this.$route.params.id}`)
        .then((resp) => {
          if (resp.data.success) {
            if (resp.data.data.length > 0) {
              this.permissiondata = resp.data.data;
              this.$store.dispatch("spinnerLoading", false);
            }
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
          this.$store.dispatch("spinnerLoading", false);
        });
    },

    //= ========data save=========
    save() {
      this.form
        .post(`role/assign/permission/${this.$route.params.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.$router.push({ name: "role" });
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    check_all: function () {
      const arraydata = this.$store.state.isp.permissions;
      if (this.form.is_check_all == true) {
        this.permissiondata = [];
      } else {
        for (const i in arraydata) {
          arraydata[i].forEach((element) => {
            // this.form.permission_id.push(element.id);
            this.permissiondata.push(element.id);
          });
        }
      }
    },
    changeCheckItem() {
      const arraydata = this.$store.state.isp.permissions;
      for (const i in arraydata) {
        arraydata[i].forEach((element) => {
          this.allpermission.push(element.id);
          if (this.allpermission.length !== this.form.permission_id.length) {
            this.form.is_check_all = false;
          } else {
            this.form.is_check_all = true;
          }
        });
      }
    },
    change_perissions_group() {},
  },
};
</script>
