<template>
  <div>
    <div>
      <b-card-code :title="title" no-body>
        <b-card-body>
          <b-button
            v-if="permission.add"
            class="mb-1"
            variant="primary"
            size="sm"
            @click.prevent="OpenModal('add')"
          >
            <feather-icon icon="PlusCircleIcon" size="16" />
            Add New
          </b-button>
          <div v-if="permission.view" class="justify-content-between flex-wrap">
            <div class="d-flex justify-content-between">
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0 mx-3"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          v-if="permission.view"
          striped
          hover
          responsive
          class="position-relative"
          ref="table"
          :per-page="Number(perPage)"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(SL)="{ item, index }">
            {{ index + 1 }}
          </template>
          <template #cell(billing_date)="{ item, index }">
            <span class="badge text-secondary" v-if="item.agent_details">
              {{ formatDateTime(item.agent_details.billing_date) }}
            </span>
          </template>
          <template #cell(last_month_commission)="{ item, index }"
            ><span v-if="item.agent_details">
              {{ item.agent_details.last_month_commission }}</span
            >
          </template>
          <template #cell(current_month_commission)="{ item, index }"
            ><span v-if="item.agent_details">
              {{ item.agent_details.current_month_commission }}</span
            >
          </template>
          <template #cell(total_client)="{ item, index }">
            <span
              v-if="item.agent_user"
              class="badge"
              :class="
                item.agent_user.length == 0
                  ? 'badge-light-warning'
                  : 'badge-light-success'
              "
            >
              {{ item.agent_user.length }}
            </span>
          </template>
          <template #cell(commission_rate)="{ item, index }">
            <span
              class="badge"
              :class="
                item.agent_details.commision_type == 1
                  ? ' badge-light-info'
                  : 'badge-light-success'
              "
              v-if="item.agent_details"
            >
              {{ item.agent_details.commission_rate }}
              {{ item.agent_details.commision_type == 1 ? "%" : "TK" }}
            </span>
          </template>
          <template #cell(actions)="{ item, index }">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="permission.view"
                :to="{ name: 'agent.agent_profile', params: { id: item.id } }"
              >
                <button class="btn btn-sm p-0">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  View
                </button>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="permission.add"
                @click.prevent="OpenModal('edit', item)"
              >
                <button class="btn btn-sm p-0">
                  <feather-icon icon="EditIcon" class="mr-50" />
                  Edit
                </button>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="permission.assign_permission"
                title="Assign Role"
                @click.prevent="assign_users('assign', item)"
              >
                <button class="btn btn-sm p-0">
                  <feather-icon icon="AlertCircleIcon" class="mr-50" />
                  Assign Users
                </button>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="permission.assign_permission"
                title="Assign Role"
                @click.prevent="assign_users('delete', item)"
              >
                <button class="btn btn-sm p-0">
                  <feather-icon icon="AlertCircleIcon" class="mr-50" />
                  Delete Assign Users
                </button>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="permission.delete"
                :action="'user-delete-by-type/' + item.id + '?user_type=agent'"
                @click.prevent="user_delete_by_type(item)"
              >
                <button class="btn btn-sm p-0">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  Delete
                </button>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-card-body
          v-if="permission.view"
          class="d-flex justify-content-between flex-wrap pt-0"
        >
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNoOfRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card-code>
    </div>
    <UserAddForm
      v-if="is_open_modal"
      :editedata="editedata"
      @my-event="getData"
    />
    {{ getRoleWisePermissions }}

    <b-modal id="user_assign--model" :title="modal_title" size="lg">
      <b-form-checkbox-group
        v-model="selectOption"
        :options="selectedUsers"
        name="flavour-1a"
        class="demo-inline-spacing"
      />

      <template #modal-footer>
        <button
          @click="saveAssignUsers()"
          class="btn btn-primary"
          type="submit"
        >
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import UserAddForm from "../user/UserAddForm.vue";
import {
  BButton,
  BModal,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
} from "bootstrap-vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import Swal from "sweetalert2";

export default {
  name: "ActionHistories",
  components: {
    BCardCode,
    StatisticCardHorizontal,
    flatPickr,
    BButton,
    BModal,
    UserAddForm,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
  },
  data() {
    return {
      title: "Agent",
      fields: [
        "SL",
        "name",
        "email",
        "phone",
        "commission_rate",
        "billing_date",
        "total_client",
        "active_client",
        "inactive_client",
        "last_month_commission",
        "current_month_commission",
        "actions",
      ],
      selectOption: [],
      selectedUsers: [],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      filter_by_date: null,
      selected: [],
      assign_user_id: null,
      modal_title: "Assign User",
      is_open_modal: false,
      editedata: null,
      // access controll
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        assign_permission: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Agents");
      this.permission.add = this.checkpermission("Agents Add");
      this.permission.edit = this.checkpermission("Agents Edit");
      this.permission.delete = this.checkpermission("Agents Delete");
      this.permission.assign_permission =
        this.checkpermission("Agents Permssions");
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  created() {
    this.getData();
  },
  watch: {
    currentPage: {
      handler: function (newVal, oldVal) {
        this.getData(newVal); // call it in the context of your component object
      },
      deep: true,
    },
  },
  methods: {
    async assign_users(action, data) {
      const route =
        action == "assign"
          ? "get-unassigned-user"
          : `get-assigned-user/${data.id}`;

      this.modal_title = action == "assign" ? "Assign User" : "Delete Users";

      this.axios
        .get(route)
        .then((resp) => {
          this.assign_user_id = data.id;
          const options = {};
          if (resp.data.success) {
            if (resp.data.data && resp.data.data.length > 0) {
              resp.data.data.forEach((element) => {
                options[element.id] = element.name;
              });
              this.selectedUsers = options;
              this.$bvModal.show("user_assign--model");
            } else {
              this.$error_message("No Avaliable User");
            }
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          this.$error_message(e);
        });
    },

    saveAssignUsers() {
      axios
        .put(`agent-assign-user/${this.assign_user_id}`, {
          selected_data: this.selectOption,
          action:
            this.modal_title == "Assign User" ? "user_assign" : "users_delete",
        })
        .then((resp) => {
          if (resp.data.success) {
            this.$s_tost(resp.data.message);
            this.getData();
            this.$bvModal.hide("user_assign--model");
            this.selectOption = [];
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {});
    },
    filterChange() {
      this.getData();
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`agents?page=${page}&item=${this.perPage}`)
        .then((resp) => {
          if (resp.data.success) {
            this.totalNoOfRows = resp.data.data.total;
            this.items = resp.data.data.data;
          } else {
            this.$error_message(resp.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch("spinnerLoading", false);
        });
    },

    async user_delete_by_type(val) {
      await Swal.fire({
        title: "Are you sure?",
        text: `You won't to delete It`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .get(`user-delete-by-type/${val.id}`, {
              params: {
                type: "agent",
              },
            })
            .then((resp) => {
              if (resp.data.success) {
                this.getData();
                this.$s_tost(resp.data.message);
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
    async OpenModal(action, val = null) {
      this.is_open_modal = true;
      if (action == "add") {
        this.$bvModal.show("modal-lg");
      } else if (action == "edit") {
        this.editedata = val;
        this.$bvModal.show("modal-lg");
      }
    },

    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },
    closeModal() {},
  },
};
</script>
