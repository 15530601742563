import auth from '@/middleware/auth'
import Categories from '../views/Inventory/categories.vue'
import Brands from '../views/Inventory/brands.vue'
import Units from '../views/Inventory/units.vue'
import Suppliers from '../views/Inventory/suppliers/index.vue'
import SupplierDetail from '../views/Inventory/suppliers/show.vue'
import Products from '../views/Inventory/products/index.vue'
import Purchases from '../views/Inventory/purchases/index.vue'
import ProductReturn from '../views/Inventory/product_return/index.vue'
import SupplierLedger from '../views/Inventory/supplier_ledger/index.vue'

export default [
  {
    path: '/inventory/categories',
    name: 'inventory_categories',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Categories,
    meta: {
      pageTitle: 'View Categories',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/brands',
    name: 'inventory_brands',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Brands,
    meta: {
      pageTitle: 'View Brands',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Brands',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/units',
    name: 'inventory_units',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Units,
    meta: {
      pageTitle: 'View Units',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Units',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/suppliers',
    name: 'inventory_suppliers',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Suppliers,
    meta: {
      pageTitle: 'View Suppliers',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Suppliers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/suppliers/:id',
    name: 'inventory_supplier_detail',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: SupplierDetail,
    meta: {
      pageTitle: 'View Supplier',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Supplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/products',
    name: 'inventory_products',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Products,
    meta: {
      pageTitle: 'View Products',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Products',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/purchases',
    name: 'inventory_purchases',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Purchases,
    meta: {
      pageTitle: 'View Purchases',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Purchases',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/product_return',
    name: 'inventory_product_return',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: ProductReturn,
    meta: {
      pageTitle: 'View Product Returns',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Product Return',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/supplier_ledger',
    name: 'supplier_ledger',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: SupplierLedger,
    meta: {
      pageTitle: 'View Supplier Ledger',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Supplier Ledger',
          active: true,
        },
      ],
    },
  },
]
