<template>
  <div>
    <div>
      <b-card-code :title="title" no-body>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex">
              <flat-pickr
                v-model="filter_by_date"
                class="form-control"
                :config="{
                  mode: 'range',
                }"
                placeholder="Search By Date"
                @on-close="filterChange"
              />

              <!-- sorting  -->
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0 mx-3"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          ref="table"
          :per-page="Number(perPage)"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(SL)="{ item, index }">
            {{ index + 1 }}
          </template>
          <template #cell(subject)="{ item, index }">
            <span v-if="item.subject_type">{{
              item.subject_type.replace("App\\Models\\", "")
            }}</span>
          </template>
          <template #cell(creator)="{ item, index }">
            {{ item.users.name }}
          </template>
          <template #cell(message)="{ item, index }">
            {{ item.description }}
          </template>
          <template #cell(date)="{ item, index }">
            {{ formatDateTime(item.created_at) }}
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNoOfRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "ActionHistories",
  components: { BCardCode, StatisticCardHorizontal, flatPickr },
  data() {
    return {
      title: "Actions History",
      form: new Form({
        name: "",
        mac_address: 1,
        status: 1,
      }),
      fields: ["SL", "subject", "creator", "message", "date"],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      filter_by_date: null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  created() {
    this.getData();
  },
  watch: {
    currentPage: {
      handler: function (newVal, oldVal) {
        this.getData(newVal); // call it in the context of your component object
      },
      deep: true,
    },
  },
  methods: {
    filterChange() {
      console.log("this.filter_by_date", this.filter_by_date);
      this.getData();
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(
          `get-all-actions-history?page=${page}&item=${this.perPage}&filter_by_date=${this.filter_by_date}`
        )
        .then((resp) => {
          if (resp.data.success) {
            this.totalNoOfRows = resp.data.data.total;
            this.items = resp.data.data.data;
          } else {
            this.$error_message(resp.data.message);
          }
        })
        .finally(() => {
          this.$store.dispatch("spinnerLoading", false);
        });
    },
    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
