<template>
	<div>
		<!-- <h5 class="text-center">Enclouser {{type}}</h5> -->
		<template
			v-if="loading"
		>
			Loading ...
		</template>
		<template
			v-else
		>
			<DynamicForm
				:submitForm="submit"
				:form="form"
				:type="type"
				:fields="dynamicFormFields"
			/>
		</template>
	</div>
</template>


<script>

import Form from "vform";
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "Enclouser",
	components: {  DynamicForm },
	props: {
		"type": {
			type: String,
			default: 'create'
		},
		"lat_lon": {
			type: String,
		},
		"id" : {
			type: Number,
			default: null
		}
	},
	data() {
	  return {

	  	form: new Form({
	        name: null,
	        type_id: null,
	        splitter_id: null,
	        primary_fiber_id: null,
	        enclouser_route: null,
	        no_of_loops: 0,
	        loop_location: null,
	        lat_lon: null,
	        installed_by: null,
	        installed_at: null,
	        comment: null,
	        status:1
	  	}),
		apiEndPoint: 'nm-enclousers',

		statusOptions: [{
			'title': 'Active',
			'value': 1
		}, {
			'title': 'Inactive',
			'value': 0
		}],

     	types: [],
		splitters: [],
		fibers: [],
		loading: false
	  }

	},
	emits: ['submitted'],
	computed: {
	  dynamicFormFields(){
	  	
	  	const formFields = [
			{
				'type': 'text',
				'name': 'name',
				'label': 'Name',
			},
			{
				'type': 'select',
				'name': 'type_id',
				'label': 'Type',
				'options': this.types,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				},
			},
			{
				'type': 'text',
				'name': 'lat_lon',
				'label': 'Latitude Longitude',
				'disabled': true
			},
			{
				'type': 'select',
				'name': 'splitter_id',
				'label': 'Splitter',
				'options': this.splitters,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				}	
			},
			{
				'type': 'select',
				'name': 'primary_fiber_id',
				'label': 'Primary Fiber',
				'options': this.fibers,
				'option_settings': {
					'title': 'cable_id',
					'value': 'id',
					'template': {
						'title': '%s (%sm - %sm)',
						'value': [
							'cable_id', 
							'meter_length_from',
							'meter_length_to'
						]
					}
				}	
			},
			{
				'type': 'text',
				'name': 'installed_by',
				'label': 'Installed By',
			},
			{
				'type': 'datetime-local',
				'name': 'installed_at',
				'label': 'Installed At',
			},
			{
				'type': 'text',
				'name': 'enclouser_route',
				'label': 'Route',
			},
			{
				'type': 'number',
				'name': 'no_of_loops',
				'label': 'Number of Loops',
			},
			{
				'type': 'text',
				'name': 'loop_location',
				'label': 'Loop Location',
			},
			{
				'type': 'textarea',
				'name': 'comment',
				'label': 'Comment',
			},
			{
				'type': 'select',
				'name': 'status',
				'label': 'Status',
				'options': this.statusOptions,
				'disabled': true	
			},
		]


		return formFields
	  }
	},
	created() {
		this.form.lat_lon = this.lat_lon
		if(this.type == 'edit'){
			this.edit()
		}
		this.getEnclouserSettings()
		this.getSplitters()
		this.getFibers()
	},
	watch: {
	    'lat_lon': function(newVal, oldVal){
	    	this.form.lat_lon = this.lat_lon
	    }
	  
	},
	methods: {
		
		submit(){
			if(this.type === 'create'){
				this.create()
			} else if(this.type === 'edit'){
				this.update()
			}
		},
		create() {
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  
				  this.$emit('submitted', item)
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},
		edit() {
			this.loading = true
			axios.get(`${this.apiEndPoint}/${this.id}`).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data;
				  this.form.name = item.name
				  this.form.type_id = item.type_id
				  this.form.splitter_id = item.splitter_id
				  this.form.primary_fiber_id = item.primary_fiber_id
				  this.form.enclouser_route = item.route
				  this.form.no_of_loops = item.no_of_loops
				  this.form.loop_location = item.loop_location
				  this.form.lat_lon = item.lat_lon
				  this.form.installed_at = item.installed_at
				  this.form.installed_by = item.installed_by
				  this.form.comment = item.comment
				  this.form.status = item.status
				} else {
				  
				}
			}).finally(()=> {
				this.loading = false
			});
		},
		update() {
			this.form.put(`${this.apiEndPoint}/${this.id}`).then((resp) => {
			  if (resp.data.success) {
			    let updatedItem = resp.data.data;

			    this.$emit('submitted', updatedItem)

			    this.form.reset()

			    this.$success_message(resp.data.message);
			  } else {
			    this.$error_message(resp.data.message, resp.data.errors);
			  }
			});
		},

		getEnclouserSettings() {
		  axios.get(`nm-enclouser-settings?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.types = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},

		getSplitters() {
		  axios.get(`nm-splitters?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.splitters = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},

		getFibers() {
		  axios.get(`nm-fibers?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.fibers = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},

 
	},
}
</script>