<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="this.items.length"
          statistic-title="Total Mikrotik"
        />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Total Active"
        />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="danger"
          :statistic="summary.total_inactive"
          statistic-title="Total In Active"
        />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button
              v-if="permission.add"
              size="sm"
              col="2"
              class="ml-2"
              variant="primary"
              :to="{ name: 'addMikrotik' }"
            >
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(mikrotik)="data">
          {{
            typeof data.mikrotik != "undefined" ? data.mikrotik.identity : ""
          }}
        </template>
        <template v-if="permission.status" #cell(status)="data">
          <b-form-checkbox
            v-model="data.item.status == 1"
            name="check-button"
            switch
            @change="changeMikrotikStatus(data.item.id)"
          ></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-if="permission.edit"
              :to="{ name: 'editmikrotik', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              Edit
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.Add_Radious"
              v-b-modal="'radius_' + data.item.id"
              ><feather-icon icon="CopyIcon" class="mr-50" />
              <span>Add To Radius</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.Sync_Miktrotik"
              v-b-modal="'syncradius_' + data.item.id"
            >
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>Sync MikroTik</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.Online_User"
              @click="sync_all(data.item.id)"
            >
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>Sync All</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.Online_User"
              @click="viewOnlineUsers(data.item)"
            >
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>Online User</span>
            </b-dropdown-item>
            <b-dropdown-item @click="viewOnlineUsers(data.item)">
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>System Monitor</span>
            </b-dropdown-item>
          </b-dropdown>

          <b-modal
            :id="'syncradius_' + data.item.id"
            title="Add To Radius"
            hide-footer
          >
            <b-button block variant="success" @click="CheckIpPool(data.item.id)"
              ><span>Sync IP Pool</span></b-button
            >
            <b-button
              block
              variant="primary"
              @click="$confirm(`add-to-radius/${data.item.id}?type=2`)"
              ><span>Sync Profile</span></b-button
            >
          </b-modal>

          <b-modal
            :id="'radius_' + data.item.id"
            title="Add To Radius"
            hide-footer
          >
            <b-button block variant="success" @click="CheckIpPool(data.item.id)"
              ><span>Add IP Pool</span></b-button
            >
            <b-button
              block
              variant="primary"
              @click="$confirm(`add-to-radius/${data.item.id}?type=2`)"
              ><span>Add Profile(Package)</span></b-button
            >
            <b-button
              block
              variant="info"
              @click="$confirm(`add-to-radius/${data.item.id}?type=3`)"
              ><span>Add MikroTik Secret (User)</span></b-button
            >
            <b-button
              block
              variant="secondary"
              @click="$confirm(`add-to-radius/${data.item.id}?type=6`)"
              ><span>Sync Simple Queue</span></b-button
            >
            <b-button
              block
              variant="warning"
              @click="$confirm(`add-to-radius/${data.item.id}?type=7`)"
              ><span>Sync Queue Type (Pcq)</span></b-button
            >
            <b-button
              block
              variant="primary"
              @click="$confirm(`add-to-radius/${data.item.id}?type=8`)"
              ><span>Sync Ethernet</span></b-button
            >
            <b-button
              block
              variant="info"
              @click="$confirm(`add-to-radius/${data.item.id}?type=9`)"
              ><span>Sync Ip Addresses</span></b-button
            >
            <b-button
              block
              variant="success"
              @click="$confirm(`add-to-radius/${data.item.id}?type=10`)"
              ><span>Sync Ip Arp</span></b-button
            >
            <b-button
              block
              variant="primary"
              @click="$confirm(`add-to-radius/${data.item.id}?type=11`)"
              ><span>Sync Interface</span></b-button
            >
          </b-modal>
        </template>
      </b-table>
      <!-- //check All ip address -->
      <b-modal size="lg" id="sync_pool_modal" title="All Ip Pool" hide-footer>
        <table class="mb-2">
          <thead>
            <tr class="text-center">
              <th>Name</th>
              <th>Range</th>
              <th>Start Ip</th>
              <th>End Ip</th>
              <th>Total Number of Ip</th>
              <th>Subnet Musk</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ipdata, index) in ip_pool_data" :key="index + 'ip'">
              <td>{{ ipdata.name }}</td>
              <input type="hidden" v-model="ip_pool.name[index]" />
              <td>
                <span class="badge badge-light-success">{{
                  spletData(ipdata.ranges, "-", "/")[0]
                }}</span>
                -
                <span class="badge badge-light-primary">{{
                  spletData(ipdata.ranges, "-", "/")[1]
                }}</span>
              </td>
              <td>
                <!-- :value="spletData(ipdata.ranges, '-', '/')[0]" -->
                <!-- v-model="ip_pool.start_ip" -->
                <input
                  v-model="ip_pool.start_ip[index]"
                  class="form-control"
                  type="text"
                  name=""
                  id=""
                  placeholder="Start Ip"
                />
              </td>
              <td>
                <!-- v-model="ip_pool.end_ip" -->
                <input
                  class="form-control"
                  v-model="ip_pool.end_ip[index]"
                  type="text"
                  id=""
                  placeholder="End Ip"
                />
              </td>
              <td>
                <input
                  placeholder="Total Ip"
                  v-model="ip_pool.number_of_ip[index]"
                  class="form-control"
                  type="number"
                  name=""
                  id=""
                />
              </td>
              <td>
                <input
                  class="form-control"
                  v-model="ip_pool.submet_mask[index]"
                  type="text"
                  placeholder="Subnet Mask"
                  id=""
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          class="btn btn-md btn-success"
          @click.prevent="save_ip_pool()"
          type="submit"
        >
          Save
        </button>
        <button class="btn btn-md btn-warning" type="submit">Close</button>
      </b-modal>
      <!-- show online user MOdal  -->
      <b-modal
        id="online-user-modal"
        title="All Online Users"
        size="xl"
        hide-header-close
        hide-footer
      >
        <b-row v-if="online_user_items" class="match-height">
          <div class="m-auto">
            <statistic-card-horizontal
              icon="UsersIcon"
              :statistic="online_user_items.length"
              statistic-title="Total Online User"
            />
          </div>
        </b-row>

        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :items="online_user_items"
          :fields="online_user_fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(serial_no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <div
              class="btn btn-danger btn-sm"
              title="Disconnect User"
              @click="$confirm(`add-to-radius/${mikrotik_id}?type=5&username=${data.item.name}`)"
            >
              <feather-icon icon="UserXIcon" size="1.5x" />
            </div>
          </template>
        </b-table>

        <button @click="close" class="btn btn-warning">Close</button>
      </b-modal>
      <!-- show online user MOdal  -->
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import {
  BTable,
  BButton,
  BCol,
  BModal,
  BRow,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardBody,
} from "bootstrap-vue";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode";
import Swal from "sweetalert2";

export default {
  name: "Mikrotik",
  components: {
    Table,
    StatisticCardHorizontal,
    BCol,
    BRow,
    BModal,
    BDropdown,
    BDropdownItem,
    BCardCode,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      title: "Mikrotik List",
      form: new Form({
        name: "",
      }),
      ip_pool: new Form({
        mikrotik_id: null,
        number_of_ip: [],
        start_ip: [],
        submet_mask: [],
        end_ip: [],
        range: "",
        name: [],
      }),
      status: false,
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "identity", label: "Mikrotik Identity", sortable: true },
        { key: "host", label: "Mikrotik IP", sortable: true },
        { key: "username", label: "API Username", sortable: true },
        { key: "port", label: "API Port", sortable: true },
        { key: "status", label: "Status" },
        { key: "action", label: "Action" },
      ],
      summary: {
        total_mikrotik: 0,
        total_active: 0,
        total_inactive: 0,
      },
      items: [],
      ip_pool_data: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Create Data",
      mikrotik_id: null,
      mikrotik_name: "",
      online_user_items: null,
      online_user_fields: [
        { key: "serial_no", label: "Sl/No", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "service", label: "Service", sortable: true },
        { key: "caller-id", label: "Caller Id", sortable: true },
        { key: "address", label: "Address", sortable: true },
        { key: "uptime", label: "Uptime", sortable: true },
        { key: "action", label: "Action" },
      ],
      summary: {
        total_active: 0,
        total_inactive: 0,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
        Add_Radious: false,
        Sync_Miktrotik: false,
        Online_User: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Mikrotik");
      this.permission.add = this.checkpermission("Add Mikrotik");
      this.permission.edit = this.checkpermission("Miktrotik Edit");
      this.permission.delete = this.checkpermission("Miktrotik Delete");
      this.permission.status = this.checkpermission("Miktrotik Change Status");
      this.permission.Sync_Miktrotik = this.checkpermission("Sync Miktrotik");
      this.permission.Add_Radious = this.checkpermission("Add Radious");
      this.permission.Online_User = this.checkpermission("Online_User");
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    this.getResults();
  },
  methods: {
    // $confirm(`add-to-radius/${data.item.id}?type=4`)
    async viewOnlineUsers(item) {
      this.mikrotik_id = item.id;
      this.mikrotik_name = item.name;
      const res = await axios.get(`add-to-radius/${item.id}?type=4`);
      if (res.data.success) {
        console.log(res.data.data);
        this.online_user_items = res.data.data;
        this.$bvModal.show("online-user-modal");
      } else {
        this.$error_message(res.data.message, res.data.errors);
      }
    },
    close() {
      this.$bvModal.hide("online-user-modal");
    },
    showModal(index) {
      let modal_id = "radius_" + index;
      this.$refs[modal_id][0].show(index);
    },
    editItem() {
      console.log(this);
    },
    async getResults(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      const resp = await axios.get(
        `mikrotik?page=${page}&item=${this.perPage}`
      );
      if (resp.data.success) {
        console.log(resp.data.data);
        this.$store.dispatch("spinnerLoading", false);
        this.items = resp.data.data.data;
        this.totalRows = resp.data.data.total;

        this.summary.total_active = this.items.filter(
          (item) => item.status == 1
        ).length;
        this.summary.total_inactive = this.items.filter(
          (item) => item.status == 0
        ).length;
      } else {
        this.$error_message(resp.data.message);
      }
    },
    statusHtml(status) {
      return this.$resolveStatusVariant(status);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeMikrotikStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`change/mikrotik/status/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.getResults();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    sync_all(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to Sync All!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.get(`add-to-radius/${id}?type=sync_all`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    CheckIpPool(id) {
      this.ip_pool.mikrotik_id = id;
      this.axios.get(`add-to-radius/${id}?type=1`).then((resp) => {
        if (resp.data.success) {
          this.$bvModal.show("sync_pool_modal");
          this.ip_pool_data = [];
          for (let i = 0; i < resp.data.data.length; i++) {
            console.log(resp.data.data[i]);
            this.ip_pool_data.push({
              name: resp.data.data[i].name,
              ranges: resp.data.data[i].ranges,
            });
            this.ip_pool.start_ip.push(
              this.spletData(resp.data.data[i].ranges, "-", "/")[0]
            );
            this.ip_pool.end_ip.push(
              this.spletData(resp.data.data[i].ranges, "-", "/")[1]
            );
            this.ip_pool.name.push(resp.data.data[i].name);
            this.ip_pool.submet_mask.push();
            this.ip_pool.number_of_ip.push();
          }
        } else {
          if (resp.data.code == 422) {
            this.$error_message(resp.data.message, resp.data.errors);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        }
      });
    },
    save_ip_pool() {
      if (
        this.ip_pool_data.length !== this.ip_pool.start_ip.length &&
        this.ip_pool_data.length !== this.ip_pool.end_ip.length &&
        this.ip_pool_data.length !== this.ip_pool.number_of_ip.length
      ) {
        console.log("this.ip_pool_data", this.ip_pool_data);
        console.log(
          "this.ip_pool.start_ip.length &&",
          this.ip_pool.start_ip.length
        );
        console.log(
          "this.ip_pool.end_ip.length &&",
          this.ip_pool.end_ip.length
        );
        console.log(
          "this.ip_pool.number_of_ip.length",
          this.ip_pool.number_of_ip.length
        );
        this.$error_message(
          "All Start IP, End IP & Total Number of IP are Required"
        );
        return false;
      }
      this.axios.post(`save-ip-pool`, this.ip_pool).then((resp) => {
        if (resp.data.success) {
          this.$bvModal.hide("sync_pool_modal");
          this.$s_tost(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
  },
};
</script>
