<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total"
          statistic-title="Total IP Pool"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Active"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_inactive"
          statistic-title="In Active"
        />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>
      <b-modal
        id="modal-lg"
        :title="`${modal_title} Ethernet`"
        size="md"
        hide-header-close
        hide-footer
      >
        <form action="">
          <div class="form-group">
            <label for="">MikroTik <sup class="text-danger">*</sup></label>
            <select
              v-if="mikrotiks"
              v-model="form.mikrotik"
              class="form-control"
            >
              <option value="">---------Please Select One -------</option>
              <option
                v-for="(mikrotik, midx) in mikrotiks"
                :key="midx"
                :value="mikrotik.id"
              >
                {{ mikrotik.identity + "-" + mikrotik.host }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Name"
              id="name"
            />
          </div>
          <div class="form-group">
            <label for="dst">Arp</label>
            <select v-model="form.arp" name="" id="" class="form-control">
              <option value="">---Select---</option>
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
              <option value="local-proxy-arp">local-proxy-arp</option>
              <option value="proxy-arp">Proxy-arp</option>
              <option value="reply-only">Reply-only</option>
            </select>
          </div>

          <div class="form-group">
            <label for="mac_address">Mac Address</label>
            <input
              disabled
              v-model="form.mac_address"
              type="text"
              class="form-control"
              placeholder="Mac Address"
              id="mac_address"
            />
          </div>
          <div class="form-group">
            <label for="mtu">MTU</label>
            <input
              v-model="form.mtu"
              type="text"
              class="form-control"
              placeholder="MTU"
              id="mtu"
            />
          </div>
          <div class="form-group">
            <label for="dst">Status</label>
            <select v-model="form.status" name="" id="" class="form-control">
              <option value="">---Select---</option>
              <option value="false">Enabled</option>
              <option value="true">Disabled</option>
            </select>
          </div>
          <div class="form-group">
            <label for="comment">Comment</label>
            <input
              v-model="form.comment"
              type="text"
              class="form-control"
              placeholder="Comment"
              id="comment"
            />
          </div>
        </form>
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="submitHandelar()" class="btn btn-primary">Save</button>
      </b-modal>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(mikrotik_id)="data">
          {{ data.item.mikrotik.identity }} ||
          {{ data.item.mikrotik.host }}
        </template>
        <template v-if="permission.status" #cell(status)="data">
          <b-form-checkbox
            v-model="data.item.status == 'false'"
            name="check-button"
            switch
            @change="changeStatus(data.item.id)"
          ></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item v-if="permission.edit">
              <button class="btn btn-sm p-0" @click.prevent="edit(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.delete"
              @click.prevent="deleteData(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalNoOfRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "Ethernet",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        name: "",
        mikrotik: "",
        id_in_mkt: "",
        arp: "",
        mac_address: "",
        mtu: "",
        status: "",
        comment: "",
        item_id: "",
      }),
      title: "Ethernet",
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "mikrotik_id", label: "mikrotik", sortable: true },
        { key: "arp", label: "arp", sortable: true },
        { key: "mac_address", label: "mac_address", sortable: true },
        { key: "mtu", label: "mtu" },
        { key: "comment", label: "comment" },
        { key: "status", label: "status" },
        "action",
      ],
      items: [],
      queue_types: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      modal_title: "Add",
      pageOptions: [3, 5, 10],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      summary: {
        total: 0,
        total_active: 0,
        total_inactive: 0,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("interface_ethernet");
      this.permission.edit = this.checkpermission("interface_ethernet_edit");
      this.permission.delete = this.checkpermission(
        "interface_ethernet_delete"
      );
      this.permission.status = this.checkpermission(
        "interface_ethernet_change_status"
      );
    },
    mikrotiks() {
      return this.$store.getters.getallmikrotiks;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`ethernet/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(data) {
      this.$store.dispatch("getallmikrotiks");
      this.modal_title = "Update";
      this.form.name = data.name;
      this.form.arp = data.arp;
      this.form.mac_address = data.mac_address;
      this.form.mtu = data.mtu;
      this.form.comment = data.comment;
      this.form.mikrotik = data.mikrotik_id;
      this.form.id_in_mkt = data.id_in_mkt;
      this.form.status = data.status;
      this.editData.id = data.id;
      this.editData.mode = true;
      this.$bvModal.show("modal-lg");
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`ethernet?page=${page}`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data.data.data;
          this.summary.total = resp.data.data.data.total;
          this.summary.total_active = resp.data.data.active;
          this.summary.total_inactive = resp.data.data.inactive;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`ethernet/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$success_message(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    deleteData(id) {
      // const options = {
      //   "Delete Mikrotik And Database Both":
      //     "Delete Mikrotik and Database Both",
      //   "Delete Only Mikrotik": "Delete Only Mikrotik",
      //   "Delete Only Database": "Delete Only Database",
      // };
      // this.Swal.fire({
      //   title: "Please Select One",
      //   input: "select",
      //   inputOptions: options,
      //   showCancelButton: true,
      // }).then((result) => {
      //   if (result.value) {
      axios
        .delete(`ethernet/${id}`, {
          params: {
            actions: result.value,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            this.getData();
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
      //   }
      // });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
