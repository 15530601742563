<template>
	<div>
		<h5 class="text-center">OLT Info ({{this.type}})</h5>
		<DynamicForm
			:no_submit="true"
			:form="form[type]"
			type="create"
			:fields="dynamicFormFields"
		/>
	</div>
</template>

<script>
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";
export default {
	name: "ConnectorOlt",
	components: { DynamicForm },
	props: {
		"type" : String,
		"id" : Number,
		"form" : Object
	},
	data() {
	  return {
	        
			booleanOptions: [{
				'title': 'Yes',
				'value': 1
			}, {
				'title': 'No',
				'value': 0
			}],
			statusOptions: [{
				'title': 'Active',
				'value': 1
			}, {
				'title': 'Inactive',
				'value': 0
			}],
			olts: [],
	  }
	},
	computed: {
		dynamicFormFields(){
		  	const formFields = [
				{
					'type': 'select',
					'name': 'olt_id',
					'label': 'Olt',
					'options': this.olts,
					'option_settings': {
						'title': 'name',
						'value': 'id',
					},
					'disabled': true
				},
				{
					'type': 'number',
					'name': 'port_number',
					'label': 'Port Number',
				},
				{
					'type': 'select',
					'name': 'is_uplink',
					'label': 'Is Uplink',
					'options': this.booleanOptions,
					'disabled': true
				},
				{
					'type': 'number',
					'name': 'meter_marking',
					'label': 'Meter Marking',
					'column_class': 'col-md-6'
				},
				{
					'type': 'text',
					'name': 'dbm',
					'label': 'dBM',
					'column_class': 'col-md-6'
				},
				{
					'type': 'textarea',
					'name': 'comment',
					'label': 'Comment',
				},
				{
					'type': 'select',
					'name': 'status',
					'label': 'Status',
					'options': this.statusOptions	
				},
			]

		return formFields
	  }
	},
	mounted(){
		this.form[this.type] = {
	        olt_id: this.id,
	        port_number: null,
	        is_uplink: 0,
	        meter_marking: null,
	        dbm: null,
	        comment: null,
	        status:1
	    }

	    this.form[this.type][`connected_${this.type}_type`] = 'olts'

		if(this.type == 'from'){
			this.form[this.type].is_uplink = 0
		} else {
			this.form[this.type].is_uplink = 1
		}
		this.getOlts()
	},
	methods: {
		
		getOlts() {
		  axios.get(`nm-olts?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.olts = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
	}
}
</script>