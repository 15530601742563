<template>
  <div>
    <div>

      <b-modal
      	id="purchase_form"
      	size="xl"
      	:title="`${formMode == 'create'?'Create':'Update'} Inventory Purchase`"
      	hide-footer
      	no-close-on-backdrop
      >
		  <section class="invoice-add-wrapper">
		    <b-row class="invoice-add">

		      <!-- Col: Left (Invoice Container) -->
		      <b-col
		        cols="12"
		      >
		        <b-form @submit.prevent>
		          <b-card
		            no-body
		            class="invoice-preview-card"
		          >

		            <!-- Invoice Client & Payment Details -->
		            <b-card-body
		              class="invoice-padding pb-0"
		            >
		              <b-row>
		                <b-col
		                  v-if="form.id"
		                  cols="12"
		                  class="mb-lg-1"
		                >
		                	<b-input-group class="input-group-merge invoice-edit-input-group disabled">
		                	  <b-input-group-prepend is-text>
		                	    <feather-icon icon="HashIcon" />
		                	  </b-input-group-prepend>
		                	  <b-form-input
		                	    id="invoice-data-id"
		                	    v-model="form.id"
		                	    disabled
		                	  />
		                	</b-input-group>
		                </b-col>
		                <b-col
		                  cols="12"
		                  lg="6"
		                  class="mb-lg-1"
		                >
		                  <h6 class="mb-2">
		                    Supplier:
		                  </h6>

		                  <!-- Select Client -->
		                  <v-select
		                    v-model="form.supplier_id"
		                    label="name"
                        :options="formItems.suppliers"
                        :reduce="name => name.id"

		                    :clearable="false"
		                  >
		                  </v-select>
		                </b-col>
                    <b-col
                        cols="12"
                        lg="3"
                        class="mb-lg-1"
                    >
                      <h6 class="mb-2">
                        Purchase Ref. No.:
                      </h6>

                      <!-- Select Client -->
                      <b-form-input
                          v-model="form.ref_no"
                          type="text"
                          readonly
                      >
                      </b-form-input>
                    </b-col>
		                <b-col
		                	cols="12"
		                	lg="3">

    	                  <!-- Selected Client -->
    	                  <div
    	                    v-if="selectedSupplier"
    	                    class="mt-1 text-right"
    	                  >
    	                    <h6 class="mb-25">
    	                      {{ selectedSupplier.name }}
    	                    </h6>
    	                    <b-card-text class="mb-25">
    	                      {{ selectedSupplier.email_address }}
    	                    </b-card-text>
    	                    <b-card-text class="mb-0">
    				            <div
    				              class="text-nowrap"
    				              v-for="( number , index) in selectedSupplier.mobile_number"
    				            >
    				              {{ selectedSupplier.mobile_number_prefix +' '+ number}}
    				              <span
    				                class="text-primary"
    				                v-if="index == 0"
    				                >
    				                (P)
    				              </span>
    				            </div>
    	                    </b-card-text>
    	                  </div>
		                </b-col>
		              </b-row>
		            </b-card-body>

		            <!-- Header -->
		            <b-card-body class="invoice-padding pb-0">

		              	<b-row>
			                <b-col
			                  cols="12"
			                  md="4"
			                  class="mb-lg-1"
			                >
			                  <h6 class="mb-2">
			                    Purchase Date:
			                  </h6>

			                  <flat-pickr
			                    v-model="form.purchased_at"
			                    class="form-control"
			                  />
			                </b-col>
			                <b-col
			                  cols="12"
			                  md="4"
			                  class="mb-lg-1"
			                >
			                  	<h6 class="mb-2">
		  	                      Supplier Invoice No:
			                  	</h6>

		  	                    <b-form-input
		  	                    	v-model="form.supplier_invoice_no"
		                            type="text"
		                        />
			                </b-col>
			                <b-col
			                  cols="12"
			                  md="4"
			                  class="mb-lg-1"
			                >
			                  <h6 class="mb-2">
			                    Supplier Invoice  Date:
			                  </h6>

			                  <flat-pickr
			                    v-model="form.supplier_invoice_date"
			                    class="form-control"
			                  />
			                </b-col>
			            </b-row>
		            </b-card-body>

		            <!-- Spacer -->
		            <hr class="invoice-spacing">

		            <!-- Items Section -->
		            <b-card-body class="invoice-padding">
		              <div
		                ref="form"
		                class="repeater-form"

		              >
		                <b-row
		                  v-for="(item, index) in form.items"
		                  :key="index"
		                  ref="row"
		                  class="pb-2"
		                >

		                  <!-- Item Form -->
		                  <!-- ? This will be in loop => So consider below markup for single item -->
		                  <b-col cols="12">

		                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
		                    <div class="d-none d-lg-flex">
		                      <b-row class="flex-grow-1 px-1">
		                        <!-- Single Item Form Headers -->
		                        <b-col
		                          cols="12"
		                          lg="4"
		                        >

			                        <small
			                          v-if="formMode == 'update' && typeof item.id == 'undefined'"
			                          class="text-warning"
			                        >
			                          (Not Saved)
			                        </small>
		                         	Product
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          Unit Price
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          Qty
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          Unit
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          Price
		                        </b-col>
		                      </b-row>
		                      <div class="form-item-action-col"  style="min-width: 50px" />
		                    </div>

		                    <!-- Form Input Fields OR content inside bordered area  -->
		                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
		                    <div
		                    	class="d-flex border rounded"
		                  		:class="formMode == 'update' && typeof item.id == 'undefined'?'bg-light-warning':''"
		                    >
		                      <b-row
		                      	class="flex-grow-1 p-2"
                            :id="item.product_id"
		                      >
		                        <!-- Single Item Form Headers -->
		                        <b-col
		                          cols="12"
		                          lg="4"
		                        >
		                          <label class="d-inline d-lg-none">Product</label>

                                   <v-select
	                  					v-model="item.product_id"
	                  					label="name"
	                  					:options="formItems.products"
            							    :selectable="option => !formSelectedProduct.includes(option.id)"
	                  					:reduce="name => name.id"
	                  					placeholder="Select Product"
                              class="mb-2 mb-md-0 item-selector-title"
                            		>
	                  		        </v-select>
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          <label class="d-inline d-lg-none">Unit Price</label>
		                          <b-form-input
		                            v-model="item.unit_price"
		                            type="number"
		                            class="mb-2 mb-md-0"

		                          />
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          <label class="d-inline d-lg-none">Qty</label>

		                          <b-form-input
                                  v-if="formMode == 'create'"
		                            v-model="item.quantity"
		                            type="number"
		                            class="mb-2 mb-md-0"
                                @keyup="showMacAddressInput(item)"
		                          />

                              <b-form-input
                                  v-if="formMode == 'update'"
                                  v-model="item.quantity"
                                  type="number"
                                  class="mb-2 mb-md-0"
                                  @keyup="updateMacAddressInput(item)"
                              />
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          <label class="d-inline d-lg-none">Unit</label>
                                     <v-select
  	                  					v-model="item.unit_id"
  	                  					label="name"
  	                  					:options="formItems.units"
  	                  					:reduce="name => name.id"
  	                  					placeholder="Select Unit"
                            			class="mb-2 mb-md-0 item-selector-title"
  	                  		        />
		                        </b-col>
		                        <b-col
		                          cols="12"
		                          lg="2"
		                        >
		                          <label class="d-inline d-lg-none">Price</label>
		                          <p class="mb-1">
		                            ৳{{ (item.unit_price * item.quantity).toLocaleString("en-US") }}
		                          </p>
		                        </b-col>
                            <div :id="'mac_'+item.product_id"></div>
                            <b-col
                                cols="12"
                                lg="12"
                                class="mt-2"
                            >
<!--                              <div>Enter Mac Address(  Seperate Product Mac Address with '  ,  '  )</div>-->
<!--                              <b-form-textarea-->
<!--                                  v-model="item.mac_address"-->
<!--                                  type="text"-->
<!--                                  class="mb-2 mb-md-0"-->
<!--                              />-->
                                  <div v-if="formMode == 'update'">
                                    <div :id="'mac'+mac.id" class="d-flex" v-for="(mac, index) in form.mac_address" :key="index">
                                      <input class="form-control"  v-model="mac.mac_address">
                                      <button>
                                        <feather-icon @click="removeMacAddress(mac)" size="16" icon="XIcon" class="cursor-pointer" />
                                      </button>
                                    </div>
                                    <div :id="'update_mac_'+item.product_id"></div>
                                  </div>

                            </b-col>
		                      </b-row>
		                      <div class="border-left d-flex flex-column justify-content-around align-items-center px-25 py-50" style="min-width: 50px">
		                        <feather-icon
		                          v-if="formMode == 'update' && typeof item.id == 'undefined'"
		                          size="16"
		                          icon="SaveIcon"
		                          class="cursor-pointer"
		                          @click="createPurchaseFormItem(item, index)"
		                        />
		                        <feather-icon
		                          size="16"
		                          icon="XIcon"
		                          class="cursor-pointer"
		                          @click="removePurchaseFormItem(item, index)"
		                        />
		                      </div>
		                    </div>
		                  </b-col>
		                </b-row>
		              </div>
		              <b-button
		                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
		                size="sm"
		                variant="primary"
		                @click="addNewItemInPurchaseForm"
		              >
		                Add Item
		              </b-button>
		            </b-card-body>

		            <!-- Spacer -->
		            <hr class="invoice-spacing">

		            <!-- Invoice Description: Total -->
		            <b-card-body class="invoice-padding pb-0">
		              <b-row>

		                <!-- Col: Total -->
		                <b-col
		                  cols="12"
		                  class="mt-md-6 d-flex justify-content-end"
		                  order="1"
		                  order-md="2"
		                >
		                  <div class="invoice-total-wrapper">
		                    <div class="invoice-total-item">
		                      <p class="invoice-total-title">
		                        Subtotal:
		                      </p>
		                      <p class="invoice-total-amount">
		                        ৳{{ formSubtotal.toLocaleString("en-US") }}
		                      </p>
		                    </div>
		                    <div class="invoice-total-item">
		                      <p class="invoice-total-title">
		                        Discount:
		                      </p>
		  	                    <b-form-input
		  	                    	v-model="form.discount"
		                            type="text"
		                            class="form-control h-25 w-50 invoice-total-amount text-right"
		                        />
		                    </div>
		                    <hr class="my-50">
		                    <div class="invoice-total-item">
		                      <p class="invoice-total-title">
		                        Total:
		                      </p>
		                      <p class="invoice-total-amount">
		                        ৳{{ formTotal.toLocaleString("en-US") }}
		                      </p>
		                    </div>
		                    <hr class="my-50">
		                    <div class="invoice-total-item">
		                      <p class="invoice-total-title">
		                        Paid:
		                      </p>
		  	                    <b-form-input
		  	                    	v-if="formMode == 'create'"
		  	                    	v-model="form.paid_amount"
		                            type="text"
		                            class="form-control h-25 w-50 invoice-total-amount text-right"
		                        />

			                    <p
			                    	v-else
			                    	class="invoice-total-amount"
			                    >
			                        ৳{{ form.paid_amount.toLocaleString("en-US") }}
			                    </p>
		                    </div>
		                    <hr class="my-50">
		                    <div class="invoice-total-item">
		                      <p class="invoice-total-title">
		                        Due:
		                      </p>

		  	                   <p class="invoice-total-amount">
		  	                     ৳{{ formDue.toLocaleString("en-US") }}
		  	                   </p>
		                    </div>
		                  </div>
		                </b-col>
		              </b-row>
		            </b-card-body>

		            <!-- Spacer -->
		            <hr class="invoice-spacing">


					<b-button
						v-if="formMode == 'create'"
						v-ripple.400="'rgba(113, 102, 240, 0.15)'"
						variant="outline-primary"
						block
						@click="createPurchase"
						:disabled="form.busy"
					>
               			{{form.busy?'Saving ...':'Save'}}
					</b-button>

					<b-button
						v-else
						v-ripple.400="'rgba(113, 102, 240, 0.15)'"
						variant="outline-primary"
						block
						@click="updatePurchase"
						:disabled="form.busy"
					>
               			{{form.busy?'Updating ...':'Update'}}
					</b-button>
		          </b-card>
		        </b-form>
		      </b-col>
		    </b-row>
		  </section>
      </b-modal>




 	  <b-modal
      	id="purchase_payment_form"
      	size="lg"
      	:title="`Payment`"
      	hide-footer
      	no-close-on-backdrop
      >
      	<PaymentList
      		:purchase="activePurchase"
      		@updatePurchaseInfoFromChild="updatePurchaseInfoFromChild"
      	></PaymentList>
  	  </b-modal>


      <b-card no-body>
        <b-card-body>
          <b-row>
          		<b-col
          			cols="12"
          			lg="3"
          			md="6"
          			class="mb-1"
          		>
	          		<v-select
		                v-model="filterData.supplier_id"
		                label="name"
						:options="formItems.suppliers"
						:reduce="name => name.id"
		                :clearable="false"
		                placeholder="Select Supplier"
		                @input="filterChange"
		            >
		            </v-select>
          		</b-col>
          		<b-col
          			cols="12"
          			lg="3"
          			md="6"
          			class="mb-1"
          		>
	          		<v-select
		                v-model="filterData.product_id"
		                label="name"
						:options="formItems.products"
						:reduce="name => name.id"
		                :clearable="false"
		                placeholder="Select Product"
		                @input="filterChange"
		            >
		            </v-select>
          		</b-col>
          		<b-col
          			cols="12"
          			lg="3"
          			md="6"
          			class="mb-1"
          		>
	          		<v-select
		                v-model="filterData.brand_id"
		                label="name"
						:options="formItems.brands"
						:reduce="name => name.id"
		                :clearable="false"
		                placeholder="Select Brand"
		                @input="filterChange"
		            >
		            </v-select>
          		</b-col>
          		<b-col
          			cols="12"
          			lg="3"
          			md="6"
          			class="mb-1"
          		>
	          		<flat-pickr
	                    v-model="filterData.purchased_at"
	                    class="form-control"
	                    :config="{
	                    	mode:'range'
	                    }"
	                    placeholder="Purchase Date"
	                    @on-close="filterChange"
                	/>
          		</b-col>
          </b-row>

          <hr class="my-2">

          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex">
              <b-button size="sm" col="2" v-b-modal.modal-lg variant="primary" @click="openCreateModal">
                <!-- @click.prevent="editData.mode = false" -->
                <feather-icon icon="PlusCircleIcon" size="16" />
                Add New</b-button>
              <!-- sorting  -->
              <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="3" label-for="sortBySelect"
                class="mb-0 ml-3">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" label-cols-sm="4" :disabled="!sortBy">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group label="Filter" label-cols-sm="3" label-align-sm="left" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          ref="table"
          :per-page="perPage"

          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(supplier)="{item, index}">
          	<router-link :to="{ name: 'inventory_supplier_detail', params: { id: item.supplier_id } }">
	          	{{item.supplier ? item.supplier.name : ''}}
	          </router-link>
          </template>

          <template #cell(purchase_ref)="{item, index}">
            {{item.purchase_ref_no}}
          </template>

          <template #cell(status)="{ item, index }">
            <b-badge pill :variant="`light-${item.payment_stat.status == 'paid'
              ? 'success'
              : item.payment_stat.status == 'over-paid'
                ? 'warning'
                : 'danger'
              }`" class="text-capitalize">
              {{ item.payment_stat.status.replaceAll("-", " ") }}
            </b-badge>
          </template>

          <template #cell(total_amount)="{ item, index }">
            ৳{{ item.payment_stat.total_amount.toLocaleString("en-US") }}
          </template>
          <template #cell(discount)="{ item, index }">
            ৳{{ item.payment_stat.discount.toLocaleString("en-US") }}
          </template>

          <template #cell(due)="{ item, index }">
            ৳{{ item.payment_stat.due_amount.toLocaleString("en-US") }}
          </template>

          <template #cell(paid)="{ item, index }">
            ৳{{ item.payment_stat.total_paid_amount.toLocaleString("en-US") }}
          </template>

          <template #cell(action)="{ item, index }">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item @click.prevent="editPurchase(item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="openPayment(item, index)">
                <feather-icon icon="DollarSignIcon" class="mr-50" />
                Payment
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deletePurchase(item, index)">
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination v-model="currentPage" :total-rows="totalNoOfRows" :per-page="perPage" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import PaymentList from "./payments/PaymentList.vue";
// import BCard from "@core/components/b-card/BCard.vue";
import { VBToggle } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "Purchases",
  components: { StatisticCardHorizontal, flatPickr, Ripple, PaymentList },

  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      title: "Inventory Purchases",
      emptyFormItem: {
        product_id: null,
        unit_id: null,
        quantity: 1,
        unit_price: 0,
        mac_address: [],
	    },
      form: new Form({
        purchased_at: "",
        supplier_invoice_no: "",
        supplier_invoice_date: "",
        supplier_id: null,
        discount: 0,
        paid_amount: 0,
        ref_no: "",
        items: [],
        mac_address: []
      }),
      formItems: {
      	products: [],
      	units: [],
      	suppliers: [],
      	brands: [],
      },
      activePurchase: {},
      fields: [

        { key: "id", label: "#", sortable: true },
        { key: "supplier", label: "Supplier", sortable: true },
        { key: "purchase_ref", label: "Purchase Ref No.", sortable: true },
        { key: "purchased_at_formatted", label: "Purchase Date", sortable: true },
        { key: "total_amount", label: "Total Amount", sortable: true },
        { key: "discount", label: "Discount", sortable: true },
        { key: "paid", label: "Paid", sortable: true },
        { key: "due", label: "Due", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      formMode: 'create',
      product: "",
      filterData: {
        supplier_id: null,
        product_id: null,
        brand_id: null,
        purchased_at: null,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    selectedSupplier() {
      let supplier = null;
      if (
        typeof this.form.supplier_id != "undefined" &&
        this.form.supplier_id != null
      ) {
        supplier = this.formItems.suppliers.find(
          (sup) => sup.id == this.form.supplier_id
        );
      }
      return supplier;
    },
    formSubtotal() {
      let subtotal = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        subtotal += this.form.items[i].quantity * this.form.items[i].unit_price;
      }
      return subtotal;
    },
    formTotal() {
      return this.formSubtotal - this.form.discount;
    },
    formDue() {
      return this.formTotal - this.form.paid_amount;
    },
    formSelectedProduct() {
      let product_ids = [];
      for (let i = 0; i < this.form.items.length; i++) {
        product_ids.push(this.form.items[i].product_id);
      }
      return product_ids;
    },
    filterQuery() {
      let filterQuery = "";

      if (this.filterData.product_id) {
        filterQuery += `product_id=${this.filterData.product_id}&`;
      }
      if (this.filterData.supplier_id) {
        filterQuery += `supplier_id=${this.filterData.supplier_id}&`;
      }
      if (this.filterData.brand_id) {
        filterQuery += `brand_id=${this.filterData.brand_id}&`;
      }
      if (this.filterData.purchased_at) {
        filterQuery += `purchased_at=${this.filterData.purchased_at}`;
      }

      return filterQuery;
    },
  },

  created() {
    this.getData();
    this.getFormItems();
  },
  watch: {
    currentPage: {
      handler: function (newVal, oldVal) {
        this.getData(newVal); // call it in the context of your component object
      },
      deep: true,
    },
  },
  methods: {
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`inventory-purchases?page=${page}&item=${this.perPage}&${this.filterQuery}`).then((resp) => {
        if (resp.data.success) {
          this.totalNoOfRows = resp.data.data.total
          this.items = resp.data.data.data;
          console.log(resp.data.data)

        } else {
          this.$error_message(resp.data.message);
        }
      }).finally(()=> {
        this.$store.dispatch("spinnerLoading", false);
      });
    },

    createPurchase() {
      console.log(this.form);
      this.form.post("inventory-purchases").then((resp) => {

        if (resp.data.success) {
          let item = resp.data.data
          console.log(item);
          this.items = [ ...this.items, item ]

          this.$success_message(resp.data.message);
          this.form.reset();
          this.$bvModal.hide("purchase_form");
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    openCreateModal(){
    	this.form.reset()
      this.form.ref_no = Math.floor(Date.now() / 1000);
        this.formMode = 'create'
    	this.form.items.push(JSON.parse(JSON.stringify(this.emptyFormItem)))
        this.$bvModal.show('purchase_form')
    },
    editPurchase(item){
      this.form.reset()
      this.formMode = 'update'
      this.form.id = item.id
      this.form.supplier_id = item.supplier_id
      this.form.purchased_at = item.purchased_at
      this.form.supplier_invoice_no = item.supplier_invoice_no

      this.form.supplier_invoice_date = item.supplier_invoice_date
      this.form.discount = item.discount
      this.form.ref_no = item.purchase_ref_no
      this.form.paid_amount = item.payment_stat.total_paid_amount
      this.form.items = item.items
      this.form.mac_address = item.mac_addresses
      let mac_addresses = []
      item.items.forEach((element) => {
        element.mac_address = []
      })
      this.$bvModal.show('purchase_form')
    },
    updatePurchase() {
      if (
        this.form.items.filter((item) => typeof item.id == "undefined").length >
        0
      ) {
      } else {
        this.submitUpdateForm();
      }
    },

    submitUpdateForm() {
      this.form.put(`inventory-purchases/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.items.every((item, index) => {
            if (item.id == updatedItem.id) {
              this.$set(this.items, index, updatedItem);
              return false;
            }
            return true;
          });
          this.form.reset();
          this.$success_message(resp.data.message);
          this.$bvModal.hide("purchase_form");
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    deletePurchase(item, index) {
      if (item && confirm("Are you sure to delete?")) {
        this.$store.dispatch("spinnerLoading", true);
        axios
          .delete(`inventory-purchases/${item.id}`)
          .then((resp) => {
            if (resp.data.success) {
              this.items.splice(index, 1);
              this.$success_message(resp.data.message);
            } else {
              this.$error_message(resp.data.message);
            }
          })
          .finally(() => {
            this.$store.dispatch("spinnerLoading", false);
          });
      }
    },

    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },

    getFormItems() {
      this.getProducts();
      this.getUnits();
      this.getSuppliers();
      this.getBrands();
    },

    // for form options
    getProducts() {
      axios.get(`inventory-products?item=all`).then((resp) => {
        if (resp.data.success) {
          this.formItems.products = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getUnits() {
      axios.get(`inventory-units?item=all&status=active`).then((resp) => {
        if (resp.data.success) {
          this.formItems.units = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getSuppliers() {
      axios.get(`inventory-suppliers?item=all&status=active`).then((resp) => {
        if (resp.data.success) {
          this.formItems.suppliers = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getBrands() {
      axios.get(`inventory-brands?item=all&status=active`).then((resp) => {
        if (resp.data.success) {
          this.formItems.brands = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    createPurchaseFormItem(item, index){

    	axios.post(`inventory-purchase-items`, {
    		...item,
    		purchase_id: this.form.id
    	}).then((resp) => {
	      if (resp.data.success) {
          	let updatedItem = resp.data.data;
            console.log(resp.data.data);
    		this.$set(this.form.items, index, updatedItem)
	        this.$success_message(resp.data.message);
	      } else {
	        this.$error_message(resp.data.message, resp.data.errors);
	      }
	    }).finally(()=> {

	    });
    },
    removePurchaseFormItem(item, index){
    	if(typeof item.id == 'undefined'){
    		this.form.items.splice(index, 1)
    	} else {
    		if(item && confirm('Are you sure to delete?')){
		        axios.delete(`inventory-purchase-items/${item.id}`).then((resp) => {
		          if (resp.data.success) {
		            this.form.items.splice(index, 1)
		            this.$success_message(resp.data.message);
		          } else {
		            this.$error_message(resp.data.message);
		          }
		        }).finally(()=> {

		        });
		    }
    	}
    },
    addNewItemInPurchaseForm() {
      this.form.items.push(JSON.parse(JSON.stringify(this.emptyFormItem)));
    },
    openPayment(item, index) {
      this.activePurchase = item;
      this.$bvModal.show("purchase_payment_form");
      console.log(item);
    },
    updatePurchaseInfoFromChild(updatedItem) {
      this.items.every((item, index) => {
        if (item.id == updatedItem.id) {
          this.$set(this.items, index, updatedItem);
          return false;
        }
        return true;
      });
    },
    filterChange() {
      setTimeout(() => {
        this.getData();
      }, 100);
    },
    filterChange(){
    	setTimeout(()=>{
    		this.getData()
    	}, 100)
    },
    pushValueToMacAddress(event, item) {
      console.log(item)
      item.mac_address.push(event.target.value)
    },
    showMacAddressInput(qty){
      axios.get(`inventory-products?item=all`).then((resp) => {
        if (resp.data.success) {
          let all_products = resp.data.data.data;
          for(let j=0; j<all_products.length; j++){
            if(all_products[j].id == qty.product_id){
              this.product = all_products[j].category.mac_address;
              if(this.product == 1){
                let div = document.getElementById(qty.product_id);
                let mac_div = document.getElementById(`mac_${qty.product_id}`);
                console.log('mac_div')
                mac_div.innerHTML = '';
                let new_div = document.createElement('div');
                // new_div.setAttribute('id', `new_div_${qty.product_id}`);
                // let select_div = document.getElementById(`new_div_${qty.product_id}`);
                for(let i=1; i<=qty.quantity; i++){
                  let new_col = document.createElement('b-col');
                  new_col.setAttribute('cols', 12);
                  new_col.setAttribute('lg', 2);
                  new_col.setAttribute('class', 'form-group');
                  let new_label = document.createElement('label');
                  // new_label.setAttribute('class', 'd-inline d-lg-none');
                  new_label.innerHTML = `MAC Address For Product No. ${i}`;
                  // console.log(new_label);
                  let new_input = document.createElement('input');
                  new_input.setAttribute('type', 'text');
                  new_input.setAttribute('name', `mac_address_${i}_product_${j}`);
                  new_input.setAttribute('class', 'mb-2 mb-md-0 form-control');
                  // new_input.setAttribute('v-on:blur', 'pushValueToMacAddress($event, item)');
                  new_input.addEventListener('blur', function(event) {
                    if(event.target.value != ''){
                      if(qty.mac_address.length > 0){
                        for (let i = 0; i < qty.mac_address.length; i++) {
                          let value = qty.mac_address[i];
                          let objKeyName = Object.keys(value)[0];
                          if (event.target.name == objKeyName) {
                            qty.mac_address[i][objKeyName] = event.target.value;
                            return;
                          }
                        };
                      }
                      let obj = {
                        [new_input.name] : event.target.value
                      }
                      qty.mac_address.push(obj)
                    }
                  });

                  new_col.appendChild(new_label);
                  new_col.appendChild(new_input);
                  // new_div.appendChild(new_col);
                  mac_div.appendChild(new_col);
                  // mac_div.setAttribute('class', 'd-flex');
                  div.appendChild(mac_div);
                }
              }
            }
          }
        } else {
          this.$error_message(resp.data.message);
        }
      });



    },

    updateMacAddressInput(item){
      let mac_address_count = this.form.mac_address.length;
      let new_fields = item.quantity - mac_address_count;
      let div = document.getElementById(item.product_id);
      let mac_div = document.getElementById(`mac_${item.product_id}`);
      mac_div.innerHTML = '';
      for(let i=1; i<=new_fields; i++){
        let new_col = document.createElement('b-col');
        new_col.setAttribute('cols', 12);
        new_col.setAttribute('lg', 2);
        new_col.setAttribute('class', 'form-group');
        let new_label = document.createElement('label');
        // new_label.setAttribute('class', 'd-inline d-lg-none');
        new_label.innerHTML = `MAC Address For Product No. ${i}`;
        // console.log(new_label);
        let new_input = document.createElement('input');
        new_input.setAttribute('type', 'text');
        new_input.setAttribute('name', `mac_address_${i}_product_${item.product_id}`);
        new_input.setAttribute('class', 'mb-2 mb-md-0 form-control');
        // new_input.setAttribute('v-on:blur', 'pushValueToMacAddress($event, item)');
        new_input.addEventListener('blur', function(event) {
          if(event.target.value != ''){
            if(item.mac_address.length > 0){
              for (let i = 0; i < item.mac_address.length; i++) {
                let value = item.mac_address[i];
                let objKeyName = Object.keys(value)[0];
                if (event.target.name == objKeyName) {
                  item.mac_address[i][objKeyName] = event.target.value;
                  return;
                }
              };
            }
            let obj = {
              [new_input.name] : event.target.value
            }
            item.mac_address.push(obj)
          }
        });

        new_col.appendChild(new_label);
        new_col.appendChild(new_input);
        // new_div.appendChild(new_col);
        mac_div.appendChild(new_col);
        // mac_div.setAttribute('class', 'd-flex');
        div.appendChild(mac_div);
      }
    },

    removeMacAddress(mac){
      axios.delete(`inventory-product-mac-address/${mac.id}`).then((resp) => {
        if(resp.data.success){
          document.getElementById(`mac${mac.id}`).classList.remove('d-flex')
          document.getElementById(`mac${mac.id}`).classList.add('d-none')
          this.$success_message(resp.data.message);
        }
        else{
          this.$error_message(resp.data.message);
        }
      })
    }


  },
};


</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
