/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <!-- v-if="permission.add" -->
        <div class="d-flex justify-content-between">
          <b-button
              v-b-modal.modal-lg
              variant="primary"
              size="sm"
          >
          <span>
            <feather-icon icon="PlusCircleIcon" size="16" />
            Add New
          </span>
          </b-button>

          <div class="d-flex justify-content-around">
            <button>Calendar</button>
            <button>Table</button>
          </div>
        </div>

      </div>

      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="md">
        <form @submit.prevent="submitHandelar">
          <div class="form-group">
            <label>Employee Name</label>
            <select
                class="form-control"
                v-model="form.employee_id"
            >
              <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{employee.name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Cause</label>
            <select v-model="form.cause" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="Sick">Sick</option>
              <option value="Casual">Casual</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">From</label>
            <input
                autofocus="autofocus"
                v-model="form.from"
                type="date"
                class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">To</label>
            <input
                autofocus="autofocus"
                v-model="form.to"
                type="date"
                class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">Duration</label>
            <input
                autofocus="autofocus"
                v-model="form.duration"
                type="number"
                class="form-control"
                placeholder="Duration"
            />
          </div>
          <div class="form-group">
            <label for="">Reason</label>
            <textarea
                autofocus="autofocus"
                v-model="form.reason"
                style="resize: none"
                class="form-control"
                cols="30"
                rows="10"
            >

            </textarea>
          </div>
          <div v-if="editData.mode == true" class="form-group">
            <div>Leave Status</div>
            <select  v-model="form.leave_status"   class="form-control">
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="Not Approved">Not Approved</option>
            </select>
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
        <template #modal-footer>
          <div class="btn btn-warning" @click="close" type="">Close</div>
        </template>
      </b-modal>
      <div class="col-lg-12">
        <div>
          <div>Filter</div>
          <div class="d-flex justify-content-between">
            <div class="form-group w-100 mr-2">
              <label for="">Name</label>
              <input
                  @keyup="filterData"
                  autofocus="autofocus"
                  type="text"
                  class="form-control"
                  v-model="filter_employee_name"
                  placeholder="Name"
              />
            </div>
          </div>
        </div>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Cause</th>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Duration</th>
            <th scope="col">Reason</th>
            <th scope="col">Leave Status</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(leave, index) in this.leaves" :key="index + 'leaves'">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ leave.employee.name }}</td>
            <td>{{ leave.leave_type }}</td>
            <td>{{ leave.from }}</td>
            <td>{{ leave.to }}</td>
            <td>{{ leave.duration }}</td>
            <td>{{ leave.reason }}</td>
            <td>{{ leave.leave_status }}</td>
            <td>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                    v-if="permission.edit"
                    @click.prevent="edit(leave.id)"
                >
                  <button class="btn btn-sm p-0">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    Edit
                  </button>
                </b-dropdown-item>
                <b-dropdown-item

                    :action="'leaveManagement/' + leave.id"
                    @click.prevent="$trash('leaveManagement/' + leave.id)"
                >
                  <button class="btn btn-sm p-0">
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    Delete
                  </button>
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

// let employeeAttendanceData = attendances;

export default {
  name: "role",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        employee_id: "",
        cause: "",
        duration: "",
        from: "",
        to: "",
        reason:"",
        leave_status:"",
      }),

      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      filter_employee_name: "",
      filter_attendance: "",
      search: "",
      attendance_from:"",
      attendance_to:"",
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        permssions: false,
      },

    };
  },
  computed: {
    employees() {
      return this.$store.state.hrm.employees.data;
    },

    leaves(){
      if(this.filter_employee_name == ""){
        return this.$store.state.hrm.leaves.data;
      }
      else{
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.leaves.data.length; i++){
          if(this.$store.state.hrm.leaves.data[i].employee.name.toLowerCase().includes(this.filter_employee_name.toLowerCase()) == true){
            filters.push(this.$store.state.hrm.leaves.data[i])
          }
        }
        return filters;
      }

    },

    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("LeaveManagement");
      this.permission.add = this.checkpermission("LeaveManagement Add");
      this.permission.edit = this.checkpermission("LeaveManagement Edit");
      this.permission.delete = this.checkpermission("LeaveManagement Delete");
    },
  },
  watch: {
    roles() {
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch("getEmployees");
    this.$store.dispatch("getLeaves");
  },
  methods: {

    filterData(){
      this.leaves
    },
    //= ===handle the form close =========
    close() {
      this.form.employee_id = "";
      this.form.cause = "";
      this.form.from = "";
      this.form.to = "";
      this.form.duration = "1";
      this.form.reason="";
      this.editData.mode = false;
      this.editData.id = 0;
      this.$bvModal.hide("modal-lg");
    },

    edit(id){
      const leave = this.leaves.find((ele) => ele.id == id);
      this.modal_title = 'Update Leave';
      this.form.employee_id = leave.employee_id;
      this.form.cause = leave.leave_type;
      this.form.from = leave.from;
      this.form.to = leave.to;
      this.form.duration = leave.duration;
      this.form.reason = leave.reason;
      this.form.leave_status = leave.leave_status;
      this.editData.mode = true;
      this.editData.id = leave.id;
      this.$bvModal.show("modal-lg");
    },

    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update(this.editData.id);
      }
    },

    update(id){
      this.form.put(`leaveManagement/${id}`)
          .then((resp) => {
            if(resp.data.success){
              this.close();
              this.$s_tost(resp.data.message);
              this.$store.dispatch("getLeaves");
            }
            else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },

    //= ========data save=========
    save() {
      this.form
          .post(`/leaveManagement`)
          .then((resp) => {
            if (resp.data.success) {
              this.close();
              this.$s_tost(resp.data.message);
              this.$store.dispatch("getLeaves");
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },

};

</script>


