<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total"
          statistic-title="Total Open"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="warning"
          :statistic="summary.total_active"
          statistic-title="Total Pending"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Total Complete"
        />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              size="sm"
              col="2"
              v-if="permission.add"
              v-b-modal.modal-lg
              variant="primary"
              @click="modelAction('add')"
            >
              <!-- @click.prevent="editData.mode = false" -->
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New
            </b-button>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>
      <b-modal
        id="modal-lg"
        :title="modal_title"
        size="lg"
        hide-header-close
        hide-footer
      >
        <form @submit.prevent="submitHandelar" class="row">
          <div class="col-sm-12 col-md-8">
            <div class="form-group">
              <label for="name">Name</label>
              <input
                v-model="form.name"
                type="text"
                name=""
                class="form-control"
                placeholder="Name"
                id="name"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label for="Priority">Priority</label>
              <select
                v-model="form.priority"
                id="Priority"
                class="form-control"
              >
                <option value="">Select</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>
          </div>
        </form>
        <button @click="close" class="btn btn-warning">Close</button>
        <button @click="submitHandelar" class="btn btn-primary">Save</button>
      </b-modal>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-if="permission.status" #cell(status)="status">
          <b-form-checkbox
            v-model="status.item.status == '0'"
            name="check-button"
            switch
            @change="changeStatus(status.item.id)"
          ></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item v-if="permission.edit">
              <button class="btn btn-sm p-0" @click.prevent="edit(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.delete"
              @click.prevent="deleteData(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <b-pagination-nav
          @input="getData"
          :link-gen="linkGen"
          :number-of-pages="items.last_page"
          :limit="perPage"
          use-router
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
      {{ getRoleWisePermissions }}
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "TicketCategory",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      title: "Ticket Category",
      form: new Form({
        name: "",
        priority: "",
      }),
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "priority", label: "priority", sortable: true },
        "Total Open",
        "Total Complete",
        "Total Pending",
        "status",
        "action",
      ],
      items: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      modal_title: "Ticket Category",
      summary: {
        total_user: 0,
        total_active: 0,
        total_inactive: 0,
      },
      lData: null,
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("ticket_category");
      this.permission.add = this.checkpermission("ticket_category_add");
      this.permission.edit = this.checkpermission("ticket_category_edit");
      this.permission.delete = this.checkpermission("ticket_category_delete");
      this.permission.status = this.checkpermission(
        "ticket_category_change_status"
      );
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-lg");
    },
    modelAction() {},
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("ticket-category")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
            this.$s_tost(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeStatus(id) {
      console.log(id);
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`ticket-category/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    //= ========get data for edit=========

    update() {
      this.form
        .put(`ticket-category/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            // this.$success_message(resp.data.message);
            this.$s_tost(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    async edit(data) {
      if (data) {
        this.form.name = data.name;
        this.form.priority = data.priority;
        this.modal_title = "Update";
        this.editData.mode = true;
        this.editData.id = data.id;
        this.$bvModal.show("modal-lg");
      } else {
        this.$error_message(resp.data.message);
      }
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },
    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`ticket-category?page=${this.currentPage}&item${this.perPage}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$store.dispatch("spinnerLoading", false);
            this.items = resp.data.data.data;
            this.summary.total = parseInt(resp.data.data.data.total);
            this.summary.total_active = parseInt(resp.data.data.active);
            this.summary.total_inactive = parseInt(resp.data.data.inactive);
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    linkGen(pageNum) {
      return { path: `/ticket-category?pages=${pageNum}` };
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`ticket-category/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
