<template>
  <div>
    <div>

      <b-modal id="create_form" title="Create Inventory Product"  hide-footer>
        <form @submit.prevent="fetchPurchase">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Purchase Reference No.</label>
                <input
                    v-model="purchase_ref"
                    type="text"
                    class="form-control"
                    name="name"
                    placeholder="..."
                />
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Fetching..':'Fetch Purchase'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal
          id="return_form"
          size="xl"
          title="Create Product Return"
          hide-footer
          no-close-on-backdrop
      >
        <section class="invoice-add-wrapper">
          <b-row class="invoice-add">

            <!-- Col: Left (Invoice Container) -->
            <b-col
                cols="12"
            >
              <b-form @submit.prevent>
                <b-card
                    no-body
                    class="invoice-preview-card"
                >

                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                      class="invoice-padding pb-0"
                  >
                    <b-row>
                      <b-col
                          v-if="form.id"
                          cols="12"
                          class="mb-lg-1"
                      >
                        <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                              id="invoice-data-id"
                              v-model="form.id"
                              disabled
                          />
                        </b-input-group>
                      </b-col>
                      <b-col
                          cols="12"
                          lg="6"
                          class="mb-lg-1"
                      >
                        <h6 class="mb-2">
                          Supplier:
                        </h6>

                        <!-- Select Client -->
                        <v-select
                            v-model="form.supplier_id"
                            label="name"
                            :options="formItems.suppliers"
                            :reduce="name => name.id"

                            :clearable="false"
                            disabled
                        >
                        </v-select>
                      </b-col>
                      <b-col
                          cols="12"
                          lg="3"
                          class="mb-lg-1"
                      >
                        <h6 class="mb-2">
                          Purchase Ref. No.:
                        </h6>

                        <!-- Select Client -->
                        <b-form-input
                            v-model="form.ref_no"
                            type="text"
                            readonly
                        >
                        </b-form-input>
                      </b-col>
                      <b-col
                          cols="12"
                          lg="3">

                        <!-- Selected Client -->
                        <div
                            v-if="selectedSupplier"
                            class="mt-1 text-right"
                        >
                          <h6 class="mb-25">
                            {{ selectedSupplier.name }}
                          </h6>
                          <b-card-text class="mb-25">
                            {{ selectedSupplier.email_address }}
                          </b-card-text>
                          <b-card-text class="mb-0">
                            <div
                                class="text-nowrap"
                                v-for="( number , index) in selectedSupplier.mobile_number"
                            >
                              {{ selectedSupplier.mobile_number_prefix +' '+ number}}
                              <span
                                  class="text-primary"
                                  v-if="index == 0"
                              >
    				                (P)
    				              </span>
                            </div>
                          </b-card-text>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0">

                    <b-row>
                      <b-col
                          cols="12"
                          md="4"
                          class="mb-lg-1"
                      >
                        <h6 class="mb-2">
                          Purchase Date:
                        </h6>

                        <flat-pickr
                            v-model="form.purchased_at"
                            class="form-control"
                            readonly
                        />
                      </b-col>
                      <b-col
                          cols="12"
                          md="4"
                          class="mb-lg-1"
                      >
                        <h6 class="mb-2">
                          Supplier Invoice No:
                        </h6>

                        <b-form-input
                            v-model="form.supplier_invoice_no"
                            type="text"
                            disabled
                        />
                      </b-col>
                      <b-col
                          cols="12"
                          md="4"
                          class="mb-lg-1"
                      >
                        <h6 class="mb-2">
                          Supplier Invoice  Date:
                        </h6>

                        <flat-pickr
                            v-model="form.supplier_invoice_date"
                            class="form-control"
                            readonly
                        />
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <!-- Items Section -->
                  <b-card-body class="invoice-padding">
                    <div
                        ref="form"
                        class="repeater-form"

                    >
                      <b-row
                          v-for="(item, index) in form.items"
                          :key="index"
                          ref="row"
                          class="pb-2"
                      >

                        <!-- Item Form -->
                        <!-- ? This will be in loop => So consider below markup for single item -->
                        <b-col cols="12">

                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                          <div class="d-none d-lg-flex">
                            <b-row class="flex-grow-1 px-1">
                              <!-- Single Item Form Headers -->
                              <b-col
                                  cols="12"
                                  lg="4"
                              >

                                <small
                                    v-if="formMode == 'update' && typeof item.id == 'undefined'"
                                    class="text-warning"
                                >
                                  (Not Saved)
                                </small>
                                Product
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                Unit Price
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                Qty
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                Unit
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                Price
                              </b-col>
                            </b-row>
                            <div class="form-item-action-col"  style="min-width: 50px" />
                          </div>

                          <!-- Form Input Fields OR content inside bordered area  -->
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                          <div
                              class="d-flex border rounded"
                              :class="formMode == 'update' && typeof item.id == 'undefined'?'bg-light-warning':''"
                          >
                            <b-row
                                class="flex-grow-1 p-2"
                                :id="item.product_id"
                            >
                              <!-- Single Item Form Headers -->
                              <b-col
                                  cols="12"
                                  lg="4"
                              >
                                <label class="d-inline d-lg-none">Product</label>

                                <v-select
                                    v-model="item.product_id"
                                    label="name"
                                    :options="formItems.products"
                                    :selectable="option => !formSelectedProduct.includes(option.id)"
                                    :reduce="name => name.id"
                                    placeholder="Select Product"
                                    class="mb-2 mb-md-0 item-selector-title"
                                    disabled
                                >
                                </v-select>
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                <label class="d-inline d-lg-none">Unit Price</label>
                                <b-form-input
                                    v-model="item.unit_price"
                                    type="number"
                                    class="mb-2 mb-md-0"
                                    disabled
                                />
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                <label class="d-inline d-lg-none">Qty</label>
                                <b-form-input
                                    v-model="item.quantity"
                                    type="number"
                                    class="mb-2 mb-md-0"
                                />
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                <label class="d-inline d-lg-none">Unit</label>
                                <v-select
                                    v-model="item.unit_id"
                                    label="name"
                                    :options="formItems.units"
                                    :reduce="name => name.id"
                                    placeholder="Select Unit"
                                    class="mb-2 mb-md-0 item-selector-title"
                                    disabled
                                />
                              </b-col>
                              <b-col
                                  cols="12"
                                  lg="2"
                              >
                                <label class="d-inline d-lg-none">Price</label>
                                <p class="mb-1">
                                  ৳{{ (item.unit_price * item.quantity).toLocaleString("en-US") }}
                                </p>
                              </b-col>
                              <!--                            <div :id="'mac_'+item.product_id"></div>-->
                              <b-col
                                  cols="12"
                                  lg="12"
                                  class="mt-2"
                              >
<!--                                <div>Enter Mac Address(  Seperate Product Mac Address with '  ,  '  )</div>-->
<!--                                <b-form-textarea-->
<!--                                    v-model="item.mac_address"-->
<!--                                    type="text"-->
<!--                                    class="mb-2 mb-md-0"-->
<!--                                />-->

                                <div :id="'mac'+mac.id" class="d-flex" v-for="(mac, index) in form.mac_address" :key="index">
                                  <input class="form-control"  v-model="mac.mac_address">
                                  <button>
                                    <feather-icon @click="removeMacAddress(mac)" size="16" icon="XIcon" class="cursor-pointer" />
                                  </button>
                                </div>

                              </b-col>
                            </b-row>
                            <div class="border-left d-flex flex-column justify-content-around align-items-center px-25 py-50" style="min-width: 50px">
                              <feather-icon
                                  v-if="formMode == 'update' && typeof item.id == 'undefined'"
                                  size="16"
                                  icon="SaveIcon"
                                  class="cursor-pointer"
                                  @click="createPurchaseFormItem(item, index)"
                              />
                              <feather-icon
                                  size="16"
                                  icon="XIcon"
                                  class="cursor-pointer"
                                  @click="removePurchaseFormItem(item, index)"
                              />
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
<!--                    <b-button-->
<!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--                        size="sm"-->
<!--                        variant="primary"-->
<!--                        @click="addNewItemInPurchaseForm"-->
<!--                    >-->
<!--                      Add Item-->
<!--                    </b-button>-->
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <!-- Invoice Description: Total -->
                  <b-card-body class="invoice-padding pb-0">
                    <b-row>

                      <!-- Col: Total -->
                      <b-col
                          cols="12"
                          class="mt-md-6 d-flex justify-content-end"
                          order="1"
                          order-md="2"
                      >
                        <div class="invoice-total-wrapper">
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">
                              Subtotal:
                            </p>
                            <p class="invoice-total-amount">
                              ৳{{ formSubtotal.toLocaleString("en-US") }}
                            </p>
                          </div>
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">
                              Discount:
                            </p>
                            <b-form-input
                                v-model="form.discount"
                                type="text"
                                class="form-control h-25 w-50 invoice-total-amount text-right"
                            />
                          </div>
                          <hr class="my-50">
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">
                              Total:
                            </p>
                            <p class="invoice-total-amount">
                              ৳{{ formTotal.toLocaleString("en-US") }}
                            </p>
                          </div>
                          <hr class="my-50">
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">
                              Paid:
                            </p>
                            <b-form-input
                                v-if="formMode == 'create'"
                                v-model="form.paid_amount"
                                type="text"
                                class="form-control h-25 w-50 invoice-total-amount text-right"
                            />

                            <p
                                v-else
                                class="invoice-total-amount"
                            >
                              ৳{{ form.paid_amount.toLocaleString("en-US") }}
                            </p>
                          </div>
                          <hr class="my-50">
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">
                              Due:
                            </p>

                            <p class="invoice-total-amount">
                              ৳{{ formDue.toLocaleString("en-US") }}
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">


                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      block
                      @click="submitReturnForm"
                      :disabled="form.busy"
                  >
                    {{form.busy?'Submitting ...':'Submit'}}
                  </b-button>
                </b-card>
              </b-form>
            </b-col>
          </b-row>
        </section>
      </b-modal>

      <b-modal id="update_form" title="Update Inventory Product"  hide-footer>
        <form @submit.prevent="updateProduct">
          <div class="row">
            <div class="col-lg-12 overflow-visible" >
              <div class="form-group">
                <label for="">Return Status</label>
                <v-select
                    v-model="form.return_amount_status"
                    label="name"
                    :options="formItems.amount_status"
                    :reduce="name => name.id"
                    placeholder="Select Amount Status"
                />
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Updating':'Update'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>


      <b-modal id="add_mac_form" title="Add Mac Address"  hide-footer>
        <div>
          Added: {{added}}
          Remaining: {{remaining}}
        </div>
        <form @submit.prevent="addMacAddress(item)">
          <div class="row">
            <div class="col-lg-12 overflow-visible" >
              <div class="form-group">
                <label for="">Mac</label>
                <input type="text" v-model="form.mac" />
                <button type="submit" >Save</button>
              </div>
            </div>
          </div>
        </form>
      </b-modal>

      <b-card no-body>

        <b-card-header>
          <div>
            <h4>{{title}}</h4>
          </div>
          <div>
            <b-button
                v-if="generatePdfLink"
                size="sm"
                variant="success"
                pill
                :href="generatePdfLink"
                target="_blank"
            >
              <feather-icon icon="FilePlusIcon" size="16" />
              PDF
            </b-button>
          </div>
        </b-card-header>

        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex">
              <b-button
                  size="sm"
                  col="2"
                  v-b-modal.modal-lg
                  variant="primary"
                  v-b-modal="'create_form'"
              >
                <!-- @click.prevent="editData.mode = false" -->
                <feather-icon icon="PlusCircleIcon" size="16" />
                Add New</b-button
              >
              <!-- sorting  -->
              <b-form-group
                  label="Sort"
                  label-size="sm"
                  label-align-sm="left"
                  label-cols-sm="3"
                  label-for="sortBySelect"
                  class="mb-0 ml-3"
              >
                <b-input-group size="sm">
                  <b-form-select
                      id="sortBySelect"
                      v-model="sortBy"
                      :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                      v-model="sortDesc"
                      size="sm"
                      label-cols-sm="4"
                      :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                  label="Filter"
                  label-cols-sm="3"
                  label-align-sm="left"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
            striped
            hover
            responsive
            class="position-relative"
            ref="table"
            :per-page="perPage"

            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >

          <template #cell(status)="{item, index}">
            {{item.return_amount_status == 0 ? 'Amount Due' : 'Amount Received'}}
          </template>

          <template #cell(date)="{item, index}">
            {{new Date(item.created_at).toISOString().substr(0, 10)}}
          </template>

          <template #cell(action)="{item, index}">
            <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click.prevent="editProduct(item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalNoOfRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import flatPickr from 'vue-flatpickr-component'
// import BCard from "@core/components/b-card/BCard.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "Products",
  components: { StatisticCardHorizontal, flatPickr },
  data() {
    return {
      title: "Product Returns",
      emptyFormItem: {
        product_id: null,
        unit_id: null,
        quantity: 1,
        unit_price: 0,
        mac_address: "",
      },
      form: new Form({
        name: "",
        brand_id: null,
        category_id: null,
        mac: "",
        product_id: null,
        purchased_at: "",
        supplier_invoice_no: "",
        supplier_invoice_date: "",
        supplier_id: null,
        discount: 0,
        paid_amount: 0,
        ref_no: "",
        items: [],
        return_amount_status: "",
        mac_address: []

      }),
      added: '',
      remaining: '',
      formItems: {
        amount_status: [
          {id: 1, name: 'Amount Received'},
          {id: 0, name: 'Amount Due'},
        ]
      },
      fields: [

        { key: "id", label: "#", sortable: true },
        { key: "return_ref", label: "Return Reference", sortable: true },
        { key: "purchase_ref", label: "Purchase Reference", sortable: true },
        { key: "return_amount", label: "Return Amount", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "date", label: "Date", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      formMode: 'create',
      filterOn: [],
      generatePdfLink: '',
      purchase_ref: '',
      item:[],
    };
  },
  computed: {
    sortOptions() {
      return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
    },
    selectedSupplier(){
      let supplier = null
      if(typeof this.form.supplier_id != 'undefined' && this.form.supplier_id != null ){
        supplier = this.formItems.suppliers.find(sup => sup.id == this.form.supplier_id)
      }
      return supplier
    },
    formSubtotal(){
      let subtotal = 0
      for(let i = 0; i < this.form.items.length; i++){
        subtotal+=(this.form.items[i].quantity * this.form.items[i].unit_price)
      }
      return subtotal
    },
    formTotal(){
      return this.formSubtotal - this.form.discount
    },
    formDue(){
      return this.formTotal - this.form.paid_amount
    },
    formSelectedProduct(){
      let product_ids = []
      for(let i = 0; i < this.form.items.length; i++){
        product_ids.push(this.form.items[i].product_id)
      }
      return product_ids
    },
  },

  created() {
    this.getData();
    this.getFormItems();
    this.getPdfGeneratedLink();
    setInterval(()=>{
      this.getPdfGeneratedLink();
    }, 270*1000)
  },
  watch: {
    currentPage: {
      handler: function(newVal, oldVal) {
        this.getData(newVal); // call it in the context of your component object
      },
      deep: true
    },
  },
  methods: {
    fetchPurchase(){
      axios.get(`fetch-inventory-purchase/${this.purchase_ref}`).then((resp)=>{
        if(resp.data.success){
          this.form.id = resp.data.data.id
          this.form.supplier_id = resp.data.data.supplier_id
          this.form.purchased_at = resp.data.data.purchased_at
          this.form.supplier_invoice_no = resp.data.data.supplier_invoice_no
          this.form.supplier_invoice_date = resp.data.data.supplier_invoice_date
          this.form.discount = resp.data.data.discount
          this.form.ref_no = resp.data.data.purchase_ref_no
          this.form.paid_amount = resp.data.data.payment_stat.total_paid_amount
          this.form.items = resp.data.data.items
          this.form.mac_address = resp.data.data.mac_addresses


          this.form.items = resp.data.data.items;


          this.$bvModal.hide('create_form')
          this.$bvModal.show('return_form')
        }
        else{
          console.log(resp.data.error)
        }
      })
    },
    submitReturnForm(){
      // console.log(this.form.id);
      this.form.post(`save-product-return/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.items.every((item, index) => {
            if(item.id == updatedItem.id){
              this.$set(this.items, index, updatedItem)
              return false;
            }
            return true;
          })
          this.form.reset()
          this.$success_message(resp.data.message);
          this.$bvModal.hide('return_form')
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`fetch-product-returns?page=${page}`).then((resp) => {
        if (resp.data.success) {
          this.totalNoOfRows = resp.data.data.total
          this.items = resp.data.data.data;

        } else {
          this.$error_message(resp.data.message);
        }
      }).finally(()=> {
        this.$store.dispatch("spinnerLoading", false);
      });
    },

    editProduct(item){
      this.form.reset()
      this.form.id = item.id
      this.form.return_amount_status = item.return_amount_status
      this.$bvModal.show('update_form')
    },

    updateProduct(){
      this.form.put(`update-product-return/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.items.every((item, index) => {
            if(item.id == updatedItem.id){
              this.$set(this.items, index, updatedItem)
              return false;
            }
            return true;
          })
          this.form.reset()
          this.$success_message(resp.data.message);
          this.$bvModal.hide('update_form')
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },


    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },

    getFormItems(){
      this.getProducts()
      this.getUnits()
      this.getSuppliers()
      this.getBrands()
    },

    // for form options
    getProducts(){
      axios.get(`inventory-products?item=all`).then((resp) => {
        if (resp.data.success) {
          this.formItems.products = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      })
    },
    getUnits(){
      axios.get(`inventory-units?item=all&status=active`).then((resp) => {
        if (resp.data.success) {
          this.formItems.units = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      })
    },
    getSuppliers(){
      axios.get(`inventory-suppliers?item=all&status=active`).then((resp) => {
        if (resp.data.success) {
          this.formItems.suppliers = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      })
    },
    getBrands(){
      axios.get(`inventory-brands?item=all&status=active`).then((resp) => {
        if (resp.data.success) {
          this.formItems.brands = resp.data.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      })
    },

    removeMacAddress(mac){
      axios.delete(`inventory-product-mac-address/${mac.id}`).then((resp) => {
        if(resp.data.success){
          document.getElementById(`mac${mac.id}`).classList.remove('d-flex')
          document.getElementById(`mac${mac.id}`).classList.add('d-none')
          this.$success_message(resp.data.message);
        }
        else{
          this.$error_message(resp.data.message);
        }
      })
    }
  },
};

</script>
