<template>
  <div>
    <div>

      <b-modal id="create_form" title="Create Inventory Product"  hide-footer>
        <form @submit.prevent="createProduct">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="col-lg-12 overflow-visible" >
              <div class="form-group">
                <label for="">Brand</label>
                 <v-select
					v-model="form.brand_id"
					label="name"
					:options="formItems.brands"
					:reduce="name => name.id"
					placeholder="Select Brand"
		        />
              </div>
            </div>
            <div class="col-lg-12 overflow-visible" >
              <div class="form-group">
                <label for="">Category</label>
                 <v-select
					v-model="form.category_id"
					label="name"
					:options="formItems.categories"
					:reduce="name => name.id"
					placeholder="Select Category"
		        />
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Submitting':'Submit'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal id="update_form" title="Update Inventory Product"  hide-footer>
        <form @submit.prevent="updateProduct">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="">Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="col-lg-12 overflow-visible" >
              <div class="form-group">
                <label for="">Brand</label>
                 <v-select
					v-model="form.brand_id"
					label="name"
					:options="formItems.brands"
					:reduce="name => name.id"
					placeholder="Select Brand"
		        />
              </div>
            </div>
            <div class="col-lg-12 overflow-visible" >
              <div class="form-group">
                <label for="">Category</label>
                 <v-select
					v-model="form.category_id"
					label="name"
					:options="formItems.categories"
					:reduce="name => name.id"
					placeholder="Select Category"
		        />
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Updating':'Update'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>


      <b-modal id="add_mac_form" title="Add Mac Address"  hide-footer>
        <div class="d-flex justify-content-around">
          <div>Added: {{added}}</div>
          <div>Remaining: {{remaining}}</div>
        </div>
        <form @submit.prevent="addMacAddress(item)">
          <div class="row">
            <div class="col-lg-12 overflow-visible" >
              <div class="form-group">
                <label for="">Mac</label>
                <input type="text" class="form-control" v-model="form.mac" />
                <button type="submit" class="mt-1">Save</button>
              </div>
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal id="show_mac_addresses" title="Show Mac Addressesd"  hide-footer>
        <div v-for="mac in item.mac_addresses" key="mac.id">
          <div class="d-flex mb-1">
            <input :id=mac.id   type="text" :value=mac.mac_address disabled class="form-control mr-1" />
            <button :id="'button_'+mac.id" @click="enableInput(mac)">Edit</button>
            <button class="hidden" :id="'save_button_'+mac.id" @click="updateMac(mac)">Save</button>
            <button class="hidden" :id="'cancel_button_'+mac.id" @click="deleteMac(mac)">Cancel</button>
          </div>
        </div>
<!--        <form @submit.prevent="addMacAddress(item)">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-12 overflow-visible" >-->
<!--              <div class="form-group">-->
<!--                <label for="">Mac</label>-->
<!--                <input type="text" class="form-control" v-model="form.mac" />-->
<!--                <button type="submit" class="mt-1">Save</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </form>-->
      </b-modal>

      <b-card no-body>

	    <b-card-header>
	      <div>
	      	<h4>{{title}}</h4>
	      </div>
	      <div>
	      		<b-button
	      			v-if="generatePdfLink"
	                size="sm"
	                variant="success"
	                pill
	                :href="generatePdfLink"
	                target="_blank"
	            >
	                <feather-icon icon="FilePlusIcon" size="16" />
	                PDF
	            </b-button>
	      </div>
	    </b-card-header>

        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex">
              <b-button
                size="sm"
                col="2"
                v-b-modal.modal-lg
                variant="primary"
                v-b-modal="'create_form'"
              >
                <!-- @click.prevent="editData.mode = false" -->
                <feather-icon icon="PlusCircleIcon" size="16" />
                Add New</b-button
              >
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0 ml-3"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          ref="table"
          :per-page="perPage"

          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <template #cell(brand)="{item, index}">
          	{{item.brand ? item.brand.name : ''}}
          </template>

          <template #cell(category)="{item, index}">
          	{{item.category ? item.category.name : ''}}
          </template>
          <template #cell(purchase_quantity)="{item, index}">
            {{item.stock_stat.purchase_quantity.toLocaleString("en-US") }}
          </template>
          <template #cell(stock)="{item, index}">
            {{item.stock_stat.stock.toLocaleString("en-US") }}
          </template>
          <template #cell(stock_value)="{item, index}">
            ৳{{item.stock_stat.stock_value.toLocaleString("en-US") }}
          </template>

          <template #cell(mac_address_added)="{item, index}">
            <button class="button_class" @click.prevent="showMacs(item)">
              {{item.mac_addresses.length}}
            </button>
          </template>

          <template #cell(action)="{item, index}">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click.prevent="editProduct(item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="addMac(item, item.mac_addresses.length, item.stock_stat.stock.toLocaleString('en-US'))">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Add Mac Address
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteProduct(item, index)">
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNoOfRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import Form from "vform";
// import BCard from "@core/components/b-card/BCard.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "Products",
  components: { StatisticCardHorizontal },
  data() {
    return {
      title: "Inventory Products",
      form: new Form({
        name: "",
        brand_id: null,
        category_id: null,
        mac: "",
        product_id: null,
        update_mac: ''
      }),
      added: '',
      remaining: '',
      formItems: {
      	brands: [],
      	categories: []
      },
      fields: [

        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "brand", label: "Brand", sortable: true },
        { key: "category", label: "Category", sortable: true },
        { key: "purchase_quantity", label: "Purchase Quantity", sortable: true },
        { key: "stock", label: "Stock", sortable: true },
        { key: "stock_value", label: "Stock Value", sortable: true },
        { key: "mac_address_added", label: "Mac Address Added", sortable: true },
        "action",
      ],
      items: [],
      item: {},
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      generatePdfLink: '',
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  created() {
    this.getData();
    this.getFormItems();
    this.getPdfGeneratedLink();
    setInterval(()=>{
    	this.getPdfGeneratedLink();
    }, 270*1000)
  },
  watch: {
    currentPage: {
       handler: function(newVal, oldVal) {
           this.getData(newVal); // call it in the context of your component object
       },
       deep: true
    },
  },
  methods: {
  	getPdfGeneratedLink(){
  		axios.get(`inventory-products/generate-pdf-generation-link`).then((resp) => {
        if (resp.data.success) {
          	this.generatePdfLink = resp.data.data.link;
        } else {
        	this.generatePdfLink = "";
        }
      })
  	},
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`inventory-products?page=${page}&item=${this.perPage}`).then((resp) => {
        if (resp.data.success) {
          this.totalNoOfRows = resp.data.data.total
          this.items = resp.data.data.data;

        } else {
          this.$error_message(resp.data.message);
        }
      }).finally(()=> {
        this.$store.dispatch("spinnerLoading", false);
      });
    },

    createProduct() {
      this.form.post("inventory-products").then((resp) => {

        if (resp.data.success) {
          let item = resp.data.data
          this.items = [ ...this.items, item ]

          this.$success_message(resp.data.message);
          this.form.reset()
          this.$bvModal.hide('create_form')
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    editProduct(item){
      this.form.reset()
      this.form.id = item.id
      this.form.name = item.name
      this.form.brand_id = item.brand_id
      this.form.category_id = item.category_id
      this.$bvModal.show('update_form')
    },

    updateProduct(){
      this.form.put(`inventory-products/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.items.every((item, index) => {
            if(item.id == updatedItem.id){
              this.$set(this.items, index, updatedItem)
              return false;
            }
            return true;
          })
          this.form.reset()
          this.$success_message(resp.data.message);
          this.$bvModal.hide('update_form')
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    addMac(item){
      this.form.reset()
      this.added = item.mac_addresses.length;
      this.remaining = parseInt(item.stock_stat.stock.toLocaleString('en-US')) - parseInt(item.mac_addresses.length)
      this.form.product_id = item.id;
      this.$bvModal.show('add_mac_form')
    },

    addMacAddress(item){
      this.form.post(`inventory-product-mac-address`).then((resp) => {
        if (resp.data.success) {
          this.form.mac = ''
          this.$success_message(resp.data.message);
          this.added = this.added + 1;
          this.remaining = this.remaining - 1;
          this.getData()
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    showMacs(item){
      this.item = item;
      this.$bvModal.show('show_mac_addresses');
    },

    deleteMac(mac){
      let input = document.getElementById(mac.id);
      input.disabled = true;
      let editButton = document.getElementById('button_'+mac.id);
      editButton.classList.remove('hidden');
      let saveButton = document.getElementById('save_button_'+mac.id);
      saveButton.classList.add('hidden');
      let cancelButton = document.getElementById('cancel_button_'+mac.id);
      cancelButton.classList.add('hidden');
    },

    updateMac(mac){
      let input = document.getElementById(mac.id);
      this.form.update_mac = input.value;
      this.form.put(`inventory-product-mac-address/${mac.id}`).then((resp)=> {
        if(resp.data.success) {
          let input = document.getElementById(mac.id);
          input.disabled = true;
          let editButton = document.getElementById('button_'+mac.id);
          editButton.classList.remove('hidden');
          let saveButton = document.getElementById('save_button_'+mac.id);
          saveButton.classList.add('hidden');
          let cancelButton = document.getElementById('cancel_button_'+mac.id);
          cancelButton.classList.add('hidden');
          this.$success_message(resp.data.message);
          this.getData()
          this.$bvModal.hide('show_mac_addresses');
        }else{
          this.$error_message(resp.data.message);
        }
      })
    },

    deleteProduct(item, index){
      if(item && confirm('Are you sure to delete?')){
        this.$store.dispatch("spinnerLoading", true);
        axios.delete(`inventory-products/${item.id}`).then((resp) => {
          if (resp.data.success) {
            this.items.splice(index, 1)
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message);
          }
        }).finally(()=> {
          this.$store.dispatch("spinnerLoading", false);
        });
      }
    },

    enableInput(mac){
      let input = document.getElementById(mac.id);
      input.disabled = false;
      let editButton = document.getElementById('button_'+mac.id);
      editButton.classList.add('hidden');
      let saveButton = document.getElementById('save_button_'+mac.id);
      saveButton.classList.remove('hidden');
      let cancelButton = document.getElementById('cancel_button_'+mac.id);
      cancelButton.classList.remove('hidden');
    },

    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },

    getFormItems(){
    	this.getCategories()
    	this.getBrands()
    },

    // for form options
    getCategories(){
    	axios.get(`inventory-categories?item=all&status=active`).then((resp) => {
	        if (resp.data.success) {
	          this.formItems.categories = resp.data.data.data;
	        } else {
	          this.$error_message(resp.data.message);
	        }
	    })
    },

    // for form options
    getBrands(){
    	axios.get(`inventory-brands?item=all&status=active`).then((resp) => {
	        if (resp.data.success) {
	          this.formItems.brands = resp.data.data.data;
	        } else {
	          this.$error_message(resp.data.message);
	        }
	    })
    },
  },
};

</script>

<style>
.button_class{
  all: unset;
  cursor: pointer;
}
</style>
