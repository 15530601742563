<template>
    <form @submit.prevent="preventSubmitForm">
      <div class="row">
        <div 
        	v-for="field in fields"
        	:class="field.column_class?field.column_class:'col-md-12'"
        >
          <div class="form-group">
            <label for="">{{ field.label }}</label>

            <template
            	v-if="['text', 'email', 'search', 'date', 'datetime-local'].indexOf(field.type) != -1"
            >
	            <input
	              v-model="form[field.name]"
	              :name="field.name"
	              :type="field.type"
	              class="form-control"
	              :disabled="field.disabled"
	            />
            </template>
            <template
            	v-else-if="field.type === 'number'"
            >
	            <input
	              v-model="form[field.name]"
	              :name="field.name"
	              type="number"
	              class="form-control"
	              :disabled="field.disabled"
	              step="any"
	            />
            	
            </template>
            <template
            	v-else-if="field.type === 'select'"
            >
            	<!-- <select
            	  v-model="form[field.name]"
            	  :name="field.name"
            	  class="form-control"
            	  :disabled="field.disabled"
            	>
            	  <option 
            	  	v-for="option in field.options" 
            	  	:value="field.option_settings ? option[field.option_settings.value] : option.value"
            	  >
            	  	{{ field.option_settings ? option[field.option_settings.title] : option.title }}
            	  </option>
            	</select> -->

            	<v-select
	                v-model="form[field.name]"
	                :label="field.option_settings ? field.option_settings.title : 'title'"
					:options="field.options"
					:selectable="option => field.not_selectable_options?!field.not_selectable_options.includes(option.id):true"
					:reduce="option => field.option_settings ? option[field.option_settings.value] : option.value"
	                
	                :clearable="false"
	            	:disabled="field.disabled"
	            >
	            	<template v-slot:option="option">
					    {{ selectCustomLabel(field, option) }}
					</template>
	            	<template v-slot:selected-option="option">
					    {{ selectCustomLabel(field, option) }}
					</template>
	            </v-select>
            </template>
            <template
            	v-else-if="field.type === 'textarea'"
            >
            	<textarea
            		v-model="form[field.name]"
            		:name="field.name"
            		:disabled="field.disabled"
            		class="form-control"
            		placeholder="..."
            		rows="3"
            		style="resize: vertical;"
            	>
            	</textarea>
            </template>
            <template
            	v-else-if="field.type === 'lat_lon'"
            >
	            <input
	              v-model="form[field.name]"
	              :name="field.name"
	              :type="field.type"
	              class="form-control mb-2"
	              :disabled="field.disabled"
	            />
	            <GoogleMapLoad :model_name="field.name" :lat_lon="form[field.name]" @updateLatLon="updateModelValue"/>
            </template>

           

            
          </div>
        </div>
      </div>
      <div 
      	v-if="no_submit === false"
      	class="row mt-1 justify-content-center" 
      >
        <div class="col-lg-12">
          <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
            {{form.busy?'Submitting':'Submit'}}
          </button>
        </div>
      </div>
    </form>
</template>

<script>

	import GoogleMapLoad from "/src/components/GoogleMapLoad";
	export default {
		name: "DynamicForm",
		components: { GoogleMapLoad },
		props: {
			'submitForm': Function, 
			'fields': {
				type: Array,
				default: [
					{
						'type': 'text',
						'name': 'name',
						'label': 'Name',
						'disabled': false,
						'options': []	
					},
					{
						'type': 'select',
						'name': 'status',
						'label': 'Status',
						'disabled': false,
						'options': [{
							'title': 'Active',
							'value': 1
						}, {
							'title': 'Inactive',
							'value': 0
						}],
						'option_settings': {
							'title': 'name',
							'value': 'id',
							'template': { // for custom label
								'title': 'Name: %s',
								'value': [
									'name', 
								]
							}
						}	
					}
				]
			},
			'form': {
				type: Object,
				default: {}
			},
			'type': {
				type: String,
				default: 'create' // edit or create
			},
			'no_submit': {
				type: Boolean,
				default: false
			}
		},
		data() {
	  		return {

	  		}
	  	},
	  	methods: {
	  		updateModelValue(data, model_name){
	  			this.form[model_name] = data
	  		},
	  		selectCustomLabel(field, option){
	  			if(field.option_settings){
	  				if(field.option_settings.template){
	  					let title_template = field.option_settings.template.title
	  					let title_template_length = title_template.split('%s').length
	  					for(let i = 0; i < title_template_length; i++){
	  						title_template = title_template.replace('%s', option[field.option_settings.template.value[i]])
	  					}
	  					return title_template
	  				} else {
	  					return option[field.option_settings.title]
	  				}
	  			} else {
	  				return option['title']
	  			}
	  		},
	  		preventSubmitForm(){
	  			if(this.no_submit === false){
	  				this.submitForm()
	  			}
	  		}
	  	}
	}
</script>