/* eslint-disable indent */
/* eslint-disable space-before-blocks */
/* eslint-disable comma-dangle */
/* eslint-disable space-infix-ops */
/* eslint-disable no-return-assign */
import axios from "axios";

export default {
  state: {
    departments: [],
    designations: [],
    employees: [],
    active_departments: [],
    active_designations: [],
    workinghours: [],
    attendances: [],
    active_shifts: [],
    payrolls: [],
    leaves: [],
  },
  getters: {
    getdepartments(state) {
      return state.departments;
    },
    getdesignations(state) {
      return state.designations;
    },
    getemployees(state) {
      return state.employees;
    },
    getactivedepartments(state) {
      return state.active_departments;
    },
    getactivedesignations(state) {
      return state.active_designations;
    },
    getworkinghours(state) {
      return state.workinghours;
    },
    getattendances(state) {
      return state.attendances;
    },
    getactive_shifts(state) {
      return state.active_shifts;
    },
    getapayrolls(state) {
      return state.payrolls;
    },
    getleaves(state) {
      return state.leaves;
    },
  },
  mutations: {
    departments(state, payload) {
      return (state.departments = payload);
    },
    designations(state, payload) {
      return (state.designations = payload);
    },
    employees(state, payload) {
      return (state.employees = payload);
    },
    active_departments(state, payload) {
      return (state.active_departments = payload);
    },
    active_designations(state, payload) {
      return (state.active_designations = payload);
    },
    workinghours(state, payload) {
      return (state.workinghours = payload);
    },
    attendances(state, payload) {
      return (state.attendances = payload);
    },
    active_shifts(state, payload) {
      return (state.active_shifts = payload);
    },
    payrolls(state, payload) {
      return (state.payrolls = payload);
    },
    leaves(state, payload) {
      return (state.leaves = payload);
    },
  },

  // actions
  actions: {
    getDepartments(context) {
      axios.get("department")
        .then((resp) => {
          if (resp.data.success) {
            context.commit("departments", resp.data.data);
          }
        });
    },
    getDesignations(context) {
      axios.get("designation")
        .then((resp) => {
          if (resp.data.success) {
            context.commit("designations", resp.data.data);
          }
        });
    },
    getEmployees(context) {
      axios.get("employee")
        .then((resp) => {
          if (resp.data.success) {
            context.commit("employees", resp.data.data);
          }
        });
    },
    getActiveDepartments(context) {
      axios.get("active_departments")
        .then((resp) => {
          if (resp.data.success) {
            context.commit("active_departments", resp.data.data);
          }
        });
    },
    getActiveDesignations(context) {
      axios.get("active_designations")
        .then((resp) => {
          if (resp.data.success) {
            context.commit("active_designations", resp.data.data);
          }
        });
    },
    getWorkingHours(context) {
      axios.get("workinghour")
        .then((resp) => {
          if (resp.data.success) {
            context.commit("workinghours", resp.data.data);
          }
        });
    },
    getAttendances(context) {
      axios.get("attendance")
          .then((resp) => {
            if (resp.data.success) {
              context.commit("attendances", resp.data.data);
            }
          });
    },
    getActiveShifts(context) {
      axios.get("active_shifts")
        .then((resp) => {
          if (resp.data.success) {
            context.commit("active_shifts", resp.data.data);
          }
        });
    },
    getPayrolls(context) {
      axios.get("payroll")
          .then((resp) => {
            if (resp.data.success) {
              context.commit("payrolls", resp.data.data);
            }
          });
    },
    getLeaves(context) {
      axios.get("leaveManagement")
          .then((resp) => {
            if (resp.data.success) {
              context.commit("leaves", resp.data.data);
            }
          });
    },
  },
};
