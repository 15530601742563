<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="summary.total"
          statistic-title="Total Income"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.complete"
          statistic-title="Total Complete"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.pending"
          statistic-title="Total Pending"
        />
      </b-col>
    </b-row>
    <!-- modal start  -->
    <b-modal id="modal-lg" :title="modal_title" hide-header-close size="sm">
      <form v-if="!viewmode" @submit.prevent="submitHandelar">
        <div class="form-group">
          <label for="exp_claimant">Service Name</label>
          <span class="text-danger" v-if="errors && errors.exp_claimant">
            {{ errors.exp_claimant[0] }}</span
          >
          <input
            v-model="form.exp_claimant"
            id="exp_claimant"
            placeholder="Service Name"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="category">Category</label>
          <span class="text-danger" v-if="errors && errors.category">
            <br />
            {{ errors.category[0] }}</span
          >
          <select v-model="form.category" class="form-control" id="category">
            <option value="">---Select---</option>
            <option
              v-for="(category, index) in category"
              :key="index"
              :value="category.name"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="amount">Amount</label>
          <span class="text-danger" v-if="errors && errors.amount">
            {{ errors.amount[0] }}</span
          >
          <input
            min="1"
            v-model="form.amount"
            id="amount"
            placeholder="Amount"
            type="number"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="">Method</label>
          <span class="text-danger" v-if="errors && errors.method">
            <br />
            {{ errors.method[0] }}</span
          >
          <select v-model="form.method" class="form-control">
            <option value="">-----Select-------</option>
            <option value="Cash">Cash</option>
            <option value="Baksh">Bkash</option>
            <option value="Rocket">Rocket</option>
            <option value="Bank">Bank</option>
          </select>
        </div>
        <div
          v-if="
            form.method == 'Baksh' ||
            form.method == 'Rocket' ||
            form.method == 'Bank'
          "
          class="form-group"
        >
          <label for="transaction_id">Transaction Id</label>
          <span class="text-danger" v-if="errors && errors.transaction_id">
            <br />
            {{ errors.transaction_id[0] }}</span
          >
          <input
            placeholder="Transaction Id"
            type="text"
            v-model="form.transaction_id"
            class="form-control"
            id="transaction_id"
          />
        </div>
        <div class="form-group">
          <label for="data">Data</label>
          <input
            type="datetime-local"
            v-model="form.issue_date"
            class="form-control"
            id=""
          />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <span class="text-danger" v-if="errors && errors.description">
            {{ errors.description[0] }}</span
          >
          <textarea
            class="form-control"
            v-model="form.description"
            id="description"
            cols="30"
            rows="5"
          ></textarea>
        </div>
      </form>
      <div v-else class="">
        <table>
          <tr>
            <td><strong> Voucher: </strong></td>
            <td>{{ viewdata.create_by }}</td>
          </tr>

          <tr>
            <td><strong> Amount: </strong></td>
            <td>{{ viewdata.amount }}</td>
          </tr>
          <tr>
            <td><strong> Category: </strong></td>
            <td>{{ viewdata.category }}</td>
          </tr>
          <tr>
            <td><strong> Claimant: </strong></td>
            <td>{{ viewdata.exp_claimant }}</td>
          </tr>
          <tr>
            <td><strong> Issue Date: </strong></td>
            <td>{{ formatDateTime(viewdata.issue_date) }}</td>
          </tr>
          <tr>
            <td colspan="2">
              {{ viewdata.description }}
            </td>
          </tr>
        </table>
      </div>
      <template #modal-footer>
        <button @click="close" class="btn btn-warning">Close</button>
        <button
          v-if="!viewmode"
          @click="submitHandelar"
          class="btn btn-primary"
          type="submit"
          :disabled="form.busy"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-card-code v-if="permission.view" :title="title" no-body>
      <b-card-body>
        <div class="row mb-2">
          <div class="col-sm-11 col-md-11">
            <form @submit.prevent="serch_by_date" action="">
              <div class="row">
                <div class="col-sm-12 col-md-2">
                  <b-button
                    v-if="permission.add"
                    size="sm"
                    col="2"
                    v-b-modal.modal-lg
                    variant="primary"
                    @click.prevent="editData.mode = false"
                  >
                    <feather-icon icon="PlusCircleIcon" size="16" />
                    Add New</b-button
                  >
                </div>
                <div class="col-sm-12 col-md-10">
                  <div class="d-flex justify-content-between">
                    <input
                      class="form-control"
                      type="date"
                      v-model="form.start_date"
                      id=""
                    />
                    <span class="font-weight-bold">To</span>
                    <input
                      class="form-control"
                      type="date"
                      v-model="form.end_date"
                      id=""
                    />

                    <input
                      class="btn btn-primary btn-sm py-0"
                      type="submit"
                      value="Search"
                    />
                  </div>
                  <div class="d-flex mt-2">
                    <select class="form-control" name="" id="">
                      <option value="">---creator---</option>
                      <option
                        v-for="uItem in dashboardusers"
                        :key="uItem.id"
                        :value="uItem.name"
                      >
                        {{ uItem.name }}
                      </option>
                    </select>
                    <select class="form-control" name="" id="">
                      <option value="">---status---</option>
                      <option value="">Pending</option>
                      <option value="">Complete</option>
                    </select>
                    <b-button
                      size="sm"
                      col="2"
                      class="ml-1"
                      variant="info"
                      @click.prevent="reset_date_field"
                    >
                      Reset</b-button
                    >
                  </div>
                </div>
                <div class="col-12 d-flex"></div>
              </div>
            </form>
          </div>
          <div class="col-sm-1 col-md-1 p-0">
            <b-dropdown
              size="sm"
              id="dropdown-left"
              text="Export"
              variant="primary"
            >
              <b-dropdown-item>
                <export-excel name="income.xls" :data="items">
                  xls
                </export-excel>
              </b-dropdown-item>
              <b-dropdown-item>
                <export-excel :data="items" type="csv" name="income.csv">
                  csv
                </export-excel></b-dropdown-item
              >
              <b-dropdown-item>
                <span @click="downloadPDF"> PDF</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
      >
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-if="permission.view"
              @click="viewItem(data.item)"
            >
              <span>
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>View</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="edit(data.item)">
              <span>
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>Edit</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.delete"
              @click.prevent="deleteData(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #cell(date)="date"
          ><span class="badge text-secondary">{{
            formatDateTime(date.item.issue_date)
          }}</span>
        </template>
        <template v-if="permission.status" #cell(status)="status">
          <b-form-checkbox
            v-model="status.item.status == '0'"
            name="check-button"
            switch
            @change="s_e_d(status.item.id)"
          ></b-form-checkbox>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
    <h2 class="col-lg-12 text-center" v-else>No Permission To View The List</h2>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import jsPDF from "jspdf";
const Swal = require("sweetalert2");
import "jspdf-autotable";
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import { statusAction } from "@/utils/helpers/functions";
export default {
  name: "DailyIncome",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      title: "Daily Income",
      modal_title: "Add New Daily Income",
      statusAction,
      form: new Form({
        description: "",
        exp_claimant: "",
        category: "",
        issue_date: "",
        amount: "",
        method: "",
        transaction_id: "",
        start_date: "",
        end_date: "",
      }),
      fields: [
        { key: "id", label: "Id" },
        { key: "create_by", label: "Creator Name", sortable: true },
        { key: "exp_voucher_no", label: "Voucher No", sortable: true },
        { key: "category", label: "Category", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        { key: "method", label: "Method", sortable: true },
        { key: "transaction_id", label: "Transaction Id", sortable: true },
        "date",
        { key: "status", label: "Status", sortable: true },
        { key: "exp_claimant", label: "Service Name" },
        "action",
      ],
      errors: {},
      viewdata: {},
      viewmode: false,
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      category: [],
      dashboardusers: [],

      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      permissionError: "",

      summary: {
        total: 0,
        compelte: 0,
        pending: 0,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    users() {
      return this.$store.state.isp.users;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Daily Income View");
      this.permission.add = this.checkpermission("Daily Income Add");
      this.permission.edit = this.checkpermission("Daily Income Edit");
      this.permission.delete = this.checkpermission("Daily Income Delete");
      this.permission.status = this.checkpermission(
        "Daily Income Change Status"
      );
    },
  },
  created() {
    this.getData(this.perPage, this.currentPage);
    this.$store.dispatch("getUsers");
    this.get_b_c_category();
    this.getUser();
  },
  methods: {
    // ============= downloadPDF  ================
    downloadPDF() {
      const doc = new jsPDF();
      var col = [
        "Id",
        "Create By",
        "Service Name",
        "Amount",
        "Method",
        "Transaction Id",
        "Issue Date",
        "Status",
        "Details",
      ];
      var rows = [];
      this.items.forEach((element) => {
        var id = [element.id];
        var create_by = [element.create_by];
        var exp_claimant = [element.exp_claimant];
        var amount = [element.amount];
        var method = [element.method];
        var transaction_id = [element.transaction_id];
        var issue_date = [element.issue_date];
        var status = [element.status == 0 ? "Pending" : "Completed"];
        var desc = [element.description];
        rows.push([
          id,
          create_by,
          exp_claimant,
          amount,
          method,
          transaction_id ? transaction_id : null,
          issue_date,
          status,
          desc,
        ]);
      });
      doc.autoTable(col, rows, { startY: 10 });
      doc.save("income.pdf");
    },
    //=========== Get All user ============
    getUser() {
      this.axios.get("dashboardusers").then((res) => {
        if (res.data.success) {
          this.dashboardusers = res.data.data;
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //=========== reset search field ============
    reset_date_field() {
      this.getData(this.perPage, this.currentPage);
    },
    async serch_by_date() {
      let ids = this.items.map(
        (item) =>
          function () {
            if (item.status == this.paymentstatue) {
            }
          }
      );
      this.form.post("daily-income/search-date").then((resp) => {
        if (resp.data.success) {
          this.items = resp.data.data;
          this.totalRows = resp.data.data.length;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },

    s_e_d(data) {
      this.axios.get("daily-income/" + data).then((res) => {
        if (res.data.success) {
          this.getData();
          this.$s_tost(res.data.message);
          // this.$store.dispatch("actions_success", res.data.message);
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //=========== Get All category ============
    get_b_c_category() {
      this.axios.get("b-c-category?type=Income").then((res) => {
        if (res.data.success) {
          this.category = res.data.data;
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    getMonthlyBill() {
      axios
        .get(`invoice/monthly-bill/${this.form.user_id}`)
        .then((res) => {
          this.form.amount = res.data.data.monthly_bill;
        })
        .catch((err) => console.log(err));
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        console.log("save");
        this.save();
      } else {
        console.log("edit");
        this.update();
      }
    },

    makePayment(id) {
      this.editData.mode = true;
      this.$root.$emit("bv::show::modal", "modal-lg");
      let data = this.items.find((x) => x.id === id);
      this.form = {
        id: data.id,
        user_id: data.user_id,
        amount: data.amount,
        received_amount: data.received_amount,
      };
    },
    //= ========data save=========
    save() {
      this.form
        .post("daily-income")
        .then((res) => {
          if (res.data.success) {
            this.close();
            this.getData();
          } else {
            this.errors = res.data.errors;
          }
        })
        .catch((e) => {
          console.log(e.response.data);
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
          }
        });
    },

    //= ========get data for edit=========
    update() {
      this.form
        .put(`daily-income/${this.editData.id}`)
        .then((res) => {
          if (res.data.success) {
            this.close();
            this.$success_message(res.data.message);
            this.getData();
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //get data list
    async getData(perPage, page) {
      this.$store.dispatch("spinnerLoading", true);

      await axios.get("daily-income").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.items = resp.data.data;
          this.totalRows = resp.data.data.length;
          this.summary.total = resp.data.total;
          this.summary.pending = resp.data.pending;
          this.summary.complete = resp.data.complete;
        } else {
          if (resp.data.code == 403) {
            this.permissionError = resp.data.message;
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        }
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //= ========get data for edit=========
    edit(data) {
      this.modal_title = "Update";
      this.form.description = data.description;
      this.form.category = data.category;
      this.form.exp_claimant = data.exp_claimant;
      this.form.amount = data.amount;
      this.form.issue_date = data.issue_date;
      this.form.method = data.method;
      this.form.transaction_id = data.transaction_id;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.viewmode = false;
      this.viewdata = {};
      this.$bvModal.show("modal-lg");
    },
    //= ======== close Modal =========
    close() {
      this.modal_title = "Add New";
      this.form.description = null;
      this.form.category = null;
      this.form.exp_claimant = null;
      this.form.amount = null;
      this.form.method = null;
      this.form.transaction_id = null;
      this.form.issue_date = null;
      this.editData.mode = false;
      this.viewdata = null;
      this.editData.mode = false;
      this.$bvModal.hide("modal-lg");
    },

    //= ======== delete Modal =========
    async deleteData(id) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`daily-income/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    //= ======== delete Modal end =========
    viewItem(data) {
      this.modal_title = "View Data";
      this.viewdata = data;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("modal-lg");
      this.viewmode = true;
    },
  },
};
</script>
