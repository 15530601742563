<template>
  <div class="">
    <div class="row mt-2">
      <div class="col-sm-12 col-md-6">
        <h1 class="font-weight-bold">Email Creadintial</h1>
        <form @submit.prevent="submitHandelar" action="" class="card-body">
          <div class="form-group">
            <label for="name">Provider</label>
            <input
              v-model="form.name"
              type="text"
              name=""
              class="form-control"
              placeholder="Enter Api Name"
              id="name"
            />
          </div>
          <div class="form-group">
            <label for="api_url">Driver</label>
            <input
              v-model="form.api_url"
              type="url"
              class="form-control"
              placeholder="Enter Api Url"
              id="api_url"
            />
          </div>
          <div class="form-group">
            <label for="api_url">Host</label>
            <input
              v-model="form.api_url"
              type="url"
              class="form-control"
              placeholder="Enter Api Url"
              id="api_url"
            />
          </div>
          <div class="form-group">
            <label for="api_key">Port</label>
            <input
              v-model="form.api_key"
              type="text"
              api_key=""
              class="form-control"
              placeholder="Enter Api Key"
              id="api_key"
            />
          </div>
          <div class="form-group">
            <label for="SenderId">Username</label>
            <input
              v-model="form.sender_id"
              type="text"
              SenderId=""
              class="form-control"
              placeholder="Enter Sender Id"
              id="SenderId"
            />
          </div>
          <div class="form-group">
            <label for="ClientId">Password</label>
            <input
              v-model="form.client_id"
              type="text"
              class="form-control"
              placeholder="Enter Client Id"
              id="ClientId"
            />
          </div>
          <div class="form-group">
            <label for="">Security</label>
            <select name="" id="" class="form-control">
              <option value="">TLS</option>
              <option value="">SSL</option>
              <option value="">Other</option>
            </select>
          </div>
        </form>
      </div>
    </div>

    <b-modal
      id="modal-lg"
      :title="modal_title"
      size="lg"
      hide-header-close
      hide-footer
    >
      <form @submit.prevent="submitHandelar" action="" class="card-body">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            v-model="form.name"
            type="text"
            name=""
            class="form-control"
            placeholder="Enter Api Name"
            id="name"
          />
        </div>
        <div class="form-group">
          <label for="api_url">Api Url</label>
          <input
            v-model="form.api_url"
            type="url"
            class="form-control"
            placeholder="Enter Api Url"
            id="api_url"
          />
        </div>
        <div class="form-group">
          <label for="api_key">Api Key</label>
          <input
            v-model="form.api_key"
            type="text"
            api_key=""
            class="form-control"
            placeholder="Enter Api Key"
            id="api_key"
          />
        </div>
        <div class="form-group">
          <label for="SenderId">Sender Id</label>
          <input
            v-model="form.sender_id"
            type="text"
            SenderId=""
            class="form-control"
            placeholder="Enter Sender Id"
            id="SenderId"
          />
        </div>
        <div class="form-group">
          <label for="ClientId">Client Id</label>
          <input
            v-model="form.client_id"
            type="text"
            class="form-control"
            placeholder="Enter Client Id"
            id="ClientId"
          />
        </div>
      </form>
      <button @click="close" class="btn btn-warning">Close</button>
      <button @click="submitHandelar" class="btn btn-primary">Save</button>
    </b-modal>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "EmailApi",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        api_url: "",
        name: "",
        api_key: "",
        sender_id: "",
        client_id: "",
        desc: "",
      }),
      editData: {
        mode: true,
        id: null,
      },
      modal_title: "New Api",
      items: null,
      default_getway: {},
      balance: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    checkBlance(id) {
      this.axios.get(`check-blance/${id}`).then((resp) => {
        console.log(resp.data);
        if (resp.data.ErrorCode == 0) {
          this.balance = resp.data.Data[0].Credits;
          this.$store.dispatch("spinnerLoading", false);
          this.$s_tost(resp.data.ErrorDescription);
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    close() {
      this.editData.mode = false;
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ========data save=========
    save() {
      this.form
        .post("sms-api")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========
    update() {
      this.form
        .put(`sms-api/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    async edit(data) {
      this.form.name = data.name;
      this.form.api_url = data.api_url;
      this.form.api_key = data.api_key;
      this.form.sender_id = data.sender_id;
      this.form.client_id = data.client_id;
      this.modal_title = "Update Api";
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("modal-lg");
    },

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`sms-api`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data.data;
          this.default_getway = resp.data.data.primary_getway;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },

    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`sms-api/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
