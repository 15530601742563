import axios from 'axios'

export default function auth({ next }) {
  if (localStorage.getItem('access_token')) {
    axios.get('single/user').then(resp => {
      if (resp.data.success) {
        next()
      } else {
        next('login')
      }
    })
  } else {
    localStorage.removeItem('access_token')
    next('/login')
  }
}
