<template>
	<div>
		<!-- <h5 class="text-center">Transmission Equipment {{type}}</h5> -->
		<template
      v-if="loading"
    >
      Loading ...
    </template>
    <template
      v-else
    >
      <DynamicForm
        :submitForm="submit"
        :form="form"
        :type="type"
        :fields="dynamicFormFields"
      />
    </template>
	</div>
</template>


<script>

import Form from "vform";
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "TransmisionEquipment",
	components: {  DynamicForm },
	props:  {
    "type": {
      type: String,
      default: 'create'
    },
    "id" : {
      type: Number,
      default: null
    }
  },
	data() {
	  return {

	  	form: new Form({
        name: null,
    		rack_id: null,
    		rack_u_position: 0,
    		brand_id: null,
    		has_nttn: 0,
    		nttn_id: null,
    		no_of_ports: 0,
    		sl_no: null,
    		has_mac: 0,
    		mac: null,
    		has_uplink_port: 0,
    		uplink_port: null,
    		default_ip: null,
    		has_vlan: 0,
    		vlan_id: null,
    		vlan_ip: null,
    		comment: null,
    		status: 1
	  	}),
  		apiEndPoint: 'nm-transmission-equipments',
  		statusOptions: [{
  			'title': 'Active',
  			'value': 1
  		}, {
  			'title': 'Inactive',
  			'value': 0
  		}],
  		booleanOptions: [{
  			'title': 'Yes',
  			'value': 1
  		}, {
  			'title': 'No',
  			'value': 0
  		}],
  		brands: [],
  		nttns: [],
  		racks: [],
      loading: false
	  }

	},
	emits: ['submitted'],
	computed: {
	  dynamicFormFields(){
	  	
  	  const formFields = [
  			{
  				'type': 'text',
  				'name': 'name',
  				'label': 'Name',
  			},
  			{
  				'type': 'select',
  				'name': 'rack_id',
  				'label': 'Rack',
  				'options': this.racks,
  				'option_settings': {
  					'title': 'detail_name',
  					'value': 'id',
  				},
  			},
  			{
  				'type': 'number',
  				'name': 'rack_u_position',
  				'label': 'Rack U Position',
  			},
  			{
  				'type': 'select',
  				'name': 'brand_id',
  				'label': 'Brand',
  				'options': this.brands,
  				'option_settings': {
  					'title': 'name',
  					'value': 'id',
  				}	
  			},
  			{
  				'type': 'select',
  				'name': 'has_nttn',
  				'label': 'Has NTTN',
  				'options': this.booleanOptions,
  			},
  			{
  				'type': 'number',
  				'name': 'no_of_ports',
  				'label': 'Total Number Of Ports',
  			},
  			{
  				'type': 'text',
  				'name': 'sl_no',
  				'label': 'Serial Number',
  			},
  			{
  				'type': 'select',
  				'name': 'has_mac',
  				'label': 'Has Mac',
  				'options': this.booleanOptions,
  			},
  			{
  				'type': 'select',
  				'name': 'has_uplink_port',
  				'label': 'Has Uplink Port',
  				'options': this.booleanOptions,
  			},
  			{
  				'type': 'text',
  				'name': 'default_ip',
  				'label': 'Default IP',
  			},
  			{
  				'type': 'select',
  				'name': 'has_vlan',
  				'label': 'Has Vlan',
  				'options': this.booleanOptions,
  			},
  			{
  				'type': 'textarea',
  				'name': 'comment',
  				'label': 'Comment',
  			},
  			{
  				'type': 'select',
  				'name': 'status',
  				'label': 'Status',
  				'options': this.statusOptions,
  				'disabled': true
  			},
  		]

  		if(this.form.has_mac == 1){
  			let has_mac_field_index = null
  			for (let i = 0; i < formFields.length; i++){
  				if(formFields[i].name === 'has_mac'){
  					has_mac_field_index = i
  					break
  				}
  			}

  			if(has_mac_field_index !== null){
  				formFields.splice(has_mac_field_index+1, 0, {
  					'type': 'text',
  					'name': 'mac',
  					'label': 'Mac',
  				})
  			}
  		}
  		if(this.form.has_uplink_port == 1){
  			let has_uplink_port_field_index = null
  			for (let i = 0; i < formFields.length; i++){
  				if(formFields[i].name === 'has_uplink_port'){
  					has_uplink_port_field_index = i
  					break
  				}
  			}

  			if(has_uplink_port_field_index !== null){
  				formFields.splice(has_uplink_port_field_index+1, 0, {
  					'type': 'text',
  					'name': 'uplink_port',
  					'label': 'Uplink Port',
  				})
  			}
  		}
  		if(this.form.has_nttn == 1){
  			let has_nttn_field_index = null
  			for (let i = 0; i < formFields.length; i++){
  				if(formFields[i].name === 'has_nttn'){
  					has_nttn_field_index = i
  					break
  				}
  			}

  			if(has_nttn_field_index !== null){
  				formFields.splice(has_nttn_field_index+1, 0, ...[{
  					'type': 'select',
  					'name': 'nttn_id',
  					'label': 'NTTN',
  					'options': this.nttns,
  					'option_settings': {
  						'title': 'name',
  						'value': 'id',
  					},
  				}])
  			}
  		}
  		if(this.form.has_vlan == 1){
  			let has_vlan_field_index = null
  			for (let i = 0; i < formFields.length; i++){
  				if(formFields[i].name === 'has_vlan'){
  					has_vlan_field_index = i
  					break
  				}
  			}

  			if(has_vlan_field_index !== null){
  				formFields.splice(has_vlan_field_index+1, 0, ...[{
  					'type': 'text',
  					'name': 'vlan_id',
  					'label': 'Vlan ID',
  				},
  				{
  					'type': 'text',
  					'name': 'vlan_ip',
  					'label': 'Vlan IP',
  				}])
  			}
  		}

		  return formFields
	  }
	},
	created() {
    if(this.type === 'edit'){
      this.edit()
    }
		this.getRacks()
		this.getBrands()
		this.getNttns()
	},
	watch: {
	},
	methods: {
    submit(){
      if(this.type === 'create'){
        this.create()
      } else if(this.type === 'edit'){
        this.update()
      }
    },
		create() {
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  
				  this.$emit('submitted', item)
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},
    edit() {
      this.loading = true
      axios.get(`${this.apiEndPoint}/${this.id}`).then((resp) => {
        if (resp.data.success) {
          let item = resp.data.data;
          this.form.id = item.id
          this.form.name = item.name
          this.form.rack_id = item.rack_id
          this.form.rack_u_position = item.rack_u_position
          this.form.brand_id = item.brand_id
          this.form.no_of_ports = item.no_of_ports
          this.form.sl_no = item.sl_no
          
          this.form.mac = item.mac
          if(this.form.mac){
            this.form.has_mac = 1
          } else {
            this.form.has_mac = 0
          }
          
          this.form.uplink_port = item.uplink_port
          if(this.form.uplink_port){
            this.form.has_uplink_port = 1
          } else {
            this.form.has_uplink_port = 0
          }

          this.form.default_ip = item.default_ip

          this.form.nttn_id = item.nttn_id
          if(this.form.nttn_id){
            this.form.has_nttn = 1
          } else {
            this.form.has_nttn = 0
          }

          this.form.vlan_id = item.vlan_id
          this.form.vlan_ip = item.vlan_ip
          if(this.form.vlan_id !== null || this.form.vlan_ip !== null){
            this.form.has_vlan = 1
          } else {
            this.form.has_vlan = 0
          }

          this.form.comment = item.comment
          this.form.status = item.status
        } else {
          
        }
      }).finally(()=> {
        this.loading = false
      });
    },
    update() {
      this.form.put(`${this.apiEndPoint}/${this.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.$emit('submitted', updatedItem)

          this.form.reset()

          this.$success_message(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

		getRacks() {
			axios.get(`nm-racks?page=1&item=all`).then((resp) => {
				if (resp.data.success) {
				  this.racks = resp.data.data.data;

				} else {
				  
				}
			}).finally(()=> {

			});
		},
		
		getBrands() {
			axios.get(`nm-brands?page=1&item=all`).then((resp) => {
				if (resp.data.success) {
				  this.brands = resp.data.data.data;

				} else {
				  
				}
			}).finally(()=> {

			});
		},

		getNttns() {
			axios.get(`nm-nttns?page=1&item=all`).then((resp) => {
				if (resp.data.success) {
					this.nttns = resp.data.data.data;
				} else {

				}
			}).finally(()=> {

			});
		},
 
	},
}
</script>