/* eslint-disable indent */
/* eslint-disable space-before-blocks */
/* eslint-disable comma-dangle */
/* eslint-disable space-infix-ops */
/* eslint-disable no-return-assign */
import axios from "axios";
import helper from "@/helper";
import Swal from "sweetalert2";
export default {
  state: {
    system_nas_type: "",
    spinner: false,
    access_token: localStorage.getItem("access_token") || null,
    /// ==============permisson list
    permissions: {},
    roles: [],
    departments: [],
    user: {
      authoraized: false,
      permission_list: [],
      info: {},
    },
    // ========== all permissions by auth user roles ===============
    roleWisePermitionsList: [],
    // ========== all permissions by auth user roles  end ===============

    users: {},
    app_balance: null,
    allmikrotiks: {},

    districts: {},

    //= ========
    zone: [],
    //= ========upazila
    upazila: [],
    districtWiseUpazile: [],
    nas: {},
    mikrotik: {},
    pop: {},
    franchises: {},
    others: {},
    general_setting: {},
    customer_edit_data: null,
    customer_data: null,
    dashboard_user: null,
  },
  getters: {
    login(state) {
      return state.access_token !== null;
    },
    getallmikrotiks(state) {
      return state.allmikrotiks;
    },
    user_details_info(state) {
      return state.users !== null;
    },
    getRoleWisePermissionslist(state) {
      return state.roleWisePermitionsList;
    },
    simpleQueue(state) {
      return state.simpleQueue;
    },
    // user
    dashboard_user(state) {
      return state.dashboard_user;
    },
  },
  mutations: {
    dashboard_user(state, payload) {
      return (state.dashboard_user = payload);
    },
    setToken(state, payload) {
      return (state.access_token = payload);
    },
    permissions(state, payload) {
      return (state.permissions = payload);
    },
    users(state, payload) {
      return (state.users = payload);
    },
    roles(state, payload) {
      return (state.roles = payload);
    },
    departments(state, payload) {
      return (state.departments = payload);
    },
    auth_user(state, payload) {
      return (state.user.authoraized = payload);
    },
    permission_list(state, payload) {
      return (state.user.permission_list = payload);
    },
    /*
     * roleWisePermitionsMuted
     * from roleWisePermitions action
     */
    roleWisePermitionsMuted(state, payload) {
      return (state.roleWisePermitionsList = payload);
    },
    districts(state, payload) {
      return (state.districts = payload);
    },
    /// ======  zones mutations
    zone(state, payload) {
      return (state.zone = payload);
    },
    /// ======  getallmikrotiks
    getallmikrotiks(state, payload) {
      return (state.allmikrotiks = payload);
    },
    /// ======  upazila
    upazila(state, payload) {
      return (state.upazila = payload);
    },

    /// ======  user_info
    user_info(state, payload) {
      return (state.user.info = payload);
    },
    districtWiseUpazile(state, payload) {
      return (state.districtWiseUpazile = payload);
    },
    nas(state, payload) { //mutations 
      return (state.nas = payload);
    },
    mikrotik(state, payload) {
      return (state.mikrotik = payload);
    },
    pop(state, payload) {
      return (state.pop = payload);
    },
    franchises(state, payload) {
      return (state.franchises = payload);
    },
    others(state, payload) {
      return (state.others = payload);
    },
    general_setting(state, payload) {
      state.system_nas_type = payload.nas_type ?? ""
      return (state.general_setting = payload);
    },
    spinnerLoadingmuted(state, payload) {
      state.spinner = payload;
    },
    customer_edit_mutation(state, payload) {
      state.customer_edit_data = payload;
    },
    customer_data_mutation(state, payload) {
      state.customer_data = payload;
    },
    app_net_balance(state, payload) {
      state.app_balance = payload;
    },
  },

  // actions
  actions: {
    // get_dashboard_user
    async get_dashboard_user(context, payload) {
      await axios
        .get("user", {
          params: {
            ...payload,
          },
        })
        .then((res) => {
          context.commit("dashboard_user", res.data.data);
        });
    },
    async get_app_net_balance(context) {
      await axios.get("app-net-balance").then((res) => {
        context.commit("app_net_balance", res.data.data);
      });
    },
    //spinnerLoading
    spinnerLoading(context, payload) {
      context.commit("spinnerLoadingmuted", payload);
    },
    //getUsers
    async getUsers(context) {
      await axios.get("show_all_customer").then((res) => {
        context.commit("users", res.data.data);
      });
    },

    // getallmikrotiks
    async getallmikrotiks(context) {
      await axios.get("allmikrotik").then((resp) => {
        context.commit("getallmikrotiks", resp.data.data);
      });
    },
    getPermissonList(context) {
      axios.get("permissions").then((resp) => {
        context.commit("permissions", resp.data.data);
      });
    },
    getRoles(context) {
      axios.get("role").then((resp) => {
        if (resp.data.success) {
          context.commit("roles", resp.data.data);
        }
      });
    },
    getDepartments(context) {
      axios.get("department").then((resp) => {
        if (resp.data.success) {
          context.commit("departments", resp.data.data);
        }
      });
    },
    async user(context) {
      const resp = await axios.get("single/user");
      if (resp.data.success) {
        context.commit("auth_user", true);
      } else {
        context.commit("auth_user", false);
        window.open("/login");
      }
    },
    //user role wise permissions
    async permission_list(context) {
      // const resp = await axios.get('user/permission/list')
      // console.log('lis',resp);
      // if (resp.data.success) {
      //   context.commit('permission_list', resp.data.data)
      // }
    },
    /*
     * roleWisePermitions
     * get all permissions by auth user roles
     */
    async roleWisePermitions(context) {
      const resp = await axios.get("user/permission/roleWisePermitions");
      if (resp.data.success) {
        context.commit("roleWisePermitionsMuted", resp.data.data);
      }
    },
    async districts(context, paylod) {
      const resp = await axios.get(`district?page=${paylod}`);
      if (resp.data.success) {
        context.commit("districts", resp.data.data);
      } else {
        if (resp.data.code == 403) {
          this.$error_message(resp.data.message, resp.data.errors);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      }
    },
    async getDistricts(context, paylod) {
      const resp = await axios.get(`district?page=${paylod}`);
      if (resp.data.success) {
        context.commit("districts", resp.data.data.data);
      } else {
        if (resp.data.code == 403) {
          this.$error_message(resp.data.message, resp.data.errors);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      }
    },

    //= ====zone Action
    async zone(context) {
      const resp = await axios.get("zone");
      if (resp.data.success) {
        context.commit("zone", resp.data.data);
      } else {
        this.$error_message(resp.data.message, resp.data.errors);
      }
    },
    //= ====upozilla Action
    async upazila(context) {
      const resp = await axios.get("upazila");
      if (resp.data.success) {
        context.commit("upazila", resp.data.data);
      } else {
        this.$error_message(resp.data.message, resp.data.errors);
      }
    },

    /// /=========get user info
    async userInfo(context) {
      const resp = await axios.get("single/user");
      if (resp.data.success) {
        context.commit("user_info", resp.data.data);
      } else {
        //  window.open('/login')
        this.$error_message(resp.data.message);
      }
    },

    /// /=========get nas data
    async nas(context, payload) {
      const resp = await axios.get(`nas?page=${payload}`);
      if (resp.data.success) {
        context.commit("nas", resp.data.data.data);
      } else {
        this.$error_message(resp.data.message);
      }
    },
    /// /=========get mikrotik data
    async mikrotik(context, payload) {
      const resp = await axios.get(`mikrotik?page=${payload}`);
      if (resp.data.success) {
        context.commit("mikrotik", resp.data.data);
      } else {
        this.$error_message(resp.data.message);
      }
    },
    /// /=========get pop data
    async pop(context, payload) {
      const resp = await axios.get(`pop?page=${payload}`);
      if (resp.data.success) {
        context.commit("pop", resp.data.data);
      } else {
        this.$error_message(resp.data.message);
      }
    },
    /// /=========get franchises data
    async franchises(context, payload) {
      const resp = await axios.get(`franchises?page=${payload}`);
      if (resp.data.success) {
        context.commit("franchises", resp.data.data);
      } else {
        this.$error_message(resp.data.message);
      }
    },
    /// /=========get franchises data
    async others(context, payload) {
      const resp = await axios.get("others", {
        params: {
          ...payload,
        },
      });
      if (resp.data.success) {
        context.commit("others", resp.data.data);
      } else {
        this.$error_message(resp.data.message);
      }
    },
    /// /=========get general_setting data
    async general_setting(context, payload) {
      const resp = await axios.get("general/setting", {
        params: {
          ...payload,
        },
      });

      if (resp.data.success) {
        context.commit("general_setting", resp.data.data);
      } else {
        console.log(resp.data.errors);
        Swal.fire({
          title: resp.data.message || "Error Establish",
          showCancelButton: false,
          text: resp.data.errors || "",
        });
      }
    },
    /// /====== District Wise Upazila
    async districtWiseUpazile(context, districtId) {
      const resp = await axios.get(`upazila/district/${districtId}`);
      if (resp.data.success) {
        context.commit("districtWiseUpazile", resp.data.upazila);
        return resp.data;
      }

      return "405";
    },
    // customer_edit action
    async customer_edit(context, data) {
      console.log("data", data);
      const resp = await axios.get(`customer/${data.id}`);
      if (resp.data.success) {
        context.commit("customer_edit_mutation", resp.data.data);
      }
    },
    // customer_data_action
    async customer_data_action(context, id) {
      const resp = await axios.get(`customer/${id}`);
      if (resp.data.success) {
        context.commit("customer_data_mutation", resp.data.data);
      }
    },
  },
};
