/* eslint-disable vue/no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable vue/html-indent */
/* eslint-disable vue/html-indent */
/* eslint-disable quote-props */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
<template>
  <div class="card p-2">
    <form id="form" @submit.prevent="save()">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="">Nas Name</label>
            <input v-model="form.nasname" name="nasname" type="text" class="form-control" placeholder="Enter Nas Name"
              validate />
          </div>
          <div class="form-group">
            <label for="">Nas Ip address</label>
            <input v-model="form.ipaddress" type="text" class="form-control" placeholder="Enter Ip address" validate
              name="Ip_address" />
          </div>
          <div class="form-group">
            <label for="">Select Nas Type</label>
            <select v-model="form.type" class="form-control">
              <option value="" selected disabled>
                --------Please Select One------
              </option>
              <option value="other">Mikrotik</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Nas Secret</label>
            <input v-model="form.secret" type="text" class="form-control" placeholder="Enter Nas Secret" />
          </div>
          <div class="form-group">
            <label for="">Nas Port</label>
            <input type="number" v-model="form.ports" class="form-control" placeholder="Enter Nas Port" />
          </div>
          <div class="form-group">
            <label for="">Incoming Port</label>
            <input v-model="form.incoming_port" type="text" class="form-control" placeholder="Incoming Port" />
          </div>
          <div class="form-group">
            <label for="">Community</label>
            <input v-model="form.community" type="text" class="form-control" placeholder="community" />
          </div>
          <div class="form-group">
            <label for="">Description</label>
            <input v-model="form.description" type="text" class="form-control" placeholder="Description" />
          </div>
          <div>
            <b-form-checkbox @change="toggleswitch" v-model="form.checked" name="check-button" switch>
              Set Mikrotik
              <b> <span v-if="!form.checked">Default: </span>{{ form.checked }}</b>
            </b-form-checkbox>
          </div>
        </div>
        <div v-if="form.checked" class="col-lg-6">
          <div class="form-group">
            <label for="">Select MikroTik</label>
            <select @change="selectmikrotik()" v-model="form.mikrotiks" class="form-control" id="">
              <option value="" selected>--------Please Select One------</option>
              <option v-for="mktitem in mikrotiklist" :key="mktitem.id" :value="mktitem">
                {{ mktitem.identity }} | {{ mktitem.host }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">MikroTik Identity</label>
            <input v-model="form.identity" name="nasname" type="text" class="form-control" placeholder="MikroTik Identity"
              validate />
          </div>
          <div class="form-group">
            <label for="">MikroTik IP</label>
            <input v-model="form.host" type="text" class="form-control" placeholder="MikroTik IP" validate
              name="Ip_address" />
          </div>

          <div class="form-group">
            <label for="">API User Name</label>
            <input v-model="form.username" type="text" class="form-control" placeholder="API User Name" validate />
          </div>
          <div class="form-group">
            <label for="">API User Password</label>
            <input v-model="form.password" type="text" class="form-control" placeholder="API  Password" validate />
          </div>
          <div class="form-group">
            <label for="">API Port</label>
            <input v-model="form.port" type="text" class="form-control" placeholder="API Port" validate />
          </div>
          <div class="form-group">
            <label for="">Site Name</label>
            <input v-model="form.sitename" type="text" class="form-control" placeholder="Site Name" validate />
          </div>
          <div class="form-group">
            <label for="">Address</label>
            <input v-model="form.address" type="text" class="form-control" placeholder="Address" validate />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 m-auto">
          <div class="d-flex">
            <button class="btn btn-primary btn-block">Submit</button>
            <router-link :to="{ name: 'ViewNas' }" class="btn btn-success mt-0 ml-3 btn-block">Back</router-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
export default {
  name: "NasAdd",
  data() {
    return {
      form: new Form({
        nasname: "",
        mikrotiks: "",
        mikrotik_id: null,
        shortname: "",
        ipaddress: "",
        type: "other",
        ports: null,
        secret: "secret",
        server_info: "",
        community: "",
        description: "RADIUS Client",
        identity: "",
        host: "",
        username: "",
        password: "",
        port: "",
        sitename: "",
        incoming_port: "",
        address: "",
        checked: false,
      }),
      mikrotiklist: [],
    };
  },
  methods: {
    async toggleswitch() {
      if (this.form.checked) {
        const resp = await this.axios.get("allmikrotik");
        if (resp.data.success) {
          this.mikrotiklist = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      } else {
        this.form.mikrotiks = "";
        this.form.mikrotik_id = "";
        this.form.identity = "";
        this.form.host = "";
        this.form.username = "";
        this.form.password = "";
        this.form.port = "";
        this.form.sitename = "";
        this.form.address = "";
      }
    },
    selectmikrotik() {
      console.log(this.form.mikrotiks);
      this.form.identity = this.form.mikrotiks.identity;
      this.form.mikrotik_id = this.form.mikrotiks.id;
      this.form.host = this.form.mikrotiks.host;
      this.form.password = this.form.mikrotiks.password;
      this.form.username = this.form.mikrotiks.username;
      this.form.port = this.form.mikrotiks.port;
      this.form.sitename = this.form.mikrotiks.sitename;
      this.form.address = this.form.mikrotiks.address;
    },
    save() {
      this.form
        .post("nas")
        .then((resp) => {
          if (resp.data.success) {
            this.$success_message(resp.data.message, true, "/ViewNas");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
