<template>
  <div class="">
    <b-row class="match-height m-auto">
      <div class="statistic_main_row">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.length"
          statistic-title="Total Franchise Packages"
        />
      </div>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(package_name)="data">
          <span>
            {{ data.item.package ? data.item.package.name : "Null" }}
          </span>
        </template>
        <template #cell(synonym)="data">
          {{ data.item.package ? data.item.package.synonym : "Null" }}
        </template>
        <template #cell(franchise_name)="data">
          {{ data.item.franchise ? data.item.franchise.name : "Null" }}
        </template>
        <template #cell(Price)="data">
          <span>
            {{
              data.item.price
                ? data.item.price
                : data.item.package.franchise_price
            }}
          </span>
        </template>

        <!-- <template #cell(pop_name)="data">
          {{ data.item.pops ? data.item.pops.name : "Null" }}
        </template> -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item v-b-modal="'price_' + data.item.id">
              <feather-icon icon="PlusCircleIcon" class="mr-50" />
              <span>Update Price</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteAction(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-modal
            :id="'price_' + data.item.id"
            title="Assign Package"
            hide-footer
          >
            <form action="" @submit.prevent="updatePrice(data.item.id)">
              <div class="from-group mb-3">
                <label for="price">Price</label>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  v-model="form.price"
                  id=""
                  placeholder="Enter Price"
                />
              </div>
              <input
                @click.prevent="closeModal(data.item.id)"
                type="submit"
                value="Cancel"
                class="btn btn-warning"
              />
              <input
                @click.prevent="updatePrice(data.item.id)"
                type="submit"
                value="Submit"
                class="btn btn-primary"
              />
            </form>
          </b-modal>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import Form from "vform";
const Swal = require("sweetalert2");

export default {
  name: "FranchisePackge",
  components: { BCardCode, StatisticCardHorizontal },

  data() {
    return {
      form: new Form({
        price: "",
      }),
      fields: [
        { key: "id", label: "#" },
        "package_name",
        "synonym",
        "franchise_name",
        "Price",
        "action",
      ],
      title: "Franchise Packge List",
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      items: [],
      statistic: {},
    };
  },
  created() {
    this.getData();
  },

  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(`price_${id}`);
      this.form.price = "";
    },
    async updatePrice(id) {
      this.$store.dispatch("spinnerLoading", true);
      await this.form
        .put(`package/assign/price-edit/${id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.closeModal(id);
            this.getData();
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      await axios
        .get(`package/assign/pop/list?page=${page}&type=franchise`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          console.log(resp.data);
          if (resp.data.success) {
            this.items = resp.data.data.data;
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    async deleteAction(id) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .get(`package/assign/list/${id}?type=franchise`)
            .then((resp) => {
              if (resp.data.success) {
                this.$success_message(resp.data.message);
                this.getData();
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
    // package/assign/list/
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
