<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.length"
          statistic-title="Total IP Pool"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.total_active"
          statistic-title="Active"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="summary.total_inactive"
          statistic-title="In Active"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modal-lg"
      :title="modal_title"
      size="lg"
      hide-header-close
      hide-footer
    >
      <form @submit.prevent="submitHandelar">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Type</label>
              <select
                v-model="form.type"
                class="form-control"
                validate
                name="type"
              >
                <option value="">----Please Select One--</option>
                <option value="Hotspot">Hotspot</option>
                <option value="PPPOE">PPPOE</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6">
            <label for="">Nas Type</label>
            <br />
            <div class="form-group">
              <label for="Radius">Radius</label>
              <input
                @change="selectMikrotik()"
                type="radio"
                v-model="form.nas_type"
                value="Radius"
                id="Radius"
              />
              <label for="MikroTik">MikroTik</label>
              <input
                @change="selectMikrotik()"
                type="radio"
                v-model="form.nas_type"
                value="MikroTik"
                id="MikroTik"
              />
            </div>
          </div>
          <div
            v-if="mikrotik_select"
            :class="fetch_api ? 'col-lg-5' : 'col-lg-6'"
          >
            <div class="form-group">
              <label for="">Mikrotik</label>
              <v-select
                v-model="selected_mikrotik"
                :options="select_options_mikrotik"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Name</label>
              <input
                v-model="form.name"
                type="text"
                class="form-control"
                placeholder="Name"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Subnet</label>
              <input
                v-model="form.subnet"
                type="text"
                class="form-control"
                placeholder="Sub Net"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Start Ip</label>
              <input
                v-model="form.start_ip"
                type="text"
                class="form-control"
                placeholder="Start Ip"
                name="start ip"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">End Ip</label>
              <input
                v-model="form.end_ip"
                type="text"
                class="form-control"
                placeholder="End Ip"
                name="end ip"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Zone</label>
              <v-select
                v-model="form.zone"
                :options="select_options_zone"
                multiple
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Total Number Of Ip</label>
              <input
                v-model="form.total_number_of_ip"
                type="text"
                class="form-control"
                placeholder="Total Number Of Ip"
                name="total_number_of_ip"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Assign Franchise</label>
              <v-select
                v-model="form.franchise"
                :options="select_options_franchise"
                multiple
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Assign Pop</label>
              <v-select
                v-model="form.pop"
                :options="select_options_pop"
                multiple
              />
            </div>
          </div>
        </div>
      </form>
      <button
        class="btn btn-primary"
        @click="submitHandelar"
        :disabled="form.busy"
      >
        Submit
      </button>
      <button @click="close" class="btn btn-warning">Close</button>
    </b-modal>

    <b-card-code no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              v-if="permission.add"
              size="sm"
              col="2"
              v-b-modal.modal-lg
              variant="primary"
              @click.prevent="editData.mode = false"
            >
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(status)="data">
          <b-form-checkbox
            v-if="permission.status"
            v-model="data.item.status == 1"
            name="check-button"
            switch
            @change="changeStatus(data.item.id)"
          ></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item v-if="permission.edit">
              <button class="btn btn-sm p-0" @click.prevent="edit(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </button>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.delete"
              v-b-modal="'radius_' + data.item.id"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>

          <b-modal
            :id="'radius_' + data.item.id"
            title="Add To Radius"
            hide-footer
          >
            <b-button
              block
              variant="success"
              @click="$confirm(`delete-ip-pool/${data.item.id}?type=1`)"
              ><span>Delete From only Database</span></b-button
            >
            <b-button
              block
              variant="primary"
              @click="$confirm(`delete-ip-pool/${data.item.id}?type=2`)"
              ><span>Delete From only Radious</span></b-button
            >
            <b-button
              block
              variant="danger"
              @click="$confirm(`delete-ip-pool/${data.item.id}?type=3`)"
              ><span>Delete From Radious and Database Both</span></b-button
            >
          </b-modal>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalNoOfRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "MikrotikIpPool",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      form: new Form({
        name: "",
        nas_type: "",
        type: "",
        subnet: "",
        zone: "",
        pop: "",
        total_number_of_ip: "",
        franchise: "",
        mikrotik: "",
        start_ip: "",
        end_ip: "",
        real_ip: "",
        real_ip_charge: "",
        have_real_ip: false,
        bandwidth: "",
      }),
      title: "IP Pool",
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name" },
        { key: "nas_type", label: "Nas Type", sortable: true },
        // {key: 'zone', label: 'Zone', sortable: true},
        {
          key: "total_number_of_ip",
          label: "total number of ip",
          sortable: true,
        },
        { key: "mikrotik", label: "Mikrotik", sortable: true },
        // {key: 'mac_address', label: 'Mac Address'},
        { key: "start_ip", label: "Start IP", sortable: true },
        { key: "end_ip", label: "End Ip", sortable: true },
        { key: "subnet", label: "Subnet", sortable: true },
        { key: "status", label: "status" },

        "action",
      ],
      items: [],
      selected_mikrotik: "",
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      modal_title: "IP Pool",
      select_options_zone: [],
      select_options_pop: [],
      select_options_franchise: [],
      select_options_mikrotik: [],
      fetch_api: false,
      mikrotik_select: false,
      summary: {
        total_user: 0,
        total_active: 0,
        total_inactive: 0,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    zone() {
      return this.$store.state.isp.others.zone;
    },
    pop() {
      return this.$store.state.isp.others.pop;
    },
    franchise() {
      return this.$store.state.isp.others.franchise;
    },
    mikrotik() {
      return this.$store.state.isp.others.mikrotik;
    },

    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("MikroTik Ip Pool");
      this.permission.add = this.checkpermission("Add MikroTik Ip Pool");
      this.permission.edit = this.checkpermission("Edit MikroTik Ip Pool");
      this.permission.delete = this.checkpermission("Delete MikroTik Ip Pool");
      this.permission.status = this.checkpermission(
        "Change MikroTik Ip Pool Status"
      );
    },
  },
  watch: {
    items() {
      this.loading = false;
    },

    zone(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: element.name,
          };
          this.select_options_zone.push(obj);
        });
      }
    },
    pop(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.name}-${element.mobile_number}-${element.email}`,
          };
          this.select_options_pop.push(obj);
        });
      }
    },
    franchise(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.name}-${element.mobile_number}-${element.email}`,
          };
          this.select_options_franchise.push(obj);
        });
      }
    },
    mikrotik(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.identity}-${element.host}`,
          };
          this.select_options_mikrotik.push(obj);
        });
      }
    },
    selected_mikrotik(value) {
      console.log(value);
      this.fetch_api = true;
      this.form.mikrotik = value.code;
      this.axios.get(`ippool/assign/mikrotik/${value.code}`).then((resp) => {
        console.log(resp);
        if (resp.data.success && resp.data.data.length > 0) {
          const options = {};
          resp.data.data.forEach((element) => {
            options[element.name] = `${element.name}`;
          });
          Swal.fire({
            title: "Please Select One",
            input: "select",
            inputOptions: options,
            inputPlaceholder: "Select One",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              const selectedData = resp.data.data.find(
                (ele) => ele.name === result.value
              );
              const ip = selectedData.ranges.split("-");
              this.form.name = selectedData.name;
              this.form.start_ip = ip["0"];
              this.form.end_ip = ip["1"];
            }
            this.fetch_api = false;
          });
        }
      });
    },
  },
  created() {
    this.getData();
    this.$store.dispatch("others", {
      pop: true,
      zone: true,
      franchise: true,
      mikrotik: true,
    });
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    selectMikrotik() {
      if (this.form.nas_type == "MikroTik") {
        this.mikrotik_select = true;
      } else {
        this.mikrotik_select = false;
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("pool")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.items.data.unshift(resp.data.data);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`pool/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$s_tost(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(data) {
      this.modal_title = "Update";
      this.form.name = data.name;
      this.form.nas_type = data.nas_type;
      this.form.type = data.type;
      this.form.subnet = data.subnet;
      this.form.zone = data.zone;
      this.form.pop = data.pop;
      this.form.total_number_of_ip = data.total_number_of_ip;
      this.form.franchise = data.franchise;
      this.form.mikrotik = data.mikrotik;
      this.form.start_ip = data.start_ip;
      this.form.end_ip = data.end_ip;
      this.form.real_ip = data.real_ip;
      this.form.real_ip_charge = data.real_ip_charge;
      this.form.have_real_ip = data.have_real_ip;
      this.form.bandwidth = data.bandwidth;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("modal-lg");
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },
    assignMikrotik() {
      console.log("apiiiiii");
    },
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`pool?page=${page}`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data;
          this.totalNoOfRows = resp.data.data.length;

          this.summary.total_active = this.items.filter(
            (item) => item.status == 1
          ).length;

          this.summary.total_inactive = this.items.filter(
            (item) => item.status == 0
          ).length;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`pool/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
