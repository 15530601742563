<template>
  <div>
    <b-card-code :title="title" no-body>
      <section class="invoice-add-wrapper">
        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <!-- Invoice Client & Payment Details -->
                <b-card-body class="invoice-padding pb-0">
                  <b-row>
                    <b-col v-if="form.id" cols="12" class="mb-lg-1">
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group disabled"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="form.id"
                          disabled
                        />
                      </b-input-group>
                    </b-col>
                    <b-col cols="12" lg="3" class="mb-lg-1">
                      <h6 class="mb-2">Type:</h6>
                      <!-- Select Client -->
                      <v-select
                        v-model="form.type"
                        label="name"
                        :options="[
                          { name: 'receiveable' },
                          { name: 'payable' },
                        ]"
                        :clearable="false"
                      >
                      </v-select>
                    </b-col>
                    <b-col cols="12" lg="3" class="mb-lg-1">
                      <h6 class="mb-2">Vendors:</h6>
                      <!-- Select Client -->
                      <v-select
                        v-model="form.vendor_id"
                        label="name"
                        :options="formItems.providers_items"
                        :reduce="(name) => name.id"
                        :clearable="false"
                      >
                      </v-select>
                    </b-col>
                    <b-col cols="12" lg="3" class="mb-lg-1">
                      <h6 class="mb-2">Issue Date:</h6>
                      <!-- Select Client -->
                      <flat-pickr
                        v-model="form.issue_date"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                </b-card-body>
                <!-- Items Section -->
                <b-card-body class="invoice-padding">
                  <div ref="form" class="repeater-form">
                    <b-row class="">
                      <b-col cols="12" class="table-responsive">
                        <table class="table border">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th width="50px">Item</th>
                              <th>Description</th>
                              <th>Unit Price</th>
                              <th>unit</th>
                              <th>Date Range</th>
                              <th>VAT (%)</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in form.items"
                              :key="index"
                            >
                              <td class="p-0">{{ index + 1 }}</td>
                              <td class="p-0">
                                <v-select
                                  class="select_input--area"
                                  style="width: 150px"
                                  v-model="item.item"
                                  label="name"
                                  :options="formItems.vendors"
                                  :selectable="
                                    (option) =>
                                      !formSelectedProduct.includes(option.id)
                                  "
                                  :reduce="(name) => name.id"
                                  placeholder="Select Product"
                                >
                                </v-select>
                              </td>
                              <td class="p-0 px-1">
                                <b-form-input
                                  style="width: 150px"
                                  v-model="item.description"
                                  type="text"
                                  class=""
                                  placeholde="Description"
                                />
                              </td>
                              <td class="p-0">
                                <b-form-input
                                  v-model="item.unit_price"
                                  type="number"
                                  placeholde="price"
                                  class=""
                                  style="min-width: 80px"
                                />
                              </td>
                              <td>
                                <b-form-input
                                  v-model="item.unit"
                                  type="number"
                                  placeholde="Unit"
                                  style="min-width: 50px"
                                  class=""
                                />
                              </td>
                              <td class="p-0">
                                <flat-pickr
                                  style="min-width: 200px"
                                  v-model="item.purchased_at"
                                  class="form-control"
                                  :config="{
                                    mode: 'range',
                                  }"
                                  placeholder="Date Range"
                                />
                              </td>
                              <td class="p-0 px-1">
                                <b-form-input
                                  style="min-width: 50px"
                                  v-model="item.vat"
                                  type="number"
                                  placeholde="0"
                                  class=""
                                />
                              </td>
                              <td class="p-0">
                                <p class="">
                                  ৳{{
                                    (
                                      item.unit_price *
                                      item.unit *
                                      (1 + item.vat / 100)
                                    ).toLocaleString("en-US")
                                  }}
                                </p>
                              </td>
                              <td class="p-0">
                                <button
                                  @click="removePurchaseFormItem(item, index)"
                                  class="cursor-pointer btn btn-sm btn-outline-danger"
                                >
                                  <feather-icon size="16" icon="Trash2Icon" />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="8"></td>
                              <td>
                                <b-button
                                  style="width: 100px"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  size="sm"
                                  variant="primary"
                                  @click="addNewItemInPurchaseForm"
                                >
                                  Add Item
                                </b-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
                <!-- Invoice Description: Total -->
                <b-card-body class="invoice-padding py-0">
                  <b-row>
                    <!-- Col: Total -->
                    <b-col cols="12" class="mt-md-6 d-flex justify-content-end">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Subtotal:</p>
                          <p class="invoice-total-amount mr-1">
                            ৳{{ formSubtotal.toLocaleString("en-US") }}
                          </p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">VAT(%):</p>
                          <p class="invoice-total-amount mr-1">
                            ৳{{ totalvat.toLocaleString("en-US") }}
                          </p>
                        </div>
                        <hr class="my-50" />
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Discount:</p>
                          <b-form-input
                            v-model="form.discount"
                            type="text"
                            class="form-control h-25 w-50 invoice-total-amount text-right"
                          />
                        </div>
                        <hr class="my-50" />
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Total:</p>
                          <p class="invoice-total-amount mr-1">
                            ৳{{ formTotal.toLocaleString("en-US") }}
                          </p>
                        </div>
                        <hr class="my-50" />
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Paid:</p>
                          <b-form-input
                            v-if="formMode == 'create'"
                            v-model="form.paid_amount"
                            type="text"
                            class="form-control h-25 w-50 invoice-total-amount text-right"
                          />
                          <p v-else class="invoice-total-amount mr-1">
                            ৳{{ form.paid_amount.toLocaleString("en-US") }}
                          </p>
                        </div>
                        <hr class="my-50" />
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Due:</p>

                          <p class="invoice-total-amount mr-1">
                            ৳{{ formDue.toLocaleString("en-US") }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" class="mt-md-6 d-flex justify-content-end">
                      <div class="my-2 float-left">
                        <b-button
                          class="px-4"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="primary"
                          block
                          @click="createPurchase"
                          :disabled="form.busy"
                        >
                          {{ form.busy ? "Saving ..." : "Save" }}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </section>
    </b-card-code>
  </div>
</template>
<script>
import Form from "vform";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { VBToggle } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import router from "@/router";
import axios from "axios";

export default {
  name: "Invoice",
  components: { BCardCode, StatisticCardHorizontal, flatPickr, Ripple },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      title: "Create Invoice",
      emptyFormItem: {
        item: null,
        description: "",
        unit: 0,
        vat: 0,
        unit_price: 0,
        total_price: 0,
      },
      form: new Form({
        type: "receiveable",
        vendor_id: null,
        issue_date: "",
        purchased_at: "",
        supplier_invoice_no: "",
        discount: 0,
        paid_amount: 0,
        items: [],
        subtotal: 0,
        vat_total: 0,
        due: 0,
        total: 0,
      }),
      formItems: {
        providers_items: [],
        vendors: [],
      },
      formMode: "create",
    };
  },
  computed: {
    formSubtotal() {
      let subtotal = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        subtotal += this.form.items[i].unit * this.form.items[i].unit_price;
      }
      this.form.subtotal = subtotal;
      return subtotal;
    },
    totalvat: function () {
      let vat = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        vat +=
          (this.form.items[i].unit_price *
            this.form.items[i].unit *
            this.form.items[i].vat) /
          100;
      }
      this.form.vat_total = vat;
      return vat;
    },
    formTotal() {
      const total = this.formSubtotal + this.totalvat - this.form.discount;
      this.form.total = total;
      return total;
    },
    formDue() {
      const due = this.formTotal - this.form.paid_amount;
      this.form.due = due;
      return due;
    },
    formSelectedProduct() {
      let product_ids = [];
      for (let i = 0; i < this.form.items.length; i++) {
        product_ids.push(this.form.items[i].product_id);
      }
      return product_ids;
    },
  },

  created() {
    this.addNewItemInPurchaseForm();
    this.getVandors();
    this.getProductItems();
  },
  methods: {
    getVandors() {
      axios
        .get(`vendor-provider?item=all&status=active`)
        .then((resp) => {
          this.formItems.providers_items = resp.data.data.data;
        })
        .finally(() => {
          this.$store.dispatch("spinnerLoading", false);
        });
    },
    getProductItems() {
      axios
        .get(`vendor-items?item=all&status=active`)
        .then((resp) => {
          this.formItems.vendors = resp.data.data.data;
        })
        .finally(() => {
          this.$store.dispatch("spinnerLoading", false);
        });
    },
    createPurchase() {
      this.form.post("vendor-invoice").then((resp) => {
        if (resp.data.success) {
          if (this.form.type == "receiveable") {
            router.push({ name: "Vendor-invoice-receiveable" });
          }
          this.$success_message(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    updatePurchase() {
      if (
        this.form.items.filter((item) => typeof item.id == "undefined").length >
        0
      ) {
      } else {
        this.submitUpdateForm();
      }
    },

    submitUpdateForm() {
      this.form.put(`inventory-purchases/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.items.every((item, index) => {
            if (item.id == updatedItem.id) {
              this.$set(this.items, index, updatedItem);
              return false;
            }
            return true;
          });
          this.form.reset();
          this.$success_message(resp.data.message);
          this.$bvModal.hide("purchase_form");
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    deletePurchase(item, index) {
      if (item && confirm("Are you sure to delete?")) {
        this.$store.dispatch("spinnerLoading", true);
        axios
          .delete(`inventory-purchases/${item.id}`)
          .then((resp) => {
            if (resp.data.success) {
              this.items.splice(index, 1);
              this.$success_message(resp.data.message);
            } else {
              this.$error_message(resp.data.message);
            }
          })
          .finally(() => {
            this.$store.dispatch("spinnerLoading", false);
          });
      }
    },
    removePurchaseFormItem(item, index) {
      if (typeof item.id == "undefined") {
        this.form.items.splice(index, 1);
      } else {
        if (item && confirm("Are you sure to delete?")) {
          axios
            .delete(`inventory-purchase-items/${item.id}`)
            .then((resp) => {
              if (resp.data.success) {
                this.form.items.splice(index, 1);
                this.$success_message(resp.data.message);
              } else {
                this.$error_message(resp.data.message);
              }
            })
            .finally(() => {});
        }
      }
    },
    addNewItemInPurchaseForm() {
      this.form.items.push(JSON.parse(JSON.stringify(this.emptyFormItem)));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.table tbody tr {
  text-align: center;
}
.select_input--area input {
  padding: 5px !important;
}
.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.invoice-total-wrapper {
  border: 1px solid #48484894;
  padding: 5px;
  max-width: 32rem !important;
}
</style>
