/* eslint-disable import/no-unresolved */
import Vue from "vue";
import VueRouter from "vue-router";
import userRoutes from "./user";
import packageRoutes from "./package";
import nasRoutes from "./nas";
import networkRoutes from "./network";
import zoneRoutes from "./zone";
import accounts from "./accounts";
import mikrotikRoutes from "./mikrotik";
import billingRoute from "./billing";
import hrmRoutes from "./hrm";
import auth from "../middleware/auth";
import guest from "../middleware/guest";
import ActionHistories from "../views/ActionHistories/ActionHistories.vue";
import UserActionHistories from "../views/ActionHistories/UserActionHistories.vue";
import SoftwareSetting from "../views/Setting/SoftwareSetting.vue";
import UserSettings from "../views/Setting/UserSettings.vue";
import ticketRoutes from "./ticket";
import vendorRoutes from "./vendor";
import sms from "./sms";
import inventory from "./inventory";
import report from "./report";
import networkManagerRoutes from "./network-manager";
// import store from '../store/index'
Vue.use(VueRouter);

const baseRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      middleware: [guest],
      layout: "full",
    },
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      middleware: [auth],
      pageTitle: "Home",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/settingGeneral",
    name: "settingGeneral",
    component: () => import("@/views/Setting/General.vue"),
    meta: {
      middleware: [auth],
      pageTitle: "Setting General",
      breadcrumb: [
        {
          text: "Setting General",
          active: true,
        },
      ],
    },
  },
  {
    path: "/system-settings",
    name: "systemSettings",
    component: () => import("@/views/Setting/System.vue"),
    meta: {
      middleware: [auth],
      pageTitle: "System Settings",
      breadcrumb: [
        {
          text: "System Setting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/software-setting",
    name: "softwareSetting",
    component: SoftwareSetting,
    meta: {
      middleware: [auth],
      pageTitle: "Software Settings",
      breadcrumb: [
        {
          text: "Software Setting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user-settings",
    name: "UserSettings",
    component: UserSettings,
    meta: {
      middleware: [auth],
      pageTitle: "User Settings",
      breadcrumb: [
        {
          text: "User Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/action-histories",
    name: "action-histories",
    component: ActionHistories,
    meta: {
      middleware: [auth],
      pageTitle: "Action Histories",
      breadcrumb: [
        {
          text: "Action Histories",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user-action-histories",
    name: "user-action-histories",
    component: UserActionHistories,
    meta: {
      middleware: [auth],
      pageTitle: "User Action Histories",
      breadcrumb: [
        {
          text: "User Action Histories",
          active: true,
        },
      ],
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const routes = baseRoutes.concat(
  userRoutes,
  packageRoutes,
  mikrotikRoutes,
  nasRoutes,
  networkRoutes,
  zoneRoutes,
  billingRoute,
  accounts,
  ticketRoutes,
  sms,
  inventory,
  report,
  vendorRoutes,
  hrmRoutes,
  networkManagerRoutes
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (!to.meta.middleware) {
//     return next()
//   }
//   const { middleware } = to.meta
//
//   const context = {
//     to,
//     from,
//     next,
//     store,
//   }
//   return middleware[0]({
//     ...context,
//   })
// })

// beforesac rouuter
// router.beforeEach((to, from, next) => {
//   if (!to.meta.middleware) {
//     return next()
//   }
//   const { middleware } = to.meta
//
//   const context = {
//     to,
//     from,
//     next,
//   }
//   return middleware[0]({
//     ...context,
//   })
//   // if (to.matched.some(record => record.meta.auth)) {
//   //   if (localStorage.getItem('access_token')) {
//   //     axios.get('single/user').then(resp => {
//   //       if (resp.data.success) {
//   //         next()
//   //       } else {
//   //         next('login')
//   //       }
//   //     })
//   //   } else {
//   //   // console.log('hello')
//   //     localStorage.removeItem('access_token')
//   //     next('/login')
//   //   }
//   // } else {
//   //   next()
//   // }
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
