<template>
	<div>
		<b-modal id="create_form" title="Create ODF Port"  hide-footer>
			<DynamicForm
				:submitForm="create"
				:form="form"
				type="create"
				:fields="dynamicFormFields"
			/>
		</b-modal>
		<b-modal id="update_form" title="Edit ODF Port"  hide-footer>
			<DynamicForm
				:submitForm="update"
				:form="form"
				type="edit"
				:fields="dynamicFormFields"
			/>
		</b-modal>

		<b-card no-body>

		    <b-card-header>
		      <div>
		      	<h4>{{title}}</h4>
		      </div>
		    </b-card-header>

	        <b-card-body>
	          <div class="d-flex justify-content-between flex-wrap">
	            <div class="d-flex">
	              <b-button
	                size="sm"
	                col="2"
	                v-b-modal.modal-lg
	                variant="primary"
	                @click="open_create_form"
	              >
	                <!-- @click.prevent="editData.mode = false" -->
	                <feather-icon icon="PlusCircleIcon" size="16" />
	                Add New</b-button
	              >
	              <!-- sorting  -->
	              <b-form-group
	                label="Sort"
	                label-size="sm"
	                label-align-sm="left"
	                label-cols-sm="3"
	                label-for="sortBySelect"
	                class="mb-0 ml-3"
	              >
	                <b-input-group size="sm">
	                  <b-form-select
	                    id="sortBySelect"
	                    v-model="sortBy"
	                    :options="sortOptions"
	                  >
	                    <template #first>
	                      <option value="">none</option>
	                    </template>
	                  </b-form-select>
	                  <b-form-select
	                    v-model="sortDesc"
	                    size="sm"
	                    label-cols-sm="4"
	                    :disabled="!sortBy"
	                  >
	                    <option :value="false">Asc</option>
	                    <option :value="true">Desc</option>
	                  </b-form-select>
	                </b-input-group>
	              </b-form-group>
	            </div>

	            <div class="d-flex justify-content-between">
	              <b-form-group
	                label="Filter"
	                label-cols-sm="3"
	                label-align-sm="left"
	                label-size="sm"
	                label-for="filterInput"
	                class="mb-0"
	              >
	                <b-input-group size="sm">
	                  <b-form-input
	                    id="filterInput"
	                    v-model="filter"
	                    type="search"
	                    placeholder="Type to Search"
	                  />
	                  <b-input-group-append>
	                    <b-button :disabled="!filter" @click="filter = ''">
	                      Clear
	                    </b-button>
	                  </b-input-group-append>
	                </b-input-group>
	              </b-form-group>
	            </div>
	            <!-- filter -->
	          </div>
	        </b-card-body>

	        <b-table
	          striped
	          hover
	          responsive
	          class="position-relative"
	          ref="table"
	          :per-page="perPage"
	          
	          :items="items"
	          :fields="fields"
	          :sort-by.sync="sortBy"
	          :sort-desc.sync="sortDesc"
	          :sort-direction="sortDirection"
	          :filter="filter"
	          :filter-included-fields="filterOn"
	          @filtered="onFiltered"
	        >


	        	<template #cell(fiber_core_id)="{item, index}">
	        	    {{item.fiber_core?item.fiber_core.detail_name:item.fiber_core_id}}
	        	</template>
	        	<template #cell(odf_id)="{item, index}">
	        	    {{item.odf?item.odf.name:item.odf_id}}
	        	</template>

	        	<template #cell(is_uplink)="{item, index}">
	        	  <b-badge
	        	    pill
	        	    :variant="`light-${item.is_uplink === 1?'success':'danger'}`"
	        	    class="text-capitalize"
	        	  >
	        	    {{item.is_uplink?'Yes':'No'}}
	        	  </b-badge>
	        	</template>

	        	<template #cell(status)="{item, index}">
	        	  <b-badge
	        	    pill
	        	    :variant="`light-${item.status === 1?'success':'danger'}`"
	        	    class="text-capitalize"
	        	  >
	        	    {{item.status?'Active':'Inactive'}}
	        	  </b-badge>
	        	</template>
	        	
	          <template #cell(action)="{item, index}">
	            <b-dropdown
	              variant="link"
	              toggle-class="text-decoration-none"
	              no-caret
	            >
	              <template v-slot:button-content>
	                <feather-icon
	                  icon="MoreVerticalIcon"
	                  size="16"
	                  class="text-body align-middle mr-25"
	                />
	              </template>
	              <b-dropdown-item @click.prevent="edit(item)">
	                <feather-icon icon="EditIcon" class="mr-50" />
	                Edit
	              </b-dropdown-item>
	              <b-dropdown-item @click.prevent="deleteItem(item, index)">
	                <feather-icon icon="Trash2Icon" class="mr-50" />
	                Delete
	              </b-dropdown-item>
	            </b-dropdown>
	          </template>
	        </b-table>

	        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
	          <!-- page length -->
	          <b-form-group
	            label="Per Page"
	            label-cols="6"
	            label-align="left"
	            label-size="sm"
	            label-for="sortBySelect"
	            class="text-nowrap mb-md-0 mr-1"
	          >
	            <b-form-select
	              id="perPageSelect"
	              v-model="perPage"
	              size="sm"
	              inline
	              :options="pageOptions"
	            />
	          </b-form-group>

	          <!-- pagination -->
	          <div>
	            <b-pagination
	              v-model="currentPage"
	              :total-rows="totalNoOfRows"
	              :per-page="perPage"
	              first-number
	              last-number
	              prev-class="prev-item"
	              next-class="next-item"
	              class="mb-0"
	            >
	              <template #prev-text>
	                <feather-icon icon="ChevronLeftIcon" size="18" />
	              </template>
	              <template #next-text>
	                <feather-icon icon="ChevronRightIcon" size="18" />
	              </template>
	            </b-pagination>
	          </div>
	        </b-card-body>
	    </b-card>
	</div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import DynamicForm from "/src/components/DynamicForm";

export default {
	name: "OdfPorts",
	components: { BCardCode, StatisticCardHorizontal, DynamicForm },
	data() {
	  return {
      title: "ODF Ports",
      form: new Form({
        fiber_core_id: null,
        odf_id: null,
        port_number: null,
        is_uplink: 0,
        meter_marking: null,
        dbm: null,
        comment: null,
        status:1
      }),
      fields: [
        
        { key: "id", label: "#", sortable: true },
        { key: "fiber_core_id", label: "Fiber Core", sortable: true },
        { key: "odf_id", label: "ODF", sortable: true },
        { key: "port_number", label: "Port Number", sortable: true },
        { key: "is_uplink", label: "Uplink", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      apiEndPoint: 'nm-odf-ports',
      booleanOptions: [{
			'title': 'Yes',
			'value': 1
		}, {
			'title': 'No',
			'value': 0
		}],
      statusOptions: [{
			'title': 'Active',
			'value': 1
		}, {
			'title': 'Inactive',
			'value': 0
		}],
	  odfs: [],
	  fiber_cores: [],
	  
	  }

	},
	computed: {
	  sortOptions() {
	    return this.fields
	      .filter((f) => f.sortable)
	      .map((f) => ({ text: f.label, value: f.key }));
	  },

	  dynamicFormFields(){
	  	const formFields = [
			{
				'type': 'select',
				'name': 'fiber_core_id',
				'label': 'Fiber Core',
				'options': this.fiber_cores,
				'option_settings': {
					'title': 'detail_name',
					'value': 'id',
				},
			},
			{
				'type': 'select',
				'name': 'odf_id',
				'label': 'ODF',
				'options': this.odfs,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				},
			},
			{
				'type': 'number',
				'name': 'port_number',
				'label': 'Port Number',
			},
			{
				'type': 'select',
				'name': 'is_uplink',
				'label': 'Is Uplink',
				'options': this.booleanOptions,
			},
			{
				'type': 'number',
				'name': 'meter_marking',
				'label': 'Meter Marking',
				'column_class': 'col-md-6'
			},
			{
				'type': 'text',
				'name': 'dbm',
				'label': 'dBM',
				'column_class': 'col-md-6'
			},
			{
				'type': 'textarea',
				'name': 'comment',
				'label': 'Comment',
			},
			{
				'type': 'select',
				'name': 'status',
				'label': 'Status',
				'options': this.statusOptions	
			},
		]

		return formFields
	  }
	},

	created() {
	  this.getData()
	  this.getOdfs()
	  this.getFiberCores()
	},
	watch: {
	    currentPage: {
	       handler: function(newVal, oldVal) {
	           this.getData(newVal); // call it in the context of your component object
	       },
	       deep: true
	    }
	  
	},
	methods: {
		getData(page = 1) {
		  this.$store.dispatch("spinnerLoading", true);
		  axios.get(`${this.apiEndPoint}?page=${page}&item=${this.perPage}`).then((resp) => {
		    if (resp.data.success) {
		      this.totalNoOfRows = resp.data.data.total
		      this.items = resp.data.data.data;

		    } else {
		      this.$error_message(resp.data.message);
		    }
		  }).finally(()=> {
		    this.$store.dispatch("spinnerLoading", false);
		  });
		},

		onFiltered(filteredItems) {
		  this.totalNoOfRows = filteredItems.length;
		  this.currentPage = 1;
		},
		create() {
			console.log('form', this.apiEndPoint)
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  this.items = [ ...this.items, item ]
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				  this.$bvModal.hide('create_form')
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},

	    open_create_form(){
	      this.form.reset()
	      this.$bvModal.show('create_form')
	  	},

	    edit(item){
	      this.form.reset()

	      this.form.id = item.id
	      this.form.fiber_core_id = item.fiber_core_id
	      this.form.odf_id = item.odf_id
	      this.form.port_number = item.port_number
	      this.form.is_uplink = item.is_uplink
	      this.form.meter_marking = item.meter_marking
	      this.form.dbm = item.dbm
  	      this.form.comment = item.comment
  	      this.form.status = item.status

	      this.$bvModal.show('update_form')
	    },
	    update(){
	      this.form.put(`${this.apiEndPoint}/${this.form.id}`).then((resp) => {
	        if (resp.data.success) {
	          let updatedItem = resp.data.data;

	          this.items.every((item, index) => {
	            if(item.id == updatedItem.id){
	              this.$set(this.items, index, updatedItem)
	              return false;
	            }
	            return true;
	          })
	          this.form.reset()
	          this.$success_message(resp.data.message);
	          this.$bvModal.hide('update_form')
	        } else {
	          this.$error_message(resp.data.message, resp.data.errors);
	        }
	      });
	    },
		deleteItem(item, index){
      		if(item && confirm('Are you sure to delete?')){
      		  this.$store.dispatch("spinnerLoading", true);
      		  axios.delete(`${this.apiEndPoint}/${item.id}`).then((resp) => {
      		    if (resp.data.success) {
      		      this.items.splice(index, 1)
      		      this.$success_message(resp.data.message);
      		    } else {
      		      this.$error_message(resp.data.message);
      		    }
      		  }).finally(()=> {
      		    this.$store.dispatch("spinnerLoading", false);
      		  });
      		}
		},

		getOdfs() {
		  axios.get(`nm-odfs?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.odfs = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},

		getFiberCores() {
		  axios.get(`nm-fiber-cores?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.fiber_cores = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},

 
	},
}
</script>