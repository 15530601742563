<template>
	<div>
		<b-card no-body>
		    <b-card-header>
		      <div>
		      	<h4>{{title}}</h4>
		      </div>
		    </b-card-header>

	        <b-card-body>
	          <div class="d-flex justify-content-between flex-wrap mb-2">
	            <div class="">
	               <b-dropdown
	                	size="sm"
						variant="primary"
						toggle-class="text-decoration-none"
						dropright
						no-caret
		            >
		              <template v-slot:button-content>
		                <feather-icon icon="PlusCircleIcon" size="16" />
	               		 Add New
		              </template>
		              <b-dropdown-item @click.prevent="addNewEntity('splitters')">
		                <feather-icon icon="DivideSquareIcon" class="mr-50" />
		                Splitter
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('enclousers')">
		                <feather-icon icon="HexagonIcon" class="mr-50" />
		                Enclouser
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('customers')">
		                <feather-icon icon="UserIcon" class="mr-50" />
		                Customer
		              </b-dropdown-item>
		              
		              <b-dropdown-divider />

		              <b-dropdown-item @click.prevent="addNewEntity('fibers')">
		                <feather-icon icon="GitMergeIcon" class="mr-50" />
		                Fiber
		              </b-dropdown-item>
		              
		              <b-dropdown-divider />

		              <b-dropdown-item @click.prevent="addNewEntity('pops')">
		                <feather-icon icon="HomeIcon" class="mr-50" />
		                Pop
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('racks')">
		                <feather-icon icon="DatabaseIcon" class="mr-50" />
		                Rack
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('routers')">
		                <feather-icon icon="HardDriveIcon" class="mr-50" />
		                Router
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('switches')">
		                <feather-icon icon="PackageIcon" class="mr-50" />
		                Switch
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('odfs')">
		                <feather-icon icon="CommandIcon" class="mr-50" />
		                ODF
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('olts')">
		                <feather-icon icon="CodesandboxIcon" class="mr-50" />
		                OLT
		              </b-dropdown-item>
		              <b-dropdown-item @click.prevent="addNewEntity('transmission_equipments')">
		                <feather-icon icon="ServerIcon" class="mr-50" />
		                Transmission Equipment
		              </b-dropdown-item>
		            </b-dropdown>
	            </div>
	            <!-- filter -->

	            <div class="align-items-center d-flex flex-wrap">
	            	<div class="custom-control-inline">
		            	<b-form-input
				          placeholder="Search By Cable ID ..."
				          v-model="searchKeyword"
				        />
				    </div>
		            <b-form-checkbox
		            	v-model="filters.customers"
						name="check-button"
						switch
						inline
				    >
				      Customer
				    </b-form-checkbox>
		            <b-form-checkbox
		            	v-model="filters.pops"
						name="check-button"
						switch
						inline
				    >
				      Pop
				    </b-form-checkbox>
		            <b-form-checkbox
		            	v-model="filters.enclousers"
						name="check-button"
						switch
						inline
				    >
				      Enclouser
				    </b-form-checkbox>
		            <b-form-checkbox
		            	v-model="filters.splitters"
						name="check-button"
						switch
						inline
				    >
				      Splitter
				    </b-form-checkbox>
		            <b-form-checkbox
		            	v-model="filters.paths"
						name="check-button"
						switch
						inline
				    >
				      Path
				    </b-form-checkbox>
				</div>
	          </div>


	           <b-row>
      				<b-col 
      					:md="'12'"
      				>
      					<div 
      						id="map" 
      						style="height: 400px;width: 100%"
      					>
      					</div>
      				</b-col>
      				

      				<CustomizePopup
      					:customizePopup="customizePopup"
      					:tempPath="tempPath"
      					:tempLatLon="tempLatLon"
      					:connectFiberCoreInfo="connectFiberCoreInfo"
      					:closeCustomizePopup="closeCustomizePopup"
      					:fiberCoreConnected="fiberCoreConnected"
      					:entitySubmitted="entitySubmitted"
      					:addConnector="addConnector"
      					:openCustomizePopup="openCustomizePopup"
      					@entityRemoved="entityRemoved"
      				/>
      			</b-row>
	        </b-card-body>

	    </b-card>
		<PopD3 
			v-if="d3.showPopup"
			:pop_id="d3.popId"
			@popupHidden="hideD3"
		/>
	</div>

</template>
<script>


import { BRow, BCol } from 'bootstrap-vue'
import { Loader } from '@googlemaps/js-api-loader';
import axios from "axios";
import DarkThemeJson from './dark-theme.json'
import CustomizePopup from './customize-popup'
import PopD3 from './pop-d3'

export default {
	name: "Map",
	components: { CustomizePopup, PopD3 },
	data() {
	  return {
	    title: "Map",
	    map: null,
	    google: null,
	    isDarkMode: true,
	    darkTheme: DarkThemeJson,
	    markers: [],
	    paths: [],
	    data: {},
	    activeMarker: null,
	    tempPath: [],
	    addingPath: false,
	    pathClickEventListener: null,
	    getLatLonClickEventListener: null,
	    connectFiberCoreInfo:{
	    	from: {
	    		title: null,
	    		id: null,
	    	},
	    	to: {
	    		title: null,
	    		id: null,
	    	}
	    },
	    customizePopup: {
	    	isOpened: false,
	    	type: null, // create, edit, view,
	    	entity: null, // pops, customers, routers, odfs, olts, switches, transmission_equipments, enclousers, splitters
	    	entityDetail: null,
	    },
	    tempLatLon: null,
	    markerIcons: {
	    	pops: 'http://maps.google.com/mapfiles/kml/paddle/P.png',
	    	splitters: 'http://maps.google.com/mapfiles/kml/paddle/S.png',
	    	enclousers: 'http://maps.google.com/mapfiles/kml/paddle/E.png',
	    	customers: 'http://maps.google.com/mapfiles/kml/paddle/C.png',
	    },
	    filters: {
	    	paths: true,
	    	enclousers: true,
	    	pops: true,
	    	splitters: true,
	    	customers: true,
	    },
	    searchKeyword: '',
	    d3: {
	    	showPopup: false,
	    	popId: null
	    }
	  }
	},
	computed: {
		activeMarkerData(){
			if (this.activeMarker === null) {
				return null
			}
			return this.data[this.activeMarker.type]?.find(item => item.id == this.activeMarker.id)
		}
	},

	mounted() {
		this.initMap({
			lat: 24.9076157,
			lng: 91.8697534
		})
		this.getData()
	},
	watch: {
		'filters': {
			handler(newVal, oldVal){
				let except = []
				if(this.filters.enclousers){
					except.push('enclousers')
				}
				if(this.filters.customers){
					except.push('customers')
				}
				if(this.filters.pops){
					except.push('pops')
				}
				if(this.filters.splitters){
					except.push('splitters')
				}

				this.hideMarkers(except)


				if(this.filters.paths){
					this.showPaths()
				} else {
					this.hidePaths()
				}
			},
			deep: true
		},
		'searchKeyword': function(newVal, oldVal){
			if(newVal.length > 1 || newVal.length == 0){
				this.getData()
			}
		}
	},

	methods: {
		initMap(lat_lon_value) {
			const vm = this
			if(this.$store.getters['app/googleMapKey']){
		  		const loader = new Loader({
		  		  apiKey: this.$store.getters['app/googleMapKey'],
		  		});


		  		const mapOptions = {
		  		  center: lat_lon_value,
		  		  zoom: 14
		  		};

		  		if(this.isDarkMode){
		  			mapOptions.styles = this.darkTheme
		  		}

		  		loader
				  .load()
				  .then((google) => {
				  	if(vm.google == null){
				  		vm.google = google
				  	}

				  	if(vm.map == null){
				    	vm.map = new vm.google.maps.Map(document.getElementById("map"), mapOptions);
				  	}
				  })
				  .catch(e => {
				    // do something
				  });
			}
		},
		setMarker(lat, lng, type, id, label = null, icon = null, detailData = null){
			const vm = this
			if(id != null && this.markers.length > 0){
				for(let i = 0; i < this.markers.length; i++){
					let marker = this.markers[i]
			
					// update marker data
					if(marker.id == id && marker.type == type){
						marker.setTitle(label)
						break;
					}

					if(i == this.markers.length-1){
						this.putMarker(lat, lng, type, id, label, icon, detailData)
					}
				}
			} else {
				this.putMarker(lat, lng, type, id, label, icon, detailData)
			}
			
		},
		putMarker(lat, lng, type, id, label = null, icon = null, detailData = null){
			if(
				this.data[type] && 
				this.data[type].findIndex(entity => entity.id == id) === -1 && 
				detailData 
			){
				this.data[type].push(detailData)
			}

			const vm = this
			let latLng = new this.google.maps.LatLng(lat, lng);
			let markerObject = {
                  position: latLng,
                  map: this.map
            }
            if(icon != null){
            	markerObject.icon = icon
            }
            if(type != null){
            	markerObject.type = type
            }
            if(label != null){
            	markerObject.title = label
            }
            if(id != null){
            	markerObject.id = id
            }
            let marker = new this.google.maps.Marker(markerObject);

            if(this.filters[type] == false){
            	marker.setMap(null)
            }

            let markerClickFunction;
			const infowindow = new vm.google.maps.InfoWindow();
	        
	        if(type === 'pops'){
				markerClickFunction = () => {
		        	let content = this.popDetailContent(this.data.pops.find(pop => pop.id == id), lat, lng, marker)
		        	
		        	infowindow.setContent(content)

				    vm.activeMarker = marker
					infowindow.open({
				      anchor: marker,
				      map: vm.map,
				      shouldFocus: false,
				    });
				}
	        } else if(id != null) {
	            markerClickFunction = () => {
	            	// vm.map.setCenter(marker.getPosition());
	            	vm.markers.forEach((mark => {
	            		if(mark.type === marker.type && mark.id === marker.id){
	            			mark.setAnimation(vm.google.maps.Animation.BOUNCE);	
	            		} else {
	            			mark.setAnimation(null);	
	            		}
	            	}))
				    vm.activeMarker = marker
				    if(vm.customizePopup.type !== 'connector'){
				    	vm.openCustomizePopup('view', type, detailData)
				    }

				    if(this.addingPath){
				    	this.tempPath.push(`${lat},${lng}`)
				    	this.drawPath(this.tempPath, 'purple')
				    	this.stopAddingPath()
				    }

				    if(this.connectFiberCoreInfo.from.title !== null){
				    	this.connectFiberCoreInfo.to.title = this.activeMarker.type
				    	this.connectFiberCoreInfo.to.id = this.activeMarker.id
				    }
				}
	        }
 			
 			let markerClickedEvent = null
 			if(typeof markerClickFunction === 'function'){
				markerClickedEvent = marker.addListener('click', markerClickFunction)
 			}

	        marker.addListener("mouseover", () => {
	        	if(['create', 'edit'].indexOf(this.customizePopup.type) > -1 && type !== 'pops'){
	        		if(markerClickedEvent){
	        			this.google.maps.event.removeListener(markerClickedEvent)
	        		}
	        	}
	        })
	        marker.addListener("mouseout", () => {
	        	if(['create', 'edit'].indexOf(this.customizePopup.type) > -1 && type !== 'pops'){
	        		if(typeof markerClickFunction === 'function'){
						markerClickedEvent = marker.addListener('click', markerClickFunction)
		 			}
	        	}
	        })


            this.markers.push(marker)
		},
		popDetailContent(detailData, lat, lng, marker){
			const vm = this
			let content = document.createElement('div')
			if(detailData && detailData.racks && detailData.racks.length > 0){
				for(let i = 0; i < detailData.racks.length; i++){
					if(i == 0){
						let button = document.createElement('button')
		        		button.classList.add('btn', 'btn-google', 'btn-sm', 'w-100')
		        		button.innerText = `D3`
		        		content.appendChild(button)
		        		content.appendChild(document.createElement('hr'))

		        		// For openning d3 tree
		        		button.addEventListener('click', (e)=>{
		        			vm.showD3(detailData.id)
		        		})
					} else if(i != 0){
	        			content.appendChild(document.createElement('hr'))
	        		}

	        		// Rack name 
	        		let rack_name_element = document.createElement('h5')
	        		rack_name_element.innerText = `Rack: ${detailData.racks[i].name}`
	        		content.appendChild(rack_name_element)
	        		content.appendChild(document.createElement('hr'))

	        		
	        		let rack_items = {
	        			routers: {
	        				'section-title': 'Router',
	        				'btn-class': 'btn-gradient-warning',
	        			},
	        			switches: {
	        				'section-title': 'Switch',
	        				'btn-class': 'btn-gradient-primary',
	        			},
	        			odfs: {
	        				'section-title': 'ODF',
	        				'btn-class': 'btn-gradient-success',
	        			},
	        			olts: {
	        				'section-title': 'OLT',
	        				'btn-class': 'btn-gradient-danger',
	        			},
	        			transmission_equipments: {
	        				'section-title': 'Transmission Equipment',
	        				'btn-class': 'btn-gradient-dark',
	        			},
	        		}

	        		for(let k = 0; k < Object.keys(rack_items).length; k++){
	        			let key = Object.keys(rack_items)[k]

	        			if(detailData.racks[i][key] && detailData.racks[i][key].length){
	        				
	        				// Rack item (e.g. routers) section title
			        		let section_title_element = document.createElement('h6')
			        		section_title_element.innerText = `${rack_items[key]['section-title']}`
			        		content.appendChild(section_title_element)
		        		
			        		// Rack item (e.g. routers) name showing in buttons. 
			        		for(let j = 0; j < detailData.racks[i][key].length; j++){
				        		let button = document.createElement('button')
				        		button.classList.add('btn', rack_items[key]['btn-class'], 'btn-sm')
				        		button.style.marginRight = '5px'
				        		button.innerText = `${detailData.racks[i][key][j].name}`
				        		content.appendChild(button)

				        		// Rack item click event for opening detail view (for connecting with other entity and other features)
				        		button.addEventListener('click', (e)=>{
				        			console.log(key, 'click')

					            	vm.markers.forEach((mark => {
					            		if(mark.type === marker.type && mark.id === marker.id){
					            			mark.setAnimation(vm.google.maps.Animation.BOUNCE);	
					            		} else {
					            			mark.setAnimation(null);	
					            		}
					            	}))

								    vm.activeMarker = marker
								    if(vm.customizePopup.type !== 'connector'){
								    	vm.openCustomizePopup('view', key, detailData.racks[i][key][j])
								    }

								    if(vm.addingPath){
								    	vm.tempPath.push(`${lat},${lng}`)
								    	vm.drawPath(vm.tempPath, 'purple')
								    	vm.stopAddingPath()
								    }

								    if(vm.connectFiberCoreInfo.from.title !== null){
								    	vm.connectFiberCoreInfo.to.title = key
								    	vm.connectFiberCoreInfo.to.id = detailData.racks[i][key][j].id
								    }
				        		})
			        		}

		        			content.appendChild(document.createElement('hr'))
		        		}
	        		}
	        	}
	        } else {
        		content.innerText = "No rack found!"
        	}
        	return content;
		},
		drawPath(lat_lons, color, path_id = null){
			const coordinates = []

			for(let i = 0; i < lat_lons.length; i++){
				coordinates.push({ 
					lat: Number(lat_lons[i].split(',')[0]), 
					lng: Number(lat_lons[i].split(',')[1])
				})
			}

			function getWorkableColor(color = 'blue'){
				if(color === 'slate'){
					return 'gray'
				} else if(color === 'rose'){
					return 'pink'
				}
				return color
			}

			// console.log('drawPath', coordinates)
			const path = new this.google.maps.Polyline({
			    path: coordinates,
			    geodesic: true,
			    strokeColor: getWorkableColor(color),
			    strokeOpacity: .8,
			    strokeWeight: 3,
			});
			path.addListener('click', (event)=>{
				this.paths.forEach(click_path => {
					if(click_path.id !== path_id){
						click_path.path.setOptions({
							strokeWeight: 3,
					    	strokeOpacity: .8,
						})
					} else {
						click_path.path.setOptions({
							strokeWeight: 6,
							strokeOpacity: 1,
						})
					}
				})
				
				this.openCustomizePopup('view', 'fiber_core_path', {id:path_id})
			})
			path.addListener('mouseover', (event)=>{
				// console.log('path mouseover', event, path)
				path.setOptions({
					strokeWeight: 6,
			    	strokeOpacity: 1,
				})
			})
			path.addListener('mouseout', (event)=>{
				// console.log('path mouseout', event, path)
				if(
					this.customizePopup.entity !== 'fiber_core_path' ||
					(this.customizePopup.entity === 'fiber_core_path' && this.customizePopup.entityDetail.id !== path_id)
				) {
					path.setOptions({
						strokeWeight: 3,
				    	strokeOpacity: .8,
					})
				}
			})

			if(this.filters.paths){
		  		path.setMap(this.map);
			}

		  	this.paths.push({
		  		id: path_id,
		  		path: path
		  	})
		},
		getData(){
			this.$store.dispatch("spinnerLoading", true);
			axios.get(`nm-map-data?keyword=${this.searchKeyword}`).then((resp) => {
				// console.log(resp)
			    if (resp.data.success) {
			    	this.removeExistingAllMarkersAndPaths(() => {
						this.data = resp.data.data;
						this.renderData()			    		
			    	})
			    } else {
			      this.$error_message(resp.data.message);
			    }
			}).finally(()=> {
			    this.$store.dispatch("spinnerLoading", false);
			});
		},
		renderData(){
			// marker.setMap(null)
			this.data?.pops.forEach((pop)=>{
				this.setMarker(
					pop.lat_lon.split(',')[0], 
					pop.lat_lon.split(',')[1], 
					'pops',
					pop.id,
					pop.name,
					this.markerIcons.pops,
					pop
				)
			})
			this.data?.enclousers.forEach((enclouser)=>{
				this.setMarker(
					enclouser.lat_lon.split(',')[0], 
					enclouser.lat_lon.split(',')[1], 
					'enclousers',
					enclouser.id,
					enclouser.name,
					this.markerIcons.enclousers,
					enclouser
				)
			})
			this.data?.splitters.forEach((splitter)=>{
				this.setMarker(
					splitter.lat_lon.split(',')[0], 
					splitter.lat_lon.split(',')[1], 
					'splitters',
					splitter.id,
					splitter.name,
					this.markerIcons.splitters,
					splitter
				)
			})
			this.data?.customers.forEach((customer)=>{
				this.setMarker(
					customer.lat_lon.split(',')[0], 
					customer.lat_lon.split(',')[1], 
					'customers',
					customer.id,
					customer.name,
					this.markerIcons.customers,
					customer
				)
			})
			// this.data?.fiber_cores.forEach((fibe_core)=>{
			// 	this.drawPath(
			// 		fibe_core.lat_lons,
			// 		fibe_core.core.color
			// 	)
			// })
			this.data?.paths.forEach((path)=>{
				let parsed_lat_lons = JSON.parse(path.lat_lons)
				if(typeof parsed_lat_lons !== 'object'){
					parsed_lat_lons = []
				}
				this.drawPath(
					parsed_lat_lons,
					path.color,
					path.id
				)

				// don't set the first and last lat_lon, as those coordinate alread
				// should have marker
				// for(let i = 1; i < parsed_lat_lons.length -1; i++){
				// 	this.setMarker(
				// 		parsed_lat_lons[i].split(',')[0], 
				// 		parsed_lat_lons[i].split(',')[1],
				// 		'fiber_core_path',
				// 		path.id,
				// 		null,
				// 		'http://maps.google.com/mapfiles/kml/paddle/red-circle-lv.png'
				// 	)
				// }


				// add direction icon
				//http://earth.google.com/images/kml-icons/track-directional/track-4.png
				/*
				if(parsed_lat_lons.length > 2 ){
					let middle_index = Math.ceil(parsed_lat_lons.length/2)-1
					this.setMarker(
						parsed_lat_lons[middle_index].split(',')[0], 
						parsed_lat_lons[middle_index].split(',')[1],
						'fiber_core_path',
						path.id,
						null,
						'http://maps.google.com/mapfiles/kml/paddle/go-lv.png',
						path
					)

				}
				*/
			})
		},
		openCustomizePopup(type = 'view', entity, detailData = null){
			this.removeTempPath()
			this.customizePopup.isOpened = true
			this.customizePopup.type = type
			this.customizePopup.entity = entity
			this.customizePopup.entityDetail = detailData
		},
		closeCustomizePopup(){
			this.customizePopup.isOpened = false
			this.customizePopup.type = null
			this.customizePopup.entity = null

			this.removeTempPath()

			this.markers.forEach((mark => {
        		mark.setAnimation(null);
        	}))
			this.paths.forEach((path => {
        		path.path.setOptions({
					strokeWeight: 3,
			    	strokeOpacity: .6,
				})
        	}))

			this.remveLatLonClickEvent()
			this.showTemporaryHiddenMarkerPath()
		},
		addPath(){
			this.addingPath = true;
			this.tempPath = []
			this.tempPath.push(`${this.activeMarker.position.lat()},${this.activeMarker.position.lng()}`)
			this.pathClickEventListener = this.map.addListener('click', this.pushPath)
		},
		pushPath(event) {
			// Because path is an MVCArray, we can simply append a new coordinate
			// and it will automatically appear.
			this.tempPath.push(`${event.latLng.lat()},${event.latLng.lng()}`);

			this.drawPath(this.tempPath, 'purple')
			// Add a new marker at the new plotted point on the polyline.

			// this.setMarker(
			// 	event.latLng.lat(), 
			// 	event.latLng.lng(), 
			// 	'fiber_core_path',
			// 	null,
			// 	null,
			// 	'http://maps.google.com/mapfiles/kml/paddle/red-circle-lv.png'
			// )
		},
		stopAddingPath(){
			this.addingPath = false;
			if(this.pathClickEventListener !== null){
				this.google.maps.event.removeListener(this.pathClickEventListener)
			}
		},
		addConnector(){
			if(this.connectFiberCoreInfo.from.title === null){
				this.connectFiberCoreInfo.from.title = this.customizePopup.entity
				this.connectFiberCoreInfo.from.id = this.customizePopup.entityDetail.id
				this.addPath()
				this.customizePopup.type = 'connector'
			} else {
				this.removeTempPath()
			}
			
		},
		removeTempPath(){
			this.stopAddingPath()
			this.tempPath = []
			this.connectFiberCoreInfo ={
		    	from: {
		    		title: null,
		    		id: null,
		    	},
		    	to: {
		    		title: null,
		    		id: null,
		    	}
		    }
		    let paths_length = this.paths.length
			for(let j = 0; j < paths_length; j++){
				if(this.paths[j].id == null && this.paths[j].path.getMap() !== null){
					this.paths[j].path.setMap(null)
				}
			}
		},
		fiberCoreConnected(created_path){
			this.closeCustomizePopup()
		    let paths_length = this.paths.length
			for(let j = 0; j < paths_length; j++){
				if(j == (paths_length - 1)){
					let parsed_lat_lons = JSON.parse(created_path.lat_lons)
					if(typeof parsed_lat_lons !== 'object'){
						parsed_lat_lons = []
					}
					this.drawPath(
						parsed_lat_lons,
						created_path.color,
						created_path.id
					)
					// don't set the first and last lat_lon, as those coordinate alread
					// should have marker
					// for(let i = 1; i < parsed_lat_lons.length -1; i++){
					// 	this.setMarker(
					// 		parsed_lat_lons[i].split(',')[0], 
					// 		parsed_lat_lons[i].split(',')[1],
					// 		'fiber_core_path',
					// 		created_path.id,
					// 		null,
					// 		'http://maps.google.com/mapfiles/kml/paddle/red-circle-lv.png'
					// 	)
					// }			
				}
			}
		},
		hidePaths(){
			this.paths.forEach(path => {
				if(path.path.getMap() !== null){
					path.path.setMap(null)
				}
			})
		},
		hideMarkers(except = []){
			this.markers.forEach(marker => {
				if(marker.getMap() !== null && except.indexOf(marker.type) === -1){
					marker.setMap(null)
				} else if(marker.getMap() === null && except.indexOf(marker.type) > -1){
					marker.setMap(this.map)
				}
			})
		},
		temporaryHideMarkerPath(except = []){
			this.hidePaths()
			this.hideMarkers(except)
		},
		showPaths(){
			if(this.filters.paths){			
				this.paths.forEach(path => {
					if(path.path.getMap() === null && path.id !== null){
						path.path.setMap(this.map)
					}
				})
			}
		},
		showMarkers(){
			this.markers.forEach(marker => {
				if(marker.getMap() === null && marker.id !== null){
					marker.setMap(this.map)
				}
			})
		},
		showTemporaryHiddenMarkerPath(){
			this.showPaths()
			this.showMarkers()
		},
		getLatLonByClickEvent(){
           	this.getLatLonClickEventListener = this.map.addListener("click", (mapsMouseEvent) => {
             	if(this.activeMarker){
             		this.activeMarker.setMap(null)
             	}
             	this.activeMarker = new google.maps.Marker({
                  position: mapsMouseEvent.latLng,
                  map: this.map
              	});

             	this.tempLatLon = `${mapsMouseEvent.latLng.lat()},${mapsMouseEvent.latLng.lng()}`
           	});
		},
		remveLatLonClickEvent(){
           	if(this.getLatLonClickEventListener !== null){
				this.google.maps.event.removeListener(this.getLatLonClickEventListener)
			}
           	this.tempLatLon = null;
           	if(this.activeMarker){
           		this.activeMarker.setMap(null)
           		this.activeMarker = null
           	}
		},
		addNewEntity(entity){
			let except = []
			except.push(entity)
			if(entity === 'pops'){
				this.getLatLonByClickEvent()
			} else if(entity === 'splitters'){
				except.push('enclousers')
				this.getLatLonByClickEvent()
			} else if(entity === 'enclousers'){
				except.push('splitters')
				this.getLatLonByClickEvent()
			} else if(entity === 'customers'){
				this.getLatLonByClickEvent()
			}  else if(['racks', 'routers', 'odfs', 'olts', 'switches', 'transmission_equipments'].indexOf(entity) > -1){
				except.push('pops')
			} 
			this.temporaryHideMarkerPath(except)
			this.openCustomizePopup('create', entity)
		},
		entitySubmitted(data){
			if(this.customizePopup.entity === 'customers'){
				this.setMarker(
					data.lat_lon.split(',')[0], 
					data.lat_lon.split(',')[1], 
					'customers',
					data.id,
					data.name,
					this.markerIcons.customers,
					data
				)
			} else if(this.customizePopup.entity === 'pops'){
				this.setMarker(
					data.lat_lon.split(',')[0], 
					data.lat_lon.split(',')[1], 
					'pops',
					data.id,
					data.name,
					this.markerIcons.pops,
					data
				)
			} else if(this.customizePopup.entity === 'splitters'){
				this.setMarker(
					data.lat_lon.split(',')[0], 
					data.lat_lon.split(',')[1], 
					'splitters',
					data.id,
					data.name,
					this.markerIcons.splitters,
					data
				)
			} else if(this.customizePopup.entity === 'enclousers'){
				this.setMarker(
					data.lat_lon.split(',')[0], 
					data.lat_lon.split(',')[1], 
					'enclousers',
					data.id,
					data.name,
					this.markerIcons.enclousers,
					data
				)
			} else if(this.customizePopup.entity === 'racks'){
				let pop_index = this.data.pops.findIndex(pop => pop.id == data.pop_id)
				if(pop_index > -1){
					if(this.data.pops[pop_index].racks === null || typeof this.data.pops[pop_index].racks === 'undefined'){
						this.data.pops[pop_index].racks = []
					}

					// push new rack
					if(this.customizePopup.type === 'create'){
						this.data.pops[pop_index].racks.push(data)
					}
					// update existing rack 
					else {
						let rack_index = this.data.pops[pop_index].racks.findIndex(rack => rack.id == data.id)
						if(rack_index > -1){
							this.data.pops[pop_index].racks[rack_index] = data
						}
					}
				}
			} else if(['routers', 'odfs', 'olts', 'switches', 'transmission_equipments'].indexOf(this.customizePopup.entity) > -1){
				let pop_index = this.data.pops.findIndex(pop => pop.id == data.rack.pop_id)
				if(pop_index > -1){
					if(this.data.pops[pop_index].racks){
						let rack_index = this.data.pops[pop_index].racks.findIndex(rack => rack.id == data.rack_id)
						if(this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity] === null || typeof this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity] === 'undefined'){
							this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity] = []
						}

						// push new entity
						if(this.customizePopup.type === 'create'){
							this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity].push(data)
						}
						// update existing entity 
						else {
							let entity_index = this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity].findIndex(entity => entity.id == data.id)
							if(entity_index > -1){
								this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity][entity_index] = data
							}
						}

					}
				}
			}

			this.closeCustomizePopup()
		},

		entityRemoved(){
			let data = this.customizePopup.entityDetail
			if(['customers', 'enclousers', 'splitters', 'pops'].indexOf(this.customizePopup.entity) > -1){
				for(let i = 0; i < this.markers.length; i++) {
					if(	
						this.customizePopup.entity === this.markers[i].type &&
						this.customizePopup.entityDetail.id == this.markers[i].id && 
						this.markers[i].getMap() !== null
					){
						this.markers[i].id = null
						this.markers[i].setMap(null)

						break;
					}
				}
			} else if(this.customizePopup.entity === 'racks'){ 
				let pop_index = this.data.pops.findIndex(pop => pop.id == data.pop_id)
				if(pop_index > -1 && this.data.pops[pop_index].racks){
					let rack_index = this.data.pops[pop_index].racks.findIndex(rack => rack.id == data.rack_id)
					if(rack_index > -1){
						this.data.pops[pop_index].racks.splice(rack_index, 1)
					}
				}
			} else if(['routers', 'odfs', 'olts', 'switches', 'transmission_equipments'].indexOf(this.customizePopup.entity) > -1){
				let pop_index = this.data.pops.findIndex(pop => pop.id == data.rack.pop_id)
				for (let pop_index = 0; pop_index < this.data.pops.length; pop_index++) {
					if(this.data.pops[pop_index].racks){
						let rack_index = this.data.pops[pop_index].racks.findIndex(rack => rack.id == data.rack_id)
						if(rack_index > -1 && this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity]){
							let entity_index = this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity].findIndex(entity => entity.id == data.id)
							if(entity_index > -1){
								this.data.pops[pop_index].racks[rack_index][this.customizePopup.entity].splice(entity_index, 1)
							}

							break;
						}
					}
				}
			} else if(this.customizePopup.entity === 'fiber_core_path'){
				for(let i = 0; i < this.paths.length; i++) {
					if(	
						this.customizePopup.entityDetail.id == this.paths[i].id && 
						this.paths[i].path.getMap() !== null
					){
						this.paths[i].id = null
						this.paths[i].path.setMap(null)

						break;
					}
				}
			}

			this.closeCustomizePopup()
		},
		removeExistingAllMarkersAndPaths(callback = null){
			const vm = this;
			function removeComplete(){
				vm.paths = []
				vm.markers = []

				if(typeof callback === 'function'){
					callback()
				}
			}
			function removeAllPaths(){
				for(let k = 0; k < vm.paths.length; k++){
					if(vm.paths[k].path.getMap() !== null){
						vm.paths[k].path.setMap(null)
					}

					if(k === (vm.paths.length - 1)){
						removeComplete()
					}
				}

				if(vm.paths.length == 0){
					removeComplete()
				}
			}
			function removeAllMarkers(){
				for(let i = 0; i < vm.markers.length; i++){
					if(vm.markers[i].getMap() !== null){
						vm.markers[i].setMap(null)
					}

					if(i === (vm.markers.length - 1)){
						removeAllPaths()
					}
				}

				if(vm.markers.length == 0){
					removeAllPaths()
				}
			}
			
			removeAllMarkers()
		},
		showD3(pop_id=null){
			this.d3.showPopup = true
			this.d3.popId = pop_id
		},
		hideD3(){
			this.d3.showPopup = false
			this.d3.popId = null                                         
		},
	}
}
</script>