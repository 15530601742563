<template>
	<div>
		<!-- fiber core -->
		<FiberCore
			:form="form"
		/>
		<hr>

		<!-- from -->
		<ConnectorSplitter	
			v-if="info.from.title === 'splitters'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>
		<ConnectorEnclouser	
			v-if="info.from.title === 'enclousers'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>
		<ConnectorCustomer	
			v-if="info.from.title === 'customers'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>
		<ConnectorRouter	
			v-if="info.from.title === 'routers'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>
		<ConnectorSwitch	
			v-if="info.from.title === 'switches'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>
		<ConnectorOdf	
			v-if="info.from.title === 'odfs'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>
		<ConnectorOlt	
			v-if="info.from.title === 'olts'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>
		<ConnectorTransmissionEquipment	
			v-if="info.from.title === 'transmission_equipments'"
			:form="form"
			:id="info.from.id"
			type="from"
		/>


		<hr/>

		<!-- to -->
		<ConnectorSplitter	
			v-if="info.to.title === 'splitters'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>
		<ConnectorEnclouser	
			v-if="info.to.title === 'enclousers'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>
		<ConnectorCustomer	
			v-if="info.to.title === 'customers'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>
		<ConnectorRouter	
			v-if="info.to.title === 'routers'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>
		<ConnectorSwitch	
			v-if="info.to.title === 'switches'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>
		<ConnectorOdf	
			v-if="info.to.title === 'odfs'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>
		<ConnectorOlt	
			v-if="info.to.title === 'olts'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>
		<ConnectorTransmissionEquipment	
			v-if="info.to.title === 'transmission_equipments'"
			:form="form"
			:id="info.to.id"
			type="to"
		/>


		<hr/>

		<!-- submit button -->
		<div 
			class="row mt-1 justify-content-center" 
		>
			<div class="col-lg-12">
			  	<button 
			  		type="button" 
			  		class="btn btn-primary btn-block" 
			  		:disabled="form.busy"
			  		@click="submitForm"
			  	>
			    	{{form.busy?'Submitting':'Submit'}}
			  	</button>
			</div>
		</div>
	</div>
</template>

<script>
import Form from "vform";
import ConnectorSplitter from './connectors/splitter.vue'
import ConnectorEnclouser from './connectors/enclouser.vue'
import ConnectorCustomer from './connectors/customer.vue'
import ConnectorRouter from './connectors/router.vue'
import ConnectorSwitch from './connectors/switch.vue'
import ConnectorOdf from './connectors/odf.vue'
import ConnectorOlt from './connectors/olt.vue'
import ConnectorTransmissionEquipment from './connectors/transmission-equipment.vue'
import FiberCore from './fiber-core.vue'
export default {
	name: "ConnectFiberCore",
	components: { 
		ConnectorSplitter, 
		FiberCore, 
		ConnectorEnclouser, 
		ConnectorCustomer, 
		ConnectorRouter, 
		ConnectorSwitch, 
		ConnectorOdf,
		ConnectorOlt,
		ConnectorTransmissionEquipment
	},
	props: {
		'lat_lons': Array,
		'info': Object
	},
	data() {
	  return {

		form: new Form({
			fiber_core: {},
			from: {},
			to: {},
		}),
	  }
	},
	emits: ['popupInfoChange'],
	computed: {

	},
	watch: {
	    lat_lons: {
	       handler: function(newVal, oldVal) {
	           this.form.fiber_core.lat_lons = this.lat_lons	
	       },
	       deep: true
	    }
	  
	},
	mounted(){
		this.form.fiber_core.lat_lons = this.lat_lons
		this.$emit('popupInfoChange', {
			key: 'title',
			value: 'Connect Fiber Core',
		})
	},
	methods:{
		submitForm(){
			this.form.post(`nm-fiber-core-map-datas/connect-fiber-core`).then((resp) => {
				if (resp.data.success) {
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()

				  this.$emit('fiber_core_connected', resp.data.data)
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		}
	}
}
</script>