/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <!-- v-if="permission.add" -->
        <b-button
          v-b-modal.modal-lg
          variant="primary"
          size="sm"
          @click.prevent="editData.mode = false"
        >
          <span>
            <feather-icon icon="PlusCircleIcon" size="16" />
            Add New
          </span>
        </b-button>
      </div>
      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="md">
        <form @submit.prevent="submitHandelar">
          <div class="form-group">
            <label for="">Title</label>
            <input
              autofocus="autofocus"
              v-model="form.title"
              type="text"
              class="form-control"
              name="title"
              placeholder="Title"
            />
          </div>
          <div class="form-group">
            <label for="">Details</label>
            <input
              autofocus="autofocus"
              v-model="form.details"
              type="text"
              class="form-control"
              name="details"
              placeholder="Details"
            />
          </div>
          <div class="form-group">
            <label for="">Status</label>
            <select v-model="form.status" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
              <option value="2">Terminate</option>
            </select>
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
        <template #modal-footer>
          <div class="btn btn-warning" @click="close" type="">Close</div>
        </template>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Details</th>
			  <th scope="col">No Of Employees</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(designation, index) in $store.state.hrm.designations.data"
              :key="index + 'designations'"
            >
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ designation.title }}</td>
              <td>{{ designation.details }}</td>
			  <td>{{ designation.no_of_employees.length }}</td>
              <td>{{ designation.status == 1 ? 'Active' : 'Inactive' }}</td>

              <td>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="permission.edit"
                    @click.prevent="edit(designation.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Edit
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="permission.delete"
                    :action="'designation/' + designation.id"
                    @click.prevent="$trash('designation/' + designation.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                      Delete
                    </button>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  nameL: "role",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        title: "",
        phone: "",
        password: "",
        email: "",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        permssions: false,
      },
    };
  },
  computed: {
    designations() {
      return this.$store.state.hrm.designations.data;
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Designation");
      this.permission.add = this.checkpermission("Designation Add");
      this.permission.edit = this.checkpermission("Designation Edit");
      this.permission.delete = this.checkpermission("Designation Delete");
    },
  },
  watch: {
    roles() {
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch("getDesignations");
  },
  methods: {
    //= ===handle the form close =========
    close() {
      this.form.title = "";
      this.form.details = "";
      this.form.status = "1";
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("designation")
        .then((resp) => {
          if (resp.data.success) {
            this.close();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getDesignations");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`designation/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.close();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getDesignations");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(id) {
      const designation = this.designations.find((ele) => ele.id === id);
      this.modal_title = `Update-${designation.title}`;
      this.form.title = designation.title;
      this.form.details = designation.details;
	  this.form.status = designation.status;
      this.editData.mode = true;
      this.editData.id = designation.id;
      this.$bvModal.show("modal-lg");
    },
  },
};
</script>
