<template>
    <div id="map" style="width: 100%; height: 200px"></div>
</template>

<script>
	import { Loader } from '@googlemaps/js-api-loader';
	export default {
		name: "GoogleMapLoad",
		props: {
			'lat_lon': String, 
			'model_name': String, 
		},
		data() {
	  		return {

	  		}
	  	},
	  	mounted() {
	  		const vm = this

	  		if(this.$store.getters['app/googleMapKey']){  			
		  		const loader = new Loader({
		  		  apiKey: this.$store.getters['app/googleMapKey'],
		  		});

		  		// default value
		  		let lat_lon_value = {
		  		    lat: 24.9076157,
		  		    lng: 91.8697534
		  		}

		  		if(this.lat_lon && this.lat_lon.split(',').length == 2){
		  		    lat_lon_value.lat = Number(this.lat_lon.split(',')[0].trim()),
		  		    lat_lon_value.lng = Number(this.lat_lon.split(',')[1].trim())
		  		}


		  		const mapOptions = {
		  		  center: lat_lon_value,
		  		  zoom: 14
		  		};

		  		loader
				  .load()
				  .then((google) => {
				    const map = new google.maps.Map(document.getElementById("map"), mapOptions);

				    let latLng = new google.maps.LatLng(mapOptions.center.lat, mapOptions.center.lng);
	                  let marker = new google.maps.Marker({
	                      position: latLng,
	                      map: map
	                  });

	                  // Configure the click listener.
	                   map.addListener("click", (mapsMouseEvent) => {
	                     	marker.setMap(null)
	                     	marker = new google.maps.Marker({
		                      position: mapsMouseEvent.latLng,
		                      map: map
		                  	});
	                     	vm.$emit('updateLatLon', `${mapsMouseEvent.latLng.lat()},${mapsMouseEvent.latLng.lng()}`, vm.model_name)
	                   });
				  })
				  .catch(e => {
				    // do something
				  });
	  		}
	  	},
	  	methods: {

	  	}
	}
</script>