<template>
  <div class="shadow" v-if="userData">
    <div class="row">
      <!-- User Sidebar -->
      <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
        <!-- User Card -->
        <div class="card">
          <div class="card-body">
            <div class="user-avatar-section">
              <div class="d-flex align-items-center flex-column">
                <img
                  class="user_avatar--img img-fluid rounded mt-3 mb-2"
                  :src="
                    avater_image !== ''
                      ? $store.state.app.assets_url + avater_image
                      : require('@/assets/images/avatars/13-small.png')
                  "
                  height="110"
                  width="110"
                  :alt="avater_image"
                />
                <div class="d-flex">
                  <div v-if="!is_change_avater">
                    <label
                      for="avater_image"
                      class="edit_image--icon btn btn-primary btn-sm mb-1"
                    >
                      <feather-icon size="20" icon="EditIcon" />
                    </label>
                    <input
                      @change="changetAvaterImage"
                      type="file"
                      class="hidden"
                      id="avater_image"
                    />
                  </div>
                  <div v-else>
                    <div
                      @click.prevent="saveAvaterImage()"
                      for="avater_image"
                      class="edit_image--icon btn btn-success btn-sm mb-1 mx-1"
                    >
                      Save
                    </div>
                    <div
                      @click.prevent="cancelChangeAvater()"
                      for="avater_image"
                      class="edit_image--icon btn btn-warning btn-sm mb-1"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
                <div class="user-info text-center">
                  <h4 class="text-capitalize">{{ userData.name }}</h4>
                  <span class="badge bg-light-secondary text-capitalize">{{
                    userData.type
                  }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-around my-2 pt-75">
              <div class="d-flex align-items-start">
                <span class="badge bg-light-primary p-75 rounded mr-1">
                  <feather-icon size="16" icon="UserIcon" class="" />
                </span>
                <div class="ms-75">
                  <h4 class="mb-0">{{ userData.agent_user.length }}</h4>
                  <small>Customers</small>
                </div>
              </div>
              <div class="d-flex align-items-start me-2">
                <span class="badge bg-light-primary p-75 rounded mr-1">
                  <feather-icon size="16" icon="DollarSignIcon" class="" />
                </span>
                <div class="ms-75">
                  <h4 class="mb-0 badge badge-light-info">
                    {{ userData.balance }}
                  </h4>
                  <small>Balance</small>
                </div>
              </div>
            </div>
            <h4 class="fw-bolder border-bottom pb-50 mb-1">Details</h4>
            <div class="info-container">
              <ul class="list-unstyled">
                <li class="mb-75">
                  <span class="fw-bolder me-25">Username:</span>
                  <span>{{ userData.name }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Billing Email:</span>
                  <span>{{ userData.email }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Status:</span>
                  <span class="badge bg-light-success">{{
                    userData.status == 1 ? "Active" : "Inactive"
                  }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Role:</span>
                  <span>{{ userData.type }}</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25"
                    >Contact:
                    <input
                      v-if="is_changePhone"
                      type="text"
                      class="form-control"
                      v-model="phone_no"
                    />
                    <span v-else>{{ userData.phone }} </span>
                    <span
                      v-if="!is_changePhone"
                      class="btn btn-sm text-primary"
                      @click.prevent="editPhone(userData.phone)"
                    >
                      <feather-icon size="20" icon="EditIcon" />
                    </span>
                    <div v-if="is_changePhone" class="mt-1">
                      <span
                        class="btn btn-sm btn-primary"
                        @click.prevent="saveUserTableData('save')"
                      >
                        <feather-icon size="20" icon="SaveIcon" />
                      </span>
                      <span
                        class="btn btn-sm btn-danger"
                        @click.prevent="saveUserTableData('cancel')"
                      >
                        <feather-icon size="20" icon="XCircleIcon" />
                      </span>
                    </div>
                  </span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Language:</span>
                  <span>Bangali</span>
                </li>
                <li class="mb-75">
                  <span class="fw-bolder me-25">Country:</span>
                  <span>Bangladesh</span>
                </li>
              </ul>
              <div v-if="$route.params.id == user.id"></div>
            </div>
          </div>
        </div>
      </div>
      <!--/ User Sidebar -->

      <!-- User Content -->
      <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
        <!-- User Pills -->
        <ul class="nav nav-pills mb-2">
          <li @click.prevent="changetab('account')" class="nav-item">
            <a v-bind:class="tab == 'account' ? 'nav-link active' : 'nav-link'">
              <i data-feather="user" class="font-medium-3 me-50"></i>
              <span class="fw-bold">Details</span></a
            >
          </li>
          <li class="nav-item" @click.prevent="changetab('security')">
            <a
              v-bind:class="tab == 'security' ? 'nav-link active' : 'nav-link'"
            >
              <i data-feather="lock" class="font-medium-3 me-50"></i>
              <span class="fw-bold">Security</span>
            </a>
          </li>
          <li class="nav-item" @click.prevent="changetab('customers')">
            <a
              v-bind:class="tab == 'customers' ? 'nav-link active' : 'nav-link'"
              class="nav-link"
            >
              <i data-feather="lock" class="font-medium-3 me-50"></i>
              <span class="fw-bold">Assigned User</span>
            </a>
          </li>
        </ul>
        <!--/ User Pills -->
        <div v-if="tab == 'account'">
          <!-- Project table -->
          <div class="card">
            <h4 class="card-header">Basic Info</h4>
            <div class="card-body">
              <strong>Addess:</strong> {{ userData.agent_details.address }}
              <br />
              <strong>Commission Rate:</strong>
              {{ userData.agent_details.commission_rate }}
              {{ userData.agent_details.commision_type == 1 ? "%" : "Tk" }}
              <br />
              <strong>BIlling Data:</strong>
              {{ userData.agent_details.billing_date }} <br />
            </div>
          </div>
          <div class="card">
            <h4 class="card-header text-capitalize">
              Commission History
              <div
                v-if="form.selectd_number.length && permission.commission"
                class=""
              >
                <button
                  @click.prevent="makePayment('payment')"
                  class="btn btn-sm btn-outline-success"
                >
                  Make Payment
                </button>
                <button
                  @click.prevent="makePayment('pending')"
                  class="btn btn-sm btn-outline-danger"
                >
                  Refund Commission
                </button>
              </div>
            </h4>
            <table class="invoice-table table text-nowrap table-responsive">
              <thead>
                <tr>
                  <th>
                    <b-form-checkbox
                      v-model="checkallnumber"
                      @change="selectAll()"
                    >
                    </b-form-checkbox>
                  </th>
                  <th>#No</th>
                  <th class="cell-fit">Customer Name</th>
                  <th class="text-truncate">Commission Amount</th>
                  <th class="text-truncate">Commission Rate</th>
                  <th class="text-truncate">Status</th>
                  <th class="text-truncate">Create Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userData.agent_details
                    .agent_commission_history"
                  :key="index"
                >
                  <th scope="row">
                    <b-form-checkbox
                      v-model="form.selectd_number"
                      :value="item"
                    >
                    </b-form-checkbox>
                  </th>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.user.name }}</td>
                  <td>{{ item.commission }} TK</td>
                  <td>
                    {{ userData.agent_details.commission_rate }}
                    {{
                      userData.agent_details.commision_type == 1 ? "%" : "Tk"
                    }}
                  </td>
                  <td>
                    <span
                      :class="`badge text-capitalize ${
                        item.payment_status == 'pending'
                          ? 'badge-light-secondary'
                          : 'badge-light-success'
                      }`"
                      >{{ item.payment_status }}</span
                    >
                  </td>
                  <td>{{ formatDateTimeOne(item.created_at) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /Project table -->
        </div>
        <div v-else-if="tab == 'security'">
          <form action="">
            <div class="row">
              <div class="col-sm-12">
                <label for="current_confirmation">Current password</label>
                <b-input-group>
                  <b-form-input
                    v-model="restPasswordform.current_password"
                    id="current_confirmation"
                    :type="passwordFieldType"
                    placeholder="Current Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm-12">
                <label for="new_password">New confirmation</label>
                <b-input-group>
                  <b-form-input
                    v-model="restPasswordform.current_password"
                    id="new_password"
                    :type="passwordFieldType"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm-12">
                <label for="password_confirmation">password confirmation</label>
                <b-input-group>
                  <b-form-input
                    v-model="restPasswordform.password_confirmation"
                    id="password_confirmation"
                    :type="passwordFieldType"
                    placeholder="Confirmation Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility('confirm')"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
            <button class="mt-2 btn btn-sm btn-primary">Submit</button>
          </form>
        </div>
        <div v-else-if="tab == 'customers'" class="card">
          <h4 class="card-header">Assigned Users</h4>
          <table class="invoice-table table text-nowrap table-responsive">
            <thead>
              <tr>
                <th>#No</th>
                <th class="text-truncate">User id</th>
                <th class="text-truncate">Name</th>
                <th class="cell-fit">Connection Date</th>
                <th class="cell-fit">Package Price</th>
                <th class="cell-fit">Total Commission</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in userData.agent_user" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    item.user.billing_info
                      ? item.user.billing_info.user_reference_number
                      : null
                  }}
                </td>
                <td>{{ item.user.name }}</td>
                <td>
                  {{
                    item.user.billing_info
                      ? item.user.billing_info.connection_date
                      : "Null"
                  }}
                </td>
                <td>
                  {{
                    item.user.billing_info
                      ? item.user.billing_info.package.price
                      : "Null"
                  }}
                </td>
                <td>{{ commissionSum(item.user.agentcommissions) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--/ User Content -->
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";
import axios from "axios";
import ticket from "@/router/ticket";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
export default {
  nameL: "AgentProfile",
  components: {
    BButton,
    BModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: "",
      tab: "account",
      userData: null,
      is_change_avater: false,
      is_changePhone: false,
      phone_no: "",
      avater_image: "",
      fileUploadData: new Form({
        file: "",
        id: this.$route.params.id,
        db_table: "users",
      }),
      form: new Form({
        action: "payment",
        commission: 0,
        selectd_number: [],
      }),
      restPasswordform: new Form({
        current_password: "",
        new_password: "",
        password_confirmation: "",
        change_field: "change_password",
        user_id: this.$route.params.id,
      }),
      permission: {
        commission: false,
      },
      checkallnumber: false,
    };
  },
  watch: {
    "form.selectd_number"() {
      if (
        this.form.selectd_number.length ===
          this.userData.agent_details.agent_commission_history.length &&
        !this.checkallnumber
      ) {
        this.checkallnumber = true;
      } else if (
        this.form.selectd_number.length !==
          this.userData.agent_details.agent_commission_history.length &&
        this.checkallnumber
      ) {
        this.checkallnumber = false;
      }
    },
  },
  mounted() {
    this.getdata();
  },

  computed: {
    passwordToggleIcon(action) {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    getRoleWisePermissions() {
      this.permission.commission = this.checkpermission("agents_commission_payment");
    },
  },
  methods: {
    async makePayment(action) {
      this.form.action = action;
      this.form.commission = this.form.selectd_number.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.commission;
        },
        0
      );
      await this.Swal.fire({
        title: `${this.form.commission} Tk`,
        text: `Are you sure? You went to ${
          action == "payment" ? "Payment" : "Refund"
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.form
            .put(`agent/commission-payment/${this.$route.params.id}`)
            .then((resp) => {
              if (resp.data.success) {
                this.$s_tost(resp.data.message);
                this.form.action = null;
                this.form.commission = null;
                window.location.reload();
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
    selectAll() {
      if (this.checkallnumber) {
        this.form.selectd_number =
          this.userData.agent_details.agent_commission_history.map(
            (item) => item
          );
      } else {
        this.form.selectd_number = [];
      }
    },

    uncheckAll() {
      if (!this.selectedNumbers.length || !this.checkAll) {
        this.checkAll = false;
      }
      if (!this.selectd_number.length || !this.checkallnumber) {
        this.checkallnumber = false;
      }
    },
    commissionSum(val) {
      return val.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.commission;
      }, 0);
    },

    getdata() {
      axios
        .get(`agents/${this.$route.params.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.userData = resp.data.data;
            this.avater_image = resp.data.data.avatar;
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    changetAvaterImage(e) {
      const file = e.target.files[0];
      this.avater_image = URL.createObjectURL(file);
      this.is_change_avater = true;
      this.fileUploadData.file = e.target.files[0];
    },

    saveAvaterImage() {
      this.fileUploadData
        .post(`save-file`)
        .then((resp) => {
          if (resp.data.success) {
            this.avater_image = resp.data.data;
            this.is_change_avater = false;
            $s_tost(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    editPhone(val) {
      this.is_changePhone = true;
      this.phone_no = val;
    },
    saveUserTableData(val) {
      if (val == "save") {
        axios
          .post(`change-user-data`, {
            user_id: this.$route.params.id,
            phone: this.phone_no,
            change_field: "phone_no",
          })
          .then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getdata();
              this.is_changePhone = false;
              this.phone_no = "";
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (val == "cancel") {
        this.is_changePhone = false;
        this.phone_no = "";
      } else if (val == "change_password") {
        this.restPasswordform
          .post(`change-user-data`)
          .then((resp) => {
            if (resp.data.success) {
              this.$router.push("/login");
              this.$s_tost(resp.data.message);
              localStorage.clear();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    cancelChangeAvater() {
      this.is_change_avater = false;
      this.avater_image =
        this.userData.avatar !== null
          ? this.userData.avatar
          : require("@/assets/images/avatars/13-small.png");
    },
    changetab(val) {
      this.tab = val;
    },
    changePassword() {
      this.form.post("");
    },
  },
};
</script>
<style>
label {
  text-transform: capitalize;
}
</style>
