<template>
  <div>
    <b-row class="match-height m-auto">
      <div class="statistic_main_row">
        <statistic-card-horizontal icon="UsersIcon" :statistic="parseInt(items.data.total)"
          statistic-title="Total Packages" />
        <statistic-card-horizontal icon="UserCheckIcon" color="success" :statistic="items.active"
          statistic-title="Active" />
        <statistic-card-horizontal icon="UserIcon" color="danger" :statistic="items.inactive"
          statistic-title="In Active" />
        <statistic-card-horizontal icon="UserCheckIcon" color="success" :statistic="items.total_hotspot"
          statistic-title="Hotspot" />
        <statistic-card-horizontal icon="UserCheckIcon" color="success" :statistic="items.total_PPPOE"
          statistic-title="PPPOE" />
      </div>
    </b-row>
    <!-- 
    <b-modal id="modal-lg" :title="modal_title" size="lg" hide-footer>
    
    </b-modal> -->

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <router-link v-if="permission.add" :to="{ name: 'addpackage' }" class="btn btn-sm btn-primary mb-2">
            <feather-icon icon="PlusCircleIcon" size="16" />
            Add New</router-link>
          <div>
            <!-- sorting  -->
            <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="3" label-for="sortBySelect"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" label-cols-sm="4" :disabled="!sortBy">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="items.data.data" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(mikrotik)="data">
          <span v-if="data.item.mikrotik">
            {{
              typeof data.item.mikrotik != "undefined"
              ? `${data.item.mikrotik.identity} | ${data.item.mikrotik.host}`
              : "N/A"
            }}
          </span>
        </template>
        <template #cell(status)="data">
          <b-form-checkbox v-if="permission.status" v-model="data.item.status == 'false'" name="check-button" switch
            @change="changeMikrotikStatus(data.item.id)"></b-form-checkbox>
        </template>

        <template #cell(type)="data">
          <div v-if="data.item.type == 'Hotspot'" class="badge badge-success text-capitalize">
            {{ data.item.type }}
          </div>
          <div v-else-if="data.item.type == 'MikroTik'" class="badge badge-light-success text-capitalize">
            {{ data.item.type }}
          </div>
          <div v-else class="badge badge-primary text-capitalize">
            {{ data.item.type }}
          </div>
        </template>
        <template #cell(fixed_expire_time)="data">
          <span v-if="data.item.fixed_expire_time" class="badge">{{
            formatDateTime(data.item.fixed_expire_time)
          }}</span>
        </template>
        <template #cell(total_users)="data">
          <span v-if="data.item.customers.length > 0" class="badge badge-warning">{{ data.item.customers.length }}</span>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item :to="{ name: 'editpackage', params: { id: data.item.id } }" v-if="permission.edit">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.delete" @click="deleteAction(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.assign_pkg" @click.prevent="openAssignModal(data.item.id)">
              <feather-icon icon="CopyIcon" class="mr-50" />
              <span>Asign Pakege</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-modal id="modal-lg" title="Asign Packege" hide-footer>
        <div class="form-group">
          <label for="">Assign Franchise</label>
          <v-select v-model="assigndata.franchise" :options="select_options_franchise" multiple />
        </div>
        <div class="form-group">
          <label for="">Assign Pop</label>
          <v-select v-model="assigndata.pop" :options="select_options_pop" multiple />
        </div>
        <input @click.prevent="closeModal" type="submit" value="Cancel" class="btn btn-warning" />
        <input @click.prevent="asignPackeges" type="submit" value="Submit" class="btn btn-primary" />
      </b-modal>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="getData(1, perPage)" />
        </b-form-group>
        <!-- pagination -->
        <b-pagination-nav @input="getData" :link-gen="linkGen" :number-of-pages="items.data.last_page" :limit="perPage"
          use-router>
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import { statusAction } from "@/utils/helpers/functions";
const Swal = require("sweetalert2");

export default {
  name: "PackageList",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      selected1: "",
      title: "Package List",
      statusAction,
      form: new Form({
        name: "",
        synonym: "",
        radius: "",
        price: "",
        pop_price: "",
        franchise_price: "",
        mikrotik: "",
        ip_pool: "",
        type: "",
        simultaneous: 1,
      }),
      assigndata: new Form({
        package: null,
        franchise: "",
        pop: "",
      }),
      fields: [
        { key: "id", label: "#" },
        { key: "name", label: "Package Name", sortable: true },
        { key: "synonym", label: "Synonym" },
        "mikrotik",
        { key: "price", label: "Price" },
        { key: "franchise_price", label: "Franchise Price" },
        { key: "type", label: "Type", sortable: true },
        "total_users",
        {
          key: "fixed_expire_time",
          label: "fixed expire Data time",
          sortable: true,
        },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      selected_mikrotik: "",
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Add Package",
      select_options_pop: [],
      select_options_franchise: [],
      select_options_mikrotik: [],
      select_options_nas: [],
      select_options_pool: [],
      fetch_api: false,
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
        assign_pkg: false,
      },
    };
  },

  computed: {
    mikrotik() {
      return this.$store.state.isp.others.mikrotik;
    },
    nas() {
      return this.$store.state.isp.others.nas;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    pop() {
      return this.$store.state.isp.others.pop;
    },
    franchise() {
      return this.$store.state.isp.others.franchise;
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Packages");
      this.permission.add = this.checkpermission("Package Create");
      this.permission.edit = this.checkpermission("Package Edit");
      this.permission.delete = this.checkpermission("Package Delete");
      this.permission.status = this.checkpermission("Package Change status");
      this.permission.assign_pkg = this.checkpermission("Asign Pakege");
    },
  },
  watch: {
    mikrotik(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.identity}-${element.host}`,
          };
          this.select_options_mikrotik.push(obj);
        });
      }
    },
    nas(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.nasname}`,
          };
          this.select_options_nas.push(obj);
        });
      }
    },
    pop(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.name}-${element.mobile_number}-${element.email}`,
          };
          this.select_options_pop.push(obj);
        });
      }
    },
    franchise(value) {
      if (value.length > 0) {
        value.forEach((element) => {
          const obj = {
            code: element.id,
            label: `${element.name}-${element.mobile_number}-${element.email}`,
          };
          this.select_options_franchise.push(obj);
        });
      }
    },
    selected_mikrotik(value) {
      this.form.mikrotik = value.code;
      this.select_options_pool = [];
      const mikrotik = this.mikrotik.find((ele) => ele.id === value.code);
      if (mikrotik.pool.length > 0) {
        mikrotik.pool.forEach((ele) => {
          const obj = {
            code: ele.id,
            label: `${ele.name}`,
          };
          this.select_options_pool.push(obj);
        });
      }
    },
  },
  created() {
    this.getData();
    this.$store.dispatch("others", {
      pop: true,
      // zone: true,
      franchise: true,
      mikrotik: true,
      nas: true,
    });
  },
  mounted() {
    this.getData(1);
  },
  methods: {
    openAssignModal(val) {
      this.$bvModal.show("modal-lg");
      this.assigndata.package = val;
    },
    closeModal() {
      this.assigndata.franchise = "";
      this.assigndata.pop = "";
      this.$bvModal.hide("modal-lg");
    },
    async asignPackeges() {
      this.assigndata
        .post("package/assign/pop")
        .then((resp) => {
          if (resp.data.success) {
            this.closeModal();
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
          // this.$error_message(resp.data.message, resp.data.errors);
        });
    },
    //get package data list
    async getData(page = this.currentPage, item = this.perPage) {
      this.$store.dispatch("spinnerLoading", true);
      await axios.get(`package?page=${page}&item=${item}`).then((resp) => {
        this.loading = false;
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.items = resp.data.data;
          const checkisexists = this.pageOptions.find(
            (item) => item.text == "All"
          );
          if (!checkisexists) {
            this.pageOptions.push({
              value: this.items.data.total,
              text: "All",
            });
          }
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    changeMikrotikStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`package/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    async deleteAction(id) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`package/${id}`).then((resp) => {
            if (resp.data.success) {
              // this.$success_message(resp.data.message);
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    linkGen(pageNum) {
      return { path: `package?pages=${pageNum}` };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
.statistic_main_row {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 20px;
}
</style>
