import auth from "@/middleware/auth";
import Invoices from "../views/Billing/invoices.vue";
import ReceivedPayment from "../views/Billing/received-payment.vue";
import PrepaidPayment from "../views/Billing/prepaid-payment.vue";
import PostpaidPayment from "../views/Billing/postpaid-payment.vue";
import FranchiseBilling from "../views/Billing/franchise-billing.vue";
import CorporateBilling from "../views/Billing/corporate-billing.vue";
import DueInvoice from "../views/Billing/DueInvoice.vue";
import BillCollectablUsers from "../views/Billing/BillCollectablUsers.vue";

export default [
  {
    path: "/invoices",
    name: "invoices",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Invoices,
    meta: {
      pageTitle: "View Invoices",
      middleware: [auth],
      breadcrumb: [
        {
          text: "View Invoices",
          active: true,
        },
      ],
    },
  },
  {
    path: "/received-payment",
    name: "receivedPayment",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: ReceivedPayment,
    meta: {
      pageTitle: "View Received Payment",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Received Payment",
          active: true,
        },
      ],
    },
  },
  {
    path: "/invoice-due",
    name: "DueInvoice",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: DueInvoice,
    meta: {
      pageTitle: "Due Invoice",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Due Invoice",
          active: true,
        },
      ],
    },
  },
  {
    path: "/prepaid-payment",
    name: "prepaidPayment",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: PrepaidPayment,
    meta: {
      pageTitle: "View Prepaid Payment",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Prepaid Payment",
          active: true,
        },
      ],
    },
  },
  {
    path: "/postpaid-payment",
    name: "postpaidPayment",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: PostpaidPayment,
    meta: {
      pageTitle: "View Postpaid Payment",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Postpaid Payment",
          active: true,
        },
      ],
    },
  },
  {
    path: "/franchise-billing",
    name: "franchiseBilling",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: FranchiseBilling,
    meta: {
      pageTitle: "View Franchise Billing",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Franchise Billing",
          active: true,
        },
      ],
    },
  },
  {
    path: "/corporate-billing",
    name: "corporateBilling",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: CorporateBilling,
    meta: {
      pageTitle: "View Corporate Billing",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Corporate Billing",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bill-collectable-users",
    name: "bill_collectabl_users",
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: BillCollectablUsers,
    meta: {
      pageTitle: "View Bill Collectabl Users",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Bill Collectabl Users",
          active: true,
        },
      ],
    },
  },
];
