<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="parseInt(items.length)"
          statistic-title="Total Users"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.active"
          statistic-title="Total Active Users"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.inactive"
          statistic-title="Total Inacticve Users"
        />
      </b-col>
    </b-row>

    <b-card-code>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <select
              @change="getData()"
              v-model="mikrotik_id"
              class="form-control mr-1"
              id=""
            >
              <option value="">---Select---</option>
              <option
                v-for="mkitem in mikrotiks"
                :key="mkitem.id"
                :value="mkitem.id"
              >
                {{ mkitem.identity + " | " + mkitem.host }}
              </option>
            </select>
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        ref="table"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(SL)="data">
          <span>{{ data.index + 1 }}</span>
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item>
              <button
                class="btn btn-sm p-0"
                @click.prevent="disconnect(data.item.name)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Disconnect User
              </button>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card-code>
  </div>
</template>
<script>
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "MikrotikUser",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      title: "AllMikrotikUsers",
      fields: [
        "SL",
        { key: "name", label: "Name" },
        { key: "service", label: "service", sortable: true },
        { key: "caller-id" },
        { key: "address" },
        { key: "uptime" },
        { key: "radius" },
        { key: "comment" },
        "action",
      ],
      items: [],
      selected_mikrotik: "",
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      modal_title: "IP Pool",
      select_options_zone: [],
      select_options_pop: [],
      select_options_franchise: [],
      select_options_mikrotik: [],
      fetch_api: false,
      mikrotik_select: false,
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
      mikrotik_id: null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      const viewAcl = this.$store.getters.getRoleWisePermissionslist.find(
        (item) => item.name == "Role Add"
      );
      this.permission.add = viewAcl ? true : false;
      const editAcl = this.$store.getters.getRoleWisePermissionslist.find(
        (item) => item.name == "Role Edit"
      );
      this.permission.edit = editAcl ? true : false;
      const deleteAcl = this.$store.getters.getRoleWisePermissionslist.find(
        (item) => item.name == "Role Delete"
      );
      this.permission.delete = deleteAcl ? true : false;
      const statusAcl = this.$store.getters.getRoleWisePermissionslist.find(
        (item) => item.name == "Role Change Status"
      );
      this.permission.status = statusAcl ? true : false;
    },
    mikrotiks() {
      return this.$store.state.isp.allmikrotiks;
    },
  },
  created() {
    this.$store.dispatch("getallmikrotiks");
  },
  mounted() {
    this.getData(1);
  },
  methods: {
    async getData(page = this.currentPage, item = this.perPage) {
      this.$store.dispatch("spinnerLoading", true);
      await axios
        .get(`get-mikrotik-online-users/${this.mikrotik_id}`)
        .then((resp) => {
          this.items = [];
          console.log(resp.data.data);
          this.$store.dispatch("spinnerLoading", false);
          this.perPage = resp.data.data.length;
          this.items = resp.data.data;
        })
        .catch(() => {
          // this.$error_message(resp.data.message, resp.data.errors);
          return [];
        });
    },
    disconnect(name) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios
        .get(`mikrotik-online-disconnect/${this.mikrotik_id}?name=${name}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$store.dispatch("spinnerLoading", false);
            this.$s_tost(resp.data.message);
            this.$ref.table.refresh();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
