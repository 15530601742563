import auth from '@/middleware/auth'
import Zone from '../views/Zone/Index.vue'
import District from '../views/Distric/Index.vue'
import Upazilla from '../views/Upazila/Index.vue'

export default [
  {
    path: '/zone',
    name: 'zone',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Zone,
    meta: {
      pageTitle: 'Zone',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Zone',
          active: true,
        },
      ],
    },
  },
  {
    path: '/district',
    name: 'district',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: District,
    meta: {
      pageTitle: 'district',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'district',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Upazila',
    name: 'Upazila',
    // eslint-disable-next-line import/no-unresolved,import/extensions
    component: Upazilla,
    meta: {
      pageTitle: 'Upazila',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Upazila',
          active: true,
        },
      ],
    },
  },
]
