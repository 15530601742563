w3w/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <!-- v-if="permission.add" -->
        <b-button
            v-b-modal.modal-lg
            variant="primary"
            size="sm"
        >
          <span>
            <feather-icon icon="PlusCircleIcon" size="16" />
            Add New
          </span>
        </b-button>
      </div>
      <div class="d-flex ml-1">
        <div class="form-group">
          <div>Employee Name</div>
          <input type="text" v-model="search" @keyup="filterData">
        </div>
        <div class="form-group ml-1">
          <div>Attendance</div>
          <select  v-model="filter_attendance" @select="filterData" class=" select-field-sizing">
            <option value="">All</option>
            <option value="Absent">Absent</option>
            <option value="Present">Present</option>
          </select>
        </div>
        <div class="form-group ml-1">
          <div>From</div>
          <input type="date" v-model="attendance_from" @change="filterData">
        </div>
        <div class="form-group ml-1 ">
          <div>To</div>
          <input type="date" v-model="attendance_to" @change="filterData">
        </div>
      </div>
      <div class="form-group ml-1 ">
        <button type="button" @click="resetFilter">Reset</button>
      </div>
      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="md">
        <form @submit.prevent="save">
          <div class="form-group">
            <label>Employee Name</label>
            <select
                class="form-control"
                v-model="form.employee_id"
            >
              <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{employee.name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">attendance</label>
            <select v-model="form.attendance" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="Present">Present</option>
              <option value="Absent">Absent</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Attendance Date</label>
            <input
                autofocus="autofocus"
                v-model="form.attendance_date"
                type="date"
                class="form-control"
                name="attendance_date"
                placeholder="Attendance Date"
            />
          </div>
          <div class="form-group">
            <label for="">Clock In</label>
            <div class="input-group clockpicker pull-center" data-placement="left" data-align="top" data-autoclose="true">
              <input
                  autofocus="autofocus"
                  v-model="form.clock_in"
                  type="text"
                  class="form-control"
                  name="clock_in"
                  placeholder="Clock In"
              />
              <span class="input-group-addon">
					      <span class="fa fa-clock-o"></span>
				      </span>
            </div>
          </div>
          <div class="form-group">
            <label for="">Clock Out</label>
            <input
                autofocus="autofocus"
                v-model="form.clock_out"
                type="time"
                class="form-control"
                name="clock_out"
                placeholder="Clock Out"
            />
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
        <template #modal-footer>
          <div class="btn btn-warning" @click="close" type="">Close</div>
        </template>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Day</th>
            <th scope="col">Date</th>
            <th scope="col">Attendance</th>
            <th scope="col">Clock In</th>
            <th scope="col">Clock Out</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(attendance, index) in attendances" :key="index + 'attendances'">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ attendance.employee.name }}</td>
            <td>{{ attendance.day }}</td>
            <td>{{ attendance.date }}</td>
            <td>{{ attendance.attendance }}</td>
            <td>{{ attendance.clock_in }}</td>
            <td>{{ attendance.clock_out }}</td>

<!--            <td>-->
<!--              <b-dropdown-->
<!--                  variant="link"-->
<!--                  toggle-class="text-decoration-none"-->
<!--                  no-caret-->
<!--              >-->
<!--                <template v-                                                                                                                               slot:button-content>-->
<!--                  <feather-icon-->
<!--                      icon="MoreVerticalIcon"-->
<!--                      size="16"-->
<!--                      class="text-body align-middle mr-25"-->
<!--                  />-->
<!--                </template>-->
<!--                <b-dropdown-item-->
<!--                    v-if="permission.edit"-->
<!--                    @click.prevent="edit(attendance.id)"-->
<!--                >-->
<!--                  <button class="btn btn-sm p-0">-->
<!--                    <feather-icon icon="EditIcon" class="mr-50" />-->
<!--                    Edit-->
<!--                  </button>-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                    v-if="permission.delete"-->
<!--                    :action="'attendance/' + attendance.id"-->
<!--                    @click.prevent="$trash('attendance/' + attendance.id)"-->
<!--                >-->
<!--                  <button class="btn btn-sm p-0">-->
<!--                    <feather-icon icon="Trash2Icon" class="mr-50" />-->
<!--                    Delete-->
<!--                  </button>-->
<!--                </b-dropdown-item>-->
<!--              </b-dropdown>-->
<!--            </td>-->
          </tr>
          </tbody>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>

        </table>
      </div>
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>

<style>
.input-group {
  width: 110px;
  margin-bottom: 10px;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group>.btn, .input-group-btn:last-child>.dropdown-toggle, .input-group-btn:first-child>.btn:not(:first-child), .input-group-btn:first-child>.btn-group:not(:first-child)>.btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.fa {
  position: relative;
  top: 5px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>


<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

// let employeeAttendanceData = attendances;

export default {
  name: "role",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        employee_id: "",
        attendance: "",
        date: "",
        clock_in: "",
        clock_out: "",
      }),

      loading: true,
      filter_attendance: "",
      search: "",
      attendance_from:"",
      attendance_to:"",
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        permssions: false,
      },

    };
  },
  computed: {
    employees() {
      return this.$store.state.hrm.employees.data;
    },
    attendances() {
      if(this.search == "" && this.filter_attendance == "" && this.attendance_from == "" && this.attendance_to == "") {
        return this.$store.state.hrm.attendances.data;
      }
      else if(this.search != "" && this.filter_attendance == "" && this.attendance_from == "" && this.attendance_to == ""){
        // return this.$store.state.hrm.attendances.data;
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search == "" && this.filter_attendance != "" && this.attendance_from == "" && this.attendance_to == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance && this.attendance_to == ""){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search != "" && this.filter_attendance != ""  && this.attendance_from == "" && this.attendance_to == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true && this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search == "" && this.filter_attendance == ""  && this.attendance_from != "" && this.attendance_to == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].date >= this.attendance_from){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search == "" && this.filter_attendance != ""  && this.attendance_from != "" && this.attendance_to == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].date >= this.attendance_from && this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search != "" && this.filter_attendance == ""  && this.attendance_from != "" && this.attendance_to == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].date >= this.attendance_from && this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search != "" && this.filter_attendance != ""  && this.attendance_from != "" && this.attendance_to == ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].date >= this.attendance_from && this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true && this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search == "" && this.filter_attendance == ""  && this.attendance_from == "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search == "" && this.filter_attendance == ""  && this.attendance_from != "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].date >= this.attendance_from && this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search == "" && this.filter_attendance != ""  && this.attendance_from == "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance && this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search == "" && this.filter_attendance != ""  && this.attendance_from != "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance && this.$store.state.hrm.attendances.data[i].date >= this.attendance_from && this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search != "" && this.filter_attendance == ""  && this.attendance_from == "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true && this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search != "" && this.filter_attendance == ""  && this.attendance_from != "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true && this.$store.state.hrm.attendances.data[i].date >= this.attendance_from  && this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search != "" && this.filter_attendance != ""  && this.attendance_from == "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true && this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance  && this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
      else if(this.search != "" && this.filter_attendance != ""  && this.attendance_from != "" && this.attendance_to != ""){
        let filters = [];
        for(let i = 0; i<this.$store.state.hrm.attendances.data.length; i++){
          if(this.$store.state.hrm.attendances.data[i].employee_name.toLowerCase().includes(this.search.toLowerCase()) == true && this.$store.state.hrm.attendances.data[i].attendance == this.filter_attendance && this.$store.state.hrm.attendances.data[i].date >= this.attendance_from  && this.$store.state.hrm.attendances.data[i].date <= this.attendance_to){
            filters.push(this.$store.state.hrm.attendances.data[i])
          }
        }
        return filters;
      }
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("EmployeeAttendance");
      this.permission.add = this.checkpermission("EmployeeAttendance Add");
      this.permission.edit = this.checkpermission("EmployeeAttendance Edit");
      this.permission.delete = this.checkpermission("EmployeeAttendance Delete");
    },
  },
  watch: {
    roles() {
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch("getEmployees");
    this.$store.dispatch("getAttendances");
  },
  methods: {
    //= ===handle the form close =========
    close() {
      this.form.name = "";
      this.form.attendance = "";
      this.form.date = "";
      this.form.clock_in = "";
      this.form.clock_out = "1";
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    // submitHandelar() {
    //   if (!this.editData.mode) {
    //     this.save();
    //   } else {
    //     this.update();
    //   }
    // },

    //= ========data save=========
    save() {
      this.form
          .put(`add-employee-attendance/${this.form.employee_id}`)
          .then((resp) => {
            if (resp.data.success) {
              this.close();
              this.$s_tost(resp.data.message);
              this.$store.dispatch("getAttendances");
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },

    filterData(){
      this.attendances()
    },

    resetFilter(){
      this.search = "";
      this.filter_attendance = "";
      this.attendance_from = "";
      this.attendance_to = "";
      this.attendances();
    }

    // filterAttendance() {
    //   console.log(this.filter_attendance);
    //   let attendance = this.attendances
    //   attendance = attendance.filter(data => {
    //     return this.attendance.attendance.includes(this.filter_attendance)
    //   })
    //   console.log(attendance);
    // },

    //= ========get data for edit=========

    // update() {
    //   this.form
    //       .put(`workinghour/${this.editData.id}`)
    //       .then((resp) => {
    //         if (resp.data.success) {
    //           this.close();
    //           this.$s_tost(resp.data.message);
    //           this.$store.dispatch("getWorkingHours");
    //         } else {
    //           this.$error_message(resp.data.message, resp.data.errors);
    //         }
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    // },
    //= ========get data for edit=========

    // edit(id) {
    //   const workinghour = this.workinghours.find((ele) => ele.id === id);
    //   this.modal_title = `Update-${workinghour.name}`;
    //   this.form.name = workinghour.name;
    //   this.form.days = [];
    //   this.form.start_time = workinghour.start_time;
    //   this.form.end_time = workinghour.end_time;
    //   this.form.status = workinghour.status;
    //   this.editData.mode = true;
    //   this.editData.id = workinghour.id;
    //   const days = workinghour.days.split(',');
    //   if (days.length > 0) {
    //     days.forEach((element) => {
    //       const obj = {
    //         code: element,
    //         label: element,
    //       };
    //       this.form.days.push(obj);
    //     });
    //   }
    //   this.$bvModal.show("modal-lg");
    // },
  },

};

</script>

<style scoped>
  .select-field-sizing{
    width: 200px;
    height: 27px;
  }
</style>
