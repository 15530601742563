<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="parseInt(items.data.total)"
          statistic-title="Total Users"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.active"
          statistic-title="Total Active Users"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.inactive"
          statistic-title="Total Inacticve Users"
        />
      </b-col>
    </b-row>

    <b-card-code>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        ref="table"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.data.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(mikrotik)="data">
          {{ data.item.mikrotik.identity }} {{ data.item.mikrotik.host }}
        </template>
        <template #cell(status)="data">
          <b-form-checkbox
            v-if="permission.status"
            v-model="data.item.status == 'false'"
            name="check-button"
            switch
            @change="changeStatus(data.item.id)"
          ></b-form-checkbox>
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item v-if="permission.edit" v-b-modal="">
              <router-link
                :to="{ name: 'editMikrotikUser', params: { id: data.item.id } }"
                class="btn btn-sm p-0"
                @click.prevent="edit(data.item)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.delete">
              <button
                class="btn btn-sm p-0"
                @click.prevent="deleteData(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </button>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData(1, perPage)"
          />
        </b-form-group>

        <!-- pagination -->
        <b-pagination-nav
          @input="getData"
          :link-gen="linkGen"
          :number-of-pages="items.data.last_page"
          :limit="perPage"
          use-router
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "MikrotikUser",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
  },
  data() {
    return {
      title: "MikrotikUser",
      fields: [
        { key: "id", label: "SL", sortable: true },
        { key: "name", label: "Name" },
        { key: "service", label: "service", sortable: true },
        { key: "password", label: "password", sortable: true },
        { key: "profile", label: "profile", sortable: true },
        { key: "remoteAddress", label: "remoteAddress", sortable: true },
        "mikrotik",
        "status",
        "action",
      ],
      items: [],
      selected_mikrotik: "",
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      modal_title: "IP Pool",
      select_options_zone: [],
      select_options_pop: [],
      select_options_franchise: [],
      select_options_mikrotik: [],
      fetch_api: false,
      mikrotik_select: false,
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("mikrotik_users");
      this.permission.add = this.checkpermission("Add_mikrotik_users");
      this.permission.edit = this.checkpermission("Edit_mikrotik_users");
      this.permission.delete = this.checkpermission("Delete_mikrotik_users");
      this.permission.status = this.checkpermission(
        "Change_mikrotik_users_status"
      );
    },
  },
  mounted() {
    this.getData(1);
  },
  methods: {
    getData(page = this.currentPage, item = this.perPage) {
      this.$store.dispatch("spinnerLoading", true);
      axios
        .get(`mikrotik-users?page=${page}&item=${item}`)
        .then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {
            this.items = resp.data.data;
            const checkisexists = this.pageOptions.find(
              (item) => item.text == "All"
            );
            if (!checkisexists) {
              this.pageOptions.push({
                value: this.items.data.total,
                text: "All",
              });
            }
          } else {
            this.$error_message(resp.data.message);
          }
        })
        .catch(() => {
          this.$error_message(resp.data.message, resp.data.errors);
          return [];
        });
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`mikrotik-users/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.$ref.table.refresh();
          this.$success_message(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    //= ======== delete Modal =========
    async deleteData(id) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`mikrotik-users/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    linkGen(pageNum) {
      return { path: `/mikrotik-users?pages=${pageNum}` };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
