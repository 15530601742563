import auth from '@/middleware/auth'
import Ticket from '../views/Tickets/Index.vue'
import TicketCategory from '../views/Tickets/TicketCategory.vue'
export default [

    {
        path: '/tickets',
        name: 'ticket',
        component: Ticket,
        meta: {
            pageTitle: 'Ticket',
            middleware: [auth],
            breadcrumb: [
                {
                    text: 'Ticket',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/ticket/category',
        name: 'TicketCategory',
        component: TicketCategory,
        meta: {
            middleware: [auth],
            pageTitle: 'User Settings',
            breadcrumb: [
                {
                    text: 'User Settings',
                    active: true,
                },
            ],
        },
    },

]
