<template>
  <div class="">
    <div class="row">
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">SL/NO #</th>
              <th scope="col">Event</th>
              <th scope="col">Description</th>
              <th scope="col">Creator</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(listItem, index) in listData" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ listItem.event || "" }}</td>
              <td>{{ listItem.description || "" }}</td>
              <td>{{ listItem.users.name || "" }}</td>
              <td>{{ formatDateTime(listItem.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";
import axios from "axios";

export default {
  nameL: "UserActionHistories",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      listData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    //= ===get data=========
    getData() {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("get-user-actions-history").then((res) => {
        this.$store.dispatch("spinnerLoading", false);
        if (res.data.success) {
          this.listData = res.data.data;
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
  },
};
</script>
