<template>
    <div>
        <div class="card">
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Radius</td>

                            <td> <b-badge class="text-capitalize"
                                    :variant="`${freeradiusstatus == 'active' ? 'light-success' : 'light-warning'}`"
                                    rounded>
                                    {{ freeradiusstatus }}
                                </b-badge></td>
                            <td>
                                <div @click="changeAction('freeradius', 'restart')" class="btn btn-outline-primary">Restart
                                    Service</div>
                                <div @click="changeAction('freeradius', `${freeradiusstatus == 'active' ? 'stop' : 'start'}`)"
                                    :class="`btn btn-outline-${freeradiusstatus == 'active' ? 'success' : 'warning'}`">{{
                                        freeradiusstatus == 'active'
                                        ?
                                        'Disable' : 'Enable' }}</div>

                            </td>
                        </tr>
                        <tr>
                            <td>MySQL</td>

                            <td> <b-badge class="text-capitalize"
                                    :variant="`${mysqlstatus == 'active' ? 'light-success' : 'light-warning'}`" rounded>
                                    {{ mysqlstatus }}
                                </b-badge></td>
                            <td>
                                <!-- <div @click="mysqlCheckToggle()"
                                    :class="`btn btn-outline-${mysqlstatus == 'active' ? 'success' : 'warning'}`">{{
                                        mysqlstatus == 'active'
                                        ?
                                        'Disable' : 'Enable' }}</div> -->
                                <div @click="changeAction('mysql', 'restart')" class="btn btn-outline-primary">Restart
                                    Service</div>
                            </td>
                        </tr>
                        <tr>
                            <td>Apache2 Server</td>

                            <td> <b-badge class="text-capitalize"
                                    :variant="`${apache2status == 'active' ? 'light-success' : 'light-warning'}`" rounded>
                                    {{ apache2status }}
                                </b-badge></td>
                            <td>
                                <!-- <div @click="mysqlCheckToggle()"
                                    :class="`btn btn-outline-${apache2status == 'active' ? 'success' : 'warning'}`">{{
                                        apache2status == 'active'
                                        ?
                                        'Disable' : 'Enable' }}</div> -->
                                <div @click="changeAction('apache2', 'restart')" class="btn btn-outline-primary">Restart
                                    Service</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
export default {
    components: {
        BCard, BButton, BAvatar, BRow, BCol, BBadge
    },
    name: "Maintanence",
    data() {
        return {
            mysqlstatus: '',
            apache2status: '',
            freeradiusstatus: ''
        }
    },
    mounted() {
        this.checkstatus();
    },
    methods: {
        checkstatus() {
            this.$store.dispatch("spinnerLoading", true);
            this.axios.get('maintanence-service?check_mysql_status=true&check_apache_status=true&check_radius_status=true').then((resp) => {
                if (resp.data.data.mysql) {
                    const regex = /Active:\s*(\w+)/;
                    const status = resp.data.data.mysql.match(regex)[1];
                    this.mysqlstatus = status ?? "Inactive"
                }
                if (resp.data.data.apache2) {
                    const regex = /Active:\s*(\w+)/;
                    const status = resp.data.data.apache2.match(regex)[1];
                    this.apache2status = status ?? "Inactive"
                }
                if (resp.data.data.freeradius) {
                    console.log(resp.data.data.freeradius);
                    const regex = /Active:\s*(\w+)/;
                    this.freeradiusstatus = resp.data.data.freeradius.match(regex)[1];
                }
            }).finally(() => {
                this.$store.dispatch("spinnerLoading", false);
            })
        },


        //accept service for ex: apache2,mysql,freeradius and action ex: stop, start, restart
        async changeAction(service, action) {
            this.$store.dispatch("spinnerLoading", true);
            await this.axios.get(`maintanence-service?service=${service}&action=${action}`).then((resp) => {
                if (resp.data.success) {
                    this.checkstatus()
                    this.$s_tost(resp.data.message);
                }
            }).finally(() => {
                this.$store.dispatch("spinnerLoading", false);
            })
        },

    },
}
</script>