import { render, staticRenderFns } from "./RemotAddress.vue?vue&type=template&id=f58c08f6&"
import script from "./RemotAddress.vue?vue&type=script&lang=js&"
export * from "./RemotAddress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports