<script>
import Vue from "vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";
const Swal = require("sweetalert2");
Vue.use(VueToast);

export default {
  name: "helper",
  methods: {
    // check permission
    checkpermission(name) {
      const data = this.$store.getters.getRoleWisePermissionslist.find(
        (item) => item.name == name
      );
      return data ? true : false;
    },

    limit_text(value, size) {
      if (!value) return "";
      value = value.toString();
      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + "...";
    },

    // check permission
    replaceString(st, rep, repWith) {
      const result = st.split(rep).join(repWith);
      return result;
    },
    // filter data
    spletData(req, split_sygn, alterSign) {
      const result = req.split(split_sygn);
      if (result.length == 2) {
        return result;
      } else {
        const result = req.split(alterSign);
        return result;
      }
    },

    $error_message(message, errors) {
      if (message === "Unauthorized") {
        this.$router.push("/login");
      } else {
        // const path = require("@/assets/mixkit-software-interface-start-2574.wav");
        // var audio = new Audio(path);
        // audio.play();
        Swal.fire({
          title: message || "Error Establish",
          showCancelButton: false,
          text: errors || "",
        });
      }
    },

    // =========== bootstrap tost message  start ===========//
    $btms(message, title = "Success", variant = "success") {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        variant: variant,
      });
    },
    // =========== bootstrap tost message  end ===========//

    $success_message(message, redirect = false, location = null) {
      // const path = require("@/assets/mixkit-correct-answer-tone-2870.wav");
      // var audio = new Audio(path);
      // audio.play();
      Swal.fire(message);
      // Vue.$toast.open({
      //   message: message || '',
      //   type: 'success',
      //   position: 'top-right',
      //   // all of other options may go here
      // })
      if (redirect === true) {
        this.$router.push(location);
      }
    },
    //success tost
    $s_tost(
      title = "Tost message",
      position = "top-right",
      InfoIcon = "InfoIcon",
      timeout = 2000
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: InfoIcon,
            // text,
            variant: "success",
          },
        },
        {
          position,
          // timeout
        }
      );
    },

    // error tost
    $e_tost(
      title = "Tost message",
      position = "top-center",
      InfoIcon = "AlertTriangleIcon",
      timeout = 2000
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: InfoIcon,
            // text,
            variant: "warning",
          },
        },
        {
          position,
          // timeout
        }
      );
    },


    //formatDateTimeCapital
    formatDateTimeCapital(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
    formatDateTime(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm a");
      }
    },
    formatDateTimeOne(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    formatDate(date) {
      let now = new Date(date);
      return now.toLocaleString();
    },

    formatDateInAgo(value) {
      if (value) {
        return moment(String(value)).fromNow();
      }
    },

    /// 


    //= =======delete=========
    async $trash(e) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(e.target.getAttribute("action")).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              e.target.parentElement.parentElement.remove();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    $confirm(route) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.get(route).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },

    $d_conf(route) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.get(route).then((resp) => {
            return resp.data;
          });
        }
      });
    },

    $can(permission) {
      return this.$store.state.isp.user.permission_list.indexOf(permission);
    },
    $validation(id) {
      const step1 = document.getElementById(id);
      const elements = step1.querySelectorAll(".form-control[validate]");
      let validate = 0;
      for (let i = 0; i < elements.length; i += 1) {
        const element = elements[i];

        const parent = element.parentElement;
        const msg = `<span class="valdation-msg">The ${element.name} filed is requred</span>`;
        if (!element.value) {
          element.classList.add("invalid");
          if (!parent.querySelector(".valdation-msg")) {
            element.parentElement.innerHTML += msg;
          }
        } else {
          validate += 1;
          if (parent.querySelector(".valdation-msg")) {
            parent.querySelector(".valdation-msg").remove();
          }

          if (element.classList.contains("invalid")) {
            element.classList.remove("invalid");
          }
        }
      }

      if (validate === elements.length) {
        return true;
      }
      return false;
    },
    $changeStatus(e) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to change this status",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          let status = "";
          if (e.target.checked === true) {
            status = 1;
          } else {
            status = 0;
          }
          this.axios
            .get(e.target.getAttribute("action"), {
              params: {
                status,
              },
            })
            .then((resp) => {
              if (resp.data.success) {
                this.$success_message(resp.data.message);
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
    $form_submit(isShow = true) {
      const template = `

          <div id="__loading__" class="__loading__">
           <div

          class="spinner-border text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div></div>
      `;

      if (isShow) {
        document.getElementsByTagName("body")["0"].innerHTML += template;
      } else {
        document.getElementById("__loading__").remove();
      }
    },
    $getLogo() {
      return "http://127.0.0.1:8000/images/general/logo.png";
    },
    $textChange(id, text) {
      document.getElementById(id).innerText = text;
    },
    $resolveStatusVariant(status) {
      if (status == 0) return "warning";
      if (status == 1) return "success";
      if (status == 2) return "danger";
      if (status == 3) return "danger";
      if (status == 4) return "success";
      if (status == 5) return "danger";
      return "primary";
    },
  },
};
</script>

<style>
.swal2-select {
  max-width: 80% !important;

  margin-left: 10% !important;
}

select.form-control.invalid {
  border: 1px solid red;
}

input.form-control.invalid {
  border-color: red;
  margin-bottom: 3px;
}

span.valdation-msg {
  color: red;
  font-style: italic;
  font-size: 12px;
}
</style>
