<template>
  <b-modal
    id="remote_adress-modal"
    :title="modal_title"
    size="xl"
    hide-header-close
    hide-footer
  >
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Address</th>
          <th scope="col">Status</th>
          <th scope="col" class="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ldata in listModalData" :key="ldata.id">
          <td>{{ ldata.id }}</td>
          <td>{{ ldata.address }}</td>
          <td>
            <span v-if="ldata.status" class="badge badge-warning"
              >Already Used</span
            >
            <span v-else class="badge badge-info">Ready to Use</span>
          </td>
          <td class="d-flex">
            <button
              @click="copyData(ldata.address)"
              class="btn btn-info btn-sm"
            >
              <feather-icon icon="CopyIcon" size="1.5x" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button @click="close" class="btn btn-warning">Close</button>
  </b-modal>
</template>

<script>
import Form from "vform";
import { BFormCheckbox } from "bootstrap-vue";
export default {
  name: "RemotAddress",
  data() {
    return {
      copyFor: "",
    };
  },
  methods: {
    // copyData
    copyData(val) {
      if (this.copyFor == "target_address") {
        this.$emit("target_address", val);
        this.form.remote_ip = val;
        this.form.target_address = val;
      } else if (this.copyFor == "remote_address") {
        this.form.remote_address = val;
        this.poll_items = null;
      }
      this.copyFor == "";
      this.listModalData = null;
      this.$bvModal.hide("remote_adress-modal");
    },

    close() {
      console.log(this.props);
      this.$bvModal.hide("remote_adress-modal");
    },
  },
  mounted() {
    console.log(this.props);
  },
  props: {
    listModalData: {
      type: Object,
      required: true,
    },
    modal_title: "",
  },
  components: {
    BFormCheckbox,
  },
};
</script>
