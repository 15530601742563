<template>
  <div>
    <div>
      <b-row class="match-height">
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
            icon="UsersIcon"
            :statistic="summary.total"
            statistic-title="Total Collection"
          />
        </b-col>
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
            icon="UserCheckIcon"
            color="success"
            :statistic="summary.complete"
            statistic-title="Total Complete"
          />
        </b-col>
        <b-col lg="4" sm="4">
          <statistic-card-horizontal
            icon="UserIcon"
            color="danger"
            :statistic="summary.pending"
            statistic-title="Total Pending"
          />
        </b-col>
      </b-row>

      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="lg">
        <form @submit.prevent="submitHandelar">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="">Client Name</label>
                <input
                  v-model="form.client_name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="Client Name"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="client_id">Client Id</label>
                <input
                  v-model="form.client_id"
                  type="text"
                  class="form-control"
                  name="name"
                  id="client_id"
                  placeholder="Client Id"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="invoice_no">Invoice No</label>
                <input
                  v-model="form.invoice_no"
                  id="invoice_no"
                  type="text"
                  class="form-control"
                  name="text"
                  placeholder="Invoice No"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <label for="">Method</label>
              <select v-model="form.method" class="form-control">
                <option value="">-----Select-------</option>
                <option value="Cash">Cash</option>
                <option value="Baksh">Bkash</option>
                <option value="Rocket">Rocket</option>
                <option value="Bank">Bank</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="monthly_bill">Monthly Bill</label>
                <input
                  v-model="form.monthly_bill"
                  type="number"
                  min="1"
                  class="form-control"
                  name="phone"
                  placeholder="Monthly Bill"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="received">Received</label>
                <input
                  v-model="form.received"
                  type="number"
                  min="1"
                  class="form-control"
                  name="password"
                  placeholder="Received"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="received_by">Received By</label>
                <select v-model="form.received_by" class="form-control">
                  <option value="">-----Select-------</option>
                  <option
                    v-for="uItem in dashboardusers"
                    :key="uItem.id"
                    :value="uItem.id"
                  >
                    {{ uItem.name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="col-lg-6"
              v-if="
                form.method == 'Baksh' ||
                form.method == 'Rocket' ||
                form.method == 'Bank'
              "
            >
              <div class="form-group">
                <label for="received_by">Transaction Id</label>
                <input
                  type="text"
                  placeholder="Transaction Id"
                  class="form-control"
                  v-model="form.transaction_id"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="received_by">Issue Date</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="form.issue_date"
                  id=""
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="received_by">Note</label>
            <textarea
              v-model="form.note"
              class="form-control"
              id=""
              cols="30"
              rows="5"
            ></textarea>
          </div>
        </form>
        <template #modal-footer>
          <button @click="close" class="btn btn-warning">Close</button>
          <button
            v-if="!viewmode"
            @click="submitHandelar"
            class="btn btn-primary"
            type="submit"
            :disabled="form.busy"
          >
            Submit
          </button>
        </template>
      </b-modal>

      <b-modal
        id="bill_transfer"
        title="Transfer Bill"
        hide-header-close
        size="md"
      >
        <form @submit.prevent="transfer_bill()">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="received_by">Transfer By</label>
                <select v-model="t_form.transfer_to" class="form-control">
                  <option value="">-----Select-------</option>
                  <option
                    v-for="uItem in dashboardusers"
                    :key="uItem.id"
                    :value="uItem.id"
                  >
                    {{ uItem.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer>
          <button @click="close" class="btn btn-warning">Close</button>
          <button
            @click.prevent="transfer_bill()"
            class="btn btn-primary"
            type="submit"
          >
            Submit
          </button>
        </template>
      </b-modal>

      <b-card-code :title="title" no-body>
        <b-card-body>
          <div class="row mb-2">
            <div class="col-sm-11 col-md-11">
              <form @submit.prevent="serch_by_date" action="">
                <div class="row">
                  <div class="col-sm-12 col-md-2">
                    <b-button
                      v-if="permission.add"
                      size="sm"
                      col="2"
                      v-b-modal.modal-lg
                      variant="primary"
                      @click.prevent="editData.mode = false"
                    >
                      <feather-icon icon="PlusCircleIcon" size="16" />
                      Add New</b-button
                    >
                  </div>
                  <div class="col-sm-12 col-md-10">
                    <div class="d-flex justify-content-between">
                      <input
                        class="form-control"
                        type="date"
                        v-model="form.start_date"
                        id=""
                      />
                      <span class="font-weight-bold">To</span>
                      <input
                        class="form-control"
                        type="date"
                        v-model="form.end_date"
                        id=""
                      />

                      <input
                        class="btn btn-primary btn-sm py-0"
                        type="submit"
                        value="Search"
                      />
                    </div>
                    <div class="d-flex mt-2">
                      <select class="form-control" name="" id="">
                        <option value="">---Creator---</option>
                        <option
                          v-for="uItem in dashboardusers"
                          :key="uItem.id"
                          :value="uItem.name"
                        >
                          {{ uItem.name }}
                        </option>
                      </select>
                      <select class="form-control" name="" id="">
                        <option value="">---Status---</option>
                        <option value="">Pending</option>
                        <option value="">Complete</option>
                      </select>
                      <b-button
                        size="sm"
                        col="2"
                        class="ml-1"
                        variant="info"
                        @click.prevent="reset_date_field"
                      >
                        Reset</b-button
                      >
                    </div>
                  </div>
                  <div class="col-12 d-flex"></div>
                </div>
              </form>
            </div>

            <div class="col-sm-1 col-md-1 p-0">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                text="Export"
                variant="primary"
              >
                <b-dropdown-item>
                  <export-excel name="income.xls" :data="items">
                    xls
                  </export-excel>
                </b-dropdown-item>
                <b-dropdown-item>
                  <export-excel :data="items" type="csv" name="income.csv">
                    csv
                  </export-excel></b-dropdown-item
                >
                <b-dropdown-item>
                  <span @click="downloadPDF"> PDF</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="listData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
        >
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="permission.view"
                @click="viewItem(data.item)"
              >
                <span>
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>View</span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="permission.edit"
                @click.prevent="openTransferModal(data.item)"
              >
                <span>
                  <feather-icon icon="ActivityIcon" class="mr-50" />
                  <span>Balance Transfer</span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="permission.edit"
                @click.prevent="edit(data.item)"
              >
                <span>
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Edit</span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="permission.edit"
                @click.prevent="deleteData(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(date)="date"
            ><span class="badge">
              {{ formatDateTime(date.item.issue_date) }}</span
            >
          </template>
          <template #cell(method)="method"
            ><span class="badge"> {{ method.item.method }}</span>
            <span v-if="method.item.transaction_id" class="badge badge-info">
              {{ method.item.transaction_id }}</span
            >
          </template>
          <template #cell(received_by)="data"
            ><span class="badge"> {{ data.item.received_by.name }}</span>
          </template>
          <template #cell(transfer_by)="data"
            ><span v-if="data.item.transfer_by" class="badge">
              {{ data.item.transfer_by.name }}</span
            >
          </template>
          <template #cell(transfer_status)="data">
            <select
              v-if="data.item.transfer_status == 1"
              @change="confirm_transfer(data.item)"
              class="form-control font-weight-bold bg-success text-white"
              style="width: 100px"
              name=""
              id=""
            >
              <option value="">Select</option>
              <option value="1">Confirm</option>
              <option value="2">Denay</option>
            </select>
            <span
              class="badge badge-light-success"
              v-else-if="data.item.transfer_status == 2"
            >
              Confirmed
            </span>
          </template>
          <template #cell(status)="status">
            <div>
              <span
                @click="s_e_d(status.item.id)"
                v-if="status.item.status == 0"
                class="badge badge-warning"
              >
                Pending..
              </span>
              <span v-else class="badge badge-success">Complete </span>
            </div>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
        {{ getRoleWisePermissions }}
      </b-card-code>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
const Swal = require("sweetalert2");
import Form from "vform";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
export default {
  nameL: "BillCollection",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      form: new Form({
        client_id: "",
        client_name: "",
        invoice_no: "",
        monthly_bill: "",
        received: "",
        received_by: "",
        method: "",
        issue_date: "",
        transaction_id: "",
        note: "",
      }),
      t_form: new Form({
        item_id: null,
        transfer_from: null,
        transfer_to: null,
      }),
      fields: [
        { key: "id", label: "Id" },
        { key: "client_name", label: "Client Name", sortable: true },
        { key: "client_id", label: "Client ID", sortable: true },
        { key: "invoice_no", label: "Invoice No", sortable: true },
        { key: "monthly_bill", label: "Monthly Bill" },
        { key: "received", label: "Received", sortable: true },
        { key: "method", label: "Method", sortable: true },
        { key: "received_by", label: "Received By", sortable: true },
        { key: "transfer_by", label: "transfer_by", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "transfer_status", label: "transfer status" },
        "date",
        "action",
      ],
      summary: {
        total: 0,
        compelte: 0,
        pending: 0,
      },
      listData: [],
      dashboardusers: [],
      permissionError: "",
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
      filter: null,
      filterOn: [],
    };
  },

  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Bill Collection");
      this.permission.add = this.checkpermission("Bill Collection Add");
      this.permission.edit = this.checkpermission("Bill Collection Edit");
      this.permission.delete = this.checkpermission("Bill Collection Delete");
      this.permission.status = this.checkpermission(
        "Change Bill Collection Status"
      );
    },
  },
  created() {
    this.getData();
    this.getUser();
  },
  methods: {
    // serch_by_date
    serch_by_date() {
      this.form
        .post("searchbillcollections")
        .then((res) => {
          if (res.data.success) {
            this.listData = res.data.data;
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // ============= downloadPDF  ================
    downloadPDF() {
      const doc = new jsPDF();
      doc.autoTable({ html: "#my-table" });

      var col = [
        "Id",
        "Create By",
        "Claimant",
        "Amount",
        "Method",
        "Transaction Id",
        "Issue Date",
        "Status",
        "Details",
      ];
      var rows = [];
      this.items.forEach((element) => {
        var id = [element.id];
        var create_by = [element.create_by];
        var exp_claimant = [element.exp_claimant];
        var amount = [element.amount];
        var method = [element.method];
        var transaction_id = [element.transaction_id];
        var issue_date = [element.issue_date];
        var status = [element.status == 0 ? "Pending" : "Completed"];
        var desc = [element.description];
        rows.push([
          id,
          create_by,
          exp_claimant,
          amount,
          method,
          transaction_id ? transaction_id : null,
          issue_date,
          status,
          desc,
        ]);
      });
      doc.autoTable(col, rows, { startY: 10 });
      doc.save("income.pdf");
    },

    // Change status
    s_e_d(data) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("billcollections/" + data).then((res) => {
        if (res.data.success) {
          this.getData();
          this.$s_tost(res.data.message);
          // this.$store.dispatch("actions_success", res.data.message);
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //=========== Get All user ============
    getUser() {
      this.axios.get("dashboardusers").then((res) => {
        if (res.data.success) {
          this.dashboardusers = res.data.data;
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //= ===haxndle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ===get data=========
    getData() {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("billcollections").then((res) => {
        this.$store.dispatch("spinnerLoading", false);
        if (res.data.success) {
          this.listData = res.data.data;
        } else {
          if (res.data.code == 403) {
            this.permissionError = res.data.message;
            this.$error_message(res.data.message, res.data.errors);
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        }
      });
    },

    //= ========data save=========
    save() {
      this.form
        .post("billcollections")
        .then((res) => {
          if (res.data.success) {
            this.$s_tost(res.data.message);
            this.getData();
            this.close();
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========
    update() {
      this.$store.dispatch("spinnerLoading", true);
      this.form
        .put(`billcollections/${this.editData.id}`)
        .then((res) => {
          if (res.data.success) {
            this.$s_tost(res.data.message);
            this.listData.unshift(this.form);
            this.$bvModal.hide("modal-lg");
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========
    edit(data) {
      this.modal_title = `Update-${data.client_name}`;
      this.form.client_id = data.client_id;
      this.form.client_name = data.client_name;
      this.form.invoice_no = data.invoice_no;
      this.form.monthly_bill = data.monthly_bill;
      this.form.received = data.received;
      this.form.issue_date = data.issue_date;
      this.form.received_by = data.received_by;
      this.form.method = data.method;
      this.form.transaction_id = data.transaction_id;
      this.form.note = data.note;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("modal-lg");
    },
    //= ======== close Modal =========
    close() {
      (this.client_id = ""),
        (this.client_name = ""),
        (this.invoice_no = ""),
        (this.monthly_bill = ""),
        (this.received = ""),
        (this.received_by = ""),
        (this.method = ""),
        (this.transaction_id = ""),
        (this.note = ""),
        (this.issue_date = ""),
        this.$bvModal.hide("modal-lg");
      this.$bvModal.hide("bill_transfer");
    },
    //= ======== delete Modal =========
    async deleteData(id) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`billcollections/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    //= ======== delete Modal end =========
    linkGen(pageNum) {
      return { path: `/billcollections?pages=${pageNum}` };
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },
    openTransferModal(data) {
      this.t_form.item_id = data.id;
      this.t_form.transfer_from = data.received_by;
      this.$bvModal.show("bill_transfer");
    },
    transfer_bill() {
      this.t_form
        .post("transfer-balance")
        .then((res) => {
          if (res.data.success) {
            this.$s_tost(res.data.message);
            this.close();
            this.getData();
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    confirm_transfer(data) {
      axios
        .post("confirm-transfer", data)
        .then((res) => {
          if (res.data.success) {
            this.$s_tost(res.data.message);
            this.close();
            this.getData();
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .chtch((e)=>{
        console.log(e);
        });
    },
  },
};
</script>
