/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <div class="row">
      <div class="col-lg-10 mb-1">
        <b-button
          size="sm"
          v-b-modal.modal-lg
          variant="primary"
          @click.prevent="editData.mode = false"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />
          Add New</b-button
        >
      </div>
      <b-modal id="modal-lg" :title="modal_title" size="lg">
        <form id="form" @submit.prevent="submitHandelar">
          <!-- <div class="form-group">
            <label for="">POP ID</label>
            <input
              v-model="form.pop_id"
              type="text"
              class="form-control"
              name="name"
              placeholder="ID"
            >
          </div> -->
          <div class="form-group">
            <label for="">Franchise Name</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              name="name"
              placeholder="Name"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Location</label>
            <input
              v-model="form.location"
              type="text"
              class="form-control"
              name="name"
              placeholder="Location"
              validate
            />
          </div>
          <div class="form-group">
            <label for="">Address</label>
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              name="name"
              placeholder="Address"
              validate
            />
          </div>
          <div class="row align-items-center">
            <div class="col-lg-10">
              <div class="form-group">
                <label for="">Mobile No</label>
                <input
                  ref="mobile_number"
                  v-model="form.mobile_number"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="mobile number"
                  validate
                />
              </div>
            </div>
            <div class="col-lg-2">
              <a
                href="#"
                class="btn btn-success btn-block"
                @click.prevent="addMobile_number"
                >+</a
              >
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-10">
              <div class="form-group">
                <label for="">Email</label>
                <input
                  ref="email"
                  v-model="form.email"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="mobile number"
                />
              </div>
            </div>
            <div class="col-lg-2">
              <a
                href="#"
                class="btn btn-success btn-block"
                @click.prevent="addEmail"
                >+</a
              >
            </div>
          </div>

          <div class="form-group">
            <label for="">Status</label>
            <br />
            <label :for="'switch'" class="__switch">
              <input :id="'switch'" v-model="form.status" type="checkbox" />
              <span class="__slider" />
            </label>
          </div>

          <div class="row">
            <div class="col-lg-2">
              <label for="">Prefix</label>
              <br />
              <label :for="'Prefix'" class="__switch">
                <input
                  :id="'Prefix'"
                  v-model="form.is_prefix"
                  type="checkbox"
                />
                <span class="__slider" />
              </label>
            </div>
            <div v-if="form.is_prefix" class="col-lg-10">
              <label for="">Prefix Text</label>
              <input v-model="form.prefix" type="text" class="form-control" />
            </div>
          </div>

          <div class="form-group">
            <label for="">Manager</label>
            <select v-model="form.manager" class="form-control" name="manager">
              <option value="">----------Please Select One ---------</option>
            </select>
          </div>

          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">Mobile No:</th>
              <th scope="col">Email</th>
              <th scope="col">Location</th>
              <th scope="col">Address</th>
              <th
                scope="col"
                v-for="(pmsList, index) in getRoleWisePermissions"
                :key="index"
                v-show="pmsList.name == 'Franchise Change Status'"
              >
                Status
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <div
              v-if="loading"
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <tr v-for="(item, index) in items.data" v-else :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                {{ item.mobile_number }}
                <div v-if="item.extra_mobile_number">
                  <span
                    v-for="(number, ndx) in JSON.parse(
                      item.extra_mobile_number
                    )"
                    :key="ndx"
                    class="badge badge-primary"
                  >
                    {{ number }}
                  </span>
                </div>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.address || "" }}</td>
              <td>{{ item.location || "" }}</td>
              <td>
                <label :for="'switch' + item.id" class="__switch">
                  <input
                    :id="'switch' + item.id"
                    type="checkbox"
                    :checked="item.status == 1"
                    :action="'change/status/franchises/' + item.id"
                    @click="$changeStatus($event)"
                  />
                  <span class="__slider" />
                </label>
              </td>

              <td>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click.prevent="edit(item.id)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                    :action="'district/' + item.id"
                    @click.prevent="$trash($event)"
                  >
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :data="items"
          align="right"
          @pagination-change-page="getResults"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  name: "Role",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        pop_id: "",
        name: "",
        location: "",
        address: "",
        mobile_number: "",
        extra_mobile_number: [],
        email: "",
        extra_email: [],
        status: true,
        manager: "",
        is_prefix: false,
        prefix: "",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Create Franchise",
    };
  },
  computed: {
    items() {
      return this.$store.state.isp.franchises;
    },
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },
  watch: {
    items() {
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch("franchises", 1);
  },
  methods: {
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      if (this.$validation("form") === true) {
        this.form
          .post("franchises")
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$s_tost(resp.data.message);
              this.items.data.push(resp.data.data);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    //= ========get data for edit=========

    update() {
      if (this.$validation("form") === true) {
        this.form
          .put(`franchises/${this.editData.id}`)
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.$bvModal.hide("modal-lg");
              this.$s_tost(resp.data.message);
              this.$store.dispatch("districts");
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    //= ========get data for edit=========
    edit(id) {
      const item = this.items.data.find((ele) => ele.id === id);
      this.modal_title = `Update-Franchises-${item.name}`;
      this.form.name = item.name;
      this.form.location = item.location;
      this.form.address = item.address;
      this.form.mobile_number = item.mobile_number;
      this.form.extra_mobile_number = JSON.parse(item.extra_mobile_number);
      this.form.email = item.email;
      this.form.extra_email = JSON.parse(item.extra_email);
      this.form.status = item.status;
      this.editData.mode = true;
      this.editData.id = item.id;
      this.$bvModal.show("modal-lg");
    },
    getResults(page = 1) {
      this.$store.dispatch("districts", page);
    },
    addMobile_number() {
      if (this.form.mobile_number.length <= 0) {
        alert("Please First Fill Up Primary Number");
        this.$refs.mobile_number.focus();
      } else {
        const number = prompt("Please Enter Mobile Number");
        this.form.extra_mobile_number.push(number);
      }
    },
    addEmail() {
      if (this.form.email.length <= 0) {
        alert("Please First Fill Up Primary Email");
        this.$refs.email.focus();
      } else {
        const number = prompt("Please Enter Email");
        this.form.extra_email.push(number);
      }
    },
  },
};
</script>
