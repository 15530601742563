<template>
	<div>
		<!-- <h5 class="text-center">ODF {{type}}</h5> -->
		<template
			v-if="loading"
		>
			Loading ...
		</template>
		<template
			v-else
		>
			<DynamicForm
				:submitForm="submit"
				:form="form"
				:type="type"
				:fields="dynamicFormFields"
			/>
		</template>
	</div>
</template>


<script>

import Form from "vform";
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";

export default {
	name: "Odf",
	components: {  DynamicForm },
	props: {
		"type": {
			type: String,
			default: 'create'
		},
		"id" : {
			type: Number,
			default: null
		}
	},
	data() {
	  return {

	  	form: new Form({
	        name: null,
			rack_id: null,
			rack_u_position: 0,
			brand_id: null,
			customer_id: null,
			no_of_ports: 0,
			no_of_trays: 0,
			sl_no: null,
			status: 1
	  	}),
		apiEndPoint: 'nm-odfs',
		statusOptions: [{
			'title': 'Active',
			'value': 1
		}, {
			'title': 'Inactive',
			'value': 0
		}],
		brands: [],
		racks: [],
		customers: [],
		loading: false
	  }

	},
	emits: ['submitted'],
	computed: {
	  dynamicFormFields(){
	  	
	  	const formFields = [
			{
				'type': 'text',
				'name': 'name',
				'label': 'Name',
			},
			{
				'type': 'select',
				'name': 'rack_id',
				'label': 'Rack',
				'options': this.racks,
				'option_settings': {
					'title': 'detail_name',
					'value': 'id',
				},
			},
			{
				'type': 'number',
				'name': 'rack_u_position',
				'label': 'Rack U Position',
			},
			{
				'type': 'select',
				'name': 'brand_id',
				'label': 'Brand',
				'options': this.brands,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				}	
			},
			{
				'type': 'select',
				'name': 'customer_id',
				'label': 'Customer',
				'options': this.customers,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				}	
			},
			{
				'type': 'number',
				'name': 'no_of_ports',
				'label': 'Total Number Of Ports',
			},
			{
				'type': 'number',
				'name': 'no_of_trays',
				'label': 'Total Number Of Trays',
			},
			{
				'type': 'text',
				'name': 'sl_no',
				'label': 'Serial Number',
			},
			{
				'type': 'select',
				'name': 'status',
				'label': 'Status',
				'options': this.statusOptions,
				'disabled': true
			},
		]

		return formFields
	  }
	},
	created() {
		if(this.type === 'edit'){
			this.edit()
		}
		this.getRacks()
		this.getBrands()
		this.getCustomers()
	},
	watch: {
	},
	methods: {
		submit(){
			if(this.type === 'create'){
				this.create()
			} else if(this.type === 'edit'){
				this.update()
			}
		},
		create() {
			this.form.post(this.apiEndPoint).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data
				  
				  this.$emit('submitted', item)
				  
				  this.$success_message(resp.data.message);
				  this.form.reset()
				} else {
				  this.$error_message(resp.data.message, resp.data.errors);
				}
			});
		},
		edit() {
			this.loading = true
			axios.get(`${this.apiEndPoint}/${this.id}`).then((resp) => {
				if (resp.data.success) {
				  let item = resp.data.data;
			      this.form.name = item.name
			      this.form.rack_id = item.rack_id
			      this.form.rack_u_position = item.rack_u_position
			      this.form.brand_id = item.brand_id
			      this.form.customer_id = item.customer_id
			      this.form.no_of_ports = item.no_of_ports
			      this.form.no_of_trays = item.no_of_trays
			      this.form.sl_no = item.sl_no
			      this.form.status = item.status
				} else {
				  
				}
			}).finally(()=> {
				this.loading = false
			});
		},
		update() {
			this.form.put(`${this.apiEndPoint}/${this.id}`).then((resp) => {
			  if (resp.data.success) {
			    let updatedItem = resp.data.data;

			    this.$emit('submitted', updatedItem)

			    this.form.reset()

			    this.$success_message(resp.data.message);
			  } else {
			    this.$error_message(resp.data.message, resp.data.errors);
			  }
			});
		},


		getRacks() {
			axios.get(`nm-racks?page=1&item=all`).then((resp) => {
				if (resp.data.success) {
				  this.racks = resp.data.data.data;

				} else {
				  
				}
			}).finally(()=> {

			});
		},
		
		getBrands() {
			axios.get(`nm-brands?page=1&item=all`).then((resp) => {
				if (resp.data.success) {
				  this.brands = resp.data.data.data;

				} else {
				  
				}
			}).finally(()=> {

			});
		},
 
		getCustomers() {
		  axios.get(`nm-customers?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.customers = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
		
 
	},
}
</script>