<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal icon="UsersIcon" :statistic="summary.total_user" statistic-title="Total User" />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal icon="UserCheckIcon" color="success" :statistic="summary.total_paid_user"
          statistic-title="Total Paid Customer" />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal icon="UserIcon" color="danger" :statistic="summary.total_unpaid_user"
          statistic-title="Total Unpaid Customer" />
      </b-col>
    </b-row>

    <b-card-code :title="title" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <!-- sorting  -->
            <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="3" label-for="sortBySelect"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" label-cols-sm="4" :disabled="!sortBy">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="d-flex justify-content-between">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button v-if="permission.add" size="sm" col="2" class="ml-2" variant="primary"
              :to="{ name: 'customerAdd' }">
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New
            </b-button>
            <b-button v-if="permission.add" size="sm" col="2" class="ml-2" variant="primary"
              @click="$bvModal.show('customer_import')">
              <feather-icon icon="UploadCloudIcon" size="16" />
              Import Customers
            </b-button>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(customer_type)>Home</template>

        <template #cell(zone)="data">
          {{
            data.item.billing_info ? data.item.billing_info.zone.name : "N/A"
          }}
        </template>

        <template #cell(package)="data">
          <span> {{
            data.item.billing_info.package.synonym
            ? `${data.item.billing_info.package.synonym}`
            : ""
          }}</span>
          <span class="badge p-0 text-success">
            {{
              data.item.billing_info
              ? `(${data.item.billing_info.package.name}) (${data.item.billing_info.package.price} TK)`
              : "N/A"
            }}
          </span>

        </template>
        <template #cell(mikrotik)="data">
          {{
            data.item.connection_info
            ? `${data.item.connection_info.mikrotik.identity} | ${data.item.connection_info.mikrotik.host}`
            : "N/A"
          }}
        </template>

        <template #cell(username)="data">
          {{ data.item.connection_info.username }}
        </template>
        <template v-if="permission.change_mikrotik_status" #cell(mikrotik_status)="data">
          <b-badge @click.prevent="enabledUser(data.item)" pill :variant="data.item.connection_info && data.item.connection_info.status == 1
              ? 'success'
              : 'warning'
            " class="text-capitalize">
            {{
              data.item.connection_info && data.item.connection_info.status == 1
              ? "Enabled"
              : "Disabled"
            }}
          </b-badge>
        </template>

        <template #cell(mac_address)="data">
          {{
            data.item.connection_info
            ? data.item.connection_info.mac_address
            : "N/A"
          }}
          <div v-if="data.item.connection_info.service == 'oVPN' ||
              data.item.connection_info.service == 'PPPoE' ||
              data.item.connection_info.service == 'PPtP'
              ">
            <div v-if="permission.change_user_mac_bind &&
                data.item.connection_info.mac_bind_status == 1
                " class="badge badge-info" @click="mac_bind(
          'bind',
          data.item.connection_info.username,
          data.item.id,
          data.item.connection_info.mikrotik_id
        )
        ">
              Mac Bind
            </div>
            <div v-else-if="permission.change_user_mac_bind &&
                data.item.connection_info.mac_bind_status == 0
                " class="badge badge-warning" @click="mac_bind(
          'bind',
          data.item.connection_info.username,
          data.item.id,
          data.item.connection_info.mikrotik_id
        )
        ">
              Mac UnBind
            </div>
          </div>
        </template>
        <!-- //billing_status -->
        <template #cell(billing_status)="data">
          <b-badge pill :variant="data.item.billing_info && data.item.billing_info.status == 0
                ? 'warning'
                : 'success'
              " class="text-capitalize">
            {{
              data.item.billing_info && data.item.billing_info.status == 0
              ? "Pending"
              : "success"
            }}
          </b-badge>
        </template>
        <!-- balance -->
        <template #cell(balance)="data">
          <b-badge :v-if="data.item.billing_info.balance" pill :variant="data.item.billing_info && data.item.billing_info.balance == null
                ? 'warning'
                : 'primary'
              " class="text-capitalize">
            {{
              `${data.item.billing_info.balance
                ? "৳ " + data.item.billing_info.balance
                : "NULL"
                }`
            }}
          </b-badge>
        </template>
        <template #cell(expire_date)="data">
          <span :class="`${data.item.customer_grace.length > 0 &&
                data.item.customer_grace[0].expire_date ==
                data.item.connection_info.expire_date
                ? 'badge badge-light-warning'
                : 'badge'
              } `">
            {{
              data.item.connection_info
              ? data.item.connection_info.expire_date
              : "N/A"
            }}
          </span>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>

            <b-dropdown-item v-if="permission.view" :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <span>
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>View</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.edit" :to="{ name: 'customerEdit', params: { id: data.item.id } }">
              <!-- :to="{ name: 'apps-users-edit', params: { id: data.item.id } }" -->
              <feather-icon icon="EditIcon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.update_blance" @click="OpenModal('update_balance', data.item.id)">
              <feather-icon icon="DollarSignIcon" class="mr-50" />
              <span>Update Balance</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="is_expire(data.item.connection_info.expire_date) || permission.graceAcl
              " @click="allow_gress_modal(data.item)">
              <feather-icon icon="CircleIcon" class="mr-50" />
              <span>Allow Grace</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="permission.delete" @click.prevent="deleteData(data.item.id)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="$store.state.isp.system_nas_type == 'Radius'" @click.prevent="assign_nas(data.item)">
              <feather-icon icon="ServerIcon" class="mr-50" />
              <span>Assign Nas</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$store.state.isp.system_nas_type == 'Radius' || $store.state.isp.system_nas_type == 'MikroTik+Radius'"
              @click.prevent="$refs.remotAddressModel.see_remote_address(data.item.id)">
              <RemoteAddressModel ref="remotAddressModel" />
              <feather-icon icon="FramerIcon" class="mr-50" />
              <span>Assign Static Ip</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card-code>

    <b-modal id="update_balance" title="Update Balance" hide-footer>
      <div class="d-flex mb-2">
        <b-form-radio v-model="balance_data.balance_type" class="mr-2" name="some-radios" value="Add">
          Add Balance
        </b-form-radio>
        <b-form-radio v-model="balance_data.balance_type" name="some-radios" value="Update">
          Adjust Balance
        </b-form-radio>
      </div>
      <input type="number" min="0" v-model="balance_data.balance" class="form-control" placeholder="Enter Balance" />
      <div class="mt-1" v-if="balance_data.balance_type == 'Update'">
        <label for="">Reasons</label>
        <textarea v-model="balance_data.reason" class="form-control" id="" cols="30" rows="2"></textarea>
      </div>
      <input type="submit" value="Submit" @click="updateBalance()" class="btn btn-block btn-primary mt-2" />
    </b-modal>
    {{ getRoleWisePermissions }}
    <CustomerImport />
  </div>
</template>
<script>
import axios from "axios";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { statusAction } from "@/utils/helpers/functions";
import Swal from "sweetalert2";
import Form from "vform";
import RemoteAddressModel from "./components/RemoteAddressModel.vue";
import CustomerImport from "./CustomerImport.vue";
import moment from "moment";
export default {
  name: "UserManage",
  components: {
    CustomerImport,
    RemoteAddressModel,
    BCardCode,
    StatisticCardHorizontal,
  },
  data() {
    return {
      title: "Home User",
      fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "username", label: "User Name" },
        { key: "customer_type", label: "CType" },
        { key: "zone", label: "Zone", sortable: true },
        "package",
        { key: "mikrotik", label: "Mikrotik", sortable: true },
        { key: "mikrotik_status", label: "Mikrotik Status" },
        { key: "mac_address", label: "Mac Address" },
        { key: "expire_date", label: "Expire Date" },
        { key: "billing_status", label: "Billing Status" },
        { key: "balance", label: "Balance" },
        "action",
      ],
      statusAction,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      items: [],
      user: {},
      type: "",
      summary: {
        total_user: 0,
        total_paid_user: 0,
        total_unpaid_user: 0,
      },
      balance_data: new Form({
        up_balance_id: null,
        balance: "",
        balance_type: "Add",
        reason: "",
      }),
      allow_gress: null,
      nowdate: new Date(),
      // access controll
      permission: {
        graceAcl: false,
        view: false,
        add: false,
        edit: false,
        delete: false,
        update_blance: false,
        change_mikrotik_status: false,
        change_user_mac_bind: false,
        user_import_customer: false,
        status: false,
      },
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Allow Customer Grace");
      this.permission.add = this.checkpermission("Customer Add");
      this.permission.edit = this.checkpermission("Customer Edit");
      this.permission.delete = this.checkpermission("Customer Delete");
      this.permission.update_blance =
        this.checkpermission("user_update_blance");
      this.permission.change_mikrotik_status = this.checkpermission(
        "change_mikrotik_status"
      );
      this.permission.change_user_mac_bind = this.checkpermission(
        "change_user_mac_bind"
      );
      this.permission.user_import_customer = this.checkpermission(
        "user_import_customer"
      );
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    mikrotik() {
      return this.$store.state.isp.others.mikrotik;
    },
    nas() {
      return this.$store.state.isp.others.nas;
    },


  },

  created() {
    this.$store.dispatch("getRoles");
    this.getData();
    this.getSummaryData();
  },
  methods: {
    /* allow grace is_expire > now 
    * return true or false
    */
    is_expire(val) {
      const inputDate = moment(val, 'DD-MM-YYYY hh:mm A');
      const currentDate = moment();
      return inputDate.isBefore(currentDate);
    },

    // user grace user or system action
    async getGrace() {
      // for = system-grace, user-grace
      await axios.get(`get-grace?gracefor=user-grace`).then((res) => {
        if (res.data.success) {
          this.allow_gress = res.data.data.allow_grace_mark;
        }
      });
    },
    //  ========= get customer data
    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`customer?type=home&page=${page}&status=1`).then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.items = resp.data.data.data;
          this.totalRows = resp.data.data.data.length;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getSummaryData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`customer-summary?type=home`).then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.summary.total_user = resp.data.data.total_user;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    statusHtml(status) {
      return this.$resolveStatusVariant(status);
    },
    async mac_bind(action, username, itemid, mikrotik_id) {
      let route = `mac-bind?itemid=${itemid}&mikrotik=${mikrotik_id}&user_name=${username}`;
      await Swal.fire({
        title: "Are you sure?",
        text: `You won't to ${action} It`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post(route).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },

    async enabledUser(data) {
      let route = `mikrotikuserstatus?itemid=${data.id}&mikrotik=${data.connection_info.mikrotik_id}&user_name=${data.connection_info.username}`;
      await Swal.fire({
        title: "Are you sure?",
        text: `You won't to Change It`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post(route).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
    // allow_gress_modal
    async allow_gress_modal(data) {
      await this.getGrace();
      const options = {};
      for (let index = 1; index <= this.allow_gress; index++) {
        options[index] = index + " Days";
      }
      await Swal.fire({
        title: "Please Select Allow Grace",
        input: "select",
        inputOptions: options,
        inputPlaceholder: "Select One",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(`customer-allow-grace`, {
              id: data.id,
              grace: result.value,
              expire_date: data.connection_info.expire_date,
            })
            .then((resp) => {
              if (resp.data.success) {
                this.$s_tost(resp.data.message);
                this.getData();
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
    //assign_nas if system nas_type == 'Radious'
    async assign_nas(data) {
      await this.$store.dispatch('nas')
      const options = {};
      this.$store.state.isp.nas.forEach((element) => {
        options[element.id] = `${element.shortname}|${element.nasname}`;
      });
      await Swal.fire({
        title: `Please Select a Nas ${data.connection_info.nas_id ? ' | current Nas' + `(${data.connection_info.nas.shortname} | ${data.connection_info.nas.nasname})` : ''}`,
        input: "select",
        inputOptions: options,
        inputPlaceholder: "Select One",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .get(`customer-assign-nas?user_id=${data.id}&nas_id=${result.value}`)
            .then((resp) => {
              if (resp.data.success) {
                this.$s_tost(resp.data.message);
                this.getData();
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
        }
      });
    },
    //  = ====== OpenModal
    OpenModal(action = "update_balance", val) {
      if (action == "customer_import") {
        this.$bvModal.show("customer_import");
      } else if (action == "update_balance") {
        this.balance_data.up_balance_id = val;
        this.$bvModal.show("update_balance");
      }
    },
    // ========== updateBalance
    updateBalance() {
      this.balance_data
        .post(`update-user-balance/${this.balance_data.up_balance_id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("update_balance");
            this.balance_data.up_balance_id = null;
            this.balance_data.balance = "";
            this.$store.dispatch("get_app_net_balance");
            this.getData();
            this.$s_tost(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
    },
  },
};
</script>
