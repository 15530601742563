/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable vue/html-indent */
/* eslint-disable vue/html-indent */
/* eslint-disable quote-props */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
<template>
  <div class="card shadow p-1">
    <div class="row">
      <div class="col-lg-12">
        <div class="headline">
          <li class="step-1 active"><span>1</span> User Personal Info</li>
          <li class="step-2"><span>2</span>Address</li>
          <li class="step-3"><span>3</span>User Account Info</li>
          <li class="step-4"><span>4</span>Billing/Payment Information</li>
          <li class="step-5"><span>5</span>Connection</li>
        </div>
      </div>
      <div class="col-lg-12 mt-4">
        <form action="" @submit.prevent="save()">
          <div id="step-1" class="step" style="display: block">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Select User Type <sup class="text-danger">*</sup></label>
                  <select v-model="form.user_type" name="user_type" class="form-control">
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option v-for="(user, index) in user_types" :key="index" :value="user.value">
                      {{ user.type }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">Full Name <sup class="text-danger">*</sup></label>
                  <input v-model="form.name" type="text" class="form-control" name="full_name" />
                </div>
                <div class="form-group">
                  <label for="">Email <sup class="text-danger">*</sup></label>
                  <input v-model="form.email" type="text" class="form-control" name="email" />
                </div>
                <div class="form-group">
                  <label for="">Mobile Number <sup class="text-danger">*</sup></label>
                  <input v-model="form.phone" type="text" class="form-control" name="phone" />
                </div>
                <div class="form-group">
                  <label for="">Father's Name <sup class="text-danger">*</sup></label>
                  <input v-model="form.father_name" type="text" class="form-control" name="father_name" />
                </div>
                <div class="form-group">
                  <label for="">Mother's Name <sup class="text-danger">*</sup></label>
                  <input v-model="form.mother_name" type="text" class="form-control" name="mother_name" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Gender <sup class="text-danger">*</sup></label>
                  <select v-model="form.gender" class="form-control" name="gender">
                    <option value="">----select one-----</option>
                    <option value="male">Male</option>
                    <option value="male">Famale</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">Proof Of Identity <sup class="text-danger">*</sup></label>
                  <select v-model="form.identification_reference" class="form-control" name="prof-of-identity">
                    <option value="">---select one----</option>
                    <option value="NID">NID</option>
                    <option value="Birthday-Certificate">
                      Birthday Certifcate
                    </option>
                    <option value="Passport">Passport</option>
                  </select>
                </div>

                <div v-if="form.identification_reference" class="form-group">
                  <label for="">{{
                      `Please Enter ${form.identification_reference} Number`
                  }}
                    <sup class="text-danger">*</sup></label>
                  <input v-model="form.identification_reference_number" type="text" class="form-control"
                    name="number" />
                </div>
                <div class="form-group">
                  <label for="">Occupation <sup class="text-danger">*</sup></label>
                  <input v-model="form.occupation" type="text" class="form-control" name="Occupation" />
                </div>
                <div class="form-group">
                  <label for="">Birthday Date <sup class="text-danger">*</sup></label>
                  <input v-model="form.birthday_date" type="date" class="form-control" name="birthday_date" />
                </div>
              </div>
            </div>
            <a class="btn btn-primary" @click.prevent="next('step-1', 'step-2')">
              Next
            </a>
          </div>
          <div id="step-2" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <h5 class="text-uppercase">
                  <b><i>{{
                      form.user_type == 2
                        ? "Business Address"
                        : " Current Address"
                  }}</i></b>
                </h5>

                <div class="form-group">
                  <label for="">House No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.house_number_one" type="text" class="form-control" name="house_number" />
                </div>
                <div class="form-group">
                  <label for="">Road No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.road_number_one" type="text" class="form-control" name="road_number" />
                </div>
                <div class="form-group">
                  <label for="">Float No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.float_number_one" type="text" class="form-control" name="float_no" />
                </div>
                <div class="form-group">
                  <label for="">Area: <sup class="text-danger">*</sup></label>
                  <input v-model="form.area_one" type="text" class="form-control" name="area" />
                </div>
                <div class="form-group">
                  <label for="">Post Code: <sup class="text-danger">*</sup></label>
                  <input v-model="form.post_code_one" type="text" class="form-control" name="post_code" />
                </div>
                <div class="form-group">
                  <label for="">District <sup class="text-danger">*</sup></label>
                  <select v-if="districts && districts.length > 0" v-model="form.district_one" class="form-control"
                    name="district" @change="districtWiseCity($event)">
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option v-for="(district, didx) in districts" :key="didx" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'district' }">Please Create District</router-link>
                </div>
                <div v-if="upazila_one && upazila_one.length > 0" class="form-group">
                  <label for="">Upazila <sup class="text-danger">*</sup></label>
                  <select id="" v-model="form.upazila_one" class="form-control" name="upazila_one">
                    <option selected disabled>
                      ------------Please Select One -----------
                    </option>
                    <option v-for="(up, uidx) in upazila_one" :key="uidx" :value="up.id">
                      {{ up.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <h5 class="text-uppercase">
                      <b><i>Permanent Address:</i></b>
                    </h5>
                  </div>
                  <div class="col-lg-6">
                    <div>
                      <b-form-checkbox v-model="sameAddress" name="check-button" switch>
                        <small>As Like Current Address</small>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">House No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.house_number_two" type="text" class="form-control" name="house_number" />
                </div>
                <div class="form-group">
                  <label for="">Road No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.road_number_two" type="text" class="form-control" name="road_number" />
                </div>
                <div class="form-group">
                  <label for="">Float No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.float_number_two" type="text" class="form-control" name="float_no" />
                </div>
                <div class="form-group">
                  <label for="">Area: <sup class="text-danger">*</sup></label>
                  <input v-model="form.area_two" type="text" class="form-control" />
                </div>
                <div class="form-group">
                  <label for="">Post Code: <sup class="text-danger">*</sup></label>
                  <input v-model="form.post_code_two" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <label for="">District <sup class="text-danger">*</sup></label>
                  <select v-if="districts && districts.length > 0" v-model="form.district_two" class="form-control"
                    name="district" @change="districtWiseCityTwo($event)">
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option v-for="(district, didx) in districts" :key="didx" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'district' }">Please Create District</router-link>
                </div>
                <div v-if="upazila_two && upazila_two.length > 0" class="form-group">
                  <label for="">Upazila</label>
                  <select id="" v-model="form.upazila_two" class="form-control" name="upazila_one">
                    <option selected disabled>
                      ------------Please Select One -----------
                    </option>
                    <option v-for="(upa, uidx) in upazila_two" :key="uidx" :value="upa.id">
                      {{ upa.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <a class="btn btn-success" @click.prevent="previous('step-2', 'step-1')">
              Previous
            </a>
            <a class="btn btn-primary" @click.prevent="next('step-2', 'step-3')">
              Next
            </a>
          </div>
          <div id="step-3" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6 mt-1">
                <label for="">Zone <sup class="text-danger">*</sup></label>
                <select v-if="zone && zone.length > 0" v-model="form.zone" name="zone" class="form-control">
                  <option value="" disabled>
                    --------Please Select One ---------
                  </option>
                  <option v-for="zon in zone" :key="zon.id" :value="zon.id">
                    {{ zon.name }}
                  </option>
                </select>
                <router-link v-else :to="{ name: 'zone' }">Please Create Zone</router-link>
              </div>
            </div>

            <div class="mt-1">
              <a class="btn btn-success" @click.prevent="previous('step-3', 'step-2')">
                Previous
              </a>
              <a class="btn btn-primary" @click.prevent="next('step-3', 'step-4')">
                Next
              </a>
            </div>
          </div>

          <div id="step-4" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <label for="">Packge</label>
                <select v-if="InPackage && InPackage.length > 0" v-model="form.package" class="form-control"
                  name="packge" @change="selectPackge">
                  <option value="" selected disabled>
                    --------Please Select One
                  </option>
                  <option v-for="(pack, pidx) in InPackage" :key="pidx" :value="pack.id">
                    <span>{{ pack.name }}
                      <!-- <span v-if="pack.synonym">{{ "-" + pack.synonym }}</span> -->
                    </span>
                  </option>
                </select>
                <router-link v-else :to="{ name: 'addpackage' }">Please Create Packge</router-link>
              </div>
              <div class="col-lg-6">
                <label for="">Monthly Bill</label>
                <input disabled v-model="form.monthly_bill" type="text" class="form-control"
                  placeholder="Montyly Bill" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 mt-1">
                <div class="form-group">
                  <label for="">Permanent Discount(input 0 if no discount):</label>
                  <input v-model="form.discount" type="number" class="form-control" placeholder="Discount" />
                </div>
                <div class="form-group">
                  <label for="">Send Billing Reminder:</label> <br />
                  <label for="">Sms</label>
                  <label :for="'sms_b'" class="__switch">
                    <input id="sms_b" v-model="form.send_sms" type="checkbox" @input="form.send_sms = !form.send_sms"
                      :checked="form.send_sms" />
                    <span class="__slider" />
                  </label>
                  <label for="">Email</label>
                  <label :for="'sms_e'" class="__switch" @click="form.send_email = !form.send_email">
                    <input id="sms_e" v-model="form.send_email" type="checkbox"
                      @input="form.send_email = !form.send_email" :checked="form.send_email" />
                    <span class="__slider" />
                  </label>
                </div>
                <div class="form-group">
                  <label for="user_payment_type">User Payment Type</label>
                  <select class="form-control" v-model="form.user_payment_type" id="user_payment_type">
                    <option value="">
                      --------Please Select One ---------
                    </option>
                    <option value="Prepaid">Prepaid</option>
                    <option value="Postpaid">Postpaid</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12 mt-1">
                    <label for="">Bank Payment</label>
                    <select v-model="bank_payment" class="form-control" name="bank_payment">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div v-if="bank_payment == '1'" class="col-lg-12 mt-1">
                    <div class="form-group">
                      <label for="">Bank Name</label>
                      <input v-model="form.bank_name" type="text" class="form-control" placeholder="Bank Name" />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Name</label>
                      <input v-model="form.bank_account_name" type="text" class="form-control"
                        placeholder="Bank Account Name" />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Number</label>
                      <input v-model="form.bank_account_number" type="text" class="form-control"
                        placeholder="Bank Account Number" />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Branch: </label>
                      <input v-model="form.bank_account_branch" type="text" class="form-control"
                        placeholder="Bank Account Branch" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <a class="btn btn-success" @click.prevent="previous('step-4', 'step-3')">
                Previous
              </a>
              <a class="btn btn-primary" @click.prevent="next('step-4', 'step-5')">
                Next
              </a>
            </div>
          </div>
          <div id="step-5" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <div v-if="nas_type == 'MikroTik'">
                  <label for="">MikroTik <sup class="text-danger">*</sup></label>
                  <!-- disabled -->
                  <select v-if="mikrotiks" v-model="form.mikrotik" class="form-control">
                    <option value="">---------Please Select One -------</option>
                    <option v-for="(mikrotik, midx) in mikrotiks" :key="midx" :value="mikrotik.id">
                      {{ mikrotik.identity + "-" + mikrotik.host }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <label for="">Connection Type: <sup class="text-danger">*</sup></label>
                <select v-model="form.connection_type" class="form-control" name="connection_type">
                  <option value="" selected disabled>
                    Select Connection Type
                  </option>
                  <option value="di_private">
                    Dynamic IP Private (PPPOE NAT)
                  </option>
                  <option value="di_public">Dynamic IP Public(PPPOE)</option>
                  <option value="si_private">Static IP Private (PPPOE)</option>
                  <option value="si_public">Static IP Public (PPPOE)</option>
                  <!-- <option value="si_private_queue">
                    Static IP Private (Queue)
                  </option>
                  <option value="si_public_queue">
                    Static IP Public (Queue)
                  </option> -->
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 mt-1">
                <label for="">User Password <sup class="text-danger">*</sup></label>
                <input v-model="form.userpassword" type="text" class="form-control" placeholder="User Password" />
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Service</label>
                <input v-model="form.service" type="text" class="form-control" placeholder="Service" />
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Remote Address</label>
                <input v-model="form.remote_address" type="text" class="form-control" placeholder="Remote Address" />
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">MAC Address</label>
                <input v-model="form.mac_address" type="text" class="form-control" placeholder="MAC Address" />
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Remote IP</label>
                <input v-model="form.remote_ip" type="text" class="form-control" placeholder="Remote IP" />
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Router Comments</label>
                <input v-model="form.router_component" type="text" class="form-control" placeholder="Router Comments" />
              </div>
              <div class="col-lg-6 mt-1">
                <div class="form-group">
                  <label for="expire_date">Expire Date</label>
                  <input id="expire_date" v-model="form.expire_date" type="datetime-local" class="form-control"
                    placeholder="Expire Date" />
                </div>
              </div>
            </div>
            <div class="mt-1">
              <a class="btn btn-success" @click.prevent="previous('step-5', 'step-4')">
                Previous
              </a>
              <button class="btn btn-primary" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BFormCheckbox } from "bootstrap-vue";
import router from "@/router";
import axios from "axios";

export default {
  name: "EditCustomer",
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      form: new Form({
        name: "",
        user_type: "Home",
        email: "",
        phone: "",
        father_name: "",
        mother_name: "",
        gender: "",
        identification_reference: "",
        identification_reference_number: "",
        occupation: "",
        birthday_date: "",
        house_number_one: "",
        area_one: "",
        float_number_one: "",
        road_number_one: "",
        post_code_one: "",
        district_one: "",
        district_two: "",
        upazila_one: "",
        upazila_two: "",

        house_number_two: "",
        area_two: "",
        float_number_two: "",
        road_number_two: "",
        post_code_two: "",

        user_reference_number: null,
        zone: "",
        registration_date: "",
        connection_date: "",
        connection_type: "",

        package: "",
        monthly_bill: "",
        send_sms: false,
        send_email: true,

        bank_name: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_branch: "",

        mikrotik: "",
        username: "",
        userpassword: "",
        service: "pppoe",
        remote_address: "",
        mac_address: "",
        remote_ip: "",
        router_component: "",
        expire_date: "",
        user_payment_type: "Prepaid",
      }),
      nas_type: "", //MikroTik Radius MikroTik+Radius
      user_types: [
        { type: "Home User", value: "Home" },
        { type: "Corporate User", value: "Corporate" },
      ],

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Create Data",
      step: {
        one: false,
        second: true,
      },
      district_select_options: {
        options: [],
      },

      selected_distric: {
        code: "",
        label: "-------Please Select One-----------",
      },
      sameAddress: false,
      bank_payment: 0,
      upazila_one: [],
      upazila_two: [],
    };
  },
  computed: {
    districts() {
      return this.$store.state.isp.others.districts;
    },
    zone() {
      return this.$store.state.isp.others.zone;
    },
    InPackage() {
      return this.$store.state.isp.others.package;
    },
    mikrotiks() {
      return this.$store.state.isp.others.mikrotik;
    },
  },
  watch: {
    items() {
      this.loading = false;
    },

    sameAddress(value) {
      if (value) {
        this.form.house_number_two = this.form.house_number_one;
        this.form.area_two = this.form.area_one;
        this.form.float_number_two = this.form.float_number_one;
        this.form.road_number_two = this.form.road_number_one;
        this.form.post_code_two = this.form.post_code_one;
      } else {
        this.form.house_number_two = "";
        this.form.area_two = "";
        this.form.float_number_two = "";
        this.form.road_number_two = "";
        this.form.post_code_two = "";
      }
      console.log(value);
    },
  },
  created() {
    this.$store.dispatch("others", {
      zone: true,
      mikrotik: true,
      user_reference_number: true,
      package: true,
      districts: true,
    });
    this.getEditdata();
  },
  methods: {
    getEditdata() {
      this.$store.dispatch("spinnerLoading", false);
      this.axios.get(`customer/${this.$route.params.id}`).then((resp) => {
        console.log(resp.data.data);
        if (resp.data.success) {
          this.form.name = resp.data.data.name;
          this.form.user_type = resp.data.data.type;
          this.form.email = resp.data.data.email;
          this.form.phone = resp.data.data.phone;
          this.form.father_name = resp.data.data.userdetails.father_name;
          this.form.mother_name = resp.data.data.userdetails.mother_name;
          this.form.gender = resp.data.data.userdetails.gender;
          this.form.identification_reference =
            resp.data.data.userdetails.identification_reference;
          this.form.identification_reference_number =
            resp.data.data.userdetails.identification_reference_number;
          this.form.occupation = resp.data.data.userdetails.occupation;
          this.form.birthday_date = resp.data.data.userdetails.birthday_date;
          this.form.house_number_one =
            resp.data.data.userdetails.house_number_one;
          this.form.area_one = resp.data.data.userdetails.area_one;
          this.form.float_number_one =
            resp.data.data.userdetails.float_number_one;
          this.form.road_number_one =
            resp.data.data.userdetails.road_number_one;
          this.form.post_code_one = resp.data.data.userdetails.post_code_one;
          this.form.district_one = resp.data.data.userdetails.district_one;
          this.form.district_two = resp.data.data.userdetails.district_two;
          this.form.upazila_one = resp.data.data.userdetails.upazila_one;
          this.form.upazila_two = resp.data.data.userdetails.upazila_two;
          this.form.house_number_two =
            resp.data.data.userdetails.house_number_two;
          this.form.area_two = resp.data.data.userdetails.area_two;
          this.form.float_number_two =
            resp.data.data.userdetails.float_number_two;
          this.form.road_number_two =
            resp.data.data.userdetails.road_number_two;
          this.form.post_code_two = resp.data.data.userdetails.post_code_two;
          this.form.bank_name = resp.data.data.userdetails.bank_name;
          this.form.bank_account_name =
            resp.data.data.userdetails.bank_account_name;
          this.form.bank_account_number =
            resp.data.data.userdetails.bank_account_number;
          this.form.bank_account_branch =
            resp.data.data.userdetails.bank_account_branch;
          this.form.zone = resp.data.data.billing_info.zone_id;
          this.form.registration_date =
            resp.data.data.billing_info.registration_date;
          this.form.connection_date =
            resp.data.data.billing_info.connection_date;
          this.form.connection_type =
            resp.data.data.billing_info.connection_type;

          this.form.package = resp.data.data.billing_info.package_id;
          this.form.monthly_bill = resp.data.data.billing_info.monthly_bill;
          this.form.send_email = resp.data.data.billing_info.email_notification;
          this.form.send_sms = resp.data.data.billing_info.sms_notification;
          this.form.discount = resp.data.data.billing_info.discount;

          this.form.mikrotik = resp.data.data.connection_info.mikrotik_id;
          this.form.userpassword = resp.data.data.connection_info.user_password;
          this.form.service = resp.data.data.connection_info.service;
          this.form.remote_address =
            resp.data.data.connection_info.remote_address;
          this.form.mac_address = resp.data.data.connection_info.mac_address;
          this.form.remote_ip = resp.data.data.connection_info.remote_ip;
          this.form.router_component =
            resp.data.data.connection_info.router_component;
          this.form.expire_date = resp.data.data.connection_info.expire_date;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
        this.$store.dispatch("spinnerLoading", false);
      });
    },

    get_softwer_system() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("software-system").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.nas_type = resp.data.data.nas_type;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    next(current, next) {
      this.form.user_reference_number =
        this.$store.state.isp.others.user_reference_number;
      if (next == "step-5") {
        this.get_softwer_system();
        if (this.nas_type == "MikroTik") {
        }
      }
      const currentElement = document.getElementById(current);
      const nextElement = document.getElementById(next);
      if (this.$validation(current) === true) {
        currentElement.style.display = "none";
        nextElement.style.display = "block";
        document.getElementsByClassName(next)[0].classList.add("active");
      }
    },
    previous(current, previous) {
      const currentElement = document.getElementById(current);
      const previousElement = document.getElementById(previous);
      currentElement.style.display = "none";
      previousElement.style.display = "block";
    },

    districtWiseCity(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_one = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    districtWiseCityTwo(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_two = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    selectPackge(e) {
      console.log("pkg");
      const pkg = this.InPackage.find(
        (ele) => ele.id.toString() === e.target.value
      );
      console.log(pkg);
      this.form.monthly_bill = pkg.price;
    },
    save() {
      if (this.$validation("step-5") === true) {
        this.form.put(`customer/${this.$route.params.id}`).then((resp) => {
          if (resp.data.success) {
            this.$success_message(resp.data.message);
            if (this.form.user_type == "Home")
              router.push({ name: "homeCustomer" });
            if (this.form.user_type == "Corporate")
              router.push({ name: "corporateCustomer" });
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
      }
    },
  },
};
</script>
<style>
sup {
  font-size: 16px !important;
  font-weight: bold;
}

.headline {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  border-bottom: 2px solid #eee;
}

.headline li {
  display: block;
  align-items: center;
  color: #423636;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.headline li span.active {
  border: 4px solid #968df4;
  color: #fff !important;
  border: 4px solid #968df4 !important;
}

.headline li span {
  /* background: #eee; */
  display: block;
  width: 60px;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  padding: 12px 0px;
  font-size: 25px;
  font-weight: 600;
  color: #000;
  border: 4px solid #eee;
  z-index: 9999999;
  background: #fff;
}

.headline li.active span {
  background: #968df4;
  color: #fff !important;
}

input.form-control.invalid {
  border-color: red;
  margin-bottom: 3px;
}

span.valdation-msg {
  color: red;
  font-style: italic;
  font-size: 12px;
}

span.active {
  background: #968df4 !important;
  color: #fff !important;
  border: 4px solid #968df4 !important;
}

.headline li:after {
  position: absolute;
  content: "";
  /* right: 0; */
  width: 75%;
  background: #ddd;
  height: 6px;
  top: 34%;
  left: 32%;
}

/* .headline li.active:after {
  background: #968df4 !important;
} */

/* .headline li.active:last-child::after {
  background: #968df4 !important;
} */
.headline li:last-child::after {
  display: none;
}

.row.content-header {
  display: none;
}
</style>
