import auth from '@/middleware/auth'
import DepartmentIndex from '../views/Department/Index.vue'
import DesignationIndex from '../views/Designation/Index.vue'
import EmployeeIndex from '../views/Employee/Index.vue'
import ShiftIndex from '../views/WorkingHour/Index.vue'
import PayrollIndex from '../views/Payroll/Index.vue'
import Attendance from '../views/Attendance/Index.vue'
import LeaveManagement from '../views/LeaveManagement/Index.vue'

export default [
  {
    path: '/departments',
    name: 'departments',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: DepartmentIndex,
    meta: {
      pageTitle: 'View Departments',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Departments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/designations',
    name: 'designations',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: DesignationIndex,
    meta: {
      pageTitle: 'View Designations',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Designations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees',
    name: 'employees',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: EmployeeIndex,
    meta: {
      pageTitle: 'View Employees',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Employees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workinghours',
    name: 'workinghours',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: ShiftIndex,
    meta: {
      pageTitle: 'View Shifts',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Shifts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/attendance',
    name: 'attendance',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: Attendance,
    meta: {
      pageTitle: 'View Attendances',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'View Attendances',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payroll',
    name: 'payroll',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: PayrollIndex,
    meta: {
      pageTitle: 'Payroll',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Payroll',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leave_management',
    name: 'leave_management',
    // eslint-disable-next-line import/extensions,import/no-unresolved
    component: LeaveManagement,
    meta: {
      pageTitle: 'Manage Leave',
      middleware: [auth],
      breadcrumb: [
        {
          text: 'Manage Leave',
          active: true,
        },
      ],
    },
  },
]
