/* eslint-disable consistent-return */ /* eslint-disable no-plusplus */ /*
eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */ /*
eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-1">
    <div class="row">
      <div class="col-lg-12">
        <div class="headline">
          <li class="step-1 active"><span>1</span> User Personal Info</li>
          <li class="step-2"><span>2</span>Address</li>
          <li class="step-3"><span>3</span>User Account Info</li>
          <li class="step-4"><span>4</span>Billing/Payment Information</li>
        </div>
      </div>

      <div class="col-lg-12 mt-4">
        <form action="" @submit.prevent="save()">
          <div id="step-1" class="step" style="display: block">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Select User Type <sup class="text-danger">*</sup></label>
                  <select v-model="form.user_type" name="user_type" class="form-control">
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option v-for="(user, index) in user_types" :key="index" :value="user.value">
                      {{ user.type }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">Full Name <sup class="text-danger">*</sup></label>
                  <input v-model="form.name" type="text" class="form-control" name="full_name" />
                </div>
                <div class="form-group">
                  <label for="">Email <sup class="text-danger">*</sup></label>
                  <input v-model="form.email" type="text" class="form-control" name="email" />
                </div>
                <div class="form-group">
                  <label for="">Mobile Number <sup class="text-danger">*</sup></label>
                  <input v-model="form.phone" type="text" class="form-control" name="phone" />
                </div>
                <div class="form-group">
                  <label for="">Father's Name <sup class="text-danger">*</sup></label>
                  <input v-model="form.father_name" type="text" class="form-control" name="father_name" />
                </div>
                <div class="form-group">
                  <label for="">Mother's Name <sup class="text-danger">*</sup></label>
                  <input v-model="form.mother_name" type="text" class="form-control" name="mother_name" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Gender <sup class="text-danger">*</sup></label>
                  <select v-model="form.gender" class="form-control" name="gender">
                    <option value="">----select one-----</option>
                    <option value="male">Male</option>
                    <option value="male">Famale</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">Proof Of Identity <sup class="text-danger">*</sup></label>
                  <select v-model="form.identification_reference" class="form-control" name="prof-of-identity">
                    <option value="">---select one----</option>
                    <option value="NID">NID</option>
                    <option value="Birthday-Certificate">
                      Birthday Certifcate
                    </option>
                    <option value="Passport">Passport</option>
                  </select>
                </div>

                <div v-if="form.identification_reference" class="form-group">
                  <label for="">{{
                      `Please Enter ${form.identification_reference} Number`
                  }}
                    <sup class="text-danger">*</sup></label>
                  <input v-model="form.identification_reference_number" type="text" class="form-control"
                    name="number" />
                </div>
                <div class="form-group">
                  <label for="">Occupation <sup class="text-danger">*</sup></label>
                  <input v-model="form.occupation" type="text" class="form-control" name="Occupation" />
                </div>
                <div class="form-group">
                  <label for="">Birthday Date <sup class="text-danger">*</sup></label>
                  <input v-model="form.birthday_date" type="date" class="form-control" name="birthday_date" />
                </div>
              </div>
            </div>
            <a class="btn btn-primary" @click.prevent="next('step-1', 'step-2')">
              Next
            </a>
          </div>
          <div id="step-2" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <h5 class="text-uppercase">
                  <b><i>{{
                      form.user_type == 2
                        ? "Business Address"
                        : " Current Address"
                  }}</i></b>
                </h5>

                <div class="form-group">
                  <label for="">House No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.house_number_one" type="text" class="form-control" name="house_number" />
                </div>
                <div class="form-group">
                  <label for="">Road No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.road_number_one" type="text" class="form-control" name="road_number" />
                </div>
                <div class="form-group">
                  <label for="">Float No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.float_number_one" type="text" class="form-control" name="float_no" />
                </div>
                <div class="form-group">
                  <label for="">Area: <sup class="text-danger">*</sup></label>
                  <input v-model="form.area_one" type="text" class="form-control" name="area" />
                </div>
                <div class="form-group">
                  <label for="">Post Code: <sup class="text-danger">*</sup></label>
                  <input v-model="form.post_code_one" type="text" class="form-control" name="post_code" />
                </div>
                <div class="form-group">
                  <label for="">District <sup class="text-danger">*</sup></label>
                  <select v-if="districts && districts.length > 0" v-model="form.district_one" class="form-control"
                    name="district" @change="districtWiseCity($event)">
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option v-for="(district, didx) in districts" :key="didx" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'district' }">Please Create District</router-link>
                </div>
                <div v-if="upazila_one && upazila_one.length > 0" class="form-group">
                  <label for="">Upazila <sup class="text-danger">*</sup></label>
                  <select id="" v-model="form.upazila_one" class="form-control" name="upazila_one">
                    <option selected disabled>
                      ------------Please Select One -----------
                    </option>
                    <option v-for="(up, uidx) in upazila_one" :key="uidx" :value="up.id">
                      {{ up.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <h5 class="text-uppercase">
                      <b><i>Permanent Address:</i></b>
                    </h5>
                  </div>
                  <div class="col-lg-6">
                    <div>
                      <b-form-checkbox v-model="sameAddress" name="check-button" switch>
                        <small>As Like Current Address</small>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">House No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.house_number_two" type="text" class="form-control" name="house_number" />
                </div>
                <div class="form-group">
                  <label for="">Road No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.road_number_two" type="text" class="form-control" name="road_number" />
                </div>
                <div class="form-group">
                  <label for="">Float No: <sup class="text-danger">*</sup></label>
                  <input v-model="form.float_number_two" type="text" class="form-control" name="float_no" />
                </div>
                <div class="form-group">
                  <label for="">Area: <sup class="text-danger">*</sup></label>
                  <input v-model="form.area_two" type="text" class="form-control" />
                </div>
                <div class="form-group">
                  <label for="">Post Code: <sup class="text-danger">*</sup></label>
                  <input v-model="form.post_code_two" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <label for="">District <sup class="text-danger">*</sup></label>
                  <select v-if="districts && districts.length > 0" v-model="form.district_two" class="form-control"
                    name="district" @change="districtWiseCityTwo($event)">
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option v-for="(district, didx) in districts" :key="didx" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'district' }">Please Create District</router-link>
                </div>
                <div v-if="upazila_two && upazila_two.length > 0" class="form-group">
                  <label for="">Upazila</label>
                  <select id="" v-model="form.upazila_two" class="form-control" name="upazila_one">
                    <option selected disabled>
                      ------------Please Select One -----------
                    </option>
                    <option v-for="(upa, uidx) in upazila_two" :key="uidx" :value="upa.id">
                      {{ upa.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <a class="btn btn-success" @click.prevent="previous('step-2', 'step-1')">
              Previous
            </a>
            <a class="btn btn-primary" @click.prevent="next('step-2', 'step-3')">
              Next
            </a>
          </div>
          <div id="step-3" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6 mt-1">
                <label for="">Zone <sup class="text-danger">*</sup></label>
                <select v-if="zone && zone.length > 0" v-model="form.zone" name="zone" class="form-control">
                  <option value="" disabled>
                    --------Please Select One ---------
                  </option>
                  <option v-for="zon in zone" :key="zon.id" :value="zon.id">
                    {{ zon.name }}
                  </option>
                </select>
                <router-link v-else :to="{ name: 'zone' }">Please Create Zone</router-link>
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Ref Number <sup class="text-danger">*</sup></label>
                <div class="d-flex">
                  <input :disabled="!edit_ref" v-model="form.user_reference_number" type="text" class="form-control"
                    placeholder="Refrence Number" />
                  <button v-b-tooltip.top.v-danger title="Make sure youn have to change?"
                    @click.prevent="edit_ref = !edit_ref" class="btn btn-primary btn-sm">
                    <feather-icon :icon="!edit_ref ? 'Edit3Icon' : 'ArrowRightIcon'" size="16" />
                  </button>
                </div>
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Registration Date: <sup class="text-danger">*</sup></label>
                <input v-model="form.registration_date" type="datetime-local" value="today" class="form-control"
                  placeholder="Registration Date" />
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Connection Date: <sup class="text-danger">*</sup></label>
                <input v-model="form.connection_date" type="datetime-local" class="form-control"
                  placeholder="Connection Date" />
              </div>
            </div>

            <div class="mt-1">
              <a class="btn btn-success" @click.prevent="previous('step-3', 'step-2')">
                Previous
              </a>
              <a class="btn btn-primary" @click.prevent="next('step-3', 'step-4')">
                Next
              </a>
            </div>
          </div>
          <div id="step-4" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Packge</label>
                  <select v-if="InPackage && InPackage.length > 0" v-model="form.package" class="form-control"
                    name="packge" @change="selectPackge">
                    <option value="" selected disabled>
                      --------Please Select One
                    </option>
                    <option v-for="(pack, pidx) in InPackage" :key="pidx" :value="pack.id">
                      <span>{{ pack.name }}
                        <span v-if="pack.synonym">{{
                            "-" + pack.synonym
                        }}</span>
                      </span>
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'addpackage' }">Please Create Packge</router-link>
                </div>
                <div class="form-group">
                  <label for="user_payment_type">Connection Fee</label>
                  <input @input="change_invoice_cal_item" v-model="form.connection_fee" type="number" min="0"
                    placeholder="Number Only (Ex:00000)" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Monthly Bill</label>
                      <input @input="change_invoice_cal_item" disabled v-model="form.monthly_bill" type="text"
                        class="form-control" placeholder="Montyly Bill" />
                    </div>
                    <div class="form-group">
                      <label for="">Permanent Discount:</label>
                      <input @input="change_invoice_cal_item" v-model="form.discount" type="number" min="0"
                        class="form-control" placeholder="Discount" />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="border card-body p-0 py-1 px-1">
                      <h6 class="text-center" for="">Invoice Bill</h6>
                      <tr v-if="form.monthly_bill">
                        <td class="w-50">Monthly</td>
                        <td>:</td>
                        <td class="w-25">{{ form.monthly_bill }}</td>
                      </tr>
                      <tr v-if="form.connection_fee">
                        <td>Connection</td>
                        <td>:</td>
                        <td>{{ form.connection_fee }}</td>
                      </tr>
                      <tr v-if="form.discount">
                        <td>Discount</td>
                        <td>:</td>
                        <td>{{ form.discount }}</td>
                      </tr>
                      <tr v-if="
                        form.discount ||
                        form.connection_fee ||
                        form.monthly_bill
                      ">
                        <td colspan="5" class="saperator_border"></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Total</td>
                        <td class="font-weight-bold">:</td>
                        <td class="font-weight-bold">
                          {{ form.total_invoice_price }}
                        </td>
                      </tr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <div>
                    <label title="" for="user_payment_type">Collect Bill
                      <span class="text-success">(From Home)</span></label>
                    <label :for="'is_home_collect'" class="__switch">
                      <input @change="change_bill_collected" id="is_home_collect" v-model="form.is_home_collect"
                        type="checkbox" @input="form.is_home_collect = !form.is_home_collect"
                        :checked="form.is_home_collect" />
                      <span class="__slider" />
                    </label>
                  </div>
                  <div v-if="form.is_home_collect">
                    <label for="">Manager</label>
                    <v-select v-model="form.collect_by" :options="select_options_manager" :clearable="false" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Send Billing Reminder:</label> <br />
                  <label for="">Sms</label>
                  <label :for="'sms_b'" class="__switch">
                    <input id="sms_b" v-model="form.send_sms" type="checkbox" @input="form.send_sms = !form.send_sms"
                      :checked="form.send_sms" />
                    <span class="__slider" />
                  </label>
                  <label for="">Email</label>
                  <label :for="'sms_e'" class="__switch" @click="form.send_email = !form.send_email">
                    <input id="sms_e" v-model="form.send_email" type="checkbox"
                      @input="form.send_email = !form.send_email" :checked="form.send_email" />
                    <span class="__slider" />
                  </label>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="user_payment_type">User Payment Type</label>
                  <select class="form-control" v-model="form.user_payment_type" id="user_payment_type">
                    <option value="">
                      --------Please Select One ---------
                    </option>
                    <option value="Prepaid">Prepaid</option>
                    <option value="Postpaid">Postpaid</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12">
                    <label for="">Bank Payment</label>
                    <select v-model="bank_payment" class="form-control" name="bank_payment">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div v-if="bank_payment == '1'" class="col-lg-12 mt-1">
                    <div class="form-group">
                      <label for="">Bank Name</label>
                      <input v-model="form.bank_name" type="text" class="form-control" placeholder="Bank Name" />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Name</label>
                      <input v-model="form.bank_account_name" type="text" class="form-control"
                        placeholder="Bank Account Name" />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Number</label>
                      <input v-model="form.bank_account_number" type="text" class="form-control"
                        placeholder="Bank Account Number" />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Branch: </label>
                      <input v-model="form.bank_account_branch" type="text" class="form-control"
                        placeholder="Bank Account Branch" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <a class="btn btn-success" @click.prevent="previous('step-4', 'step-3')">
                Previous
              </a>
              <button class="btn btn-primary" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { BFormCheckbox } from "bootstrap-vue";
import router from "@/router";
import axios from "axios";
export default {
  name: "UserCreate",
  components: {
    BFormCheckbox,
  },

  data() {
    return {
      form: new Form({
        name: "Fahim Muntasir",
        user_type: "Home",
        email: "fahimmbpi@gmail.com",
        phone: "01795297424",
        father_name: "Iqbal Hussen",
        mother_name: "Taslima Begum",
        gender: "male",
        identification_reference: "NID",
        identification_reference_number: "Hello",
        occupation: "Programmer",
        birthday_date: "1999-01-10",
        house_number_one: "10",
        area_one: "10",
        float_number_one: "10",
        road_number_one: "10",
        post_code_one: "10",
        district_one: "",
        district_two: "",
        upazila_one: "",
        upazila_two: "",

        house_number_two: "10",
        area_two: "10",
        float_number_two: "10",
        road_number_two: "10",
        post_code_two: "10",

        user_reference_number: null,
        zone: "2",
        registration_date: "",
        connection_date: "",
        connection_type: "",

        package: "",
        monthly_bill: "",
        send_sms: false,
        send_email: true,

        bank_name: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_branch: "",

        mikrotik: "",
        username: "",
        userpassword: "",
        service: "PPPoE",
        remote_address: "",
        mac_address: "",
        remote_ip: "",
        router_component: "",
        expire_date: "",
        user_payment_type: "Prepaid",
        queue_name: "",
        queue: "",
        destination: "",
        target_address: "",
        distanitaion: "",
        queue_type: "",
        queue_download: "",
        download_priority: "",
        queue_upload: "",
        priority_upload: "",
        connection_fee: "",
        total_invoice_price: "",
        discount: "",
        is_home_collect: false,
        collect_by: "",
      }),
      nas_type: "", //MikroTik Radius MikroTik+Radius
      user_types: [
        { type: "Home User", value: "Home" },
        { type: "Corporate User", value: "Corporate" },
      ],

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "",
      step: {
        one: false,
        second: true,
      },
      district_select_options: {
        options: [],
      },

      selected_distric: {
        code: "",
        label: "-------Please Select One-----------",
      },
      sameAddress: false,
      bank_payment: 0,
      upazila_one: [],
      upazila_two: [],
      remot_address_items: null,
      remot_address_start_ip: null,
      remot_address_end_ip: null,
      poll_items: null,
      interfaces: [],
      queue_types: [],
      queue_data: [],
      listModalData: [],
      copyFor: "",
      is_queue: true,
      pkg_info: null,

      allmacAddress: null,
      all_remote_address: null,
      edit_ref: false,
      select_options_manager: [],
    };
  },
  computed: {
    districts() {
      return this.$store.state.isp.others.districts;
    },
    user_infos() {
      return this.$store.state.isp.user.info;
    },
    zone() {
      return this.$store.state.isp.others.zone;
    },
    InPackage() {
      return this.$store.state.isp.others.package;
    },
    mikrotiks() {
      return this.$store.state.isp.others.mikrotik;
    },
  },
  watch: {
    items() {
      this.loading = false;
    },

    sameAddress(value) {
      if (value) {
        this.form.house_number_two = this.form.house_number_one;
        this.form.area_two = this.form.area_one;
        this.form.float_number_two = this.form.float_number_one;
        this.form.road_number_two = this.form.road_number_one;
        this.form.post_code_two = this.form.post_code_one;
      } else {
        this.form.house_number_two = "";
        this.form.area_two = "";
        this.form.float_number_two = "";
        this.form.road_number_two = "";
        this.form.post_code_two = "";
      }
      console.log(value);
    },
  },
  created() {
    this.$store.dispatch("others", {
      zone: true,
      mikrotik: true,
      user_reference_number: true,
      package: true,
      districts: true,
    });
  },
  methods: {
    change_invoice_cal_item() {
      const connection_fee = parseInt(
        this.form.connection_fee ? this.form.connection_fee : 0
      );
      const monthly_bill = parseInt(
        this.form.monthly_bill ? this.form.monthly_bill : 0
      );
      const discount = parseInt(this.form.discount ? this.form.discount : 0);
      this.form.total_invoice_price = connection_fee + monthly_bill - discount;
    },
    get_softwer_system() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("software-system").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.nas_type = resp.data.data.nas_type;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    next(current, next) {
      this.form.user_reference_number =
        parseInt(this.$store.state.isp.others.user_reference_number) + 1;
      const currentElement = document.getElementById(current);
      const nextElement = document.getElementById(next);
      if (this.$validation(current) === true) {
        currentElement.style.display = "none";
        nextElement.style.display = "block";
        document.getElementsByClassName(next)[0].classList.add("active");
      }
    },
    previous(current, previous) {
      const currentElement = document.getElementById(current);
      const previousElement = document.getElementById(previous);
      currentElement.style.display = "none";
      previousElement.style.display = "block";
    },

    districtWiseCity(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_one = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    districtWiseCityTwo(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_two = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    selectPackge(e) {
      const pkg = this.InPackage.find(
        (ele) => ele.id.toString() === e.target.value
      );
      this.is_queue = pkg.queue_id ? true : false;
      this.form.monthly_bill = pkg.price;
      this.form.mikrotik = pkg.mikrotik_id;
      this.pkg_info = pkg;
      this.change_invoice_cal_item();
    },

    save() {
      if (this.$validation("step-4") === true) {
        this.form.post("new/customer/register").then((resp) => {
          if (resp.data.success) {
            this.$success_message(resp.data.message);
            router.push({ name: "pendingCustomer" });
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
      }
    },
    close() {
      this.$bvModal.hide("remote_adress-modal");
      this.$bvModal.hide("modal-lg");
      this.$bvModal.hide("add_queue_modal");
    },
    //= ===handle the form submit=========
    submitHandelar(val = null) {
      if (val == "save_queue_type") {
        this.savequeue();
      } else if (val == "save_ip_address") {
        this.saveIp();
      }
      this.$bvModal.hide("modal-lg");
    },

    change_bill_collected() {
      if (this.form.is_home_collect) {
        this.select_options_manager = [];
        this.$store.dispatch("spinnerLoading", true);
        this.axios.get("user").then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {
            resp.data.data.forEach((ele) => {
              const obj = {
                code: ele.id,
                label: `${ele.name}`,
              };
              this.select_options_manager.push(obj);
            });
            //  console.log("Users " + JSON.stringify(this.users))
          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
          this.select_options_queue.push(obj);
        });
      }
    },
  },
};
</script>
<style>
sup {
  font-size: 16px !important;
  font-weight: bold;
}

.headline {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  border-bottom: 2px solid #eee;
}

.headline li {
  display: block;
  align-items: center;
  color: #423636;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.headline li span.active {
  border: 4px solid #968df4;
  color: #fff !important;
  border: 4px solid #968df4 !important;
}

.headline li span {
  /* background: #eee; */
  display: block;
  width: 60px;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  padding: 12px 0px;
  font-size: 25px;
  font-weight: 600;
  color: #000;
  border: 4px solid #eee;
  z-index: 9999999;
  background: #fff;
}

.headline li.active span {
  background: #968df4;
  color: #fff !important;
}

input.form-control.invalid {
  border-color: red;
  margin-bottom: 3px;
}

span.valdation-msg {
  color: red;
  font-style: italic;
  font-size: 12px;
}

span.active {
  background: #968df4 !important;
  color: #fff !important;
  border: 4px solid #968df4 !important;
}

.headline li:after {
  position: absolute;
  content: "";
  /* right: 0; */
  width: 75%;
  background: #ddd;
  height: 6px;
  top: 34%;
  left: 32%;
}

/* .headline li.active:after {
  background: #968df4 !important;
} */

/* .headline li.active:last-child::after {
  background: #968df4 !important;
} */
.headline li:last-child::after {
  display: none;
}

.row.content-header {
  display: none;
}

.saperator_border {
  content: "";
  border-top: 1px solid;
}
</style>
