<template>
	<div>
		<Customer 
			v-if="entity === 'customers'"
			@submitted="formSubmitted"
			:type="type"
			:lat_lon="lat_lon"
			:id="id"
		/>
		<Splitter 
			v-if="entity === 'splitters'"
			@submitted="formSubmitted"
			:type="type"
			:lat_lon="lat_lon"
			:id="id"
		/>
		<Enclouser 
			v-if="entity === 'enclousers'"
			@submitted="formSubmitted"
			:type="type"
			:lat_lon="lat_lon"
			:id="id"
		/>
		<Pop 
			v-if="entity === 'pops'"
			@submitted="formSubmitted"
			:type="type"
			:lat_lon="lat_lon"
		/>
		<Rack 
			v-if="entity === 'racks'"
			@submitted="formSubmitted"
			:type="type"
		/>
		<Router 
			v-if="entity === 'routers'"
			@submitted="formSubmitted"
			:type="type"
			:id="id"
		/>
		<NMSwitch 
			v-if="entity === 'switches'"
			@submitted="formSubmitted"
			:type="type"
			:id="id"
		/>
		<Odf 
			v-if="entity === 'odfs'"
			@submitted="formSubmitted"
			:type="type"
			:id="id"
		/>
		<Olt 
			v-if="entity === 'olts'"
			@submitted="formSubmitted"
			:type="type"
			:id="id"
		/>
		<TransmissionEquipment 
			v-if="entity === 'transmission_equipments'"
			@submitted="formSubmitted"
			:type="type"
			:id="id"
		/>
		<Fiber 
			v-if="entity === 'fibers'"
			@submitted="formSubmitted"
			:type="type"
		/>
	</div>
</template>


<script>
import Customer from './forms/customer.vue'
import Splitter from './forms/splitter.vue'
import Enclouser from './forms/enclouser.vue'
import Pop from './forms/pop.vue'
import Rack from './forms/rack.vue'
import Router from './forms/router.vue'
import NMSwitch from './forms/switch.vue'
import Odf from './forms/odf.vue'
import Olt from './forms/olt.vue'
import TransmissionEquipment from './forms/transmission-equipment.vue'
import Fiber from './forms/fiber.vue'
export default {
	name: "MapForm",
	props: [
		"type",
		"entity",
		"lat_lon",
		"id"
	],
	components: {  
		Customer, 
		Splitter, 
		Enclouser, 
		Pop, 
		Rack, 
		Router, 
		NMSwitch, 
		Odf,
		Olt,
		TransmissionEquipment,
		Fiber
	},
	emits: ['submitted', 'popupInfoChange'],
	data() {
	  return {
	      
	  }
	},
	computed: {
	  
	},
	created() {
		this.changeTitle()
	},
	watch: {
	   entity: function(newVal, oldVal){
	   		this.changeTitle()
	   }
	},
	methods: {
		formSubmitted(data){
			this.$emit('submitted', data)
		},
		changeTitle(){
			let title = "";
			if(['customers', 'splitters', 'enclousers', 'pops', 'racks', 'routers', 'odfs', 'olts', 'fibers'].indexOf(this.entity) > -1){
				title = this.entity.slice(0, this.entity.length-1);
				title = title[0].toUpperCase()+title.slice(1, title.length)
			} else if(this.entity ===  'switches'){
				title = 'Switch'
			} else if(this.entity ===  'transmission_equipments'){
				title = 'Transmission Equipment'
			}

			this.$emit('popupInfoChange', {
				key: 'title',
				value: title + ' ' + this.type,
			})
		}
	},
}
</script>