/* eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */
/* eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-2">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <!-- v-if="permission.add" -->
        <b-button
          v-b-modal.modal-lg
          variant="primary"
          size="sm"
          @click.prevent="editData.mode = false"
        >
          <span>
            <feather-icon icon="PlusCircleIcon" size="16" />
            Add New
          </span>
        </b-button>
      </div>
      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="md">
        <form @submit.prevent="submitHandelar">
          <div class="form-group">
            <label for="">Name</label>
            <input
              autofocus="autofocus"
              v-model="form.name"
              type="text"
              class="form-control"
              name="name"
              placeholder="Name"
            />
          </div>
          <div class="form-group">
            <label for="">Days</label>
            <v-select
                v-model="form.days"
                :options="select_options_days"
                :multiple="true"
            />
          </div>
          <div class="form-group">
            <label for="">Start Time</label>
            <input
              autofocus="autofocus"
              v-model="form.start_time"
              type="time"
              class="form-control"
              name="start_time"
              placeholder="Start Time"
            />
          </div>
          <div class="form-group">
            <label for="">End Time</label>
            <input
              autofocus="autofocus"
              v-model="form.end_time"
              type="time"
              class="form-control"
              name="end_time"
              placeholder="End Time"
            />
          </div>
          <div class="form-group">
            <label for="">Status</label>
            <select v-model="form.status" class="form-control">
              <option value="" selected disabled>
                ---------Please Select One -------
              </option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
              <option value="2">Terminate</option>
            </select>
          </div>
          <button class="btn btn-primary" type="submit" :disabled="form.busy">
            Submit
          </button>
        </form>
        <template #modal-footer>
          <div class="btn btn-warning" @click="close" type="">Close</div>
        </template>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Days</th>
              <th scope="col">Start time</th>
              <th scope="col">End time</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(workinghour, index) in $store.state.hrm.workinghours.data" :key="index + 'workinghours'">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ workinghour.name }}</td>
              <td>{{ workinghour.days }}</td>
              <td>{{ workinghour.start_time }}</td>
              <td>{{ workinghour.end_time }}</td>
              <td>{{ workinghour.status == 1 ? 'Active' : 'Inactive' }}</td>

              <td>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="permission.edit"
                    @click.prevent="edit(workinghour.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      Edit
                    </button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="permission.delete"
                    :action="'workinghour/' + workinghour.id"
                    @click.prevent="$trash('workinghour/' + workinghour.id)"
                  >
                    <button class="btn btn-sm p-0">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                      Delete
                    </button>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal } from "bootstrap-vue";

export default {
  nameL: "role",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      form: new Form({
        name: "",
        days: "",
        start_time: "",
        end_time: "",
        status: "",
      }),

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      select_options_days: [
          {'code':'Sun','label':"Sun"},
          {'code':'Mon','label':"Mon"},
          {'code':'Tue','label':"Tue"},
          {'code':'Wed','label':"Wed"},
          {'code':'Thu','label':"Thu"},
          {'code':'Fri','label':"Fri"},
          {'code':'Sat','label':"Sat"},
      ],
      modal_title: "Create Data",
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        permssions: false,
      },
    };
  },
  computed: {
    workinghours() {
      return this.$store.state.hrm.workinghours.data;
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Shift");
      this.permission.add = this.checkpermission("Shift Add");
      this.permission.edit = this.checkpermission("Shift Edit");
      this.permission.delete = this.checkpermission("Shift Delete");
    },
  },
  watch: {
    roles() {
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch("getWorkingHours");
  },
  methods: {
    //= ===handle the form close =========
    close() {
      this.form.name = "";
      this.form.days = "";
      this.form.start_time = "";
      this.form.end_time = "";
      this.form.status = "1";
      this.$bvModal.hide("modal-lg");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("workinghour")
        .then((resp) => {
          if (resp.data.success) {
            this.close();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getWorkingHours");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`workinghour/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.close();
            this.$s_tost(resp.data.message);
            this.$store.dispatch("getWorkingHours");
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(id) {
      const workinghour = this.workinghours.find((ele) => ele.id === id);
      this.modal_title = `Update-${workinghour.name}`;
      this.form.name = workinghour.name;
      this.form.days = [];
      this.form.start_time = workinghour.start_time;
      this.form.end_time = workinghour.end_time;
      this.form.status = workinghour.status;
      this.editData.mode = true;
      this.editData.id = workinghour.id;
      const days = workinghour.days.split(',');
      if (days.length > 0) {
        days.forEach((element) => {
          const obj = {
            code: element,
            label: element,
          };
          this.form.days.push(obj);
        });
      }
      this.$bvModal.show("modal-lg");
    },
  },
};
</script>
