<template>
	<div>
		<h5 class="text-center">Enclouser Info ({{this.type}})</h5>
		<DynamicForm
			:no_submit="true"
			:form="form[type]"
			type="create"
			:fields="dynamicFormFields"
		/>
	</div>
</template>

<script>
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";
export default {
	name: "ConnectorEnclouser",
	components: { DynamicForm },
	props: {
		"type" : String,
		"id" : Number,
		"form" : Object
	},
	data() {
	  return {
	        typeOptions: [{
				'title': 'Input',
				'value': 'input'
			}, {
				'title': 'Output',
				'value': 'output'
			}],
			enclousers: [],
	  		fiber_cores: [],
	  }
	},
	computed: {
		dynamicFormFields(){
	  	const formFields = [
			{
				'type': 'select',
				'name': 'enclouser_id',
				'label': 'Enclouser',
				'options': this.enclousers,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				},
				'disabled': true
			},
			{
				'type': 'select',
				'name': 'type',
				'label': 'Type',
				'options': this.typeOptions,
				'disabled': true
			},
			{
				'type': 'number',
				'name': 'meter_marking',
				'label': 'Meter Marking',
				'column_class': 'col-md-6'
			},
			{
				'type': 'text',
				'name': 'dbm',
				'label': 'dBM',
				'column_class': 'col-md-6'
			},
			{
				'type': 'textarea',
				'name': 'comment',
				'label': 'Comment',
			},
		]

		if(this.form[this.type]['type'] === 'output'){
			let type_field_index = null
			for (let i = 0; i < formFields.length; i++){
				if(formFields[i].name === 'type'){
					type_field_index = i
					break
				}
			}

			if(type_field_index !== null){
				formFields.splice(type_field_index+1, 0, {
					'type': 'select',
					'name': 'input_fiber_core_id',
					'label': 'Input Fiber Core',
					'options': this.fiber_cores,
					'option_settings': {
						'title': 'detail_name',
						'value': 'id',
					},
				})
			}

		} else {
			this.form[this.type]['input_fiber_core_id'] = null
		}

		return formFields
	  }
	},
	mounted(){
		this.form[this.type] = {
	        enclouser_id: this.id,
	        input_fiber_core_id: null,
	        type: null,
	        meter_marking: null,
	        dbm: null,
	        comment: null,
	    }

	    this.form[this.type][`connected_${this.type}_type`] = 'enclousers'

		if(this.type == 'from'){
			this.form[this.type].type = 'output'
		} else {
			this.form[this.type].type = 'input'
		}
		this.getEnclousers()
		this.getFiberCores()
	},
	methods: {
		
		getEnclousers() {
		  axios.get(`nm-enclousers?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.enclousers = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
		getFiberCores() {
		  axios.get(`nm-fiber-cores?page=1&item=all&type=enclouser_input_fiber_core&enclouser_id=${this.id}`).then((resp) => {
		    if (resp.data.success) {
		      this.fiber_cores = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
	}
}
</script>