import auth from "@/middleware/auth";
import Customer from "../views/Customer/Index.vue";
import CorporateCustomer from "../views/Customer/CorporateCustomer.vue";
import SuspendedCustomer from "../views/Customer/SupendedUser.vue";
import DeletedCustomer from "../views/Customer/DeletedUser.vue";
import PendingCustomer from "../views/Customer/PendingUser.vue";
import ImportsCustomers from "../views/Customer/ImportsCustomers.vue";
import CustomerAdd from "../views/Customer/Add.vue";
import EditCustomer from "../views/Customer/EditCustomer.vue";
import UserSessionHistory from "../views/Customer/UserSessionHistory.vue";
import EditImportUser from "../views/Customer/EditImportUser.vue";
import EditMikrotikUser from "../views/Customer/EditMikrotikUser.vue";
import NewCustomerRegistration from "../views/Customer/NewCustomerRegistration.vue";
import Role from "../views/Role/Index.vue";
import Agents from "../views/Agents/Index.vue";
import Profile from "../views/profile/Index.vue";
import User from "../views/Users.vue";
import RolePermission from "../views/Role/RolePermission.vue";
import MikrotikUser from "../views/Mikrotik/MikrotikUser.vue";
import UserProfile from "../views/user/UserProfile.vue";
import AgentProfile from "../views/profile/AgentProfile.vue";
// import index from "../views/Customer/view/index.vue"
export default [
  {
    path: "/customerAdd",
    name: "customerAdd",
    component: CustomerAdd,
    meta: {
      pageTitle: "User Add",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Add Page",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/user-view/:id",
  //   name: "user.user_view",
  //   component: index,
  //   meta: {
  //     pageTitle: "User Add",
  //     middleware: [auth],
  //     breadcrumb: [
  //       {
  //         text: "User Add Page",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/edit-import-user/:id",
    name: "EditImportUser",
    component: EditImportUser,
    meta: {
      pageTitle: "Edit Import User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Edit Import User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-edit/:id",
    name: "customerEdit",
    component: EditCustomer,
    meta: {
      pageTitle: "Customer Edit",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Customer Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/Customer/users-edit/UsersEdit.vue"),
    meta: {
      pageTitle: "Edit User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Edit User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/users/view/:id",
    name: "apps-users-view",
    component: () => import("@/views/Customer/users-view/UsersView.vue"),
    meta: {
      pageTitle: "View User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "View User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/users/notification",
    name: "apps-users-notification",
    component: () => import("@/views/user/UserNotification.vue"),
    meta: {
      pageTitle: "User Notification",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Notification",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-mikrotik-user/:id",
    name: "editMikrotikUser",
    component: EditMikrotikUser,
    meta: {
      pageTitle: "User Add",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Add Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users",
    name: "users",
    component: User,
    meta: {
      pageTitle: "Users",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/home/customer",
    name: "homeCustomer",
    component: Customer,
    meta: {
      pageTitle: "User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/corporate/customer",
    name: "corporateCustomer",
    component: CorporateCustomer,
    meta: {
      pageTitle: "User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer/expiring",
    name: "expiring_customer",
    component: SuspendedCustomer,
    meta: {
      pageTitle: "Suspended User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer/expired",
    name: "expired_customer",
    component: SuspendedCustomer,
    meta: {
      pageTitle: "Suspended User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/suspended/customer",
    name: "suspendedCustomer",
    component: SuspendedCustomer,
    meta: {
      pageTitle: "Suspended User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pending/customer",
    name: "pendingCustomer",
    component: PendingCustomer,
    meta: {
      pageTitle: "Suspended User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/import",
    name: "ImportsCustomers",
    component: ImportsCustomers,
    meta: {
      pageTitle: "Import Customers",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Import Customers",
          active: true,
        },
      ],
    },
  },
  {
    path: "/deleted/customer",
    name: "deletedCustomer",
    component: DeletedCustomer,
    meta: {
      pageTitle: "Deleted User",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/register/customer",
    name: "newCustomerRegistration",
    component: NewCustomerRegistration,
    meta: {
      pageTitle: "New Customer Registration",
      middleware: [auth],
      breadcrumb: [
        {
          text: "User Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/role",
    name: "role",
    component: Role,
    meta: {
      pageTitle: "role",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Role",
          active: true,
        },
      ],
    },
  },
  {
    path: "/agents",
    name: "agents",
    component: Agents,
    meta: {
      pageTitle: "agents",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Agents",
          active: true,
        },
      ],
    },
  },
  {
    path: "/role/:id/permission",
    name: "RolePermission",
    component: RolePermission,
    meta: {
      pageTitle: "RolePermission",
      middleware: [auth],
      breadcrumb: [
        {
          text: "RolePermission",
          active: true,
        },
      ],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      pageTitle: "Profile",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Profile",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user-mikrotik",
    name: "user.mikrotik",
    component: MikrotikUser,
    meta: {
      pageTitle: "MikroTik users",
      middleware: [auth],
      breadcrumb: [
        {
          text: "MikroTik users",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user-profile/:id",
    name: "user.users_profile",
    component: UserProfile,
    meta: {
      pageTitle: "Users Profile",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Users Profile",
          active: true,
        },
      ],
    },
  },
  {
    path: "/agent-profile/:id",
    name: "agent.agent_profile",
    component: AgentProfile,
    meta: {
      pageTitle: "Agent Profile",
      middleware: [auth],
      breadcrumb: [
        {
          text: "Agent Profile",
          active: true,
        },
      ],
    },
  },
  {
    path: "/session-history",
    name: "usersessionhistory",
    component: UserSessionHistory,
    meta: {
      pageTitle: "session-history",
      middleware: [auth],
      breadcrumb: [
        {
          text: "session-history",
          active: true,
        },
      ],
    },
  },
];
