<template>
  <div>
    <div>

      <b-modal id="create_form" title="Create Inventory Brand"  hide-footer>
        <form @submit.prevent="createBrand">
          <div class="row">
            <div class="col-lg-12">
              <div class="fom-group">
                <label for="">Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="fom-group">
                <label for="">Status</label>
                <select
                  v-model="form.status"
                  class="form-control"
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Submitting':'Submit'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal id="update_form" title="Update Inventory Brand"  hide-footer>
        <form @submit.prevent="updateBrand">
          <div class="row">
            <div class="col-lg-12">
              <div class="fom-group">
                <label for="">Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="..."
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="fom-group">
                <label for="">Status</label>
                <select
                  v-model="form.status"
                  class="form-control"
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-block" :disabled="form.busy">
                {{form.busy?'Updating':'Update'}}
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-card-code :title="title" no-body>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex">
              <b-button
                size="sm"
                col="2"
                v-b-modal.modal-lg
                variant="primary"
                v-b-modal="'create_form'"
              >
                <!-- @click.prevent="editData.mode = false" -->
                <feather-icon icon="PlusCircleIcon" size="16" />
                Add New</b-button
              >
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mb-0 ml-3"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">none</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    label-cols-sm="4"
                    :disabled="!sortBy"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- filter -->
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          ref="table"
          :per-page="perPage"

          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <template #cell(total_products)="{item, index}">
            {{item.products.length}}
          </template>
          <template #cell(status)="{item, index}">
          	{{item.status ? 'Active' : 'Inactive'}}
          </template>
          <template #cell(action)="{item, index}">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click.prevent="editBrand(item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteBrand(item, index)">
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNoOfRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";

export default {
  name: "Brands",
  components: { BCardCode, StatisticCardHorizontal },
  data() {
    return {
      title: "Inventory Brands",
      form: new Form({
        name: "",
        status: 1,
      }),
      fields: [

        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "total_products", label: "Total Products", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "action",
      ],
      items: [],
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  created() {
    this.getData();
  },
  watch: {
    currentPage: {
       handler: function(newVal, oldVal) {
           this.getData(newVal); // call it in the context of your component object
       },
       deep: true
    }
  },
  methods: {

    getData(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`inventory-brands?page=${page}&item=${this.perPage}`).then((resp) => {
        if (resp.data.success) {
          this.totalNoOfRows = resp.data.data.total
          this.items = resp.data.data.data;

        } else {
          this.$error_message(resp.data.message);
        }
      }).finally(()=> {
        this.$store.dispatch("spinnerLoading", false);
      });
    },

    createBrand() {
      this.form.post("inventory-brands").then((resp) => {

        if (resp.data.success) {
          let item = resp.data.data
          this.items = [ ...this.items, item ]

          this.$success_message(resp.data.message);
          this.form.reset()
          this.$bvModal.hide('create_form')
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    editBrand(item){
      this.form.reset()
      this.form.id = item.id
      this.form.name = item.name
      this.form.status = item.status
      this.$bvModal.show('update_form')
    },
    updateBrand(){
      this.form.put(`inventory-brands/${this.form.id}`).then((resp) => {
        if (resp.data.success) {
          let updatedItem = resp.data.data;

          this.items.every((item, index) => {
            if(item.id == updatedItem.id){
              this.$set(this.items, index, updatedItem)
              return false;
            }
            return true;
          })
          this.form.reset()
          this.$success_message(resp.data.message);
          this.$bvModal.hide('update_form')
          this.getData()
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    deleteBrand(item, index){
      if(item && confirm('Are you sure to delete?')){
        this.$store.dispatch("spinnerLoading", true);
        axios.delete(`inventory-brands/${item.id}`).then((resp) => {
          if (resp.data.success) {
            this.items.splice(index, 1)
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message);
          }
        }).finally(()=> {
          this.$store.dispatch("spinnerLoading", false);
        });
      }
    },

    onFiltered(filteredItems) {
      this.totalNoOfRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
