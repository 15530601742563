<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="items.data.total"
          statistic-title="Total IP Pool"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="items.active"
          statistic-title="Active"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="items.inactive"
          statistic-title="In Active"
        />
      </b-col>
    </b-row>

    <b-card-code no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <b-button
              v-if="permission.add"
              size="sm"
              col="2"
              v-b-modal.simple_queue_modal
              variant="primary"
              @click="modelAction('add')"
            >
              <!-- @click.prevent="editData.mode = false" -->
              <feather-icon icon="PlusCircleIcon" size="16" />
              Add New</b-button
            >
            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class="mb-0 ml-3"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  label-cols-sm="4"
                  :disabled="!sortBy"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- filter -->
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.data.data"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(mikrotik_id)="data">
          {{ data.item.mikrotik.identity }} ||
          {{ data.item.mikrotik.host }}
        </template>
        <template #cell(status)="data" v-if="permission.status">
          <b-form-checkbox
            v-model="data.item.status == 'false'"
            name="check-button"
            switch
            @change="changeStatus(data.item.id)"
          ></b-form-checkbox>
        </template>
        <template
          #cell(action)="data"
          v-if="permission.edit || permission.delete"
        >
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-if="permission.edit"
              @click.prevent="edit(data.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              Edit
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permission.delete"
              @click.prevent="deleteData(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData(1, perPage)"
          />
        </b-form-group>

        <!-- pagination -->
        <b-pagination-nav
          @input="getData"
          :link-gen="linkGen"
          :number-of-pages="items.data.last_page"
          :limit="perPage"
          use-router
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-card-code>
    {{ getRoleWisePermissions }}
    <AddQueue />
  </div>
</template>
<script>
import Form from "vform";
import { BButton, BModal, BRow, BCol, BTable } from "bootstrap-vue";
import Table from "@/components/table.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
import AddQueue from "./AddQueue.vue";

const Swal = require("sweetalert2");
import BCardCode from "@core/components/b-card-code/BCardCode";

export default {
  name: "Queue",
  components: {
    BButton,
    BModal,
    Table,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BTable,
    BCardCode,
    AddQueue,
  },
  data() {
    return {
      form: new Form({
        queue_name: "",
        mikrotik: "",
        queue_type_upload: "",
        queue_type_download: "",
        target: "",
        destination: "",
        max_limit_download: "",
        max_limit_upload: "",
        burst_limit_download: "",
        burst_limit_upload: "",
        burst_threshold_upload: "",
        burst_threshold_download: "",
        priority_download: "",
        priority_upload: "",
        burst_time_download: "",
        burst_time_upload: "",
        comment: "",
      }),
      title: "Queue",
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "mikrotik_id", label: "mikrotik", sortable: true },
        { key: "name", label: "Name" },
        { key: "target", label: "target", sortable: true },
        { key: "dst", label: "dst" },
        { key: "max-limit", label: "max limit (U/D)", sortable: true },
        { key: "priority", label: "priority", sortable: true },
        { key: "status", label: "status" },
        "action",
      ],
      items: [],
      queue_types: [],
      interfaces: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      perPage: 10,
      modal_title: "Add",
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      totalNoOfRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: true,
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Simple Queue");
      this.permission.add = this.checkpermission("Simple Queue Add");
      this.permission.edit = this.checkpermission("Simple Queue Edit");
      this.permission.delete = this.checkpermission("Simple Queue Delete");
      this.permission.status = this.checkpermission(
        "Simple Queue Change Status"
      );
    },
    mikrotiks() {
      return this.$store.getters.getallmikrotiks;
    },
  },
  mounted() {
    this.getData(1);
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$bvModal.hide("simple_queue_modal");
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("queue")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("simple_queue_modal");
            this.$success_message(resp.data.message);
            this.items.data.unshift(resp.data.data);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.form
        .put(`queue/${this.editData.id}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("simple_queue_modal");
            this.$success_message(resp.data.message);
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========

    edit(data) {
      this.$store.dispatch("getallmikrotiks");
      this.modal_title = "Update";
      this.form.queue_name = data.name;
      this.form.mikrotik = data.mikrotik_id;
      this.editData.mode = true;
      this.editData.id = data.id;
      this.$bvModal.show("simple_queue_modal");
    },
    getData(page = this.currentPage, item = this.perPage) {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`queue?page=${page}&item=${item}`).then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.items = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    changeStatus(id) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get(`queue/${id}`).then((resp) => {
        if (resp.data.success) {
          this.$success_message(resp.data.message);
          this.getData();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    linkGen(pageNum) {
      return { path: `/queue?pages=${pageNum}` };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getqueueType() {
      axios.get("queuetype/withselect").then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.queue_types = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getImterfaces() {
      axios.get("interface-withselect").then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.interfaces = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    modelAction(val = null) {
      if (val == "add") {
        this.editData.mode = false;
      }
      this.getImterfaces();
      this.getqueueType();
      this.$store.dispatch("getallmikrotiks");
      this.$bvModal.show("simple_queue_modal");
    },
    async deleteData(id) {
      await this.Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`queue/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
    },
  },
};
</script>
