<template>
  <div class="">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <b-button
          v-if="permission.add"
          Category
          Add
          v-b-modal.modal-lg
          variant="primary"
          size="sm"
          @click.prevent="editData.mode = false"
        >
          <feather-icon icon="PlusCircleIcon" size="16" />
          Add New
        </b-button>
      </div>
      <b-modal id="modal-lg" :title="modal_title" hide-header-close size="sm">
        <form @submit.prevent="submitHandelar">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              v-model="form.name"
              id="name"
              placeholder="Name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="Type">Type</label>
            <select class="form-control" v-model="form.type" id="Type">
              <option value="">---SELECT--</option>
              <option value="Income">Income</option>
              <option value="Expance">Expance</option>
            </select>
          </div>
        </form>

        <template #modal-footer>
          <button @click="close()" class="btn btn-warning">Close</button>
          <button
            @click="submitHandelar"
            class="btn btn-primary"
            type="submit"
            :disabled="form.busy"
          >
            Submit
          </button>
        </template>
      </b-modal>
      <div class="col-lg-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
              <th scope="col">Date</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(listItem, index) in listData" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ listItem.name }}</td>
              <td>{{ listItem.type }}</td>
              <td>
                <div
                  @click="s_e_d(listItem.id)"
                  v-if="listItem.status == 0"
                  class="btn"
                >
                  <feather-icon
                    class="text-success"
                    icon="ToggleRightIcon"
                    size="2x"
                  />
                </div>
                <div class="btn" v-else @click="s_e_d(listItem.id)">
                  <feather-icon
                    class="text-warning"
                    icon="ToggleLeftIcon"
                    size="2x"
                  />
                </div>
              </td>
              <td>{{ formatDate(listItem.created_at) }}</td>
              <td class="d-flex">
                <div>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-if="permission.edit" v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="permission.edit"
                      @click.prevent="edit(listItem.id)"
                    >
                      <button class="btn btn-sm p-0">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        Edit
                      </button>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="permission.delete">
                      <button class="btn btn-sm p-0">
                        <feather-icon icon="Trash2Icon" class="mr-50" />
                        Delete
                      </button>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {{ getRoleWisePermissions }}
  </div>
</template>
<script>
import Form from "vform";
const Swal = require("sweetalert2");
export default {
  nameL: "BillCollectionCategory",
  data() {
    return {
      form: new Form({
        name: "",
        type: "",
      }),

      listData: [],
      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      permission: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        status: false,
      },
      modal_title: "Create Category",
    };
  },
  computed: {
    getRoleWisePermissions() {
      this.permission.view = this.checkpermission("Account Category");
      this.permission.add = this.checkpermission("Account Category Add");
      this.permission.edit = this.checkpermission("Account Category Edit");
      this.permission.delete = this.checkpermission("Account Category Delete");
      this.permission.status = this.checkpermission(
        "Account Category Change Status"
      );
    },
  },
  created() {
    this.getData();
  },
  methods: {
    // status change
    s_e_d(data) {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("b-c-category/" + data).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("actions_success", res.data.message);
          this.getData();
        } else {
          this.$error_message(res.data.message, res.data.errors);
        }
      });
    },
    //= ===haxndle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },
    //= ===get data=========

    getData() {
      this.$store.dispatch("spinnerLoading", true);
      this.axios.get("b-c-category").then((res) => {
        this.$store.dispatch("spinnerLoading", false);
        if (res.data.success) {
          this.listData = res.data.data;
        } else {
          if (res.data.code == 403) {
            this.permissionError = res.data.message;
            this.$error_message(res.data.message, res.data.errors);
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        }
      });
    },

    //= ========data save=========
    save() {
      this.form
        .post("b-c-category")
        .then((res) => {
          if (res.data.success) {
            this.$store.dispatch("actions_success", res.data.message);
            this.listData.unshift(this.form);
            this.$bvModal.hide("modal-lg");
            this.getData();
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========

    update() {
      this.$store.dispatch("spinnerLoading", true);
      this.form
        .put(`b-c-category/${this.editData.id}`)
        .then((res) => {
          if (res.data.success) {
            this.$store.dispatch("actions_success", res.data.message);
            this.listData.unshift(this.form);
            this.$bvModal.hide("modal-lg");
            this.getData();
          } else {
            this.$error_message(res.data.message, res.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========get data for edit=========
    edit(id) {
      const data = this.listData.find((ele) => ele.id === id);
      this.modal_title = `Update-${data.name}`;
      this.form.name = data.name;
      this.form.type = data.type;
      this.editData.mode = true;
      this.editData.id = id;
      this.$bvModal.show("modal-lg");
    },
    //= ======== delete Modal =========
    async deleteData(id) {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`b-c-category/${id}`).then((resp) => {
            if (resp.data.success) {
              this.$success_message(resp.data.message);
              this.getData();
            } else {
            }
          });
        }
      });
    },
    //= ======== delete Modal end =========
    //= ======== close Modal =========
    close() {
      this.$bvModal.hide("modal-lg");
    },
  },
};
</script>
