<template>
  <b-modal id="modal-lg" :title="modal_title" size="sm" hide-footer>
    <form id="form" @submit.prevent="submitHandelar">
      <div class="col-lg-12">
        <div class="form-group">
          <label for="">User Name</label>
          <select v-model="form.user_id" class="form-control" validate name="type" @change="getMonthlyBill" disabled>
            <option v-for="(user, index) in users" :key="index" :value="user.id">
              {{ user.name }}
            </option>
          </select>
        </div>
        <div v-if="!editData.mode" class="form-group">
          <label for="">Invoice For</label>
          <select v-model="form.invoice_for" class="form-control" validate name="type">
            <option value="" selected disabled>
              ---------Please Select One-------
            </option>
            <option value="Monthly Bill">Monthly Bill</option>
            <option value="Add Balance">Add Balance</option>
            <!-- <option>Add Balance</option> -->
            <option>Connection Fee</option>
          </select>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label for="">Amount </label>
          <input v-model="form.amount" type="text" class="form-control" placeholder="Amount" validate />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label for="">Received Amount </label>
          <div>
            <input v-model="form.received_amount" type="text" class="form-control" placeholder="Received Amount"
              validate />
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label for="">Paid By</label>
          <select v-model="form.paid_by" class="form-control" validate name="type">
            <option value="" selected disabled>
              ---------Please Select One-------
            </option>
            <option value="cash">Cash</option>
            <option value="bkash">Bkash</option>
            <option value="nagad">Nagad</option>
          </select>
        </div>
        <div v-if="form.paid_by == 'bkash' || form.paid_by == 'nagad'" class="form-group">
          <label for="transection_id">Transaction Id</label>
          <input placeholder="Enter Transaction Id" type="text" v-model="form.transection_id" class="form-control"
            id="transection_id" />
        </div>
      </div>

      <button class="btn btn-primary" type="submit" :disabled="form.busy">
        Submit
      </button>
    </form>
  </b-modal>
</template>
<script>
import axios from "axios";
import Form from "vform";
export default {
  name: "MakePayment",
  // componetn props
  props: {
    paymentInfo: {
      type: Object,
    },
  },
  data() {
    return {
      //=====form data
      form: new Form({
        id: "",
        user_id: null,
        amount: null,
        received_amount: null,
        paid_by: "cash",
        transection_id: "",
      }),
      modal_title: "",
      //=====for data edit
      editData: {
        mode: false,
        id: null,
      },
    };
  },
  computed: {
    users() {
      this.form.id = this.paymentInfo.id;
      this.form.modal_title = this.paymentInfo.modal_title;
      this.form.user_id = this.paymentInfo.user_id;
      this.form.amount = this.paymentInfo.amount;
      return this.$store.state.isp.users;
    },
  },

  mounted() {
    this.form.modal_title = this.paymentInfo.modal_title;
    this.form.user_id = this.paymentInfo.user_id;
    this.form.amount = this.paymentInfo.amount;
    if (this.paymentInfo.user_id !== null) this.editData.mode = true;
    console.log(this.form);
  },
  created() {
    this.form.modal_title = this.paymentInfo.modal_title;
    this.form.user_id = this.paymentInfo.user_id;
    this.form.amount = this.paymentInfo.amount;
    if (this.paymentInfo.user_id !== null) this.editData.mode = true;
    console.log("created", this.paymentInfo);
  },
  methods: {
    data_method() {
      this.form.modal_title = this.paymentInfo.modal_title;
      this.form.user_id = this.paymentInfo.user_id;
      this.form.amount = this.paymentInfo.amount;
      if (this.paymentInfo.user_id !== null) this.editData.mode = true;
      console.log("mountend", this.paymentInfo);
    },
    //= ===handle the form submit=========
    submitHandelar() {
      if (!this.editData.mode) {
        this.save();
      } else {
        this.update();
      }
    },

    //= ========data save=========
    save() {
      this.form
        .post("invoice/create")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.$success_message(resp.data.message);
            // window.location.reload();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========get data for edit=========
    update() {
      axios
        .post("invoice/update", this.form)
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            // window.location.reload();
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          this.$error_message(e.message);
        });
    },
    // =======getMonthlyBill=========
    getMonthlyBill() {
      axios
        .get(`invoice/monthly-bill/${this.form.user_id}`)
        .then((res) => {
          this.form.amount = res.data.data.monthly_bill;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
