<template>
  <div class="container card shadow p-2">
    <h2 class="text-center" v-if="permissionError !== ''">
      {{ permissionError }}
    </h2>
    <div v-else class="row">
      <b-card-code :title="title" no-body>
        <b-card-body>
          <form id="form" @submit.prevent="save">
            <div class="row">
              <!-- <div class="col-12">
                <h5>Invoice Generation Days</h5>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="d-flex form-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="form"
                    name=""
                    id=""
                  />
                </div>
              </div> -->
            </div>
            <input type="submit" value="Save" class="btn btn-primary" />
          </form>
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

export default {
  name: "UserSettings",
  data() {
    return {
      title: "User Settings",
      form: new Form({}),
      permissionError: "",
    };
  },
  computed: {
    getRoleWisePermissions() {
      return this.$store.getters.getRoleWisePermissionslist;
    },
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("software-system").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          console.log(resp.data.data);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    save() {
      this.$store.dispatch("spinnerLoading", true);
      this.form.post("software-system").then((resp) => {
        if (resp.data.success) {
          this.getData();
          this.$success_message(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
  },
  components: { BCardCode },
};
</script>
