import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import bootstrap from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueSocialauth from "vue-social-auth";

import axios from "axios";

import vSelect from "vue-select";
import { Skeleton } from "vue-loading-skeleton";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import Loading from "./views/Loading.vue";
import "vue-select/dist/vue-select.css";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import excel from "vue-excel-export";

Vue.use(excel);

/// //=======custom global vue function import==================

import helper from "./helper.vue";
const Swal = require("sweetalert2");

/// //=======global component========

// pinia
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ToastPlugin);
Vue.use(bootstrap);

// Composition API
Vue.use(VueCompositionAPI);

Vue.component("v-select", vSelect);
Vue.component("Skeleton", Skeleton);

Vue.component("pagination", require("laravel-vue-pagination"));

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;
// axios.defaults.baseURL = 'https://sms.novocom-bd.com'
axios.defaults.baseURL = "https://demo.isp.fahimworks.com/api/v1/";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common = {
  Authorization: `Bearer ${store.state.isp.access_token}`,
};
// support cors platform
Vue.prototype.axios = axios;
Vue.prototype.Swal = Swal;
Vue.mixin(helper);

Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId:
        "258601294805-dq7238pc6e66itpvffvqi1pjhharaif4.apps.googleusercontent.com",
      redirectUri: "http://localhost:8080", // Your client app URL
    },
  },
});

new Vue({
  router,
  store,
  components: {
    Loading,
  },
  render: (h) => h(App),
}).$mount("#app");
