<template>
	<div>
		<b-row>
			
	      <b-col 
	      	md="6"
	      	class="mt-1"
	      >
	      	Purchase Reference : <b># {{ purchase.id }}</b>
	      </b-col>
	      <b-col 
	      	md="6" 
	      	class="text-right mt-1"
           >
                <h6 class="mb-25">
                  {{ purchase.supplier.name }}
                </h6>
                <b-card-text class="mb-25">
                  {{ purchase.supplier.email_address }}
                </b-card-text>
                <b-card-text class="mb-0">
		            <div 
		              class="text-nowrap" 
		              v-for="( number , index) in purchase.supplier.mobile_number"
		            >
		              {{ purchase.supplier.mobile_number_prefix +' '+ number}} 
		              <span 
		                class="text-primary" 
		                v-if="index == 0"
		                >
		                (P)
		              </span>
		            </div>
                </b-card-text>
	      </b-col>
		</b-row>
		<hr class="my-2">
	    <b-row class="match-height">
	      <b-col lg="4">
	        <statistic-card-horizontal
	          icon="DatabaseIcon"
	          :statistic="'৳'+payment_stat.total_amount.toLocaleString('en-US')"
	          statistic-title="Total Amount"
	        />
	      </b-col>
	      <b-col lg="4">
	        <statistic-card-horizontal
	          icon="PlusIcon"
	          color="success"
	          :statistic="'৳'+payment_stat.total_paid_amount.toLocaleString('en-US')"
	          statistic-title="Paid"
	        />
	      </b-col>
	      <b-col lg="4">
	        <statistic-card-horizontal
	          icon="MinusIcon"
	          color="danger"
	          :statistic="'৳'+payment_stat.due_amount.toLocaleString('en-US')"
	          statistic-title="Due"
	        />
	      </b-col>
	    </b-row>
		<b-row>
		    <b-col
		      lg="12"
		      class="mb-1"
		    >
		      <!-- button on right -->
		      <b-input-group>
		        <b-form-input 
		        	placeholder="Amount" 
		        	v-model="form.amount"
		        />
		        <b-input-group-append>
		          <b-button 
		          	variant="primary"
		          	:disabled="form.busy || form.amount < 1 "
		          	@click="createPayment"
		          >
		            {{ form.busy?'Adding':'Add' }} Payment
		          </b-button>
		        </b-input-group-append>
		      </b-input-group>
		    </b-col>
		</b-row>
	    <b-table
          striped
          hover
          responsive
          class="position-relative"
          ref="table"
          :per-page="payments.length"
          
          :items="payments"
          :fields="fields"
        >

          <template #cell(index)="{item, index}">
          	{{ index+1 }}
          </template>

          <template #cell(amount)="{item, index}">
          	৳{{item.amount.toLocaleString("en-US")}}
          </template>

          <template #cell(created_at)="{item, index}">
          	{{ new Date(item.created_at).toDateString() }}
          </template>


          <template #cell(action)="{item, index}">
           	<b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click.prevent="deletePayment(item, index)">
                <feather-icon icon="Trash2Icon" class="mr-50" />
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
	</div>
</template>
<script>
import Form from "vform";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import axios from "axios";
export default {
  name: "PaymentList",
  components: { BCardCode, StatisticCardHorizontal },
  props: ['purchase'],
  data(){
  	return {
  	  payments: [],	
      fields: [
        
        { key: "index", label: "#" },
        { key: "amount", label: "Amount" },
        { key: "created_at", label: "Paid at" },
        "action",
      ],

      form: new Form({
        purchase_id: this.purchase.id
        ,
		amount: 0
      })
  	}

  },
  computed: {
  	payment_stat(){
  		let stat = {
  			total_amount: 0,
  			total_paid_amount: 0,
  			due_amount: 0,
            status: 'paid'
  		}
  		if(this.purchase.payment_stat){
  			stat.total_amount = this.purchase.payment_stat.total_amount
  		}
  		for(let i=0; i<this.payments.length; i++) {
  		    stat.total_paid_amount += this.payments[i].amount;
  		}
  		stat.due_amount = stat.total_amount - stat.total_paid_amount;

  		if(stat.due_amount == 0){
  		    stat.status = "paid";
  		} else if(stat.due_amount < 0){
  		    stat.status = "over-paid";
  		} else {
  		    stat.status = "due";    
  		}

  		return stat
  	}
  },
  created(){
  	this.payments = this.purchase.payments
  },
  methods: {


    deletePayment(item, index){
      if(item && confirm('Are you sure to delete?')){
        this.$store.dispatch("spinnerLoading", true);
        axios.delete(`inventory-purchase-payments/${item.id}`).then((resp) => {
          if (resp.data.success) {
            this.payments.splice(index, 1)
            this.updatePurchaseProp()
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message);
          }
        }).finally(()=> {
          this.$store.dispatch("spinnerLoading", false);
        });
      }
    },

    updatePurchaseProp(){
    	let purchase = JSON.parse(JSON.stringify(this.purchase));
    	purchase.payments = JSON.parse(JSON.stringify(this.payments));
    	purchase.payment_stat.total_amount = this.payment_stat.total_amount;
    	purchase.payment_stat.total_paid_amount = this.payment_stat.total_paid_amount;
    	purchase.payment_stat.due_amount = this.payment_stat.due_amount;
    	purchase.payment_stat.status = this.payment_stat.status;

    	this.$emit("updatePurchaseInfoFromChild", purchase);
    },

    createPayment() {
      this.form.post("inventory-purchase-payments").then((resp) => {
        
        if (resp.data.success) {
          let item = resp.data.data
          this.payments = [ ...this.payments, item ]
          this.form.reset()
          this.updatePurchaseProp()
          this.$success_message(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
  }
}
</script>