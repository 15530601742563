/* eslint-disable consistent-return */ /* eslint-disable no-plusplus */ /*
eslint-disable vue/no-unused-vars */ /* eslint-disable vue/no-unused-vars */ /*
eslint-disable no-undef */ /* eslint-disable vue/html-indent */ /*
eslint-disable vue/html-indent */ /* eslint-disable quote-props */ /*
eslint-disable no-whitespace-before-property */ /* eslint-disable dot-notation
*/ /* eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */ /* eslint-disable dot-notation */ /*
eslint-disable dot-notation */
<template>
  <div class="card shadow p-1">
    <div class="row">
      <div class="col-lg-12">
        <div class="headline">
          <li class="step-1 active"><span>1</span> User Personal Info</li>
          <li class="step-2"><span>2</span>Address</li>
          <li class="step-3"><span>3</span>User Account Info</li>
          <li class="step-4"><span>4</span>Billing/Payment Information</li>
          <li class="step-5"><span>5</span>Connection</li>
        </div>
      </div>

      <div class="col-lg-12 mt-4">
        <form action="" @submit.prevent="save()">
          <div id="step-1" class="step" style="display: block">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for=""
                    >Select User Type <sup class="text-danger">*</sup></label
                  >
                  <select
                    v-model="form.user_type"
                    name="user_type"
                    class="form-control"
                  >
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option
                      v-for="(user, index) in user_types"
                      :key="index"
                      :value="user.value"
                    >
                      {{ user.type }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""
                    >Full Name <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    name="full_name"
                  />
                </div>
                <div class="form-group">
                  <label for="">Email <sup class="text-danger">*</sup></label>
                  <input
                    v-model="form.email"
                    type="text"
                    class="form-control"
                    name="email"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Mobile Number <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.phone"
                    type="text"
                    class="form-control"
                    name="phone"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Father's Name <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.father_name"
                    type="text"
                    class="form-control"
                    name="father_name"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Mother's Name <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.mother_name"
                    type="text"
                    class="form-control"
                    name="mother_name"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Gender <sup class="text-danger">*</sup></label>
                  <select
                    v-model="form.gender"
                    class="form-control"
                    name="gender"
                  >
                    <option value="">----select one-----</option>
                    <option value="male">Male</option>
                    <option value="male">Famale</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""
                    >Proof Of Identity <sup class="text-danger">*</sup></label
                  >
                  <select
                    v-model="form.identification_reference"
                    class="form-control"
                    name="prof-of-identity"
                  >
                    <option value="">---select one----</option>
                    <option value="NID">NID</option>
                    <option value="Birthday-Certificate">
                      Birthday Certifcate
                    </option>
                    <option value="Passport">Passport</option>
                  </select>
                </div>

                <div v-if="form.identification_reference" class="form-group">
                  <label for=""
                    >{{
                      `Please Enter ${form.identification_reference} Number`
                    }}
                    <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.identification_reference_number"
                    type="text"
                    class="form-control"
                    name="number"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Occupation <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.occupation"
                    type="text"
                    class="form-control"
                    name="Occupation"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Birthday Date <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.birthday_date"
                    type="date"
                    class="form-control"
                    name="birthday_date"
                  />
                </div>
              </div>
            </div>
            <a
              class="btn btn-primary"
              @click.prevent="next('step-1', 'step-2')"
            >
              Next
            </a>
          </div>
          <div id="step-2" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <h5 class="text-uppercase">
                  <b
                    ><i>{{
                      form.user_type == 2
                        ? "Business Address"
                        : " Current Address"
                    }}</i></b
                  >
                </h5>

                <div class="form-group">
                  <label for=""
                    >House No: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.house_number_one"
                    type="text"
                    class="form-control"
                    name="house_number"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Road No: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.road_number_one"
                    type="text"
                    class="form-control"
                    name="road_number"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Float No: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.float_number_one"
                    type="text"
                    class="form-control"
                    name="float_no"
                  />
                </div>
                <div class="form-group">
                  <label for="">Area: <sup class="text-danger">*</sup></label>
                  <input
                    v-model="form.area_one"
                    type="text"
                    class="form-control"
                    name="area"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Post Code: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.post_code_one"
                    type="text"
                    class="form-control"
                    name="post_code"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >District <sup class="text-danger">*</sup></label
                  >
                  <select
                    v-if="districts && districts.length > 0"
                    v-model="form.district_one"
                    class="form-control"
                    name="district"
                    @change="districtWiseCity($event)"
                  >
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option
                      v-for="(district, didx) in districts"
                      :key="didx"
                      :value="district.id"
                    >
                      {{ district.name }}
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'district' }"
                    >Please Create District</router-link
                  >
                </div>
                <div
                  v-if="upazila_one && upazila_one.length > 0"
                  class="form-group"
                >
                  <label for="">Upazila <sup class="text-danger">*</sup></label>
                  <select
                    id=""
                    v-model="form.upazila_one"
                    class="form-control"
                    name="upazila_one"
                  >
                    <option selected disabled>
                      ------------Please Select One -----------
                    </option>
                    <option
                      v-for="(up, uidx) in upazila_one"
                      :key="uidx"
                      :value="up.id"
                    >
                      {{ up.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <h5 class="text-uppercase">
                      <b><i>Permanent Address:</i></b>
                    </h5>
                  </div>
                  <div class="col-lg-6">
                    <div>
                      <b-form-checkbox
                        v-model="sameAddress"
                        name="check-button"
                        switch
                      >
                        <small>As Like Current Address</small>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for=""
                    >House No: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.house_number_two"
                    type="text"
                    class="form-control"
                    name="house_number"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Road No: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.road_number_two"
                    type="text"
                    class="form-control"
                    name="road_number"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Float No: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.float_number_two"
                    type="text"
                    class="form-control"
                    name="float_no"
                  />
                </div>
                <div class="form-group">
                  <label for="">Area: <sup class="text-danger">*</sup></label>
                  <input
                    v-model="form.area_two"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for=""
                    >Post Code: <sup class="text-danger">*</sup></label
                  >
                  <input
                    v-model="form.post_code_two"
                    type="text"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label for=""
                    >District <sup class="text-danger">*</sup></label
                  >
                  <select
                    v-if="districts && districts.length > 0"
                    v-model="form.district_two"
                    class="form-control"
                    name="district"
                    @change="districtWiseCityTwo($event)"
                  >
                    <option value="" selected disabled>
                      ------Please Select One -----
                    </option>
                    <option
                      v-for="(district, didx) in districts"
                      :key="didx"
                      :value="district.id"
                    >
                      {{ district.name }}
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'district' }"
                    >Please Create District</router-link
                  >
                </div>
                <div
                  v-if="upazila_two && upazila_two.length > 0"
                  class="form-group"
                >
                  <label for="">Upazila</label>
                  <select
                    id=""
                    v-model="form.upazila_two"
                    class="form-control"
                    name="upazila_one"
                  >
                    <option selected disabled>
                      ------------Please Select One -----------
                    </option>
                    <option
                      v-for="(upa, uidx) in upazila_two"
                      :key="uidx"
                      :value="upa.id"
                    >
                      {{ upa.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <a
              class="btn btn-success"
              @click.prevent="previous('step-2', 'step-1')"
            >
              Previous
            </a>
            <a
              class="btn btn-primary"
              @click.prevent="next('step-2', 'step-3')"
            >
              Next
            </a>
          </div>
          <div id="step-3" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6 mt-1">
                <label for="">Zone <sup class="text-danger">*</sup></label>
                <select
                  v-if="zone && zone.length > 0"
                  v-model="form.zone"
                  name="zone"
                  class="form-control"
                >
                  <option value="" disabled>
                    --------Please Select One ---------
                  </option>
                  <option v-for="zon in zone" :key="zon.id" :value="zon.id">
                    {{ zon.name }}
                  </option>
                </select>
                <router-link v-else :to="{ name: 'zone' }"
                  >Please Create Zone</router-link
                >
              </div>
              <div class="col-lg-6 mt-1">
                <label for=""
                  >Ref Number <sup class="text-danger">*</sup></label
                >
                <div class="d-flex">
                  <input
                    :disabled="!edit_ref"
                    v-model="form.user_reference_number"
                    type="text"
                    class="form-control"
                    placeholder="Refrence Number"
                  />
                  <button
                    v-b-tooltip.top.v-danger
                    title="Make sure youn have to change?"
                    @click.prevent="edit_ref = !edit_ref"
                    class="btn btn-primary btn-sm"
                  >
                    <feather-icon
                      :icon="!edit_ref ? 'Edit3Icon' : 'ArrowRightIcon'"
                      size="16"
                    />
                  </button>
                </div>
              </div>
              <div class="col-lg-6 mt-1">
                <label for=""
                  >Registration Date: <sup class="text-danger">*</sup></label
                >
                <input
                  v-model="form.registration_date"
                  type="datetime-local"
                  value="today"
                  class="form-control"
                  placeholder="Registration Date"
                />
              </div>
              <div class="col-lg-6 mt-1">
                <label for=""
                  >Connection Date: <sup class="text-danger">*</sup></label
                >
                <input
                  v-model="form.connection_date"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Connection Date"
                />
              </div>
            </div>

            <div class="mt-1">
              <a
                class="btn btn-success"
                @click.prevent="previous('step-3', 'step-2')"
              >
                Previous
              </a>
              <a
                class="btn btn-primary"
                @click.prevent="next('step-3', 'step-4')"
              >
                Next
              </a>
            </div>
          </div>

          <div id="step-4" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Packge</label>
                  <select
                    v-if="InPackage && InPackage.length > 0"
                    v-model="form.package"
                    class="form-control"
                    name="packge"
                    @change="selectPackge"
                  >
                    <option value="" selected disabled>
                      --------Please Select One
                    </option>
                    <option
                      v-for="(pack, pidx) in InPackage"
                      :key="pidx"
                      :value="pack.id"
                    >
                      <span
                        >{{ pack.name }}
                        <span v-if="pack.synonym">{{
                          "-" + pack.synonym
                        }}</span>
                      </span>
                    </option>
                  </select>
                  <router-link v-else :to="{ name: 'addpackage' }"
                    >Please Create Packge</router-link
                  >
                </div>
                <div class="form-group">
                  <label for="user_payment_type">Connection Fee</label>
                  <input
                    @input="change_invoice_cal_item"
                    v-model="form.connection_fee"
                    type="number"
                    min="0"
                    placeholder="Number Only (Ex:00000)"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Monthly Bill</label>
                      <input
                        @input="change_invoice_cal_item"
                        disabled
                        v-model="form.monthly_bill"
                        type="text"
                        class="form-control"
                        placeholder="Montyly Bill"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Permanent Discount:</label>
                      <input
                        @input="change_invoice_cal_item"
                        v-model="form.discount"
                        type="number"
                        min="0"
                        class="form-control"
                        placeholder="Discount"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="border card-body p-0 py-1 px-1">
                      <h6 class="text-center" for="">Invoice Bill</h6>
                      <tr v-if="form.monthly_bill">
                        <td class="w-50">Monthly</td>
                        <td>:</td>
                        <td class="w-25">{{ form.monthly_bill }}</td>
                      </tr>
                      <tr v-if="form.connection_fee">
                        <td>Connection</td>
                        <td>:</td>
                        <td>{{ form.connection_fee }}</td>
                      </tr>
                      <tr v-if="form.discount">
                        <td>Discount</td>
                        <td>:</td>
                        <td>{{ form.discount }}</td>
                      </tr>
                      <tr
                        v-if="
                          form.discount ||
                          form.connection_fee ||
                          form.monthly_bill
                        "
                      >
                        <td colspan="5" class="saperator_border"></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Total</td>
                        <td class="font-weight-bold">:</td>
                        <td class="font-weight-bold">
                          {{ form.total_invoice_price }}
                        </td>
                      </tr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <div>
                    <label title="" for="user_payment_type"
                      >Collect Bill
                      <span class="text-success">(From Home)</span></label
                    >
                    <label :for="'is_home_collect'" class="__switch">
                      <input
                        @change="change_bill_collected"
                        id="is_home_collect"
                        v-model="form.is_home_collect"
                        type="checkbox"
                        @input="form.is_home_collect = !form.is_home_collect"
                        :checked="form.is_home_collect"
                      />
                      <span class="__slider" />
                    </label>
                  </div>
                  <div v-if="form.is_home_collect">
                    <label for="">Manager</label>
                    <v-select
                      v-model="form.collect_by"
                      :options="select_options_manager"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="">Send Billing Reminder:</label> <br />
                  <label for="">Sms</label>
                  <label :for="'sms_b'" class="__switch">
                    <input
                      id="sms_b"
                      v-model="form.send_sms"
                      type="checkbox"
                      @input="form.send_sms = !form.send_sms"
                      :checked="form.send_sms"
                    />
                    <span class="__slider" />
                  </label>
                  <label for="">Email</label>
                  <label
                    :for="'sms_e'"
                    class="__switch"
                    @click="form.send_email = !form.send_email"
                  >
                    <input
                      id="sms_e"
                      v-model="form.send_email"
                      type="checkbox"
                      @input="form.send_email = !form.send_email"
                      :checked="form.send_email"
                    />
                    <span class="__slider" />
                  </label>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="user_payment_type">User Payment Type</label>
                  <select
                    class="form-control"
                    v-model="form.user_payment_type"
                    id="user_payment_type"
                  >
                    <option value="">
                      --------Please Select One ---------
                    </option>
                    <option value="Prepaid">Prepaid</option>
                    <option value="Postpaid">Postpaid</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12">
                    <label for="">Bank Payment</label>
                    <select
                      v-model="bank_payment"
                      class="form-control"
                      name="bank_payment"
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div v-if="bank_payment == '1'" class="col-lg-12 mt-1">
                    <div class="form-group">
                      <label for="">Bank Name</label>
                      <input
                        v-model="form.bank_name"
                        type="text"
                        class="form-control"
                        placeholder="Bank Name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Name</label>
                      <input
                        v-model="form.bank_account_name"
                        type="text"
                        class="form-control"
                        placeholder="Bank Account Name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Number</label>
                      <input
                        v-model="form.bank_account_number"
                        type="text"
                        class="form-control"
                        placeholder="Bank Account Number"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Bank Account Branch: </label>
                      <input
                        v-model="form.bank_account_branch"
                        type="text"
                        class="form-control"
                        placeholder="Bank Account Branch"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <a
                class="btn btn-success"
                @click.prevent="previous('step-4', 'step-3')"
              >
                Previous
              </a>
              <a
                class="btn btn-primary"
                @click.prevent="next('step-4', 'step-5')"
              >
                Next
              </a>
            </div>
          </div>
          <div id="step-5" style="display: none" class="step">
            <div class="row">
              <div class="col-lg-6">
                <label for=""
                  >Connection Type: <sup class="text-danger">*</sup></label
                >
                <select
                  @change="change_con_type()"
                  v-model="form.connection_type"
                  class="form-control"
                  name="connection_type"
                >
                  <option value="" selected disabled>---Select Type--</option>
                  <option v-show="!is_queue" value="di_private">
                    Dynamic IP Private (PPPOE NAT)
                  </option>
                  <option v-show="!is_queue" value="di_public">
                    Dynamic IP Public(PPPOE)
                  </option>
                  <option v-show="!is_queue" value="si_private">
                    Static IP Private (PPPOE)
                  </option>
                  <option v-show="!is_queue" value="si_public">
                    Static IP Public (PPPOE)
                  </option>

                  <option value="si_private_queue">
                    Static IP Private (Queue)
                  </option>
                  <option value="si_public_queue">
                    Static IP Public (Queue)
                  </option>
                  <option value="si_m_b">Static IP MAC Bind (ARP)</option>
                </select>
              </div>
              <div class="col-lg-6">
                <div v-if="nas_type == 'MikroTik'">
                  <label for=""
                    >MikroTik <sup class="text-danger">*</sup></label
                  >
                  <select
                    disabled
                    v-if="mikrotiks"
                    v-model="form.mikrotik"
                    class="form-control"
                  >
                    <option value="">---------Please Select One -------</option>
                    <option
                      v-for="(mikrotik, midx) in mikrotiks"
                      :key="midx"
                      :value="mikrotik.id"
                    >
                      {{ mikrotik.identity + "-" + mikrotik.host }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              v-if="
                form.connection_type == 'di_private' ||
                form.connection_type == 'di_public' ||
                form.connection_type == 'si_private' ||
                form.connection_type == 'si_public'
              "
              class="row"
            >
              <div class="col-lg-6 mt-1">
                <label for="">User Name <sup class="text-danger">*</sup></label>
                <div class="from-group d-flex">
                  <input
                    title="Please Copy The Ref To Use Previous Name"
                    readonly
                    class="w-25 form-control"
                    :value="form.user_reference_number + '-'"
                  />
                  <input
                    v-model="form.username"
                    type="text"
                    class="w-75 form-control"
                    placeholder="User Name"
                  />
                </div>
              </div>
              <div class="col-lg-6 mt-1">
                <label for=""
                  >User Password <sup class="text-danger">*</sup></label
                >
                <input
                  v-model="form.userpassword"
                  type="text"
                  class="form-control"
                  placeholder="User Password"
                />
              </div>
            </div>
            <div class="row">
              <div
                v-if="
                  form.connection_type == 'di_private' ||
                  form.connection_type == 'di_public' ||
                  form.connection_type == 'si_private' ||
                  form.connection_type == 'si_public'
                "
                class="col-lg-6 mt-1"
              >
                <label for="Service">Service</label>
                <select
                  v-model="form.service"
                  id="Service"
                  class="form-control"
                >
                  <option value="oVPN">oVPN</option>
                  <option value="PPPoE">PPPoE</option>
                  <option value="PPtP">PPtP</option>
                </select>
              </div>
              <div
                v-if="
                  form.connection_type == 'si_public' ||
                  form.connection_type == 'si_private'
                "
                class="col-lg-6 my-1"
              >
                <label for="">Remote Address</label>
                <div class="d-flex">
                  <input
                    v-model="form.remote_address"
                    type="text"
                    class="form-control"
                    placeholder="Remote Address"
                  />
                  <button
                    @click.prevent="see_remote_address()"
                    class="btn btn-primary btn-sm"
                  >
                    <feather-icon icon="EyeIcon" size="16" />
                  </button>
                </div>
              </div>

              <div
                v-if="
                  form.connection_type == 'si_private_queue' ||
                  form.connection_type == 'si_m_b' ||
                  form.connection_type == 'si_public_queue'
                "
                class="col-lg-6"
              >
                <label class="mt-1 mr-2" for="queues">Queues</label>
                <div class="d-flex">
                  <select
                    @change="change_queue_name"
                    class="form-control"
                    v-model="form.queue_name"
                    id=""
                  >
                    <!-- let data = this.items.find((x) => x.id === id); -->
                    <option value="">---Select--</option>
                    <option
                      v-for="queue_item in queue_data"
                      :key="queue_item.id"
                      :value="queue_item.name"
                    >
                      {{ queue_item.name }}
                    </option>
                  </select>
                  <button
                    @click.prevent="modelAction('add_simple_queue')"
                    class="btn btn-primary btn-sm"
                  >
                    <feather-icon icon="PlusCircleIcon" size="16" />
                  </button>
                </div>
              </div>
              <div
                v-if="
                  form.connection_type == 'si_private_queue' ||
                  form.connection_type == 'si_m_b' ||
                  form.connection_type == 'si_public_queue'
                "
                class="col-lg-6 my-1"
              >
                <label for="target_address">Target Address</label>
                <div class="d-flex">
                  <input
                    v-model="form.target_address"
                    type="text"
                    class="form-control"
                    placeholder="Ex: 192.168.0.1"
                    id="target_address"
                  />
                  <button
                    @click.prevent="modelAction('target_address')"
                    class="btn btn-primary btn-sm"
                  >
                    <feather-icon icon="EyeIcon" size="16" />
                  </button>
                </div>
              </div>
              <div
                v-if="
                  form.connection_type == 'si_private_queue' ||
                  form.connection_type == 'si_m_b' ||
                  form.connection_type == 'si_public_queue'
                "
                class="col-lg-6 my-1"
              >
                <label for="destination">Destination (Interfaces)</label>
                <select
                  v-model="form.destination"
                  class="form-control"
                  id="destination"
                >
                  <option value="">
                    ---Select Destination (Interfaces)----
                  </option>
                  <option
                    v-for="inf in interfaces"
                    :key="inf.id"
                    :value="inf.name"
                  >
                    {{ inf.name }}
                  </option>
                </select>
              </div>
              <div
                v-if="
                  form.connection_type == 'si_private_queue' ||
                  form.connection_type == 'si_m_b' ||
                  form.connection_type == 'si_public_queue'
                "
                class="col-lg-6 my-1"
              >
                <label for="queue_type">Queue Type (PCQ)</label>
                <div class="d-flex">
                  <select
                    disabled
                    v-model="form.queue"
                    class="form-control"
                    id="queue_type"
                  >
                    <option value="">---Select Queue Type----</option>
                    <option
                      v-for="q_t in queue_types"
                      :key="q_t.id"
                      :value="`${q_t.name}/${q_t.name}`"
                    >
                      {{ q_t.name }}
                    </option>
                  </select>
                  <button
                    @click.prevent="modelAction('add_queue_type')"
                    v-b-modal="'add_queue-modal'"
                    class="btn btn-primary btn-sm"
                  >
                    <feather-icon icon="PlusCircleIcon" size="16" />
                  </button>
                </div>
              </div>
              <div
                v-if="
                  form.connection_type == 'si_public' ||
                  form.connection_type == 'si_m_b' ||
                  form.connection_type == 'si_private'
                "
                class="col-lg-6 mt-1"
              >
                <!-- form.connection_type == 'si_m_b' || -->
                <label for="">MAC Address</label>
                <input
                  v-model="form.mac_address"
                  type="text"
                  class="form-control"
                  placeholder="EX: 00:00:00:00:00:00"
                />
              </div>
              <div class="col-lg-6 mt-1">
                <label for="">Router Comments</label>
                <input
                  v-if="
                    form.connection_type == 'si_private_queue' ||
                    form.connection_type == 'si_m_b' ||
                    form.connection_type == 'si_public_queue'
                  "
                  disabled
                  v-model="form.router_component"
                  type="text"
                  class="form-control"
                  placeholder="Router Comments"
                />
                <input
                  v-else
                  v-model="form.router_component"
                  type="text"
                  class="form-control"
                  placeholder="Router Comments"
                />
              </div>
              <div class="col-lg-6 mt-1">
                <div class="form-group">
                  <label for="expire_date"
                    >Custom Expire Date
                    <feather-icon
                      v-b-tooltip.hover.v-primary
                      title="User will be expired on this day every month"
                      variant="outline-primary"
                      icon="InfoIcon"
                      size="1.5x"
                    />
                  </label>
                  <input
                    id="expire_date"
                    min="1"
                    v-model="form.expire_date"
                    type="datetime-local"
                    class="form-control"
                    placeholder="Expire Date"
                  />
                </div>
              </div>
            </div>
            <div class="mt-1">
              <a
                class="btn btn-success"
                @click.prevent="previous('step-5', 'step-4')"
              >
                Previous
              </a>
              <button class="btn btn-primary" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-modal
      id="remote_adress-modal"
      :title="modal_title"
      size="xl"
      hide-header-close
      hide-footer
    >
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Address</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ldata in listModalData" :key="ldata.id">
            <td>{{ ldata.id }}</td>
            <td>{{ ldata.address }}</td>
            <td>
              <span v-if="ldata.status" class="badge badge-warning"
                >Already Used</span
              >
              <span v-else class="badge badge-info">Ready to Use</span>
            </td>
            <td class="d-flex">
              <button
                @click="copyData(ldata.address)"
                class="btn btn-info btn-sm"
              >
                <feather-icon icon="CopyIcon" size="1.5x" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="close" class="btn btn-warning">Close</button>
    </b-modal>
    <b-modal
      id="add_queue_modal"
      title="Add Queue"
      size="md"
      hide-header-close
      hide-footer
    >
      <form action="">
        <div class="form-group">
          <label for="">MikroTik <sup class="text-danger">*</sup></label>
          <select v-if="mikrotiks" v-model="form.mikrotik" class="form-control">
            <option value="">---------Please Select One -------</option>
            <option
              v-for="(mikrotik, midx) in mikrotiks"
              :key="midx"
              :value="mikrotik.id"
            >
              {{ mikrotik.identity + "-" + mikrotik.host }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            name=""
            class="form-control"
            placeholder="Name"
            id="name"
          />
        </div>
        <div class="form-group">
          <label for="target">Target</label>
          <input
            type="text"
            class="form-control"
            placeholder="Target"
            id="target"
          />
        </div>
        <div class="form-group">
          <label for="dst">Dst</label>
          <input type="text" class="form-control" placeholder="Dst" id="dst" />
        </div>
        <div class="form-group">
          <label for="max-limit">Max Limit</label>
          <input
            type="text"
            class="form-control"
            placeholder="max-limit"
            id="max-limit"
          />
        </div>
        <div class="form-group">
          <label for="burst-limit">Burst Limit</label>
          <input
            type="text"
            class="form-control"
            placeholder="Burst Limit"
            id="burst-limit"
          />
        </div>
        <div class="form-group">
          <label for="comment">Comment</label>
          <input
            type="text"
            class="form-control"
            placeholder="Comment"
            id="comment"
          />
        </div>
      </form>
      <button @click="close" class="btn btn-warning">Close</button>
      <button
        @click.prevent="submitHandelar('save_queue_type')"
        class="btn btn-primary"
      >
        Save
      </button>
    </b-modal>

    <b-modal
      id="modal-lg"
      :title="`${modal_title} IP Address`"
      size="md"
      hide-header-close
      hide-footer
    >
      <form action="">
        <div class="form-group">
          <label for="">MikroTik <sup class="text-danger">*</sup></label>
          <select v-if="mikrotiks" v-model="form.mikrotik" class="form-control">
            <option value="">---------Please Select One -------</option>
            <option
              v-for="(mikrotik, midx) in mikrotiks"
              :key="midx"
              :value="mikrotik.id"
            >
              {{ mikrotik.identity + "-" + mikrotik.host }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="name">Name</label>
          <input
            v-model="ipform.name"
            type="text"
            class="form-control"
            placeholder="Name"
            id="name"
          />
        </div>
        <div class="form-group">
          <label for="address">Ip Address</label>
          <input
            v-model="ipform.address"
            type="text"
            class="form-control"
            placeholder="Ip Address"
            id="address"
          />
        </div>
        <div class="form-group">
          <label for="network">Network</label>
          <input
            v-model="ipform.network"
            type="text"
            class="form-control"
            placeholder="Network"
            id="network"
          />
        </div>
        <div class="form-group">
          <label for="interface">Interface</label>
          <select v-model="ipform.interface" class="form-control" id="">
            <option value="">---Select One--</option>
            <option
              v-for="intItem in interfaces"
              :key="intItem.id"
              :value="intItem.name"
            >
              {{ intItem.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="interface">Total Ip</label>
          <input
            v-model="ipform.total_ip"
            type="number"
            class="form-control"
            min="1"
            placeholder="Total Ip"
            id="total_ip"
          />
        </div>
        <div class="form-group">
          <label for="comment">Comment</label>
          <textarea
            class="form-control"
            name=""
            v-model="ipform.comment"
            id="comment"
            placeholder="Comment"
          ></textarea>
        </div>
      </form>
      <button @click="close" class="btn btn-warning">Close</button>
      <button
        @click.prevent="submitHandelar('save_ip_address')"
        class="btn btn-primary"
      >
        Save
      </button>
    </b-modal>
    <AddQueue />
  </div>
</template>
<script>
import Form from "vform";
import { BFormCheckbox } from "bootstrap-vue";
import router from "@/router";
import axios from "axios";
import AddQueue from "../Queue/AddQueue.vue";
export default {
  name: "EditImportUser",
  components: {
    BFormCheckbox,
    AddQueue,
  },

  data() {
    return {
      form: new Form({
        name: "Fahim Muntasir",
        user_type: "Home",
        email: "fahimmbpi@gmail.com",
        phone: "01795297424",
        father_name: "Iqbal Hussen",
        mother_name: "Taslima Begum",
        gender: "male",
        identification_reference: "NID",
        identification_reference_number: "Hello",
        occupation: "Programmer",
        birthday_date: "1999-01-10",
        house_number_one: "10",
        area_one: "10",
        float_number_one: "10",
        road_number_one: "10",
        post_code_one: "10",
        district_one: "",
        district_two: "",
        upazila_one: "",
        upazila_two: "",

        house_number_two: "10",
        area_two: "",
        float_number_two: "",
        road_number_two: "",
        post_code_two: "",

        user_reference_number: null,
        zone: "2",
        registration_date: "",
        connection_date: "",
        connection_type: "",

        package: "",
        monthly_bill: "",
        send_sms: false,
        send_email: true,

        bank_name: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_branch: "",

        mikrotik: "",
        username: "",
        userpassword: "",
        service: "PPPoE",
        remote_address: "",
        mac_address: "",
        remote_ip: "",
        router_component: "",
        expire_date: "",
        user_payment_type: "Prepaid",
        queue_name: "",
        queue: "",
        destination: "",
        target_address: "",
        distanitaion: "",
        queue_type: "",
        queue_download: "",
        download_priority: "",
        queue_upload: "",
        priority_upload: "",
        connection_fee: "",
        total_invoice_price: "",
        discount: "",
        is_home_collect: false,
        collect_by: "",
        import_item_id: null,
      }),
      nas_type: "", //MikroTik Radius MikroTik+Radius
      user_types: [
        { type: "Home User", value: "Home" },
        { type: "Corporate User", value: "Corporate" },
      ],

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "",
      step: {
        one: false,
        second: true,
      },
      district_select_options: {
        options: [],
      },

      selected_distric: {
        code: "",
        label: "-------Please Select One-----------",
      },
      sameAddress: false,
      bank_payment: 0,
      upazila_one: [],
      upazila_two: [],
      remot_address_items: null,
      remot_address_start_ip: null,
      remot_address_end_ip: null,
      poll_items: null,
      interfaces: [],
      queue_types: [],
      queue_data: [],
      listModalData: [],
      copyFor: "",
      is_queue: true,
      pkg_info: null,
      ipform: new Form({
        name: "",
        mikrotik: "",
        address: "",
        network: "",
        interface: "",
        total_ip: "",
        comment: "",
      }),
      queueForm: new Form({
        name: "",
        mikrotik: "",
        queue_kind: "",
        rate: "",
        burst_rate: "",
        burst_threshold: "",
        burst_time: "",
        src_address: "",
        dst_address: "",
        type: "",
      }),
      allmacAddress: null,
      all_remote_address: null,
      edit_ref: false,
      select_options_manager: [],
    };
  },
  computed: {
    districts() {
      return this.$store.state.isp.others.districts;
    },
    user_infos() {
      return this.$store.state.isp.user.info;
    },
    zone() {
      return this.$store.state.isp.others.zone;
    },
    InPackage() {
      return this.$store.state.isp.others.package;
    },
    mikrotiks() {
      return this.$store.state.isp.others.mikrotik;
    },
  },
  watch: {
    items() {
      this.loading = false;
    },

    sameAddress(value) {
      if (value) {
        this.form.house_number_two = this.form.house_number_one;
        this.form.area_two = this.form.area_one;
        this.form.float_number_two = this.form.float_number_one;
        this.form.road_number_two = this.form.road_number_one;
        this.form.post_code_two = this.form.post_code_one;
      } else {
        this.form.house_number_two = "";
        this.form.area_two = "";
        this.form.float_number_two = "";
        this.form.road_number_two = "";
        this.form.post_code_two = "";
      }
      console.log(value);
    },
  },
  created() {
    this.getData();
    this.$store.dispatch("others", {
      zone: true,
      mikrotik: true,
      user_reference_number: true,
      package: true,
      districts: true,
    });
  },

  methods: {
    getData() {
      axios.get(`customerimport/${this.$route.params.id}`).then((resp) => {
        this.form.import_item_id = this.$route.params.id;
        console.log("resp", resp.data.data);
        this.loading = false;
        if (resp.data.success) {
          this.form.name = resp.data.data.Name;
          this.form.email = resp.data.data.Email;
          this.form.phone = resp.data.data.Mobile;
          this.form.birthday_date = resp.data.data.Date_of_birth;
          this.form.package = resp.data.data.Package_id;
          // this.form. = resp.data.data.Billing_status;
          // this.form.user_type = resp.data.data.Connctinon_type;
          // this.form = resp.data.data.Customer_type;
          // this.form = resp.data.data.Expire_date;
          // this.form = resp.data.data.Monthly_bill;
          // this.form = resp.data.data.Nationalid;
          // this.form = resp.data.data.Package_discount;
          // this.form = resp.data.data.Package_id;
          // this.form = resp.data.data.Package_price;
          // this.form = resp.data.data.Payment_type;
          // this.form = resp.data.data.Zone;
          // this.form = resp.data.data.connection_date;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    change_invoice_cal_item() {
      const connection_fee = parseInt(
        this.form.connection_fee ? this.form.connection_fee : 0
      );
      const monthly_bill = parseInt(
        this.form.monthly_bill ? this.form.monthly_bill : 0
      );
      const discount = parseInt(this.form.discount ? this.form.discount : 0);
      this.form.total_invoice_price = connection_fee + monthly_bill - discount;
    },
    get_softwer_system() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("software-system").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.nas_type = resp.data.data.nas_type;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    next(current, next) {
      this.form.user_reference_number =
        parseInt(this.$store.state.isp.others.user_reference_number) + 1;
      if (next == "step-5") {
        this.get_softwer_system();
        if (this.nas_type == "MikroTik") {
        }
      }
      const currentElement = document.getElementById(current);
      const nextElement = document.getElementById(next);
      if (this.$validation(current) === true) {
        currentElement.style.display = "none";
        nextElement.style.display = "block";
        document.getElementsByClassName(next)[0].classList.add("active");
      }
    },
    previous(current, previous) {
      const currentElement = document.getElementById(current);
      const previousElement = document.getElementById(previous);
      currentElement.style.display = "none";
      previousElement.style.display = "block";
    },

    districtWiseCity(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_one = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    districtWiseCityTwo(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_two = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    selectPackge(e) {
      const pkg = this.InPackage.find(
        (ele) => ele.id.toString() === e.target.value
      );
      this.is_queue = pkg.queue_id ? true : false;
      this.form.monthly_bill = pkg.price;
      this.form.mikrotik = pkg.mikrotik_id;
      this.pkg_info = pkg;
      this.change_invoice_cal_item();
    },

    save() {
      if (this.$validation("step-5") === true) {
        this.form.post("customer").then((resp) => {
          if (resp.data.success) {
            this.$success_message(resp.data.message);
            if (this.form.user_type == "Home")
              router.push({ name: "homeCustomer" });
            if (this.form.user_type == "Corporate")
              router.push({ name: "corporateCustomer" });
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        });
      }
    },
    async get_all_remote_address() {
      await axios.get("all_remote_address").then((resp) => {
        if (resp.data.success) {
          this.all_remote_address = resp.data.data;
        }
      });
    },
    async see_remote_address(page = 1) {
      this.$store.dispatch("spinnerLoading", true);
      await this.get_all_remote_address();
      await axios.get(`pool?page=${page}`).then((resp) => {
        this.poll_items = null;
        if (resp.data.success) {
          this.poll_items = resp.data.data;
          this.$store.dispatch("spinnerLoading", false);
          const options = {};
          resp.data.data.forEach((element) => {
            options[element.id] = element.name;
          });
          this.Swal.fire({
            title: "Please Select A Ip Pool",
            input: "select",
            inputOptions: options,
            inputPlaceholder: "Select One",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              const find_item = this.poll_items.find(
                (item) => item.id == result.value
              );
              if (find_item) {
                const number = find_item.start_ip.split(".");
                const start_number = number.pop();
                const end_number = find_item.end_ip.split(".").pop();
                this.listModalData = [];
                for (let i = start_number; i < end_number; i++) {
                  const address =
                    number[0] + "." + number[1] + "." + number[2] + "." + i;
                  const checked_status = this.all_remote_address.find(
                    (ele) => ele.remote_address == address
                  );
                  let status = checked_status ? true : false;
                  this.listModalData.push({
                    status: status,
                    address: address,
                  });
                }
                this.copyFor = "remote_address";
                this.modal_title = "remote adress";
                this.$bvModal.show("remote_adress-modal");
              } else {
                this.$error_message("Data Not Found");
              }
            }
          });
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    close() {
      this.$bvModal.hide("remote_adress-modal");
      this.$bvModal.hide("modal-lg");
      this.$bvModal.hide("add_queue_modal");
    },
    //= ===handle the form submit=========
    submitHandelar(val = null) {
      if (val == "save_queue_type") {
        this.savequeue();
      } else if (val == "save_ip_address") {
        this.saveIp();
      }
      this.$bvModal.hide("modal-lg");
    },

    //= ========data save=========
    savequeue() {
      this.queueForm
        .post("queue-type")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //= ========data save=========
    saveIp() {
      this.ipform
        .post("queue-type")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //= ========data save=========
    savequeue() {
      this.form
        .post("ipaddresses")
        .then((resp) => {
          if (resp.data.success) {
            this.$bvModal.hide("modal-lg");
            this.getData();
            this.$success_message(resp.data.message);
          } else {
            this.$error_message(resp.data.message, resp.data.errors);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getImterfaces() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("interface-withselect").then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.interfaces = resp.data.data;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getqueueType() {
      axios.get("queuetype/withselect").then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          this.queue_types = resp.data.data;
          console.log(resp.data);
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    getqueue() {
      axios
        .get(`queue_all_mikrotik?mikrotik=${this.form.mikrotik}`)
        .then((resp) => {
          if (resp.data.success) {
            console.log(resp.data.data);
            this.$store.dispatch("spinnerLoading", false);
            this.queue_data = resp.data.data;
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },
    change_con_type() {
      if (
        this.form.connection_type == "si_m_b" ||
        this.form.connection_type == "si_public_queue" ||
        this.form.connection_type == "si_private_queue"
      ) {
        this.form.service =
          this.form.connection_type == "si_m_b"
            ? "ARP"
            : this.form.connection_type == "si_public_queue"
            ? "Public Queue"
            : this.form.connection_type == "si_private_queue"
            ? "Private Queue"
            : this.form.service;

        this.getqueueType();
        this.getqueue();
        this.form.queue_name = this.pkg_info.queue_id;
        // this.form.service = ''
      }
    },
    change_queue_name() {
      const data = this.queue_data.find(
        (el) => el.name == this.form.queue_name
      );
      this.getImterfaces();
      this.form.destination = data.dst;
      this.form.queue = data.queue_type;
      this.form.router_component = data.comment;
    },
    getImterfaces() {
      axios
        .get(`interface-withselect?mikrotik=${this.form.mikrotik}`)
        .then((resp) => {
          if (resp.data.success) {
            this.$store.dispatch("spinnerLoading", false);
            this.interfaces = resp.data.data;
          } else {
            this.$error_message(resp.data.message);
          }
        });
    },

    async getCInfoMacAddress() {
      await axios.get("all_remote_ip_address").then((resp) => {
        if (resp.data.success) {
          this.allmacAddress = resp.data.data;
        }
      });
    },

    async getIpAddress() {
      await this.getCInfoMacAddress();
      await axios.get("ipaddresses_all").then((resp) => {
        if (resp.data.success) {
          this.$store.dispatch("spinnerLoading", false);
          // this.listModalData = resp.data.data;
          this.listModalData = [];
          resp.data.data.forEach((el) => {
            console.log("el.address", el);
            const number = el.address.split(".");
            const last_item = number.pop();
            const last_address_item = last_item.split("/");
            const last_address = last_address_item[0];

            let range =
              last_address_item[1] == 9
                ? 8388606
                : last_address_item[1] == 10
                ? 4194302
                : last_address_item[1] == 11
                ? 2097150
                : last_address_item[1] == 12
                ? 1048574
                : last_address_item[1] == 13
                ? 524286
                : last_address_item[1] == 14
                ? 262142
                : last_address_item[1] == 15
                ? 131070
                : last_address_item[1] == 16
                ? 65534
                : last_address_item[1] == 17
                ? 32766
                : last_address_item[1] == 18
                ? 16382
                : last_address_item[1] == 19
                ? 8190
                : last_address_item[1] == 20
                ? 4094
                : last_address_item[1] == 21
                ? 2046
                : last_address_item[1] == 22
                ? 1022
                : last_address_item[1] == 23
                ? 210
                : last_address_item[1] == 24
                ? 254
                : last_address_item[1] == 25
                ? 126
                : last_address_item[1] == 26
                ? 62
                : last_address_item[1] == 27
                ? 30
                : last_address_item[1] == 28
                ? 14
                : last_address_item[1] == 29
                ? 6
                : last_address_item[1] == 30
                ? 2
                : last_address_item[1] == 31
                ? 2
                : 0;

            for (let i = 0; i < range + 2; i++) {
              const num = parseInt(last_address);
              const tmum = num + i;
              const address =
                number[0] + "." + number[1] + "." + number[2] + "." + tmum;

              console.log("this.allmacAddress", this.allmacAddress);
              const checked_status = this.allmacAddress.find(
                (ele) => ele.remote_ip == address
              );
              let status = checked_status ? true : false;
              this.listModalData.push({
                status: status,
                address: address,
              });
            }
          });
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    modelAction(val = null) {
      if (val == "add_simple_queue") {
        this.editData.mode = false;
        this.getImterfaces();
        this.$store.dispatch("getallmikrotiks");
        this.$bvModal.show("simple_queue_modal");
      } else if (val == "target_address") {
        this.copyFor = "target_address";
        this.modal_title = "Target Address";
        this.getIpAddress();
        this.$bvModal.show("remote_adress-modal");
      } else if (val == "remote_address") {
        this.copyFor = "remote_address";
        this.modal_title = "Remote Address";
        this.getIpAddress();
        this.$bvModal.show("remote_adress-modal");
      } else if (val == "add_queue") {
        this.$bvModal.show("add_queue_modal");
      }
    },
    copyData(val) {
      if (this.copyFor == "target_address") {
        this.form.remote_ip = val;
        this.form.target_address = val;
      } else if (this.copyFor == "remote_address") {
        this.form.remote_address = val;
        this.poll_items = null;
      }
      this.copyFor == "";
      this.listModalData = null;
      this.$bvModal.hide("remote_adress-modal");
    },
    change_bill_collected() {
      if (this.form.is_home_collect) {
        this.select_options_manager = [];
        this.$store.dispatch("spinnerLoading", true);
        this.axios.get("user").then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {
            resp.data.data.forEach((ele) => {
              const obj = {
                code: ele.id,
                label: `${ele.name}`,
              };
              this.select_options_manager.push(obj);
            });
            //  console.log("Users " + JSON.stringify(this.users))
          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
          this.select_options_queue.push(obj);
        });
      }
    },
  },
};
</script>
<style>
sup {
  font-size: 16px !important;
  font-weight: bold;
}

.headline {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  border-bottom: 2px solid #eee;
}

.headline li {
  display: block;
  align-items: center;
  color: #423636;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.headline li span.active {
  border: 4px solid #968df4;
  color: #fff !important;
  border: 4px solid #968df4 !important;
}

.headline li span {
  /* background: #eee; */
  display: block;
  width: 60px;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  padding: 12px 0px;
  font-size: 25px;
  font-weight: 600;
  color: #000;
  border: 4px solid #eee;
  z-index: 9999999;
  background: #fff;
}

.headline li.active span {
  background: #968df4;
  color: #fff !important;
}

input.form-control.invalid {
  border-color: red;
  margin-bottom: 3px;
}

span.valdation-msg {
  color: red;
  font-style: italic;
  font-size: 12px;
}

span.active {
  background: #968df4 !important;
  color: #fff !important;
  border: 4px solid #968df4 !important;
}

.headline li:after {
  position: absolute;
  content: "";
  /* right: 0; */
  width: 75%;
  background: #ddd;
  height: 6px;
  top: 34%;
  left: 32%;
}

/* .headline li.active:after {
  background: #968df4 !important;
} */

/* .headline li.active:last-child::after {
  background: #968df4 !important;
} */
.headline li:last-child::after {
  display: none;
}

.row.content-header {
  display: none;
}

.saperator_border {
  content: "";
  border-top: 1px solid;
}
</style>
