<template>
	<div>
		<h5 class="text-center">Customer Info ({{this.type}})</h5>
		<DynamicForm
			:no_submit="true"
			:form="form[type]"
			type="create"
			:fields="dynamicFormFields"
		/>
	</div>
</template>

<script>
import DynamicForm from "/src/components/DynamicForm";
import axios from "axios";
export default {
	name: "ConnectorCustomer",
	components: { DynamicForm },
	props: {
		"type" : String,
		"id" : Number,
		"form" : Object
	},
	data() {
	  return {
			customers: [],
	  		fiber_cores: [],
	  }
	},
	computed: {
		dynamicFormFields(){
	  	const formFields = [
			{
				'type': 'select',
				'name': 'customer_id',
				'label': 'Customer',
				'options': this.customers,
				'option_settings': {
					'title': 'name',
					'value': 'id',
				},
				'disabled': true
			},
			{
				'type': 'text',
				'name': 'dbm',
				'label': 'dBM',
			},
			{
				'type': 'textarea',
				'name': 'comment',
				'label': 'Comment',
			},
		]

		return formFields
	  }
	},
	mounted(){
		this.form[this.type] = {
	        customer_id: this.id,
	        dbm: null,
	        comment: null,
	    }

	    this.form[this.type][`connected_${this.type}_type`] = 'customers'

		this.getCustomers()
	},
	methods: {
		
		getCustomers() {
		  axios.get(`nm-customers?page=1&item=all`).then((resp) => {
		    if (resp.data.success) {
		      this.customers = resp.data.data.data;

		    } else {
		      
		    }
		  }).finally(()=> {
		    
		  });
		},
	}
}
</script>