<template>
    <b-modal id="remote_adress-modal" :title="modal_title" size="xl" hide-header-close hide-footer>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Address</th>
                    <th scope="col">Status</th>
                    <th scope="col" class="text-center">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="ldata, index in listModalData" :key="index + 'ind'">
                    <td>{{ index + 1 }}</td>
                    <td>{{ ldata.address }}</td>
                    <td>
                        <span v-if="ldata.status" class="badge badge-warning">Already Used</span>
                        <span v-else class="badge badge-info">Ready to Use</span>
                    </td>
                    <td class="d-flex">
                        <button @click.prevent="assign_remot_ip(ldata.address)" class="btn btn-info btn-sm">
                            <feather-icon icon="CopyIcon" size="1.5x" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button @click="close" class="btn btn-warning">Close</button>
    </b-modal>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            user_id: null,
            modal_title: null,
            poll_items: null,
            copyFor: null,
            listModalData: null
        }
    },
    methods: {
        // get_all_remote_address
        async get_all_remote_address() {
            await axios.get("all_remote_address").then((resp) => {
                if (resp.data.success) {
                    this.all_remote_address = resp.data.data;
                }
            });
        },
        // see_remote_address
        async see_remote_address(user_id = null, page = 1) {
            this.user_id = user_id
            this.$store.dispatch("spinnerLoading", true);
            await this.get_all_remote_address();
            await axios.get(`pool?page=${page}`).then((resp) => {
                this.poll_items = null;
                if (resp.data.success) {
                    this.poll_items = resp.data.data;
                    this.$store.dispatch("spinnerLoading", false);
                    const options = {};
                    resp.data.data.forEach((element) => {
                        options[element.id] = element.name;
                    });
                    this.Swal.fire({
                        title: "Please Select A Ip Pool",
                        input: "select",
                        inputOptions: options,
                        inputPlaceholder: "Select One",
                        showCancelButton: true,
                    }).then((result) => {
                        if (result.value) {
                            const find_item = this.poll_items.find(
                                (item) => item.id == result.value
                            );
                            if (find_item) {
                                const number = find_item.start_ip.split(".");
                                const start_number = number.pop();
                                const end_number = find_item.end_ip.split(".").pop();
                                this.listModalData = [];
                                for (let i = start_number; i < end_number; i++) {
                                    const address =
                                        number[0] + "." + number[1] + "." + number[2] + "." + i;
                                    const checked_status = this.all_remote_address.find(
                                        (ele) => ele.remote_address == address
                                    );
                                    let status = checked_status ? true : false;
                                    this.listModalData.push({
                                        status: status,
                                        address: address,
                                    });
                                }
                                this.copyFor = "remote_address";
                                this.modal_title = "Remote Address";
                                this.$bvModal.show("remote_adress-modal");
                            } else {
                                this.$error_message("Data Not Found");
                            }
                        }
                    });
                } else {
                    this.$error_message(resp.data.message);
                }
            });
        },

        async assign_remot_ip(ip) {
            console.log(ip, this.user_id);
            await this.Swal.fire({
                title: "Are you sure?",
                text: `You won't to assing ip ${ip} in this user`,
                icon: "warning",
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.axios.post(`update-dynamic-table`, {
                        table: 'user_connection_infos',
                        querycolumn: 'user_id',
                        id: this.user_id,
                        update_table: 'remote_address',
                        update_value: ip,
                        extra_action: 'add_radreplay_table_data',
                    }).then((resp) => {
                        if (resp.data.success) {
                            this.$s_tost(resp.data.message)
                        } else {
                            this.$error_message(resp.data.message, resp.data.errors)
                        }
                    })
                }
            })
        },
        // close modal 
        close() {
            this.$bvModal.hide("remote_adress-modal");
        },
    },
}
</script>